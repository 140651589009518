import React, { useContext, useEffect, useState } from "react";
import { Autocomplete, Box, Typography } from "@mui/material";
import { colors, shadow } from "../../../styles/theme.tsx";
import { ColorButton } from "../../../components/Button.tsx";
import "../../../index.css";
import InputField from "../../../components/InputField.tsx";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid2";
import { IFinantialInformation_selects } from "../../../constants/types.tsx";
import { getValueStorage, saveValueStorage } from "../../../common/storage.ts";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { envProps } from "../../../common/envProps.ts";
import { SVG_pointer } from "../../../assets/svg/MapIcons.ts";
import LocationPin from "../../../components/LocationPin.tsx";
import { requestSquematic } from "../../../common/CommonFunctions.tsx";
import { errorAlert } from "../../../common/alerts.tsx";
import { useTranslation } from "react-i18next";
import { yupContactValidations } from "../../../common/validations/yupContactValidations.ts";
import { IRegisterStepState } from "../RegisterComponent.tsx";

export default function ContactInformation({ setRegisterStep }: IRegisterStepState) {
  const { t } = useTranslation();
  const { getUser, setIsLoading } = useContext(AuthContext);
  const [viewMap, setViewMap] = useState(false);
  const [houseTenure, setHouseTenure] = useState<any[]>([]);
  const [houseType, setHouseType] = useState<any[]>([]);

  const [departmentList, setDepartmentList] = useState<any[]>([]);
  const [selectedDepartm, setSelectedDepartm] = useState<any | null>(null);

  const [cityList, setCityList] = useState<any[]>([]);
  const [selectedCity, setSelectedCity] = useState<any | null>(null);

  const [neighList, setNeighList] = useState<any[]>([]);
  const [selectedNeigh, setSelectedNeigh] = useState<any | null>(null);

  useEffect(() => {
    (async () => {
      saveValueStorage("authSession", {
        ...getValueStorage("authSession"),
        getLocation: {
          latitude: undefined,
          longitude: undefined,
        },
      });
      setIsLoading(true);
      fetchDepartments();

      const res: IFinantialInformation_selects = await requestSquematic(
        "GET",
        "/api/app/financial_data/get_selects",
        {},
        getUser.token
      );

      if (res) {
        setHouseTenure(
          res.results!.housing_category.map((item: any) => ({
            id: item.c129_rowid,
            value: item.c129_descripcion,
          }))
        );
        setHouseType(
          res.results!.housing_type.map((item: any) => ({
            id: item.c132_rowid,
            value: item.c132_descripcion,
          }))
        );
      }
    })();
  }, []);

  const fetchDepartments = async () => {
    setIsLoading(true);
    const data = await requestSquematic(
      "GET",
      "/api/panel/geographic_location/get_departments",
      {
        c102_id_pais: "169",
      },
      getUser.token
    );

    if (data && data.results) {
      setDepartmentList(
        data
          .results!.map((departments: any) => ({
            id: departments.c102_id,
            value: departments.c102_descripcion,
          }))
          .sort((a: any, b: any) => a.value.localeCompare(b.value))
      );
    }
    setIsLoading(false);
  };

  const fetchCities = async (departmentId: string) => {
    formik.setFieldValue("department", departmentId);
    setIsLoading(true);

    const data = await requestSquematic(
      "GET",
      "/api/panel/geographic_location/get_cities",
      {
        c103_id_pais: "169",
        c103_id_depto: departmentId,
      },
      getUser.token
    );

    if (data) {
      if (data.results.length === 0) {
        // No hay ciudades disponibles
        setCityList([]);
      } else {
        setCityList(
          data.results!.map((city: any) => ({
            id: city.c103_id,
            value: city.c103_descripcion,
          }))
        );
      }
    }

    setIsLoading(false);
  };

  const fetchBarrios = async (ciudadId: string) => {
    formik.setFieldValue("city", ciudadId);
    setIsLoading(true);

    const data = await requestSquematic(
      "GET",
      "/api/panel/neighborhood/get_by_location",
      {
        c104_id_pais: "169",
        c104_id_depto: formik.values.department,
        c104_id_ciudad: ciudadId,
      },
      getUser.token
    );

    if (data) {
      const filteredResults = data!.results.filter(
        ({ c104_ind_estado }: any) => c104_ind_estado?.trim() === "1"
      );

      if (filteredResults.length === 0) {
        // No hay barrios disponiblse, se muestra el mensaje
        setNeighList([]);
        formik.setFieldValue("stateNeigh", true);
      } else {
        formik.setFieldValue("stateNeigh", false);
        setNeighList(filteredResults);
      }
    }

    setIsLoading(false);
  };

  // Mapea las opciones
  const houseTenureOptions = houseTenure.map((item) => ({
    value: item.id + "",
    label: item.value,
  }));

  const houseTypeOptions = houseType.map((item) => ({
    value: item.id + "",
    label: item.value,
  }));

  // Inicializar variables
  const formik = useFormik({
    initialValues: {
      home_address: "",
      home_address_description: "",
      house_type: "",
      house_tenure: "",
      department: "",
      city: "",
      stateNeigh: false,
      neighborhood: "",
      stratum: "3",
    },
    validationSchema: yupContactValidations,
    onSubmit: (values) => {
      handleGetThirdInfo();
    },
  });

  //Validar la existencia del tercero
  const handleGetThirdInfo = async () => {
    setIsLoading(true);

    const res = await requestSquematic(
      "GET",
      "/api/app/third/get_third_information",
      {},
      getUser.token
    );

    if (res) {
      let razon_social = res.results!.c120_razon_social;
      handleSubmitData(razon_social);
    } else {
      await errorAlert("Error", "No se encontró el tercero.", "");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // Si hay cambios en el departamento o ciudad, se borra el barrio y su error
    formik.setFieldValue("neighborhood", "");
    formik.setFieldValue("stateNeigh", false);
    setSelectedNeigh(null);
  }, [formik.values.department, formik.values.city]);

  useEffect(() => {
    // Si hay solo cambios en el departamento, se borra la ciudad y su error
    setSelectedCity(null);
  }, [formik.values.department]);

  //Enviar a almacenar la informacion de residencia
  const handleSubmitData = async (razon_social: string) => {
    const res = await requestSquematic(
      "POST",
      "/api/app/contact_details/create",
      {
        c125_contacto: razon_social,
        c125_direccion1: formik.values.home_address,
        c125_id_pais: "169",
        c125_id_depto: formik.values.department,
        c125_id_ciudad: formik.values.city,
        c125_id_barrio: formik.values.neighborhood,
        c125_cod_postal: "+57",
        c125_estrato: formik.values.stratum,
        c125_celular: getUser.phone,
        c125_descripcion_de_direccion: formik.values.home_address_description,
        c125_lat_barrio: getValueStorage("authSession").getLocation?.latitude!.toString() ?? "",
        c125_lon_barrio: getValueStorage("authSession").getLocation?.longitude!.toString() ?? "",
        c125_housing_type: formik.values.house_type,
      },
      getUser.token
    );

    if (res) {
      saveValueStorage("authSession", {
        ...getValueStorage("authSession"),
        hasContact: true,
        getLocation: {
          latitude: null,
          longitude: null,
        },
        houseTenure: formik.values.house_tenure,
      });
      let obj = {
        prevStep: 5,
        nextStep: 6,
        desc: "el usuario lleno la informacion de contacto",
      }
      saveValueStorage("registerStep", obj);
      if (setRegisterStep) setRegisterStep(obj);
    }
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: envProps.GOOGLE_KEY,
    libraries: ["geometry"],
  });

  const google = window.google;

  if (!isLoaded) {
    return null;
  }

  return (
    <>
      {viewMap ? (
        <LocationPin
          inputAddress={formik.values.home_address}
          setViewMap={setViewMap}
          title={t("contactInformation.mapLabel")}
        />
      ) : (
        <>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 20,
                color: colors.black,
                textTransform: "uppercase",
                marginBottom: "5px",
              }}
              lineHeight={1}
              textAlign={"center"}
            >
              {t("common.form")}
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 20,
                color: colors.primary,
                textTransform: "uppercase",
                marginBottom: "5px",
              }}
              lineHeight={1}
              textAlign={"center"}
            >
              {t("common.forRequestCredit")}
            </Typography>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"left"}
            mt={5}
          >
            <Box>
              <Box style={{ color: colors.darkGray, fontSize: 13 }}>
                {t("common.completeAllFields")}
              </Box>
              <Box
                className="text-primary"
                style={{
                  fontSize: 15,
                  fontWeight: 700,
                  marginTop: 0,
                  color: colors.primary,
                  textTransform: "uppercase",
                  lineHeight: 1,
                }}
              >
                {t("contactInformation.title")}
              </Box>
            </Box>
            <Grid container spacing={2} columns={12} mt={2.5}>
              <Grid size={12}>
                <InputField
                  name="home_address"
                  label={t("contactInformation.address")}
                  formik={formik}
                  maxLength={150}
                  disabled={getUser.isLoading}
                  onChange={() =>
                    saveValueStorage("authSession", {
                      ...getValueStorage("authSession"),
                      getLocation: {
                        latitude: undefined,
                        longitude: undefined,
                      },
                    })
                  }
                  onBlur={() => {
                    // Simula el touch del campo para mostrar los errores
                    formik.setTouched({ home_address: true });

                    //Validar otros campos (como 'prueba' o 'admin')
                    if (
                      formik.values.home_address !== "" &&
                      !formik.values.home_address
                        .split(" ")
                        .some(
                          (word) =>
                            word.toLowerCase() === "prueba" ||
                            word.toLowerCase() === "admin"
                        ) &&
                      !formik.errors.home_address
                    ) {
                      setViewMap(true);
                    }
                  }}
                />
                {getValueStorage("authSession").getLocation?.latitude &&
                  getValueStorage("authSession").getLocation?.longitude && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        width: { xs: "100%", xl: 410 },
                        margin: "0 auto",
                      }}
                    >
                      <GoogleMap
                        mapContainerStyle={{
                          width: "100%",
                          height: 150,
                          borderRadius: 5,
                          marginTop: 20,
                          boxShadow: shadow.black,
                        }}
                        center={{
                          lat: getValueStorage("authSession").getLocation?.latitude,
                          lng: getValueStorage("authSession").getLocation?.longitude,
                        }}
                        onLoad={(map) => {
                          map.setCenter({
                            lat: getValueStorage("authSession").getLocation?.latitude ?? 0,
                            lng: getValueStorage("authSession").getLocation?.longitude ?? 0,
                          });
                          map.setZoom(17);
                        }}
                        options={{
                          disableDefaultUI: true, // Desactiva la UI predeterminada del mapa
                          draggable: false, // Desactiva el arrastre del mapa
                          scrollwheel: false, // Desactiva el desplazamiento con la rueda del mouse
                          zoomControl: false, // Desactiva el control de zoom
                          streetViewControl: false, // Desactiva el control de vista de calle
                          mapTypeControl: false, // Desactiva el control de tipo de mapa
                          clickableIcons: false, // Desactiva los iconos de clic
                          headingInteractionEnabled: false, // Desactiva la interacción con la cabecera
                          keyboardShortcuts: false, // Desactiva los atajos de teclado
                        }}
                      >
                        <Marker
                          position={{
                            lat: getValueStorage("authSession").getLocation?.latitude,
                            lng: getValueStorage("authSession").getLocation?.longitude,
                          }}
                          //   animation={google.maps.Animation.DROP}
                          icon={{
                            path: SVG_pointer,
                            scale: 0.075,
                            strokeWeight: 0.8,
                            fillColor: colors.primary,
                            fillOpacity: 1,
                            anchor: new google.maps.Point(200, 520),
                          }}
                        />
                      </GoogleMap>

                      <ColorButton
                        sx={{
                          width: "fit-content",
                          padding: "0px 20px",
                          fontSize: 12,
                          mt: 2,
                          height: 50,
                        }}
                        onClick={() => setViewMap(true)}
                        disabled={getUser.isLoading}
                      >
                        {t("contactInformation.changeUbi")}
                      </ColorButton>
                    </Box>
                  )}
              </Grid>
              <Grid size={12}>
                <InputField
                  name="home_address_description"
                  label={t("contactInformation.dirDesc")}
                  formik={formik}
                  maxLength={150}
                  disabled={getUser.isLoading}
                  type="textarea"
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 4 }}>
                <InputField
                  name="house_type"
                  formik={formik}
                  label={t("contactInformation.housingType")}
                  type="select"
                  options={houseTypeOptions}
                  disabled={getUser.isLoading}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 4 }}>
                <InputField
                  name="house_tenure"
                  formik={formik}
                  label={t("contactInformation.houseTenure")}
                  type="select"
                  options={houseTenureOptions}
                  disabled={getUser.isLoading}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 4 }}>
                <InputField
                  name="stratum"
                  formik={formik}
                  label={t("contactInformation.stratum")}
                  type="select"
                  options={[
                    { value: "1", label: "1" },
                    { value: "2", label: "2" },
                    { value: "3", label: "3" },
                    { value: "4", label: "4" },
                    { value: "5", label: "5" },
                    { value: "6", label: "6" },
                  ]}
                  disabled={getUser.isLoading}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <Autocomplete
                  options={departmentList}
                  getOptionLabel={(option) => option.value}
                  value={selectedDepartm}
                  onChange={(event, newValue) => {
                    setSelectedDepartm(newValue);
                    formik.setFieldValue("city", "");
                    fetchCities(newValue ? newValue.id + "" : "");
                    setNeighList([]);

                    // Si se selecciona una opción válida, actualizar Formik; si no, establecer en cadena vacía
                    formik.setFieldValue(
                      "department",
                      newValue ? newValue.id + "" : ""
                    );
                  }}
                  disabled={getUser.isLoading || !departmentList.length}
                  onInputChange={(event, inputValue) => {
                    // Si el valor ingresado no tiene una coincidencia, limpiar el campo en Formik
                    const matchedOption = departmentList.find(
                      (option) => option.value === inputValue
                    );
                    if (!matchedOption) {
                      formik.setFieldValue("department", "");
                    }
                  }}
                  noOptionsText={t("common.empty")}
                  renderInput={(params) => (
                    <InputField
                      {...params}
                      name="department"
                      formik={formik}
                      noFormik
                      label={t("contactInformation.department")}
                      disabled={getUser.isLoading || !departmentList.length}
                      placeholder={
                        departmentList.length === 0 ? "" : "Seleccione"
                      }
                      inputProps={{
                        ...params.inputProps,
                        readOnly: !!selectedDepartm, // Si hay una selección, el campo será de solo lectura
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <Autocomplete
                  options={cityList}
                  getOptionLabel={(option) => option.value}
                  value={selectedCity}
                  onChange={(event, newValue) => {
                    setSelectedCity(newValue);
                    fetchBarrios(newValue ? newValue.id + "" : "");

                    // Si se selecciona una opción válida, actualizar Formik; si no, establecer en cadena vacía
                    formik.setFieldValue(
                      "city",
                      newValue ? newValue.id + "" : ""
                    );
                  }}
                  disabled={getUser.isLoading || !cityList.length}
                  onInputChange={(event, inputValue) => {
                    // Si el valor ingresado no tiene una coincidencia, limpiar el campo en Formik
                    const matchedOption = cityList.find(
                      (option) => option.value === inputValue
                    );
                    if (!matchedOption) {
                      formik.setFieldValue("city", "");
                    }
                  }}
                  noOptionsText={t("common.empty")}
                  renderInput={(params) => (
                    <InputField
                      {...params}
                      name="city"
                      formik={formik}
                      noFormik
                      label={t("contactInformation.city")}
                      disabled={getUser.isLoading || !cityList.length}
                      placeholder={cityList.length === 0 ? "" : "Seleccione"}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: !!selectedCity, // Si hay una selección, el campo será de solo lectura
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 12 }}>
                <Autocomplete
                  options={neighList}
                  getOptionLabel={(option) => option.c104_descripcion}
                  value={selectedNeigh}
                  onChange={(event, newValue) => {
                    setSelectedNeigh(newValue);
                    // Si se selecciona una opción válida, actualizar Formik; si no, establecer en cadena vacía
                    formik.setFieldValue(
                      "neighborhood",
                      newValue ? newValue.c104_id + "" : ""
                    );
                  }}
                  disabled={getUser.isLoading || !neighList.length}
                  onInputChange={(event, inputValue) => {
                    // Si el valor ingresado no tiene una coincidencia, limpiar el campo en Formik
                    const matchedOption = neighList.find(
                      (option) => option.c104_descripcion === inputValue
                    );
                    if (!matchedOption) {
                      formik.setFieldValue("neighborhood", "");
                    }
                  }}
                  noOptionsText={t("common.empty")}
                  renderInput={(params) => (
                    <InputField
                      {...params}
                      name="neighborhood"
                      formik={formik}
                      noFormik
                      label={t("contactInformation.neighborhood")}
                      disabled={getUser.isLoading || !neighList.length}
                      placeholder={neighList.length === 0 ? "" : "Seleccione"}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: !!selectedNeigh, // Si hay una selección, el campo será de solo lectura
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              marginTop={8}
            >
              <ColorButton
                sx={{
                  width: "fit-content",
                  padding: "15px 40px",
                  fontSize: 16,
                }}
                type="submit"
                onClick={() => {
                  formik.handleSubmit();
                }}
                disabled={getUser.isLoading}
              >
                {t("common.continue")}
              </ColorButton>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
