import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../hooks/useAuth.tsx";
import { formatNumberPoint, requestSquematic } from "../../../../common/CommonFunctions.tsx";
import { isAccepted } from "./Referrals.tsx";
import { IUserAuth } from "../../../../constants/types.tsx";
import { getValueStorage } from "../../../../common/storage.ts";

interface IItemProp {
  isReferrar: boolean;
  amount: string;
  name: string;
  date: string;
  amountPay: string;
  transactionCost: string;
  taxesCost: string;
}

export default function MovementsReferrals() {
  const { t } = useTranslation();
  const { getUser, setIsLoading, signOut } = React.useContext(AuthContext);
  const authSession: IUserAuth = getValueStorage("authSession");
  const [data, setData] = React.useState<IItemProp[]>([]);

  const fetchData = async () => {
    setIsLoading(true);

    const data = await requestSquematic(
      "GET",
      "/api/app/referred/get_history",
      {},
      authSession.token,
      signOut
    );

    if (data) {
      const data1: IItemProp[] = [];
      const data2: IItemProp[] = [];

      data.results!.referrals.forEach((item) => {
        if (
          item.user_referred.third &&
          item.c160_valor_pagado !== ".0000" &&
          item.user_referred.third.first_loan !== null &&
          isAccepted(item.user_referred.third.first_loan.c200_ind_estado)
        ) {
          data1.push({
            amount: item.c160_valor_pagado,
            date: item.c160_fecha_creacion,
            isReferrar: true,
            name: item.user_referred.third.c120_razon_social,
            amountPay: "",
            transactionCost: "",
            taxesCost: "",
          });
        }
      });

      data.results!.referred_banking_transaction.forEach((item) => {
        if (item.c162_ind_estado === "1") {
          data2.push({
            amount: item.c162_valor_pagado,
            date: item.c162_fecha_creacion,
            isReferrar: false,
            name: "Solicitud de desembolso",
            amountPay: item.c162_valor_total,
            transactionCost: item.c162_valor_costo_tran,
            taxesCost: item.c162_valor_cuatro_por_mil,
          });
        }
      });

      const dataReferrals = [...data1, ...data2]
        .sort((a, b) => Number(new Date(a.date)) - Number(new Date(b.date)))
        .reverse();

      setData(dataReferrals);
    }

    setIsLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} marginTop={2}>
      <p className="mt-3 mb-5 text-xl font-bold dark:text-white">
        {t("movementsReferrals.title")}
      </p>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableCell className="!text-center dark:!bg-navy-800 dark:text-white">NOMBRE</TableCell>
          <TableCell className="!text-center dark:!bg-navy-800 dark:text-white">VALOR</TableCell>
        </TableHead>
        <TableBody>
          {
            data.length === 0 ?
              <TableRow>
                <TableCell colSpan={2} className="!text-center dark:!bg-navy-800 dark:!text-white">{t("moneyReferrals.tableEmpty")}</TableCell>
              </TableRow>
              :
              data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell className="dark:!bg-navy-800 dark:text-white">{item.name}</TableCell>
                  <TableCell className="!text-center !font-bold !text-primary dark:!bg-navy-800">+ ${formatNumberPoint(item.amount)}</TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>
    </Box>
  );
}