import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { colors, shadow } from "../styles/theme.tsx";
import { ColorButton } from "../components/Button.tsx";
import "../index.css";
import * as regex from "../common/regex.ts";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { SVG_pointer } from "../assets/svg/MapIcons.ts";
import { envProps } from "../common/envProps.ts";
import axios from "axios";
import { AuthContext } from "../hooks/useAuth.tsx";
import { errorAlert, MySwal } from "../common/alerts.tsx";
import { LocationRequestComponent } from "../constants/permissions.tsx";
import { getValueStorage, saveValueStorage } from "../common/storage.ts";

// Interfaces para busqueda de ciudaddes y direcciones
interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface Location {
  lat: number;
  lng: number;
}

interface Geometry {
  location: Location;
}

interface Result {
  address_components: AddressComponent[];
  geometry: Geometry;
}

interface GeocodeResponse {
  results: Result[];
  status: string;
}

const formatAddress = (address: string): string => {
  let formattedAddress = address;

  // Reemplaza "Calle" por "Cl" si coincide con el regex
  if (regex.calleRegex.test(address)) {
    formattedAddress = formattedAddress.replace(regex.calleRegex, "Cl");
  }

  // Reemplaza "Carrera" por "Kr" si coincide con el regex
  if (regex.carreraRegex.test(formattedAddress)) {
    formattedAddress = formattedAddress.replace(regex.carreraRegex, "Kr");
  }

  return formattedAddress;
};

export default function LocationPin({
  inputAddress,
  title,
  setViewMap,
  setIsLoading: setIsloadingExternal,
}: {
  inputAddress: any;
  title: string;
  setViewMap: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading?: (isLoading: boolean) => void; // No es obligatorio pasar este prop
}) {
  const { getUser, setIsLoading: setIsloadingInternal } =
    useContext(AuthContext);
  const { registerForLocation } = LocationRequestComponent();
  const [getCity, setgetCity] = useState("");

  // Se envia solo `setIsloading` cuando es un modal, de lo contrario usamos el interno
  const setIsLoading = setIsloadingExternal || setIsloadingInternal;

  const [region, setRegion] = useState({
    lat: 0,
    lng: 0,
  });

  // Estado para la ubicación a guardar
  const [locationNow, setLocationNow] = useState({
    latitude: 0,
    longitude: 0,
  });

  // Fetch para obtener la ciudad según la ubicación del dispositivo
  const fetchLocation = async () => {
    const loc = await registerForLocation({ setIsLoading }); // Espera a obtener la ubicación

    if (loc) {
      try {
        const { latitude, longitude } = loc;

        const apiKey = envProps.GOOGLE_KEY;
        // Geocodificación inversa para obtener la ciudad
        const response = await axios.get<GeocodeResponse>(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
        );

        if (response.data.results.length > 0) {
          const addressComponents = response.data.results[0].address_components;          

          // Busca el componente de la dirección que corresponde a la ciudad
          const city = addressComponents.find((component) =>
            component.types.includes("locality") || component.types.includes("administrative_area_level_2")
          );

          if (city) {
            setgetCity(city.long_name);
          } else {
            errorAlert(
              "Opps!",
              "Parece que no pudimos encontrar tu dirección.",
              ""
            );
          }
        } else {
          errorAlert(
            "Error!",
            "No se encontraron resultados de la ciudad.",
            ""
          );
        }
      } catch (error) {
        errorAlert("Error!", "Ubicación fallida.", "");
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Fech para enviar las coordenada al MapView
  const fetchCoordinates = async () => {
    // Se formatea la direccion
    const address = formatAddress(inputAddress);

    // Con la dirección formateada y la ciudad obtenida, enviamos las coordenadas al mapa, que solo buscará en Colombia
    if (getCity !== "") {
      const direccion = `${address}, ${getCity},Colombia`;
      const apiKey = envProps.GOOGLE_KEY;
      const response = await axios.get<GeocodeResponse>(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          direccion
        )}&components=country:CO&key=${apiKey}`
      );

      if (response.data.status === "OK") {
        const geometry = response.data.results[0].geometry;
        // Se guarda la región, y la mitad del mapa representa la ubicación exacta
        // Si el redux esta lleno, se muestra el redux
        setRegion({
          lat: getValueStorage("authSession").getLocation?.latitude
            ? getValueStorage("authSession").getLocation?.latitude
            : geometry.location.lat,
          lng: getValueStorage("authSession").getLocation?.longitude
            ? getValueStorage("authSession").getLocation?.longitude
            : geometry.location.lng,
        });

        //Guardamos la ubicación actual, si el usuario no ha arrastrado el puntero
        saveValueStorage("authSession", {
          ...getValueStorage("authSession"),
          getLocation: {
            latitude: getValueStorage("authSession").getLocation?.latitude
              ? getValueStorage("authSession").getLocation?.latitude
              : geometry.location.lat,
            longitude: getValueStorage("authSession").getLocation?.longitude
              ? getValueStorage("authSession").getLocation?.longitude
              : geometry.location.lng,
          },
        });
      } else {
        errorAlert(
          "Opps!",
          "Parece que no pudimos encontrar tu dirección. Por favor, arrastra el puntero verde para ubicarla.",
          ""
        );
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchLocation();
  }, []);

  // Al obtener la ciudad, podremos obtener las coordenadas para el mapa
  useEffect(() => {
    fetchCoordinates();
  }, [getCity]);

  // Guardamos las coordenadas que se obtienen al finalizar el movimiento del punto
  const handleMarkerDragEnd = (event) => {
    setLocationNow({
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
    });
  };

  // Enviamos las coordenadas a Redux y regresamos a la pantalla anterior
  const handleSend = async () => {
    saveValueStorage("authSession", {
      ...getValueStorage("authSession"),
      getLocation: {
        latitude:
          locationNow.latitude !== 0
            ? locationNow.latitude
            : getValueStorage("authSession").getLocation?.latitude,
        longitude:
          locationNow.longitude !== 0
            ? locationNow.longitude
            : getValueStorage("authSession").getLocation?.longitude,
      },
    });
    setViewMap(false);
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: envProps.GOOGLE_KEY,
    libraries: ["geometry"],
  });

  const google = window.google;

  // Carga inicial del mapa
  const onLoad = useCallback((map: google.maps.Map) => {
    map.setCenter(region);
    map.setZoom(16);
  }, []);

  if (!isLoaded) {
    return null;
  }

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"left"}
      sx={{ minHeight: 500 }}
    >
      {/* Se muestra el mapa solo si se ha obtenido la ciudad */}
      {getCity !== "" && (
        <>
          <Box position={"relative"}>
            <Box
              sx={{
                display: { xs: "block", sm: "none" },
                bottom: 15,
                width: "100%",
                mt: 2,
                order: { xs: 1, sm: 2 },
              }}
            >
              <Box
                sx={{
                  margin: "0 auto",
                  width: { xs: "100", sm: 260 },
                  background: colors.white,
                  borderRadius: 5,
                  textAlign: "center",
                  padding: 2,
                  boxShadow: { sm: shadow.black },
                }}
              >
                <Typography
                  sx={{ color: colors.primary, fontWeight: 700, lineHeight: 1 }}
                >
                  {title}
                </Typography>
                <Typography sx={{ lineHeight: 1, mt: 0.5 }}>
                  Arrastra el puntero verde a la ubicación exacta
                </Typography>
              </Box>
            </Box>
            <GoogleMap
              mapContainerStyle={{
                width: "100%",
                height: 500,
                borderRadius: 5,
                marginTop: 20,
                boxShadow: shadow.black,
              }}
              center={region}
              onLoad={onLoad}
              options={{
                clickableIcons: false,
                streetViewControl: false,
                headingInteractionEnabled: false,
                minZoom: 17,
                maxZoom: 20,
              }}
            >
              <Marker
                position={region}
                animation={google.maps.Animation.DROP}
                icon={{
                  path: SVG_pointer,
                  scale: 0.075,
                  strokeWeight: 0.8,
                  fillColor: colors.primary,
                  fillOpacity: 1,
                  anchor: new google.maps.Point(200, 520),
                }}
                draggable={true}
                onDragEnd={handleMarkerDragEnd} // Manejar el evento de arrastre
              />
            </GoogleMap>

            <Box
              sx={{
                position: "absolute",
                display: { xs: "none", sm: "flex" },
                flexDirection: "column",
                bottom: 15,
                mt: 2,
                order: { xs: 1, sm: 2 },
                width: { xs: "100", sm: 260 },
                background: colors.white,
                borderRadius: 5,
                textAlign: "center",
                padding: 2,
                boxShadow: { sm: shadow.black },
                transform: "translateX(50%)",
                right: "50%",
              }}
            >
              <Typography
                sx={{ color: colors.primary, fontWeight: 700, lineHeight: 1 }}
              >
                {title}
              </Typography>
              <Typography sx={{ lineHeight: 1, mt: 0.5 }}>
                Arrastra el puntero verde a la ubicación exacta
              </Typography>
            </Box>
          </Box>
          <ColorButton
            sx={{
              width: "fit-content",
              padding: "15px 40px",
              fontSize: 16,
              mt: 5,
            }}
            onClick={handleSend}
            disabled={getUser.isLoading}
          >
            Confirmar
          </ColorButton>
        </>
      )}
    </Box>
  );
}
