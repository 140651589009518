import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function FormsInput({
  nameParam,
  setChanges,
  id,
  setValids,
  isLoading,
  keyboardType = "text",
  condition,
  maxLength,
}: {
  nameParam: string;
  setChanges: any;
  id: string;
  setValids: any;
  isLoading: boolean;
  keyboardType?: string;
  condition?: any;
  maxLength?: number;
}) {
  const [value, setValue] = React.useState("");
  const { t } = useTranslation();

  React.useEffect(() => {
    setValids((state: any) => ({ ...state, [id]: value !== "" }));
  }, []);

  return (
    <Box sx={{ marginTop: 1 }}>
      <label
        style={{
          fontSize: 13,
          fontWeight: 600,
          textWrap: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
        htmlFor={id}
      >
        {t(nameParam) ?? ''}
      </label >
      <TextField
        name={id}
        sx={{
          width: "100%"
        }}
        type="text"
        value={value}
        placeholder={`${id === "1" ? "Cambia tus " : "Cambia tu "}${t(nameParam) ?? ''}`}
        onChange={({ target }) => {
          if (target.value !== '') {
            let element = document.getElementById(`errorM_${id}`);
            if (element) {
              element.style.display = "none";
            }
          }
          if (keyboardType === "number") {
            target.value = target.value.replace(/[^0-9]/g, '');
          }
          const resCondition = condition(target.value);
          setChanges((state: any) => ({ ...state, [id]: target.value }));
          setValue(target.value);
          setValids((state: any) => ({ ...state, [id]: resCondition }));
        }}
        disabled={isLoading}
        inputProps={{
          maxLength: maxLength, // Máximo de caracteres
          inputMode: keyboardType === "number" ? 'numeric' : 'text', // Ayuda en dispositivos móviles
        }}
        onInput={(e: React.FormEvent<HTMLInputElement>) => {
          if (keyboardType === "number") {
            // Remueve cualquier carácter que no sea un numero
            const target = e.target as HTMLInputElement;
            target.value = target.value.replace(/[^0-9]/g, '');
          }
        }}
      />
      <Typography id={`errorM_${id}`} sx={{ color: "red", fontSize: 13, marginTop: 1, fontWeight: "bold", display: "none" }}></Typography>
    </Box>
  );
};