import React from "react";
import { Box, styled, TextField, Typography } from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import VerificationInput from "react-verification-input";
import { useTranslation } from "react-i18next";
import { ColorButton } from "../../../components/Button.tsx";
import "../../../index.css";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import { requestSquematic } from "../../../common/CommonFunctions.tsx";
import { saveValueStorage } from "../../../common/storage.ts";
import { IRegisterStepState } from "../RegisterComponent.tsx";

export const CssTextField = styled(TextField)<{ isError: boolean }>(
  ({ isError }) => ({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: isError ? `${colors.red} !important` : colors.primary,
      },
      "&:hover fieldset": {
        borderColor: isError ? `${colors.red} !important` : colors.primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: isError ? `${colors.red} !important` : colors.primary,
      },
    },
  })
);

export default function EmailValidate({ setRegisterStep }: IRegisterStepState) {
  const { getUser, setIsLoading } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const [email, setEmail] = React.useState<string>("");
  const [otpCode, setOtpCode] = React.useState<string>("");
  const [isOtpSend, setIsOtpSend] = React.useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = React.useState<boolean>(false);
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [isTouched, setIsTouched] = React.useState<boolean>(false);

  // Al cambiar el email
  const handleChange = (value: string) => {
    setEmail(value);
    setIsOtpSend(false);
    setOtpCode("");
    setIsValidEmail(re.test(String(email).toLowerCase()));
  };

  // Enviar codigo OTP
  const handleSendOtpEmail = async () => {
    setIsLoading(true);

    const data = await requestSquematic(
      "POST",
      "/api/app/validation_otp/send_otp_email",
      { email, c001_cod_lenguaje: t("common.lenguage") }
    );

    if (data) {
      setIsOtpSend(true);
    }

    setIsLoading(false);
  };

  React.useEffect(() => {
    saveValueStorage("registerStep", {
      prevStep: "home",
      nextStep: 0,
      desc: "inicio del proceso de registro",
    });
  }, []);

  // Validamos el codigo OTP que ingresa el usuario contra el que se envio al correo
  const handleEmailValidation = async () => {
    setIsLoading(true);

    const data = await requestSquematic(
      "POST",
      "/api/app/validation_otp/validate_otp",
      { otp_code: otpCode, value: email }
    );

    if (data && data.results) {
      saveValueStorage("userInfo", {
        user_email: email,
        user_phone: "",
        otpEmail: otpCode,
        otpPhone: "",
        token: "",
        password: "",
      });
      let obj = {
        prevStep: 0,
        nextStep: 1,
        desc: "el usuario verifico el codigo otp del correo electronico",
      };
      saveValueStorage("registerStep", obj);
      if (setRegisterStep) setRegisterStep(obj);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: colors.black,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("verifyCode.title")}
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("verifyCode.subEmail")}
        </Typography>
      </Box>
      {/* Input */}
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"left"}
        mt={5}
        sx={{ marginX: { sm: 4, xl: 10 } }}
      >
        <Box sx={{ width: 105, height: 105, margin: "0 auto" }}>
          <img
            src={require("../../../assets/image/Correo.webp")}
            alt={""}
            loading="lazy"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
        <CssTextField
          id="outlined-basic"
          label=""
          variant="outlined"
          type="email"
          onChange={({ target }) => handleChange(target.value)}
          isError={!isValidEmail && email !== ""}
        />
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          marginTop={5}
        >
          <ColorButton
            sx={{
              width: "fit-content",
              padding: "15px 40px",
              fontSize: 16,
            }}
            onClick={() => handleSendOtpEmail()}
            disabled={!isValidEmail || getUser.isLoading || isOtpSend}
          >
            {t("common.continue")}
          </ColorButton>
        </Box>
      </Box>
      {/* Se ha enviado */}
      {isOtpSend && (
        <>
          <Box
            mt={8}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 20,
                color: colors.black,
                textTransform: "uppercase",
                marginBottom: "5px",
              }}
              lineHeight={1}
              textAlign={"center"}
            >
              {t("verifyCode.message")}
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 20,
                color: colors.primary,
                textTransform: "uppercase",
                marginBottom: "5px",
              }}
              lineHeight={1}
              textAlign={"center"}
            >
              {t("verifyCode.code")}
            </Typography>
          </Box>
          {/* Destino del codigo */}
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            mt={5}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 20,
                color: colors.black,
                textTransform: "uppercase",
                marginBottom: 5,
              }}
              lineHeight={1}
              textAlign={"center"}
            >
              {t("verifyCode.toEmail")}
            </Typography>
            <VerificationInput
              length={4}
              validChars="0-9"
              placeholder=""
              classNames={{
                container: "container-characters",
                character: "characters",
                characterInactive: "character--inactive",
                characterSelected: "character--selected",
              }}
              autoFocus={true}
              onChange={(e) => {
                // Limpiamos el otp cuando se modifique
                setOtpCode("");
              }}
              onComplete={(e) => {
                // Se llena el otp cuando se completa los campos
                setOtpCode(e);
              }}
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            marginTop={5}
          >
            <ColorButton
              sx={{
                width: "fit-content",
                padding: "15px 40px",
                fontSize: 16,
              }}
              onClick={handleEmailValidation}
              disabled={getUser.isLoading || !otpCode}
            >
              {t("common.send")}
            </ColorButton>
          </Box>
        </>
      )}
    </>
  );
}
