import React from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { colors, sizes, shadow } from "../../styles/theme.tsx";
import { useTranslation } from "react-i18next";

export default function OrganizationalCulture() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          flex: "1",
          flexGrow: 1,
          marginTop: `${sizes.header}px`,
        }}
      >
        <Box
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
          flexDirection={"column"}
          sx={{
            flexGrow: 1,
            width: { xs: "85%", xl: "70%" },
            margin: "0 auto"
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 32, md: 43 },
              color: colors.primary,
              textTransform: "uppercase",
            }}
            lineHeight={1}
            my={{ xs: 10, lg: "107px" }}
          >
            {t("organizationalCulture.title")}
          </Typography>
          {/* MISION */}
          <Grid
            sx={{
              background: colors.white,
              height: "auto",
              borderRadius: "18px",
              boxShadow: shadow.black,
              padding: { xs: "40px", sm: "70px" },
              position: "relative",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: { lg: "100px" },
            }}
          >
            <Box
              sx={{
                width: 136,
                height: 10,
                background: colors.primary,
                position: "absolute",
                top: "0",
                left: "50%",
                borderRadius: 2,
                transform: "translateX(-50%)",
              }}
            />

            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: 32, md: 43 },
                  color: colors.primary,
                  textTransform: "uppercase",
                }}
                lineHeight={1}
                textAlign={"center"}
              >
                {t("organizationalCulture.misionTitle")}
              </Typography>

              <Typography
                sx={{
                  fontSize: { xs: 20, md: 23 },
                  color: colors.gray,
                  marginTop: "20px",
                }}
                lineHeight={1.3}
              >
                {t("organizationalCulture.misionP1")}
                <br /><br />
                {t("organizationalCulture.misionP2")}
                <br /><br />
                {t("organizationalCulture.misionP3")}
              </Typography>
            </Box>
          </Grid>

          {/* VISION */}
          <Grid
            sx={{
              background: colors.white,
              height: "auto",
              borderRadius: "18px",
              boxShadow: shadow.black,
              padding: { xs: "40px", sm: "70px" },
              position: "relative",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: { lg: "100px" },
            }}
          >
            <Box
              sx={{
                width: 136,
                height: 10,
                background: colors.primary,
                position: "absolute",
                top: "0",
                left: "50%",
                borderRadius: 2,
                transform: "translateX(-50%)",
              }}
            />
            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: 32, md: 43 },
                  color: colors.primary,
                  textTransform: "uppercase",
                }}
                lineHeight={1}
                textAlign={"center"}
              >
                {t("organizationalCulture.visionTitle")}
              </Typography>

              <Typography
                sx={{
                  fontSize: { xs: 20, md: 23 },
                  color: colors.gray,
                  marginTop: "20px",
                }}
                lineHeight={1.3}
              >
                {t("organizationalCulture.visionP1")}
                <br /><br />
                {t("organizationalCulture.visionP2")}
                <br /><br />
                {t("organizationalCulture.visionP3")}
              </Typography>
            </Box>
          </Grid>

          {/* VALORES */}
          <Grid
            sx={{
              background: colors.white,
              height: "auto",
              borderRadius: "18px",
              boxShadow: shadow.black,
              padding: { xs: "40px", sm: "70px" },
              position: "relative",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: { lg: "100px" },
            }}
          >
            <Box
              sx={{
                width: 136,
                height: 10,
                background: colors.primary,
                position: "absolute",
                top: "0",
                left: "50%",
                borderRadius: 2,
                transform: "translateX(-50%)",
              }}
            />
            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: 32, md: 43 },
                  color: colors.primary,
                  textTransform: "uppercase",
                }}
                lineHeight={1}
                textAlign={"center"}
              >
                {t("organizationalCulture.valuesTitle")}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: 20, md: 23 },
                  color: colors.gray,
                  marginTop: "20px",
                }}
                lineHeight={1.3}
              >
                <strong>{t("organizationalCulture.valuesP1").split(":")[0]}:</strong> {t("organizationalCulture.valuesP1").split(":")[1]}
                <br /><br />
                <strong>{t("organizationalCulture.valuesP2").split(":")[0]}:</strong> {t("organizationalCulture.valuesP2").split(":")[1]}
                <br /><br />
                <strong>{t("organizationalCulture.valuesP3").split(":")[0]}:</strong> {t("organizationalCulture.valuesP3").split(":")[1]}
                <br /><br />
                <strong>{t("organizationalCulture.valuesP4").split(":")[0]}:</strong> {t("organizationalCulture.valuesP4").split(":")[1]}
              </Typography>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
