import * as Yup from "yup";

export const yupContactUs = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimo 3 caracteres")
    .matches(
      /^(?! )[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/,
      "Solo se permiten letras y sin espacios al final"
    )
    .test(
      "no-prueba-admin",
      "El campo no puede contener 'prueba' o 'admin'",
      (value) => {
        return (
          !value ||
          !value
            .split(" ")
            .some(
              (word) =>
                word.toLowerCase() === "prueba" ||
                word.toLowerCase() === "admin"
            )
        );
      }
    )
    .required("El campo es obligatorio"),
  email: Yup.string()
    .test(
      "is-valid-email",
      "Ingresa un correo válido",
      (value) => !value || /^\S+@\S+\.\S+$/.test(value) // Validación personalizada
    )
    .required("El campo es obligatorio"),
  city: Yup.string()
    .min(3, "Minimo 3 caracteres")
    .matches(
      /^(?! )[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/,
      "Solo se permiten letras y sin espacios al final"
    )
    .test(
      "no-prueba-admin",
      "El campo no puede contener 'prueba' o 'admin'",
      (value) => {
        return (
          !value ||
          !value
            .split(" ")
            .some(
              (word) =>
                word.toLowerCase() === "prueba" ||
                word.toLowerCase() === "admin"
            )
        );
      }
    )
    .required("El campo es obligatorio"),
  nit: Yup.string().min(9, "Minimo 9 caracteres"),
  phone: Yup.string()
    .required("El campo es obligatorio")
    .matches(/^[0-9]*$/, "Solo se permiten números")
    .min(7, "Número de contacto debe ser minimo 7 digitos"),
  message: Yup.string()
    .required("El campo es obligatorio")
    .matches(
      /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9,.; ]+$/,
      "No se permiten caracteres especiales o saltos de linea"
    )
    .test(
      "no-prueba-admin",
      "El campo no puede contener 'prueba' o 'admin'",
      (value) => {
        return (
          !value ||
          !value
            .split(" ")
            .some(
              (word) =>
                word.toLowerCase() === "prueba" ||
                word.toLowerCase() === "admin"
            )
        );
      }
    ),
  agreedTerms: Yup.boolean()
    .oneOf(
      [true],
      "Para continuar, debes aceptar nuestra Política de Tratamiento de Datos"
    )
    .required(
      "Para continuar, debes aceptar nuestra Política de Tratamiento de Datos"
    ),
});
