import React from "react";
import Widget from "./components/Widget.tsx";
import {
  CalendarMonthIcon,
  ChevronRightIcon,
  LeaderboardIcon,
  PaidIcon,
} from "./assets/iconsMui.tsx";
import { useTranslation } from "react-i18next";
import Card from "./components/Card.tsx";
import { AuthContext } from "../../hooks/useAuth.tsx";
import {
  IGetLoanRequestedOrApproved,
  IUserAuth,
  Loan,
  loans,
  PropItemsLoanInit,
} from "../../constants/types.tsx";
import { getValueStorage } from "../../common/storage.ts";
import {
  formatDateTime,
  formatNumberPoint,
  getPercentCredit,
} from "../../common/CommonFunctions.tsx";
import { Modal } from "../../common/alerts.tsx";
import PaymentHistory from "./pages/Payments/PaymentHistory.tsx";
import DoPay from "./pages/Payments/DoPay.tsx";
import { DuesDetails } from "./pages/DuesDetails.tsx";
import Referrals from "../User/RegisterSubComponents/Referrals/Referrals.tsx";
import { ChannelTypes } from "./pages/Payments/ChannelTypes.tsx";
import { RenovationInfoLoan } from "./pages/Renovations/RenovationInfoLoan.tsx";
import { Box } from "@mui/material";
import Lottie from "lottie-react";

export const calcSaldoMora = (loanPayment: loans, loan: PropItemsLoanInit) => {
  const hoy = new Date();
  let total = 0;


  loanPayment.payment_plans.forEach((plan) => {
    const fechaCuota = new Date(plan.c202_fecha_cuota);
    const valorCuota = parseFloat(plan.c202_vlr_cuota);

    //Si la fecha de la cuota es menor o igual a la actual y el estado es cero se suma el valor de la cuota
    if (fechaCuota <= hoy && plan.c202_ind_estado === "0") {
      const totalDuesInt = plan.additional_payment_plan.reduce(
        (sum, { c210_vlr_cuota }) => sum + parseInt(c210_vlr_cuota),
        0
      );
      total += valorCuota + totalDuesInt;
    }

    //Si la fecha de la cuota es menor o igual a la actual y el estado es "2", se suma el valor pendiente de la cuota correspondiente
    if (fechaCuota <= hoy && plan.c202_ind_estado === "2") {
      const cuotaPendiente = loanPayment.banking_transaction.find(
        (trans) => trans.c230_nro_cuota === plan.c202_nro_cuota
      );
      if (cuotaPendiente) {
        total += parseFloat(cuotaPendiente.c230_valor_pendiente);
      }
    }
  });

  let penaltiesToPay = 0;
  if (loan?.penalties_to_pay) {
    penaltiesToPay = parseFloat(loan.penalties_to_pay);
  }

  return total + penaltiesToPay;
};

export default function HomeDashboard({ headertohome, removeFunction }) {
  const { t } = useTranslation();
  const { getUser, setIsLoading, signOut } = React.useContext(AuthContext);
  const { c120_nombres } = getValueStorage("profileData");
  const authSession: IUserAuth = getValueStorage("authSession");
  const [valueParcialPay, setValueParcialPay] = React.useState<number>(0);
  const [loanPayment, setLoanPayment] = React.useState<loans | null>(null);
  const [loan, setLoan] = React.useState<PropItemsLoanInit | null>(null);
  const [nextDueToPay, setNextDueToPay] = React.useState<any | null>(null);
  const [pending_loans, setPending_loans] = React.useState<
    (IGetLoanRequestedOrApproved & { hasFather: boolean })[]
  >([]);
  const [widgetMora, setWidgetMora] = React.useState<number>(0);
  const [disabledRenewal, setDisabledRenewal] = React.useState(false);
  const [percentValState, setPercentValState] = React.useState(0);
  const [isHovered, setIsHovered] = React.useState(false);
  const [dataReno, setDataReno] = React.useState<Loan>({
    loan: {
      value_to_paid: "",
      loanId: "",
      personType: {
        id: "",
        name: "",
      },
      creditLine: "",
      amount: 0,
      dues: 0,
      period: 0,
      valPerDue: 0,
      isRenovation: true,
      periodDescription: "",
      c200_fecha_creacion: "",
      payment_plan_penalites: [],
      banking_transaction: [],
      c200_ind_estado: "",
      c200_rowid_nuevo_prestamo: "",
      minDue: "",
      penalties_to_pay: "",
      loans: loan,
    },
  });

  React.useEffect(() => {
    // Validar que exista la funcion del header
    if (headertohome?.renovation === null) {
      setDisabledRenewal(true);
    }
    setLoan(getValueStorage("loan"));
    setLoanPayment(getValueStorage("loans"));
    const storedValue = getValueStorage("updatedPendingLoans");
    // Verifica que el valor sea un objeto y conviértelo a array
    if (storedValue && typeof storedValue === "object") {
      const convertedArray = Object.values(storedValue);
      setPending_loans(
        convertedArray as (IGetLoanRequestedOrApproved & {
          hasFather: boolean;
        })[]
      );
    } else {
      console.error(
        "El valor de 'updatedPendingLoans' no es válido:",
        storedValue
      );
    }

    if (getValueStorage("loan")) {
      setDataReno({
        loan: {
          value_to_paid: getValueStorage("loan").totalCredit,
          loanId: getValueStorage("loan").c200_rowid,
          personType: {
            id: getValueStorage("loan").industrial_classification.c017_rowid,
            name: getValueStorage("loan").industrial_classification
              .c017_descripcion,
          },
          creditLine: getValueStorage("loan").c200_rowid_concepto,
          amount: parseInt(getValueStorage("loan").monto),
          dues: parseInt(getValueStorage("loan").c200_plazo),
          period: parseInt(getValueStorage("loan").c200_rowid_periodo),
          valPerDue: parseInt(getValueStorage("loan").fullDue),
          isRenovation: true,
          periodDescription: getValueStorage("loan").description,
          c200_fecha_creacion: getValueStorage("loan").c200_fecha_creacion,
          payment_plan_penalites:
            getValueStorage("loan").payment_plan_penalites,
          banking_transaction: getValueStorage("loan").banking_transaction,
          c200_ind_estado: getValueStorage("loan").c200_ind_estado,
          c200_rowid_nuevo_prestamo:
            getValueStorage("loan").c200_rowid_nuevo_prestamo,
          minDue: getValueStorage("loan").minDue,
          penalties_to_pay: getValueStorage("loan").penalties_to_pay,
          loans: loan,
        },
      });
    }
  }, [getUser.isLoading]);

  React.useEffect(() => {
    setNextDueToPay(
      loanPayment?.payment_plans.filter(
        (plan) => plan.c202_ind_estado === "0" || plan.c202_ind_estado === "2"
      )[0]
    );

    if (loanPayment?.parcial_payment?.c260_ind_estado !== "9") {
      setValueParcialPay(
        parseInt(loanPayment?.parcial_payment?.c260_valor_acumulado ?? "0")
      );
    }
    //Calcular valor en mora
    if (loanPayment && loan) setWidgetMora(calcSaldoMora(loanPayment, loan));
  }, [loanPayment, setLoanPayment]);

  React.useEffect(() => {
    // Calcular porcentaje para renovar
    if (loan) {
      const { percentVal } = getPercentCredit(dataReno.loan, 70);
      setPercentValState(percentVal);
    }
  }, [loan, setLoan]);

  // Ejecucion de prueba de funciones
  // const handleClick = () => {
  //   if (headertohome?.prueba) {
  //     headertohome.prueba();
  //   }
  // };

  // Funcion para desabilitar la renovación
  const handleRenovation = () => {
    // Validar que exista la funcion del header
    if (headertohome?.renovation) {
      headertohome.renovation();
    }
  };

  const widgets = [
    {
      icon: <PaidIcon className="h-7 w-7 text-primary" />,
      title: t("homeDashboard.widgets.first"),
      subtitle: formatNumberPoint(widgetMora),
      type: "money",
    },
    {
      icon: <CalendarMonthIcon className="h-7 w-7 text-primary" />,
      title: t("homeDashboard.widgets.second"),
      subtitle: nextDueToPay?.c202_fecha_cuota
        ? formatDateTime(nextDueToPay?.c202_fecha_cuota, t, false, true)
        : "-",
      type: "date",
    },
    {
      icon: <PaidIcon className="h-7 w-7 text-primary" />,
      title: t("homeDashboard.widgets.third"),
      subtitle: formatNumberPoint(parseInt(loan?.totalCredit ?? "0")),
      type: "money",
    },
    {
      icon: <LeaderboardIcon className="h-7 w-7 text-primary" />,
      title: t("homeDashboard.widgets.fourth"),
      subtitle: authSession.credits_finished,
      type: "number",
    },
  ];

  let gridClass =
    widgets.length >= 6
      ? "3xl:grid-cols-6"
      : widgets.length === 5
      ? "3xl:grid-cols-5"
      : widgets.length === 4
      ? "3xl:grid-cols-4"
      : widgets.length === 3
      ? "3xl:grid-cols-3"
      : widgets.length === 2
      ? "3xl:grid-cols-2"
      : "3xl:grid-cols-1";

  //Realizar un pago
  const doPay = () => {
    Modal(
      <DoPay
        setIsLoading={setIsLoading}
        getUser={getUser}
        loanLocal={loan}
        signOut={signOut}
      />,
      {
        title: "",
        width: 800,
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "bg-snow dark:bg-navy-800",
          htmlContainer: "!px-3 !overflow-x-hidden",
        },
      }
    );
  };

  //Ver historial de pagos
  const paymentHistory = () => {
    setIsLoading(true);
    Modal(
      <PaymentHistory
        setIsLoading={setIsLoading}
        getUser={getUser}
        loanLocal={loan}
      />,
      {
        title: "",
        width: 800,
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "bg-snow dark:bg-navy-800",
          htmlContainer: "!px-3 !overflow-x-hidden",
        },
      }
    );
  };

  //Ver cuotas
  const seeRates = () => {
    Modal(<DuesDetails loan={loan?.c200_rowid} setIsLoading={setIsLoading} />, {
      title: "",
      width: "600px",
      showCloseButton: true,
      showConfirmButton: false,
      customClass: {
        popup: `bg-snow dark:bg-navy-800 px-0 sm:w-auto`,
        htmlContainer: " !overflow-x-hidden",
      },
    });
  };

  // Renueva tu crédito
  const handleRenewal = () => {
    if (!loan) {
      console.error("Loan data is null or empty");
      return; // Salimos de la función si `loan` es null o está vacío
    }
    Modal(
      <RenovationInfoLoan
        loan={dataReno.loan}
        setIsLoading={setIsLoading}
        getUser={getUser}
        loanLocal={loan}
        signOut={signOut}
        onClose={handleRenovation}
      />,
      {
        title: "",
        showCloseButton: true,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 500,
        customClass: {
          popup: `bg-snow dark:bg-navy-800 px-0 sm:w-auto`,
          htmlContainer: " !overflow-x-hidden",
        },
      }
    );
  };

  // Refiere y gana
  const handleReferrals = () => {
    Modal(<Referrals />, {
      title: "",
      showCloseButton: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 500,
      customClass: {
        popup: `bg-snow dark:bg-navy-800 px-0 sm:w-auto`,
        htmlContainer: " !overflow-x-hidden",
      },
    });
  };

  // Como pagar
  const handleHowToPay = () => {
    Modal(<ChannelTypes />, {
      title: "",
      showCloseButton: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 350,
    });
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      setIsHovered((prev) => !prev);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="mx-3">
      <div
        className={`mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 ${gridClass}`}
      >
        {widgets.map((widget, index) => {
          return (
            <Widget
              key={index}
              icon={widget.icon}
              title={widget.title}
              subtitle={widget.subtitle}
              type={widget.type}
            />
          );
        })}
      </div>

      <div className="my-16 md:my-[100px] w-full flex flex-col items-center justify-center">
        <p className="text-center m-0 text-4xl font-bold text-primary dark:text-primary">
          {t("homeDashboard.hi")} {c120_nombres}
          {"!"}
        </p>
        <p className="m-0 text-4xl font-bold text-secondary-800 dark:text-white">
          {getValueStorage("profileData").c120_ind_sexo === "0"
            ? t("homeDashboard.welcomeMen")
            : t("homeDashboard.welcomeFemale")}
        </p>
      </div>
      {/* TARJETAS CREDITO ACTIVO Y PAGO */}
      <div className="mx-auto flex gap-5 flex-col lg:flex-row mb-[20px] lg:h-[20rem]">
        <Card extra="flex-1 flex-grow items-center justify-center py-6 flex-col h-full px-6">
          <p className="m-0 text-2xl font-bold text-primary dark:text-white">
            {t("homeDashboard.card1.yourCredit")}
          </p>
          <p className="m-0 text-base text-secondary-600 dark:text-white">
            {loan?.description} - {loan?.c200_plazo} cuotas
          </p>
          <p className="mt-4 text-base font-bold text-secondary-800 dark:text-white">
            {t("homeDashboard.card1.nextDueValue")}
          </p>
          <p className="m-0 text-4xl font-bold text-primary dark:text-primary">
            $
            {formatNumberPoint(
              parseInt(loan?.totalDue ?? "0") - valueParcialPay
            )}
          </p>
          {/* Solo desktop */}
          <div className="m-0 mt-8 hidden sm:block">
            <p className=" text-base font-bold text-secondary-700 dark:text-white ">
              {t("homeDashboard.card1.limitDatePayment")}
              {":"}
              <span className="text-balance ml-2 text-secondary-800 dark:text-white">
                {loan?.limitDate &&
                  formatDateTime(loan?.limitDate + " 00:00:00", t)}
              </span>
            </p>

            <p className="m-0 mt-2 text-base font-bold text-secondary-700 dark:text-white">
              {t("homeDashboard.card1.payReferences")}
              {":"}
              <span className="ml-2 text-primary dark:text-primary">
                {loan?.c200_rowid}
              </span>
            </p>
          </div>

          {/* Solo mobile */}
          <div className=" mt-8 block sm:hidden text-center">
            <p className=" text-base font-bold text-secondary-700 dark:text-white ">
              {t("homeDashboard.card1.limitDatePayment")}
              {":"}
            </p>
            <p className="font-bold text-balance text-secondary-800 dark:text-white">
              {loan?.limitDate &&
                formatDateTime(loan?.limitDate + " 00:00:00", t)}
            </p>

            <p className="mt-4 text-base font-bold text-secondary-700 dark:text-white">
              {t("homeDashboard.card1.payReferences")}
              {":"}
            </p>
            <div className="text-primary font-bold dark:text-primary">
              {loan?.c200_rowid}
            </div>
          </div>

          <div
            className="mt-3 cursor-pointer text-primary hover:opacity-75 font-medium	"
            onClick={seeRates}
          >
            {t("homeDashboard.viewRates")}
            <ChevronRightIcon />
          </div>
        </Card>
        <Card extra="!flex-row flex-1 py-6 px-4 2xl:px-6 justify-center gap-2 h-full">
          <div className="w-[25%] items-center justify-center hidden 3xl:flex">
            <img
              className="w-[130px] h-[130px] rounded-full"
              src={require("./assets/images/pse_logo.png")}
              alt=""
            />
          </div>
          <div className="w-full 3xl:w-[75%] flex justify-center items-center flex-col">
            <p className="m-0 text-2xl md:text-xl font-bold text-primary dark:text-primary">
              {t("homeDashboard.card2.title")}
            </p>

            <p className="m-0 text-base font-bold text-secondary-800 dark:text-white">
              {t("homeDashboard.card2.subtitle")}
            </p>

            <p className="my-4 text-lg text-secondary-800 dark:text-white">
              {t("homeDashboard.card2.description")}
            </p>
            <div className="flex w-full justify-center items-center gap-2 flex-col 3xl:flex-row">
              <button
                className="w-full sm:w-content rounded-lg bg-primary py-3 px-2 text-white font-bold text-base"
                onClick={doPay}
              >
                {t("homeDashboard.card2.button1")}
              </button>
              <button
                className="w-full sm:w-content rounded-lg bg-primary py-3 px-2 text-white font-bold text-base"
                onClick={paymentHistory}
              >
                {t("homeDashboard.card2.button2")}
              </button>
            </div>
          </div>
        </Card>
      </div>
      {/* Tarjetas */}
      <div className=" flex gap-5 flex-col lg:flex-row mb-[80px] h-full ">
        <Card extra="flex-1 flex-grow items-center justify-center py-6 flex-col min-h-[257px] px-2">
          <div className="min-h-[120px] w-[80%] sm:w-[280px] lg:w-[200px] 2xl:w-[250px] flex items-center justify-center ">
            <div className="relative w-full h-full flex items-center justify-center">
              <Box
                top={0}
                bottom={0}
                display="flex"
                alignItems="center"
                justifyContent="right"
                className="dark:text-white"
                sx={{
                  fontWeight: "bold",
                  fontSize: 11,
                  mr: 0.2,
                }}
              >
                {`${(percentValState + "").split(".")[0]}%`}
              </Box>
              <div
                className={`loading ${
                  loan === null ||
                  pending_loans.length !== 0 ||
                  disabledRenewal ||
                  loan.c200_ind_estado + "" !== "5" ||
                  (loan.c200_ind_estado + "" === "11" &&
                    !loan.c200_rowid_nuevo_prestamo) ||
                  (loan.c200_ind_estado + "" !== "11" &&
                    loan.c200_rowid_nuevo_prestamo)
                    ? "paused"
                    : ""
                }`}
                title={`Porcentaje pagado: ${percentValState}%`}
                style={
                  {
                    "--loading-percentage": `${percentValState}%`,
                  } as React.CSSProperties
                }
              />

              <Box
                className="top-[-17px] lg:top-[35px] "
                sx={{
                  position: "absolute",
                  left: "70%",
                  transform: "translateX(-50%)",
                  zIndex: 2,
                }}
              >
                <img
                  className="w-[30px] sm:w-[35px] "
                  src={require("./assets/images/Estrella.webp")}
                  alt=""
                />
                <p className="text-secondary-800 dark:text-white font-normal text-sm text-center">
                  70%
                </p>
              </Box>
            </div>
          </div>

          <div className="text-center mt-2">
            <p className=" text-2xl md:text-xl font-bold text-primary dark:text-primary">
              {t("homeDashboard.cards3.renewYourCredit")}
            </p>
            <p className="leading-4 text-sm font-semibold text-secondary-800 dark:text-white mx-[40px]">
              {t("homeDashboard.cards3.requestRenewalOneClick")}
            </p>
          </div>
          <button
            className={`w-fit sm:w-content rounded-lg  py-1 px-3 text-white font-bold  font-medium text-xs mt-4 ${
              loan === null || pending_loans.length !== 0 || disabledRenewal
                ? "hover:opacity-100 bg-gray"
                : "hover:opacity-75 bg-primary"
            }`}
            onClick={handleRenewal}
            disabled={
              loan === null || pending_loans.length !== 0 || disabledRenewal
              // || !headertohome?.renovation
            }
          >
            {t("homeDashboard.cards3.seeMore")}
            <ChevronRightIcon sx={{ fontSize: 20 }} />
          </button>
        </Card>
        <Card extra="flex-1 flex-grow items-center justify-center py-6 flex-col min-h-[257px] px-2">
          <Lottie
            style={{
              width: 120,
              height: 120,
            }}
            loop
            autoPlay
            animationData={require("./assets/animations/enviar.json")}
          />
          <div className="text-center mt-2 ">
            <p className=" text-2xl md:text-xl font-bold text-primary dark:text-primary">
              {t("homeDashboard.cards3.referAndEarn")}
            </p>
            <p className="leading-4 text-sm font-semibold text-secondary-800 dark:text-white mx-6">
              {t("homeDashboard.cards3.referFriendsAndEarn")}
            </p>
          </div>
          <button
            className="w-fit sm:w-content rounded-lg bg-primary py-1 px-3 text-white font-bold  hover:opacity-75 font-medium text-xs mt-4"
            onClick={handleReferrals}
          >
            {t("homeDashboard.cards3.seeMore")}
            <ChevronRightIcon sx={{ fontSize: 20 }} />
          </button>
        </Card>
        <Card extra="flex-1 flex-grow items-center justify-center py-6 flex-col min-h-[257px] px-2">
          <div className="container flex justify-center">
            <div className="left-side">
              <div className={`card ${isHovered ? "hover" : ""}`}>
                <div className="card-line"></div>
                <div className="buttons"></div>
              </div>
              <div className={`post ${isHovered ? "hover" : ""}`}>
                <div className="post-line"></div>
                <div className="screen">
                  <div className={`dollar ${isHovered ? "hover" : ""}`}>$</div>
                </div>
                <div className="numbers"></div>
                <div className="numbers-line2"></div>
              </div>
            </div>
          </div>
          <div className="text-center mt-2.5 ">
            <Box
              className="text-2xl md:text-xl font-bold text-primary dark:text-primary"
              sx={{ lineHeight: { lg: "23px" } }}
            >
              {t("homeDashboard.cards3.howToPayCredit")}
            </Box>
            <p className="leading-4 text-sm font-semibold text-secondary-800 dark:text-white">
              {t("homeDashboard.cards3.paymentPoints")}
            </p>
          </div>
          <button
            className="w-fit sm:w-content rounded-lg bg-gray py-1 px-3 text-white font-bold font-medium text-xs mt-5"
            onClick={handleHowToPay}
            disabled
          >
            {t("homeDashboard.cards3.seeMore")}
            <ChevronRightIcon sx={{ fontSize: 20 }} />
          </button>
        </Card>
      </div>
    </div>
  );
}
