import * as Yup from "yup";

export const yupBankValidations = Yup.object().shape({
  bank: Yup.string().required("Selecciona una opción"),
  type_account: Yup.string().required("Selecciona una opción"),
  number_account: Yup.string()
    .matches(/^[0-9]*$/, "El número de cuenta solo debe contener números")
    .min(7, "El número de cuenta debe tener al menos 7 dígitos")
    .required("El campo es obligatorio"),
});
