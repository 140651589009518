import { errorAlert } from "../../common/alerts.tsx";
import {
  detectDevice,
  formatDateToDBString,
  getCurrentFormattedDate,
  isLoanDataEmpty,
  requestSquematic,
} from "../../common/CommonFunctions.tsx";
import { saveValueStorage, wipeStorage } from "../../common/storage.ts";
import i18n from "../../constants/translations/i18n";
import { IUserAuth } from "../../constants/types.ts";

export const handleSigInService = async (
  email: { value: string; isValid: boolean },
  password: string,
  setIsLoading: (isLoading: boolean) => void,
  signIn: (userAuth: IUserAuth) => void,
  navigate: (path: string) => void
) => {
  if (!email.isValid) {
    errorAlert("Error", "El formato del correo electrónico no es válido.", "");
    return true;
  }
  setIsLoading(true);

  const data = await requestSquematic("POST", "/api/app/user/login", {
    c001_clave: password,
    c001_correo_electronico: email.value,
    c001_token_dispositivo: null,
    idioma: i18n.language,
  });

  if (data) {
    // wipeStorage();
    saveValueStorage("profileData", {
      ...data.results!.user.third,
      email,
    });
    saveValueStorage("login", { email, password });

    const res = await requestSquematic("GET", "/api/website/panel", {});
    if (res) {
      saveValueStorage("panelData", res.results);
    }

    const {
      has_contact,
      has_financial,
      has_products,
      has_references,
      has_rejected,
      has_simulate,
      has_third,
      has_offered_again,
      token,
      user,
      has_validations,
      has_video_call,
      revalidate_labor_docs,
      revalidate_labor_video,
      revalidate_house_video,
      has_video_call_third,
    } = data.results!;

    const {
      c001_correo_electronico,
      c001_esactivo,
      c001_notas,
      c001_numero_celular,
      c001_codigo_referido,
      third,
      c001_rowid,
    } = user;

    let loanApproved = {
      c200_rowid: user.loan_approved?.c200_rowid ?? "",
      c200_rowid_nuevo_prestamo:
        user.loan_approved?.c200_rowid_nuevo_prestamo ?? "",
      c200_ind_estado: user.loan_approved?.c200_ind_estado ?? "",
    };

    let loanActive = {
      c200_rowid: user.loan_active?.c200_rowid ?? "",
      c200_rowid_nuevo_prestamo:
        user.loan_active?.c200_rowid_nuevo_prestamo ?? "",
      c200_ind_estado: user.loan_active?.c200_ind_estado ?? "",
    };

    const userLoged = {
      dashboard: false,
      isOld: user.is_old,
      credits_finished: user.credits_finished,
      userId: c001_rowid,
      login: true,
      email: c001_correo_electronico,
      phone: c001_numero_celular,
      active: c001_esactivo,
      notes: c001_notas,
      photo:
        third?.attached_file?.c180_ruta?.replace("images", "restored_image") ??
        "",
      token,
      referral: c001_codigo_referido,
      hasContact: has_contact,
      hasFinancial: has_financial,
      financialData: has_financial,
      hasProduct: has_products,
      hasReferences: has_references,
      hasRejected: has_rejected,
      hasSimulate: has_simulate,
      hasThird: has_third,
      hasOfferedAgain: has_offered_again,
      hasValidations: has_validations,
      hasVideoCall: has_video_call,
      loanApproved,
      loanActive,
      loanPending: {
        c200_rowid: user.loan_pending?.c200_rowid ?? "",
        c200_rowid_nuevo_prestamo:
          user.loan_pending?.c200_rowid_nuevo_prestamo ?? "",
        c200_ind_estado: user.loan_pending?.c200_ind_estado ?? "",
      },
      loanRequest: {
        c200_rowid: user.loan_request?.c200_rowid ?? "",
        c200_rowid_nuevo_prestamo:
          user.loan_request?.c200_rowid_nuevo_prestamo ?? "",
        c200_ind_estado: user.loan_request?.c200_ind_estado ?? "",
        c204_paso_renovacion: user.loan_request?.register_status
          ? user.loan_request?.register_status[0].c204_paso_renovacion
          : "",
      },
      lastLoanFinished: {
        c231_rowid:
          user.last_loan_finished?.last_banking_transaction_extension
            .c231_rowid ?? "",
        c231_referencia_credito:
          user.last_loan_finished?.last_banking_transaction_extension
            .c231_referencia_credito ?? "",
        c231_fecha_transaccion:
          user.last_loan_finished?.last_banking_transaction_extension
            .c231_fecha_transaccion ?? "",
      },
      revalidateLaborDocs: revalidate_labor_docs,
      videoCallOfferDate: "",
      revalidateLaborVideo: revalidate_labor_video,
      revalidateHouseVideo: revalidate_house_video,
      hasVideoCallThird: has_video_call_third,
      inProcessRenovation: {
        inProcessRenovation: (() => {
          // Validar si alguno de los valores es vacío
          if (
            !user.loan_active?.c200_rowid_nuevo_prestamo + "" ||
            !user.loan_request?.c200_rowid + ""
          ) {
            return false;
          }
          // Si ambos están presentes, realizar la validación
          return (
            user.loan_active?.c200_rowid_nuevo_prestamo + "" ===
            user.loan_request?.c200_rowid + ""
          );
        })(),
      },
    };

    // Funcion booleana para determinar si la (fecha de agendamiento + 10min) es menor a la fecha actual del dispositivo.
    const timeDifference = () => {
      if (has_video_call.c155_fecha_agendamiento) {
        const dateSheduled = new Date(
          has_video_call.c155_fecha_agendamiento.replaceAll(" ", "T")
        );

        // Sumamos 10 min
        dateSheduled.setMinutes(dateSheduled.getMinutes() + 10);

        // Navega a la pantalla NoResponde cuando es true, de lo contrario a Recordatorio
        const value =
          formatDateToDBString(dateSheduled) < getCurrentFormattedDate();
        return value;
      }
    };

    isLoanDataEmpty(userLoged, signIn);
    // Función para navegar a la página de registro
    const navigationNow = () => navigate("/sign-up");

    if (!loanActive.c200_rowid) {
      if (has_rejected) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 20,
          desc: "el usuario se logea y el credito fue rechazado por el cliente o el panel",
        });
        navigationNow();
      } else if (
        revalidate_labor_docs.c110_json &&
        has_video_call?.c155_rowid_estado + "" === "6"
      ) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 26,
          desc: "el usuario se logea y se envia a validacion de documentos",
        });
        navigationNow();
      } else if (
        revalidate_labor_docs.c110_json &&
        (has_video_call?.c155_rowid_estado + "" === "4" ||
          has_video_call_third?.c155_rowid_estado + "" === "6")
      ) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 27,
          desc: "el usuario se logea y se solicitan los documentos",
        });
        navigationNow();
      } else if (revalidate_labor_video.c110_json) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 28,
          desc: "el usuario se logea y se solicita video del negocio",
        });
        navigationNow();
      } else if (has_validations) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 29,
          desc: "el usuario se logea y tiene revalidaciones",
        });
        navigationNow();
      } else if (!has_third) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 3,
          desc: "el usuario se logea y no tiene datos personales",
        });
        navigationNow();
      } else if (!has_contact) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 5,
          desc: "el usuario se logea y no tiene informacion de contacto",
        });
        navigationNow();
      } else if (!has_financial || !has_financial.c126_rowid) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 6,
          desc: "el usuario se logea y no tiene informacion financiera",
        });
        navigationNow();
      } else if (!has_references) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 8,
          desc: "el usuario se logea y no tiene informacion de referencias",
        });
        navigationNow();
      } else if (!has_simulate) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 10,
          desc: "el usuario se logea y no tiene la simulacion",
        });
        navigationNow();
      } else if (
        has_video_call.c155_tipo + "" === "2" &&
        has_video_call.c155_rowid_estado + "" === "9"
      ) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 38,
          desc: "el usuario se logea y no tiene agendamiento de videollamada laboral",
        });
        navigationNow();
      } else if (
        !has_video_call.c155_rowid_estado &&
        user.loan_request?.c200_rowid &&
        !user.loan_active?.c200_rowid &&
        !user.is_old
      ) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 14,
          desc: "el usuario se logea y no tiene agendamiento de videollamada",
        });
        navigationNow();
      } else if (detectDevice() !== "Desktop" && has_video_call.c155_url) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 21,
          desc: "el usuario se logea y tiene videollamada entrante",
        });
        navigationNow();
      } else if (has_video_call.c155_url && !timeDifference()) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 16,
          desc: "el usuario se logea y tiene videollamada entrante, pero no es mobile",
        });
        navigationNow();
      } else if (
        ["1", "2"].includes(has_video_call.c155_rowid_estado + "") &&
        !timeDifference() &&
        !has_video_call.c155_url &&
        !user.loan_active?.c200_rowid &&
        !user.isOld
      ) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 16,
          desc: "el usuario se logea y tiene agendamiento de videollamada",
        });
        navigationNow();
      } else if (
        (!timeDifference() && has_video_call.c155_rowid_estado + "" === "5") ||
        (timeDifference() && has_video_call.c155_rowid_estado + "" === "5") ||
        (["1", "2"].includes(has_video_call.c155_rowid_estado + "") &&
          timeDifference() &&
          !user.loan_active?.c200_rowid &&
          !user.isOld)
      ) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 19,
          desc: "el usuario se logea y no atiende la vc",
        });
        navigationNow();
      } else if (
        (!has_products &&
          !has_video_call.c155_url &&
          has_video_call?.c155_rowid_estado + "" === "6") ||
        (loanApproved.c200_ind_estado + "" === "3" && !loanActive?.c200_rowid)
      ) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 24,
          desc: "el usuario se logea y esta en espera",
        });
        navigationNow();
      } else if (
        has_video_call.c155_rowid_estado + "" === "9" &&
        has_video_call.calcel_reason?.c157_motivo &&
        !loanActive?.c200_rowid &&
        !user.isOld
      ) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 25,
          desc: "el usuario se logea y tiene que reagendar la vc",
        });
        navigationNow();
      } else if (userLoged.isOld && !userLoged.loanActive?.c200_rowid) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 30,
          desc: "el usuario se logea, es antiguo pero no tiene creditos activos",
        });
        navigationNow();
      }
    } else {
      if (detectDevice() !== "Desktop" && has_video_call.c155_url) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 21,
          desc: "el usuario se logea y tiene videollamada entrante",
        });
        navigationNow();
      } else if (has_video_call.c155_url && !timeDifference()) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 16,
          desc: "el usuario se logea y tiene videollamada entrante, pero no es mobile",
        });
        navigationNow();
      } 
      else if (
        revalidate_labor_docs.c110_json &&
        has_video_call?.c155_rowid_estado + "" === "6"
      ) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 26,
          desc: "el usuario se logea y se envia a validacion de documentos",
        });
        navigationNow();
      } else if (
        revalidate_labor_docs.c110_json &&
        (has_video_call?.c155_rowid_estado + "" === "4" ||
          has_video_call_third?.c155_rowid_estado + "" === "6")
      ) {
        saveValueStorage("registerStep", {
          prevStep: "login",
          nextStep: 27,
          desc: "el usuario se logea y se solicitan los documentos",
        });
        navigationNow();
      } 
      else {
        userLoged.dashboard = true;
        isLoanDataEmpty(userLoged, signIn);
        navigate("/");
      }
    }
  }
  setIsLoading(false);
};
