import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../../../styles/theme.tsx";
import { AuthContext } from "../../../../hooks/useAuth.tsx";
import { ColorButton } from "../../../../components/Button.tsx";
import { Modal, MySwal } from "../../../../common/alerts.tsx";
import { KeyboardBackspaceIcon } from "../../../Dashboard/assets/iconsMui.tsx";
import MoneyReferrals from "./MoneyReferrals.tsx";

export default function HowWorks() {
  const { t } = useTranslation();
  const { getUser } = React.useContext(AuthContext);
  const steps = [
    {
      step: "2",
      desc: t("howWorks.step2"),
    },
    {
      step: "3",
      desc: t("howWorks.step3"),
    },
    {
      step: "4",
      desc: t("howWorks.step4"),
    },
  ];

  //Informacion sobre como cobrar los referidos
  const handleArrearsReferrals = () => {
    MySwal.close();
    Modal(<ArrearsReferrals />, {
      title: "",
      showCloseButton: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 800,
      customClass: {
        popup: `bg-snow dark:bg-navy-800 px-0 sm:w-auto`,
        htmlContainer: " !overflow-x-hidden",
      },
    });
  };

  //Informacion sobre como cobrar los referidos
  const handleMoneyReferrals = () => {
    MySwal.close();
    Modal(<MoneyReferrals />, {
      title: "",
      showCloseButton: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 800,
      customClass: {
        popup: `bg-snow dark:bg-navy-800 px-0 sm:w-auto`,
        htmlContainer: " !overflow-x-hidden",
      },
    });
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      marginTop={2}
    >
      <p className="mt-5 mb-3 text-xl font-bold text-primary uppercase">
        {t("howWorks.title")}
      </p>
      {/* <Box sx={{ width: "80%" }}>
        <img src={require("../../../../assets/image/ManosLogo.webp")} alt="how works" loading="lazy" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
      </Box> */}
      <Box sx={{ width: "100%" }}>
        <p className="text-base mb-1 text-left dark:text-white">
          {t("howWorks.desc")}
        </p>
        <p
          className="text-base mb-1 text-left ml-4 cursor-pointer dark:text-white"
          title="Copiar al portapapeles"
          onClick={() => navigator.clipboard.writeText(getUser.referral)}
        >
          <span style={{ color: colors.primary, fontWeight: "bold" }}>1.</span>{" "}
          {t("howWorks.step1").split("-")[0]}{" "}
          <span
            style={{
              color: colors.primary,
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            {t("howWorks.step1").split("-")[1]}
          </span>
        </p>
        {steps.map((item, index) => (
          <p
            className="text-base mb-1 text-left ml-4 cursor-pointer dark:text-white"
            key={index}
          >
            <span style={{ color: colors.primary, fontWeight: "bold" }}>
              {item.step}.
            </span>{" "}
            {item.desc}
            {item.step === "2" && (
              <Box
                sx={{
                  width: "100%",
                  my: 2,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Box sx={{ width: 100 }}>
                  <img
                    src={require("../../../../assets/image/LogoTienda.webp")}
                    alt={""}
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Box>
            )}
          </p>
        ))}
        <ColorButton
          onClick={handleArrearsReferrals}
          sx={{
            width: "100%",
            marginTop: 4,
            paddingY: 2,
            borderRadius: 10,
            lineHeight: 1,
          }}
        >
          {t("howWorks.button1")}
        </ColorButton>
        <ColorButton
          onClick={handleMoneyReferrals}
          sx={{
            width: "100%",
            marginTop: 1,
            paddingY: 2,
            borderRadius: 10,
            lineHeight: 1,
          }}
        >
          {t("howWorks.button2")}
        </ColorButton>
      </Box>
    </Box>
  );
}

function ArrearsReferrals() {
  const { t } = useTranslation();
  const requirements = [
    {
      rule: "Regla 1",
      desc: t("arrearsReferrals.rule1"),
    },
    {
      rule: "Regla 2",
      desc: t("arrearsReferrals.rule2"),
    },
    {
      rule: "Regla 3",
      desc: t("arrearsReferrals.rule3"),
    },
    {
      rule: "Regla 4",
      desc: t("arrearsReferrals.rule4"),
    },
  ];

  //Volver atras
  const handleBack = () => {
    MySwal.close();
    Modal(<HowWorks />, {
      title: "",
      showCloseButton: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 800,
      customClass: {
        popup: `bg-snow dark:bg-navy-800 px-0 sm:w-auto`,
        htmlContainer: " !overflow-x-hidden",
      },
    });
  };

  return (
    <>
      <div className="relative">
        <button
          className="absolute flex flex-row gap-1 items-center text-secondary-800 hover:opacity-80 dark:text-primary top-0 left-0 "
          onClick={handleBack}
        >
          <KeyboardBackspaceIcon />
          <p className="text-sm ">{t("common.back")}</p>
        </button>
      </div>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        marginTop={2}
      >
        <p className="my-3 text-xl font-bold text-primary uppercase">
          {t("arrearsReferrals.title")}
        </p>
        <Box>
          <p className="mb-1 text-base text-left dark:text-white">
            {t("arrearsReferrals.desc")}
          </p>
          {requirements.map((item, index) => (
            <p className="mb-1 text-base text-left dark:text-white" key={index}>
              <span style={{ color: colors.primary, fontWeight: "bold" }}>
                {item.rule}:
              </span>
              <br />
              <span style={{ color: colors.primary, fontWeight: "bold" }}>
                *{"  "}
              </span>
              {item.desc}
            </p>
          ))}
        </Box>
      </Box>
    </>
  );
}
