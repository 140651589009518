import React from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../hooks/useAuth.tsx";
import {
  formatNumberPoint,
  requestSquematic,
} from "../../../../common/CommonFunctions.tsx";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../../styles/theme.tsx";
import { ColorButton } from "../../../../components/Button.tsx";
import {
  confirmationAlert,
  Modal,
  successAlert,
} from "../../../../common/alerts.tsx";
import MyReferrals from "./MyReferrals.tsx";
import MovementsReferrals from "./MovementsReferrals.tsx";
import HowWorks from "./HowWorks.tsx";
import { KeyboardBackspaceIcon } from "../../../Dashboard/assets/iconsMui.tsx";
import { getValueStorage, saveValueStorage } from "../../../../common/storage.ts";
import { IRegisterStep, IRegisterStepState } from "../../RegisterComponent.tsx";
import { IUserAuth } from "../../../../constants/types.tsx";

const limitValue = 30000;

export const isAccepted = (c200_ind_estado: string) =>
  c200_ind_estado === "5" ||
  c200_ind_estado === "9" ||
  c200_ind_estado === "11";

export default function Referrals({ setRegisterStep }: IRegisterStepState) {
  const { t } = useTranslation();
  const { getUser, setIsLoading, signOut } = React.useContext(AuthContext);
  const authSession: IUserAuth = getValueStorage("authSession");
  const [data, setData] = React.useState({
    amount: 0,
    requestAmount: 0,
    hasPending: false,
  });

  const fetchData = async () => {
    setIsLoading(true);

    const data = await requestSquematic(
      "GET",
      "/api/app/referred/init",
      {},
      authSession.token,
      signOut
    );

    if (data) {
      let amount = 0;
      let hasPending = false;
      let requestAmount = 0;

      data.results!.forEach((item) => {
        if (item.c160_ind_estado_solicitud === "1") {
          requestAmount += parseInt(item.c160_valor_pagado);
          hasPending = true;
        } else if (
          item.c160_ind_estado_solicitud === "0" &&
          isAccepted(
            item.user_referred.third?.first_loan?.c200_ind_estado ?? ""
          )
        ) {
          amount += parseInt(item.c160_valor_pagado);
        }
      });

      setData({
        amount,
        hasPending,
        requestAmount,
      });
    }

    setIsLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  //Solicitar desembolso de saldo por referidos
  const handleRequest = async () => {
    const confirmation = await confirmationAlert(
      t("referrals.requestModal.title"),
      t("referrals.requestModal.desc"),
      t("referrals.requestModal.button")
    );
    if (confirmation) {
      setIsLoading(true);

      const data = await requestSquematic(
        "POST",
        "/api/app/referred/disburse_referrals",
        {},
        authSession.token,
        signOut
      );

      if (data) {
        await successAlert("Solicitud de desembolso realizada con exito");
        fetchData();
      }

      setIsLoading(false);
    }
  };

  //Ver los referidos
  const handleMyReferrals = () => {
    Modal(<MyReferrals />, {
      title: "",
      showCloseButton: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 800,
      customClass: {
        popup: `bg-snow dark:bg-navy-800 px-0 sm:w-auto`,
        htmlContainer: " !overflow-x-hidden",
      },
    });
  };

  //Ver los movimientos de referidos
  const handleSeeRefMovements = () => {
    Modal(<MovementsReferrals />, {
      title: "",
      showCloseButton: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 800,
      customClass: {
        popup: `bg-snow dark:bg-navy-800 px-0 sm:w-auto`,
        htmlContainer: " !overflow-x-hidden",
      },
    });
  };

  //Ver como funcionan los referidos
  const handleHowWorks = () => {
    Modal(<HowWorks />, {
      title: "",
      showCloseButton: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 800,
      customClass: {
        popup: `bg-snow dark:bg-navy-800 px-0 sm:w-auto`,
        htmlContainer: " !overflow-x-hidden",
      },
    });
  };

  //Volver atras
  const handleBack = () => {
    let objectStep: IRegisterStep = {
      prevStep: 31,
      nextStep: 30,
      desc: "pasa a ver los referidos",
    };
    saveValueStorage("registerStep", objectStep);
    if (setRegisterStep) {
      setRegisterStep(objectStep);
    }
  };

  return (
    <>
      {setRegisterStep && (
        <div style={{ marginBottom: 30 }} className="relative">
          <button
            className="absolute flex flex-row gap-1 items-center text-secondary-800 hover:opacity-80 dark:text-primary top-0 left-[-30px]"
            onClick={handleBack}
          >
            <KeyboardBackspaceIcon />
            <p className="text-sm ">{t("common.back")}</p>
          </button>
        </div>
      )}

      <div className="flex flex-col items-center justify-center">
        <p className="mt-5 mb-3 text-xl font-bold text-primary dark:text-primary uppercase">
          {t("referrals.title")}
        </p>
        <p className="mb-3 text-2xl font-bold dark:text-white">
          ${formatNumberPoint(data.amount)}
        </p>
        <ColorButton
          onClick={handleRequest}
          sx={{
            width: { sm: 279 },
            marginTop: 1,
            paddingY: 1,
            borderRadius: 10,
          }}
          disabled={
            getUser.isLoading || data.amount < limitValue || data.hasPending
          }
        >
          {t("referrals.button")}
        </ColorButton>
        <p className="mt-5 text-xl font-bold dark:text-white">
          {t("referrals.yourCode")}
        </p>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: colors.primary,
            textAlign: "center",
            marginY: 3,
            cursor: "pointer",
          }}
          title="Copiar al portapapeles"
          onClick={() => navigator.clipboard.writeText(getUser.referral)}
        >
          {getUser.referral}
        </Typography>
        <ColorButton
          onClick={handleMyReferrals}
          sx={{
            width: "100%",
            marginTop: 1,
            paddingY: 2,
            borderRadius: 10,
            lineHeight: 1
          }}
        >
          {t("referrals.yourReferrals")}
        </ColorButton>
        <ColorButton
          onClick={handleSeeRefMovements}
          sx={{
            width: "100%",
            marginTop: 1,
            paddingY: 2,
            borderRadius: 10,
            lineHeight: 1
          }}
        >
          {t("referrals.history")}
        </ColorButton>
        <ColorButton
          onClick={handleHowWorks}
          sx={{
            width: "100%",
            marginTop: 1,
            paddingY: 2,
            borderRadius: 10,
            lineHeight: 1
          }}
        >
          {t("referrals.howWorks")}
        </ColorButton>
      </div>
    </>
  );
}
