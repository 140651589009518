import React, { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import { useDarkMode } from "../hooks/DarkModeProvider.js";
import { useTranslation } from "react-i18next";
import HomeDashboard from "../HomeDashboard.tsx";
import Profile from "../pages/User/Profile.tsx";
import {
  DehazeIcon,
  HomeIcon,
  LogoutIcon,
  PersonIcon,
} from "../assets/iconsMui.tsx";

export const DinamicRoutes = () => {
  const { t } = useTranslation();
  return [
    {
      name: t("homeDashboard.title"),
      path: "/",
      icon: <HomeIcon />,
      component: <HomeDashboard headertohome={{}} removeFunction={() => {}} />,
      labelLanguage: "homeDashboard.title",
    },
    {
      name: t("profile.title"),
      path: "/profile",
      icon: <PersonIcon />,
      component: <Profile />,
      labelLanguage: "profile.title",
    },
  ];
};

export default function SidebarDashboard({ open, onClose }) {
  const { t } = useTranslation();
  const routes = DinamicRoutes();
  let location = useLocation();
  const { signOut, setIsLoading } = useContext(AuthContext);
  const navigation = useNavigate();
  const { isDarkMode } = useDarkMode();

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return location.pathname === routeName;
  };

  const handleSignOut = () => {
    setIsLoading(true);
    setTimeout(() => {
      signOut();
      navigation("/");
    }, 1000);
  };

  return (
    <div
      className={`w-[313px] sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-[100vw]"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <DehazeIcon className="h-5 w-5" />
      </span>

      <div className={`mt-[50px] flex items-center`}>
        <img
          src={
            isDarkMode
              ? require("../../../assets/image/LogoHorizontalBlanco.webp")
              : require("../../../assets/image/LogoHorizontalVerde.webp")
          }
          alt="logo"
          className="w-[60%] display-block mx-auto"
        />
      </div>

      {/* Divider */}
      <div className="my-[48px] h-px bg-secondary-300 dark:bg-white/30" />

      {/* Nav items */}
      <ul className="mb-auto pt-1">
        {routes.map((route, index) => {
          return (
            <Link key={index} to={route.path}>
              <div className="relative mb-3 flex hover:cursor-pointer">
                <li
                  className="my-[3px] flex cursor-pointer items-center px-8"
                  key={index}
                >
                  <span
                    className={`${
                      activeRoute(route.path) === true
                        ? "font-bold text-primary dark:text-white"
                        : "font-medium text-secondary-600"
                    }`}
                  >
                    {route.icon ? route.icon : "default icon"}{" "}
                  </span>
                  <p
                    className={`leading-1 ml-4 flex ${
                      activeRoute(route.path) === true
                        ? "font-bold text-primary dark:text-white"
                        : "font-medium text-secondary-600"
                    }`}
                  >
                    {route.name}
                  </p>
                </li>
                {activeRoute(route.path) ? (
                  <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-primary dark:bg-white" />
                ) : null}
              </div>
            </Link>
          );
        })}

        <button
          key={routes.length}
          onClick={handleSignOut}
          style={{ all: "unset" }}
        >
          <div className="relative mb-3 flex hover:cursor-pointer">
            <li
              className="my-[3px] flex cursor-pointer items-center px-8"
              key={routes.length}
            >
              <span className={"font-medium text-secondary-600"}>
                <LogoutIcon />
              </span>
              <p
                className={"leading-1 ml-4 flex font-medium text-secondary-600"}
              >
                {t("common.signOut")}
              </p>
            </li>
          </div>
        </button>
      </ul>

      {/* Nav item end */}
    </div>
  );
}
