import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import { useTranslation } from "react-i18next";
import { ColorButton } from "../../../components/Button.tsx";
import Lottie from "lottie-react";
import { getValueStorage, saveValueStorage } from "../../../common/storage.ts";
import { IRegisterStepState } from "../RegisterComponent.tsx";
import { IUserAuth } from "../../../constants/types.tsx";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import { useNavigate } from "react-router-dom";

export default function EndedVC({ setRegisterStep }: IRegisterStepState) {
  const { t } = useTranslation();
  const { signIn } = React.useContext(AuthContext);
  const navigate = useNavigate();

  const handleContinue = () => {
    const getUserTemp: IUserAuth = getValueStorage("authSession");
    if (getUserTemp.loanActive?.c200_rowid) {
      let obj = {
        ...getUserTemp,
        dashboard: true,
      }
      signIn(obj);
      saveValueStorage("authSession", obj);
      navigate("/");
    } else if (setRegisterStep) {
      let obj = {
        prevStep: 23,
        nextStep: 24,
        desc: "La asesora finalizo la llamada",
      }
      saveValueStorage("registerStep", obj);
      setRegisterStep(obj);
    }
  }

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Box
          sx={{
            mt: 5,
            marginBottom: 2,
          }}
        >
          <Box
            sx={{ width: 150, height: 150 }}
          >
            <Lottie
              style={{
                width: "100%",
                height: "100%",
              }}
              loop={false}
              autoPlay
              animationData={require("../../../assets/animations/check.json")}
            />
          </Box>
        </Box>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 17,
            color: colors.primary,
            textTransform: "uppercase",
            mt: 1,
            marginBottom: 0.3,
            width: 250,
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("endedVC.title")}
        </Typography>
      </Box>
      {/* ¿Cuál es el siguiente paso? */}
      <Box textAlign={"center"} sx={{ marginX: { xs: 1, sm: 3, md: 5 } }}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 17,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
            marginTop: 10,
          }}
          lineHeight={1}
        >
          {t("endedVC.proccess")}
        </Typography>

        <Typography
          sx={{
            fontSize: 16,
            color: colors.black,
            lineHeight: 1.3,
            marginBottom: "20px",
            textAlign: "justify",
            mt: 2,
          }}
        >
          {t("endedVC.proccess1_1")}
          <strong> {t("endedVC.proccess1_2")}</strong>
          {t("endedVC.proccess1_3")}
          <strong> {t("endedVC.proccess1_4")} </strong>
          {t("endedVC.proccess1_5")}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        marginTop={8}
      >
        <ColorButton
          sx={{
            width: "fit-content",
            padding: "15px 40px",
            fontSize: 16,
          }}
          onClick={handleContinue}
        >
          {t("common.continue")}
        </ColorButton>
      </Box>
    </>
  );
}
