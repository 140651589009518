import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import { useTranslation } from "react-i18next";
import { ColorButton } from "../../../components/Button.tsx";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import {
  IncommingVCButton,
  PulsatingImage,
} from "../../../assets/animations/customAnimations.tsx";
import {
  calculateRemainingTime,
  requestSquematic,
  useMatches,
} from "../../../common/CommonFunctions.tsx";
import { saveValueStorage } from "../../../common/storage.ts";
import moment from "moment";
import { IRegisterStepState } from "../RegisterComponent.tsx";

export default function IncommingVC({ setRegisterStep }: IRegisterStepState) {
  const { t } = useTranslation();
  const { getUser, signIn, setIsLoading } = React.useContext(AuthContext);
  const { matchesLG } = useMatches();
  const [time, setTime] = React.useState<string>("00:00");

  const matchesXS = useMediaQuery("(min-width:488px)");
  const matchesminSm = useMediaQuery("(min-width:500px)");

  // Función para manejar el rechazo de la llamada
  const handleDeclineCall = async () => {
    setIsLoading(true)
    getUser.hasVideoCall.c155_rowid_estado = "5";
    getUser.hasVideoCall.c155_url = "";

    const resVC = await requestSquematic(
      "POST",
      "/api/panel/vc_validations/update_video_call",
      getUser.hasVideoCall,
      getUser.token
    );

    if (resVC) {
      signIn({
        ...getUser,
      });
      let obj = {
        prevStep: 21,
        nextStep: 19,
        desc: "el usuario nunca contesto la llamada",
      };
      saveValueStorage("registerStep", obj);
      if (setRegisterStep) setRegisterStep(obj);
    }
    setIsLoading(false)
  };

  // Funcion para contestar la llamada
  const handleCall = () => {
    setIsLoading(true)
    let obj = {
      prevStep: 21,
      nextStep: 22,
      desc: "el usuario contesto la llamada",
    };
    saveValueStorage("registerStep", obj);
    if (setRegisterStep) setRegisterStep(obj);
    setIsLoading(false)
  };

  // UseEffect para el temporizador
  React.useEffect(() => {
    let dateAdvisor = moment(getUser.hasVideoCall.c155_fecha_inicio);
    let dateAdvanced = moment(getUser.hasVideoCall.c155_fecha_inicio)
      .tz("America/Bogota")
      .add(5, "minutes");
    let dateFormated = moment(dateAdvanced.format());

    const updateRemainingTime = () => {
      const remainingTime = calculateRemainingTime(
        dateFormated.format(),
        false
      );
      setTime(remainingTime);
      //Hacer que vibre cada 2 segundos
      if (parseInt(remainingTime.split(":")[1]) % 2 === 0) {
        if ("vibrate" in navigator) {
          navigator.vibrate(500);
        }
      }

      if (remainingTime === "00:00" && dateAdvisor) {
        // Esperamos que se muestre 00:00 antes de enviarlo a no responde y vaciar la URL
        setTimeout(() => {
          setIsLoading(true)
          signIn({
            ...getUser,
            hasVideoCall: {
              ...getUser.hasVideoCall,
              c155_rowid_estado: "39",//Estado el usuario nunca contesto
              c155_url: "",
            },
          });
          let obj = {
            prevStep: 21,
            nextStep: 19,
            desc: "el usuario nunca contesto la llamada",
          };
          saveValueStorage("registerStep", obj);
          if (setRegisterStep) setRegisterStep(obj);
          setIsLoading(false)
        }, 100);
      }
    };

    // Iniciar el intervalo del temporizador
    const intervalId = setInterval(updateRemainingTime, 1000);

    return () => {
      // Limpiar el intervalo del temporizador al desmontar el componente
      clearInterval(intervalId);
    };
  }, [getUser, signIn]);

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Box mt={3} textAlign={"center"}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: 20,
              color: colors.primary,
              textTransform: "uppercase",
            }}
            lineHeight={1}
            textAlign={"center"}
          >
            {t("incommingVC.title")}
          </Typography>
        </Box>
        <Box
          sx={{
            width: { xs: 200, sm: 250 },
            marginY: 5,
          }}
        >
          <PulsatingImage
            src={require("../../../assets/image/LogoVerticalVerde.webp")}
            alt="LogoVerticalVerde"
            sx={{ width: "100%" }}
          />
        </Box>
        <Box textAlign={"center"}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: 20,
              color: colors.primary,
              textTransform: "uppercase",
            }}
            lineHeight={1}
            textAlign={"center"}
          >
            CAMILA QUINTERO
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: 17,
              color: colors.primary,
              textTransform: "uppercase",
            }}
            lineHeight={1}
            textAlign={"center"}
          >
            {t("incommingVC.advisor")} FINOVA
          </Typography>
        </Box>
        <Box mx={{ xs: matchesXS ? 8 : 2, sm: 5 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: 18,
              color: colors.red,
              textTransform: "uppercase",
              marginBottom: 2,
              marginTop: 8,
            }}
            lineHeight={1}
            textAlign={"center"}
          >
            {t("confirmLoan.important")}
          </Typography>
          <Typography
            sx={{ marginTop: 2, lineHeight: 1.2, textAlign: "center" }}
          >
            {t("incommingVC.permissionsRequired")}
            <strong>{t("incommingVC.permissionsRequired2")}</strong>
          </Typography>
        </Box>
        <Typography
          sx={{
            marginTop: 3,
            lineHeight: 1.2,
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {t("incommingVC.videoCallNotice")}
        </Typography>
        {/* BOTONES */}
        {!matchesLG && (
          <Box
            sx={{
              width: { xs: matchesminSm ? "70%" : "90%", sm: "75%" },
              display: "flex",
              justifyContent: "space-between",
              marginTop: 5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  color: colors.primary,
                }}
              >
                {t("incommingVC.answer")}
              </Typography>
              <IncommingVCButton
                sx={{
                  backgroundColor: colors.primary,
                  border: "none",
                  width: "fit-content",
                  padding: "15px",
                  borderRadius: "50%",
                  marginTop: 1,
                }}
                onClick={handleCall}
              >
                <img
                  src={require("../../../assets/icons/phoneWhite.png")}
                  style={{ width: 40, height: 40, objectFit: "contain" }}
                  alt="phone_reject"
                />
              </IncommingVCButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  color: colors.blueGray,
                }}
              >
                {t("incommingVC.reject")}
              </Typography>
              <ColorButton
                sx={{
                  backgroundColor: colors.disabled,
                  border: "none",
                  width: "fit-content",
                  padding: "13px",
                  borderRadius: "50%",
                  marginTop: 1,
                }}
                onClick={handleDeclineCall}
              >
                <img
                  src={require("../../../assets/icons/phoneReject.png")}
                  style={{ width: 45, height: 45, objectFit: "contain" }}
                  alt="phone_reject"
                />
              </ColorButton>
            </Box>
          </Box>
        )}

        {/* TIEMPO DE ESPERA */}
        <Box
          sx={{
            width: { xs: "90%", md: "75%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            textAlign: "center",
            marginTop: { xs: 8, md: 5 },
          }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: 45, lineHeight: 1 }}>
            {time}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              color: colors.primary,
              fontSize: 22,
              lineHeight: 1,
            }}
          >
            {t("incommingVC.waitingTime")}
          </Typography>
          <Typography sx={{ fontWeight: "bold", marginTop: 2, lineHeight: 1.2 }}>
            {t("incommingVC.timeRun")}
            <br />
            <strong style={{ color: colors.primary }}>
              {t("incommingVC.subTimeRun")}
            </strong>
          </Typography>
        </Box>
      </Box>
    </>
  );
}
