import React from "react";
import { Box, Typography, Zoom } from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import {
  formatDateTime,
  useUpdatePasoRenovacion,
} from "../../../common/CommonFunctions.tsx";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import { ColorButton } from "../../../components/Button.tsx";
import { getValueStorage, saveValueStorage } from "../../../common/storage.ts";
import i18n from "../../../constants/translations/i18n.js";
import { ISimulatorData, IUserAuth, navigationModals } from "../../../constants/types.tsx";
import { IRegisterStep, IRegisterStepState } from "../RegisterComponent.tsx";
import renovationStep from "../../Dashboard/services/RenovationStep.tsx";
import { ModalDashboard } from "../../../common/alerts.tsx";
import CancelVC from "./CancelVC.tsx";

type NoResponseProps = IRegisterStepState & navigationModals;

interface ICancelVc {
  prevStep: number;
  desc: string;
  setRegisterStep?: React.Dispatch<React.SetStateAction<IRegisterStep>>;
  setIsLoading?: (isLoading: boolean) => void;
  parseRenovacion?: () => any;
}
// Navegar hasta cancelar la solicitud
export const handleCancelVc = ({
  prevStep,
  desc,
  setRegisterStep,
  setIsLoading,
}: ICancelVc) => {
  if (setRegisterStep) {
    let obj = {
      prevStep: prevStep,
      nextStep: 17,
      desc: desc,
    };
    saveValueStorage("registerStep", obj);
    setRegisterStep(obj);
  } else {
    if (setIsLoading) {
      ModalDashboard({
        element: <CancelVC setIsLoading={setIsLoading} />,
      });
    }
  }
};

export default function NoResponse({
  setIsLoading,
  setRegisterStep,
}: NoResponseProps) {
  const { t } = useTranslation();
  const { parseRenovacion, updatePasoRenovacion } = useUpdatePasoRenovacion();

  const registerStep: IRegisterStep = getValueStorage("registerStep");

  //Solo navegar al calendario
  const handleReSchedule = async () => {
    const simulationData: ISimulatorData = getValueStorage("simulationData");
    const authSession: IUserAuth = getValueStorage("authSession");

    let vcInfoLoan = "";
    if (authSession.hasVideoCall) {
      vcInfoLoan = authSession.hasVideoCall.c155_rowid_prestamo;
    }

    saveValueStorage("simulationData", {
      c200_rowid: simulationData ? simulationData.c200_rowid : vcInfoLoan,
      c200_valor: simulationData ? simulationData.c200_valor : "",
      c200_rowid_ciiu: simulationData ? simulationData.c200_rowid_ciiu : "",
      c200_plazo: simulationData ? simulationData.c200_plazo : "",
      c200_rowid_concepto: simulationData
        ? simulationData.c200_rowid_concepto
        : "",
      c200_rowid_periodo: simulationData
        ? simulationData.c200_rowid_periodo
        : "",
      date_first_due: simulationData ? simulationData.date_first_due : "",
      due_value: simulationData ? simulationData.due_value : "",
      total_due_to_pay: simulationData ? simulationData.total_due_to_pay : "",
    });

    /* saveValueStorage("authSession", {
      ...authSession,
      hasVideoCall: {
        ...authSession.hasVideoCall,
        c155_rowid_estado: '2'
      }
    }); */

    let obj = {
      prevStep: 19,
      nextStep: 14,
      desc: "el usuario no respondio, se reagendara la videollamada",
    };

    saveValueStorage("registerStep", obj);
    if (setRegisterStep) {
      setRegisterStep(obj);
    } else {
      // Conserva el paso de renovacion 5
      await updatePasoRenovacion(
        "5",
        setIsLoading,
        parseRenovacion().tipoVideollamada
      );
      let dinamicElement = renovationStep({
        setIsLoading: setIsLoading,
        parseRenovacion: parseRenovacion,
      });
      if (dinamicElement) {
        ModalDashboard({
          element: dinamicElement,
        });
      }
    }
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Box
          sx={{
            width: 160,
            mt: 5,
            marginBottom: 2,
          }}
        >
          <Zoom in={true}>
            <img
              src={require("../../../assets/image/TrianguloError.webp")}
              alt={""}
              loading="lazy"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Zoom>
        </Box>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 17,
            color: colors.red,
            textTransform: "uppercase",
            mt: 4,
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("noResponse.title")}
        </Typography>
        <Typography
          className="dark:text-white"
          sx={{
            fontWeight: "bold",
            fontSize: 18,
            color: colors.black,
            textAlign: "center",
            textTransform: "uppercase",
          }}
          lineHeight={1}
        >
          <strong>{t("noResponse.subTitle")}</strong> {t("noResponse.desc")}
        </Typography>
      </Box>
      {/* Importante */}
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"left"}
        marginTop={10}
        textAlign={"left"}
        sx={{ mx: setRegisterStep ? 0 : 2 }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 17,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "20px",
          }}
          lineHeight={1}
        >
          {t("confirmLoan.important")}
        </Typography>
        <Typography
          className="dark:text-white"
          sx={{
            fontSize: 16,
            color: colors.black,
            marginBottom: "20px",
            lineHeight: 1.3,
            wordBreak: "keep-all",
          }}
        >
          · {t("noResponse.importantPoint1")}
          <strong> {t("noResponse.importantPoint1_2")}</strong>
        </Typography>
        <Typography
          className="dark:text-white"
          sx={{
            fontSize: 16,
            color: colors.black,
            marginBottom: "20px",
            lineHeight: 1.3,
          }}
        >
          · {t("noResponse.importantPoint2")}
          <strong> {t("noResponse.importantPoint2_1")} </strong>
          {t("noResponse.importantPoint2_2")}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        marginTop={8}
      >
        <ColorButton
          sx={{
            width: "fit-content",
            padding: { xs: "15px 60px", sm: "15px 80px" },
            fontSize: 16,
          }}
          onClick={handleReSchedule}
        >
          {t("videoCall.reschedule")}
        </ColorButton>
        <ColorButton
          sx={{
            width: "fit-content",
            padding: "8px 20px",
            fontSize: 13,
            mt: 1,
            background: colors.blueGray,
            borderColor: colors.blueGray,
            borderRadius: "13px !important",
          }}
          onClick={() =>
            handleCancelVc({
              prevStep: registerStep && registerStep.nextStep,
              desc: "Cancelar solicitud desde NoResponse",
              setRegisterStep,
              setIsLoading,
            })
          }
        >
          {t("common.cancelRequest")}
        </ColorButton>
      </Box>
    </>
  );
}
