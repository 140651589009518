import React from "react";
import { Modal, MySwal } from "../../../../common/alerts.tsx";
import { KeyboardBackspaceIcon } from "../../assets/iconsMui.tsx";
import { useTranslation } from "react-i18next";
import DoPay from "./DoPay.tsx";

export default function WebViewWompi({ url, setIsLoading, getUser, loanLocal, signOut }) {
  const iframeRef = React.useRef(null);
  const { t } = useTranslation();

  const handleGoBack = () => {
    MySwal.close();
    Modal(
      <DoPay setIsLoading={setIsLoading} getUser={getUser} loanLocal={loanLocal} signOut={signOut} />,
      {
        title: "",
        width: 800,
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "bg-snow dark:bg-navy-800",
          htmlContainer: "!px-3 !overflow-x-hidden",
        },
      }
    );
  }

  return (
    <div className="p-2 lg:p-8 h-[500px]">
      <div className="relative">
        <button
          className="absolute flex flex-row gap-1 items-center text-secondary-800 hover:opacity-80 top-0 left-0 "
          onClick={handleGoBack}
        >
          <KeyboardBackspaceIcon />
          <p className="text-sm ">{t("common.back")}</p>
        </button>
      </div>
      <iframe
        id="wompi_pse_iframe"
        ref={iframeRef}
        src={url}
        width="100%"
        height="100%"
        style={{ border: "none", marginTop: "30px" }}
        title='Wompi PSE'
      />
    </div>
  );
}