import { Button, ButtonProps, keyframes, styled } from "@mui/material";
import React, { CSSProperties } from "react";
import { colors } from "../../styles/theme.tsx";
import zIndex from "@mui/material/styles/zIndex";

const pulsateKeyFrames = keyframes`
  0% {
    transform: scale(1);
  }
  50% { 
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const waveKeyFrames = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.3;
  }
  100% {
    transform: scale(1.8);
    opacity: 0;
  }
`;

export const IncommingVCButton = styled(Button)<ButtonProps>({
  position: 'relative',
  zIndex: 1,
  animation: `${pulsateKeyFrames} 1s ease-in-out infinite`,
  // Agregar el pseudo-elemento para la onda
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '50%',
    backgroundColor: colors.springGreen, // color de la onda
    animation: `${waveKeyFrames} 1s ease-in-out infinite`,
    zIndex: -1,
  },
});

export const PulsatingImage = styled("img")<CSSProperties>(({ theme, ...props }) => ({
  animation: `${pulsateKeyFrames} 1.5s ease-in-out infinite`,
  ...props
}));

const bounceKeyFrames = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;

export const BounceImage = styled("img")<CSSProperties>(({ theme, ...props }) => ({
  animation: `${bounceKeyFrames} 2s ease`,
  ...props
}));