import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import { useTranslation } from "react-i18next";
import { ColorButton } from "../../../components/Button.tsx";
import { getValueStorage, saveValueStorage } from "../../../common/storage.ts";
import { useNavigate } from "react-router-dom";
import { IRegisterStepState } from "../RegisterComponent.tsx";
import renovationStep from "../../Dashboard/services/RenovationStep.tsx";
import { ModalDashboard } from "../../../common/alerts.tsx";
import { useUpdatePasoRenovacion } from "../../../common/CommonFunctions.tsx";
import { navigationModals } from "../../../constants/types.tsx";

// Combinar las dos interfaces
type RequestPreAprovedProps = IRegisterStepState & navigationModals;

export default function RequestPreAproved({
  setIsLoading,
  setRegisterStep,
}: RequestPreAprovedProps) {
  const { t } = useTranslation();

  let process = false;
  const { parseRenovacion } = useUpdatePasoRenovacion();

  const handleContinue = async () => {
    let obj = {
      prevStep: 13,
      nextStep: 14,
      desc: "se va para el calendarVc",
    };
    saveValueStorage("registerStep", obj);
    if (setRegisterStep) {
      setRegisterStep(obj);
    } else {
      // Conserva el paso de renovacion 5
      let dinamicElement = renovationStep({
        setIsLoading: setIsLoading,
        parseRenovacion: parseRenovacion,
      });
      if (dinamicElement) {
        ModalDashboard({
          element: dinamicElement,
        });
      }
    }
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Box
          sx={{
            width: { xs: 210, sm: 250 },
            marginBottom: 2,
          }}
        >
          <img
            src={require("../../../assets/image/Aprovado.webp")}
            alt={""}
            loading="lazy"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Box>

        {/* Felicitaciones */}
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: { xs: 32, sm: 35 },
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
            marginTop: 1.5,
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("preAproved.congratulations")}
        </Typography>
        {getValueStorage("authSession").inProcessRenovation
          ?.inProcessRenovation ? (
          <Typography
            sx={{
              color: colors.dimGray,
              textTransform: "uppercase",
              fontSize: 14.5,
              textAlign: "center",
            }}
            lineHeight={1}
          >
            {t("requestPreAproved.yourRenovation")}
            <strong style={{ color: colors.primary }}>
              {t("requestPreAproved.yourRenovationPreapproved")}
            </strong>
          </Typography>
        ) : (
          <Typography
            className="dark:!text-white"
            sx={{
              color: colors.dimGray,
              textTransform: "uppercase",
              fontSize: 14.5,
              textAlign: "center",
            }}
            lineHeight={1}
          >
            {t("preAproved.hasBeenPreapproved").split("*")[0]}
            <strong style={{ color: colors.primary }}>
              {t("preAproved.hasBeenPreapproved").split("*")[1]}
            </strong>
          </Typography>
        )}
      </Box>

      {/* Estas muy cerca */}
      <Box my={8} textAlign={"center"}>
        <Typography
          className="dark:!text-white"
          sx={{
            color: colors.black,
            textTransform: "uppercase",
            fontSize: 14.5,
          }}
          lineHeight={1}
        >
          {t("preAproved.toClose")}
          <strong>{t("preAproved.toClose_2")}</strong>

          {t("preAproved.toClose_3")}
        </Typography>
        <Typography
          my={0.5}
          sx={{
            fontWeight: "bold",
            fontSize: { xs: 28, sm: 31 },
            color: colors.primary,
            textTransform: "uppercase",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("preAproved.getYourMoney")}
        </Typography>
        <Typography
          className="dark:!text-white"
          sx={{
            color: colors.dimGray,
            textTransform: "uppercase",
            fontSize: 14.5,
          }}
          lineHeight={1}
        >
          {t("preAproved.getYourMoney_2")}
          <strong>{t("preAproved.24h")}</strong>
        </Typography>
      </Box>

      {/* ¿Cuál es el siguiente paso? */}
      <Box
        textAlign={"center"}
        sx={{
          marginX: {
            xs: 3,
            sm: 5,
            xl: 11,
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 17,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
        >
          {t("preAproved.whoNextStep")}
        </Typography>

        <Typography
          className="dark:!text-white"
          sx={{
            fontSize: 16,
            color: colors.black,
            lineHeight: 1.3,
            marginBottom: "20px",
            textAlign: "left",
            mt: 1.5,
          }}
        >
          {t("preAproved.nextStepP1")}
          <strong>{t("videoCall.vc")} </strong>
          {t("preAproved.nextStepP2").split("-")[0]}
          <strong>{t("preAproved.nextStepP2").split("-")[1]}</strong>
        </Typography>
      </Box>

      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        marginTop={{ xs: 5, md: "40px" }}
      >
        <ColorButton
          sx={{ width: "fit-content", padding: "15px 40px", fontSize: 16 }}
          onClick={handleContinue}
        >
          {t("calendar.scheduleV")}
        </ColorButton>
      </Box>
    </>
  );
}
