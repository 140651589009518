import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, MySwal } from "../../../../common/alerts.tsx";
import { Renovation } from "./Renovation.tsx";
import {
  ChevronRightIcon,
  KeyboardBackspaceIcon,
} from "../../assets/iconsMui.tsx";
import {
  formatNumberPoint,
  formattedDate,
} from "../../../../common/CommonFunctions.tsx";
import Lottie from "lottie-react";
import RenovationSimulator from "../../../../components/simulators/RenovationSimulator.tsx";
import {
  navigationModals,
  PropItemsLoanInit,
} from "../../../../constants/types.tsx";
import {
  getValueStorage,
  saveValueStorage,
} from "../../../../common/storage.ts";

export const ChooseCredit = ({ onClose, setIsLoading }: navigationModals) => {
  const { t } = useTranslation();
  const [loans, setLoans] = React.useState<PropItemsLoanInit[]>([]);

  React.useEffect(() => {
    setIsLoading(true);
    const data = getValueStorage("dataLoans")
      ? getValueStorage("dataLoans").loans
      : [];

    setLoans(data);
    setIsLoading(false);
  }, []);

  // Volver al modal de renovacion y cerrar el modal actual
  const handleBack = () => {
    MySwal.close();
    Modal(<Renovation onClose={onClose} setIsLoading={setIsLoading} />, {
      title: "",
      width: "auto",
      showCloseButton: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        popup: "bg-snow dark:bg-navy-800 px-0 !w-max sm:!w-[380px]",
        htmlContainer: "!px-3 !overflow-x-hidden",
      },
    });
  };

  // Navegar a RenovaionSimulator
  const handleRenovationSimulator = (simulatorData) => {
    // Guardar los datos para la simulacion en un array
    saveValueStorage("dataLoans", { selectedLoan: [simulatorData] });

    MySwal.close();
    Modal(
      <RenovationSimulator onClose={onClose} setIsLoading={setIsLoading} />,
      {
        title: "",
        width: "auto",
        showCloseButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          popup: "bg-snow dark:bg-navy-800 px-0 !w-max sm:!w-[380px]",
          htmlContainer: "!px-3 !overflow-x-hidden",
        },
      }
    );
  };

  return (
    <>
      {onClose && (
        <div className="relative">
          <button
            className="flex flex-row gap-1 items-center text-secondary-800 hover:opacity-80 dark:text-primary "
            onClick={handleBack}
          >
            <KeyboardBackspaceIcon />
            <p className="text-sm ">{t("common.back")}</p>
          </button>
        </div>
      )}

      <div className="pt-8 pb-8 overflow-x-hidden">
        <p className=" text-xl sm:text-2xl text-secondary-800 font-medium	dark:text-white">
          {t("chooseCredit.choose")}
        </p>
        <p className="-mt-1 mb-4 text-xl sm:text-2xl text-primary font-semibold	">
          {t("chooseCredit.youWant")}
        </p>

        <div className=" w-[100%] h-[150px]">
          <Lottie
            style={{ width: "100%", height: "100%" }}
            loop
            autoPlay
            animationData={require("../../assets/animations/cerdoMonedas.json")}
          />
        </div>

        <div className="mt-2 gap-2 flex flex-col overflow-y-auto max-h-96">
          {loans.map((item, index) => {
            return (
              <button
                key={index}
                onClick={() =>
                  handleRenovationSimulator({
                    value_to_paid: item.totalCredit,
                    loanId: item.c200_rowid,
                    personType: {
                      id: item.industrial_classification.c017_rowid,
                      name: item.industrial_classification.c017_descripcion,
                    },
                    creditLine: item.c200_rowid_concepto,
                    amount: parseInt(item.monto),
                    dues: parseInt(item.c200_plazo),
                    period: parseInt(item.c200_rowid_periodo),
                    valPerDue: parseInt(item.fullDue),
                    isRenovation: true,
                    periodDescription: item.description,
                  })
                }
                className="flex grid grid-cols-12 bg-white dark:!bg-navy-600 w-[98%] hover:opacity-80  mx-auto py-3 px-4 rounded-lg shadow-md dark:text-white dark:shadow-none"
              >
                <div className="h-full flex flex-col text-left col-span-6 ">
                  <p className="capitalize text-base font-semibold	leading-4	dark:text-secondary-500">
                    {item.name}
                  </p>
                  <p className=" text-xs leading-4 ">
                    {formattedDate(
                      item.c200_fecha_creacion.split(" ")[0] ??
                      "1996-08-14 00:00:00"
                    )}
                  </p>
                </div>
                <div className="h-full col-span-5 flex items-center justify-center">
                  <p className="text-primary font-semibold	">
                    $ {formatNumberPoint(parseInt(item.monto))}
                  </p>
                </div>
                <div className="h-full col-span-1 flex items-center justify-center">
                  <ChevronRightIcon />
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};
