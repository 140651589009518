import React, { useContext } from "react";
import { Modal } from "../common/alerts.tsx";
import { PermissionModal } from "../components/Modals.tsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { AuthContext } from "../hooks/useAuth.tsx";

type RequestPermissionsResult = MediaStream | null;

// Solicita permisos de ubicación
export const LocationRequestComponent = () => {
  // Ajuste el ancho del modal según el tamaño de pantalla
  const theme = useTheme();
  const matchesXS = useMediaQuery("(max-width:599px)");
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const registerForLocation = async ({
    setIsLoading,
  }): Promise<{
    latitude: number;
    longitude: number;
  } | null> => {
    if (!navigator.geolocation) {
      alert("Geolocalización no está soportada por este navegador.");
      return null;
    }

    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          resolve({ latitude, longitude });
        },
        (error) => {
          setIsLoading(false);
          // Muestra el modal si no hay permiso de geolocalización
          Modal(<PermissionModal type={"ubicación"} />, {
            title: "",
            width: matchesXS ? "90%" : matches ? "45%" : "70%",
            showCloseButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          });
          resolve(null);
        }
      );
    });
  };

  return { registerForLocation };
};

// Solicita permisos de cámara y micrófono
export const VideoCameraRequestComponent = () => {
  const theme = useTheme();
  const matchesXS = useMediaQuery("(max-width:599px)");
  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  const registerForVideoCamera = async ({
    setIsLoading,
  }): Promise<RequestPermissionsResult> => {
    try {
      // Solicita permisos de cámara y micrófono
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });

      // // Detiene el flujo para liberar los recursos temporalmente
      stream.getTracks().forEach((track) => track.stop());

      // Retorna el flujo si se concedieron los permisos
      return stream;
    } catch (err) {
      setIsLoading(false);
      // Muestra el modal si se deniegan los permisos
      Modal(<PermissionModal type={"cámara y micrófono"} />, {
        title: "",
        width: matchesXS ? "90%" : matches ? "45%" : "70%",
        showCloseButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      });

      return null;
    }
  };

  return { registerForVideoCamera };
};
