import React from "react";

function useOutsideAlerter(ref, setX) {
  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setX(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setX]);
}

const Dropdown = ({
  button,
  items,
  classNames,
  animation,
  isOpen: externalIsOpen,
  setIsOpen: setExternalIsOpen,
}: {
  button: any;
  items: any;
  classNames: string;
  animation?: string;
  isOpen?: boolean; // No es obligatorio pasar este prop
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>; // No es obligatorio pasar este prop
}) => {
  const wrapperRef = React.useRef(null);
  const [internalIsOpen, setInternalIsOpen] = React.useState(false);

  // Si no se pasa `isOpen` y `setIsOpen`, usamos el estado interno
  const isOpen = externalIsOpen !== undefined ? externalIsOpen : internalIsOpen;
  const setIsOpen = setExternalIsOpen || setInternalIsOpen;

  useOutsideAlerter(wrapperRef, setIsOpen);

  return (
    <div ref={wrapperRef} className="relative flex">
      <div
        className="flex"
        style={{ cursor: "pointer" }}
        onMouseDown={() => setIsOpen(!isOpen)}
      >
        {button}
      </div>
      <div
        className={`${classNames} absolute z-10 ${
          animation
            ? animation
            : "origin-top-right transition-all duration-300 ease-in-out"
        } ${isOpen ? "scale-100" : "scale-0"}`}
      >
        {items}
      </div>
    </div>
  );
};

export default Dropdown;
