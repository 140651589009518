import React, { useContext, useEffect, useState } from "react";
import { Autocomplete, Box, Typography } from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import { ColorButton } from "../../../components/Button.tsx";
import "../../../index.css";
import InputField from "../../../components/InputField.tsx";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid2";
import {
  IBanks,
  IPanelAll,
  IRegisterFinancial,
} from "../../../constants/types.tsx";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import { requestSquematic } from "../../../common/CommonFunctions.tsx";
import { yupBankValidations } from "../../../common/validations/yupBankValidations.ts";
import { getValueStorage, saveValueStorage } from "../../../common/storage.ts";
import { useTranslation } from "react-i18next";
import { IRegisterStepState } from "../RegisterComponent.tsx";

interface bankFormData {
  bank: string;
  type_account: string;
  number_account: string;
}

export default function BankInformation({ setRegisterStep }: IRegisterStepState) {
  const dataPanel: IPanelAll = getValueStorage("panelData");
  const dataRegisterFinancial: IRegisterFinancial = getValueStorage(
    "registerProcessData"
  );
  const { t } = useTranslation();
  const { getUser, setIsLoading, signIn } = useContext(AuthContext);
  const [typeAccount, setTypeAccount] = useState<any[]>([]);
  const [bank, setBank] = useState<IBanks[]>([]);
  const [selectedBank, setSelectedBank] = useState<IBanks | null>(null);

  useEffect(() => {
    setBank(dataPanel.all_banks);
    setTypeAccount(
      dataPanel.all_labels_financial_data.map((item: any) => ({
        id: item.c006_valor + "",
        value: item.c006_descripcion,
      }))
    );
  }, []);

  // Mapea las opciones
  const typeAccountOptions = typeAccount.map((item) => ({
    value: item.id + "",
    label: item.value,
  }));

  // Inicializar variables
  const formik = useFormik({
    initialValues: {
      bank: "",
      type_account: "",
      number_account: "",
    },
    validationSchema: yupBankValidations,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  //Enviar la informacion financiera y bancaria
  const handleSubmit = async (bankInformation: bankFormData) => {
    setIsLoading(true);

    let salaryTemp = dataRegisterFinancial.salary;
    if (salaryTemp === "" || salaryTemp === "-") {
      salaryTemp = "0";
    }

    const objToSend = {
      c126_cargo: dataRegisterFinancial.charge,
      c126_empresa: dataRegisterFinancial.company,
      c126_id_banco: `${bankInformation.bank}`,
      c126_ingresos: parseInt(salaryTemp.replaceAll(".", "")),
      c126_nro_cuenta: bankInformation.number_account,

      c126_periodo_pago:
        dataRegisterFinancial.period_pay === "-"
          ? "10"
          : dataRegisterFinancial.period_pay, // 10 - Sin periodo de pago
      c126_rowid_ocupacion:
        dataRegisterFinancial.occupation === "-"
          ? "674"
          : dataRegisterFinancial.occupation, // 674 - Sin ocupación

      c126_tipo_cuenta: bankInformation.type_account,
      c126_direccion_trabajo: dataRegisterFinancial.company_address,
      c126_rowid_categoria_vivienda: dataRegisterFinancial.houseTenure,
      c126_rowid_estadolaboral: dataRegisterFinancial.laboral_status,
      c126_num_empresa: dataRegisterFinancial.company_phone,
    };
    const data = await requestSquematic(
      "POST",
      "/api/app/financial_data/create",
      objToSend,
      getUser.token
    );

    if (data) {
      signIn({
        ...getUser,
        hasFinancial: data.results,
        houseTenure: "",
      });
      let obj = {
        prevStep: 7,
        nextStep: 8,
        desc: "se crea la informacion financiera y bancaria",
      }
      saveValueStorage("registerStep", obj);
      if (setRegisterStep) setRegisterStep(obj);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: colors.black,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("common.form")}
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("common.forRequestCredit")}
        </Typography>
      </Box>
      <Box display={"flex"} flexDirection={"column"} alignItems={"left"} mt={5}>
        <Box>
          <Box style={{ color: colors.darkGray, fontSize: 13 }}>
            {t("common.completeAllFields")}
          </Box>
          <Box
            className="text-primary"
            style={{
              fontSize: 15,
              fontWeight: 700,
              marginTop: 0,
              color: colors.primary,
              textTransform: "uppercase",
              lineHeight: 1,
            }}
          >
            {t("bankInformation.title")}
          </Box>
        </Box>

        <Grid container spacing={2} mt={2.5}>
          <Grid size={{ xs: 12, sm: 6, xl: 4 }}>
            <Autocomplete
              options={bank}
              getOptionLabel={(option) => option.c021_descripcion}
              value={selectedBank}
              onChange={(event, newValue) => {
                setSelectedBank(newValue);
                // Si se selecciona una opción válida, actualizar Formik; si no, establecer en cadena vacía
                formik.setFieldValue(
                  "bank",
                  newValue ? newValue.c021_id + "" : ""
                );
              }}
              disabled={getUser.isLoading}
              onInputChange={(event, inputValue) => {
                // Si el valor ingresado no tiene una coincidencia, limpiar el campo en Formik
                const matchedOption = bank.find(
                  (option) => option.c021_descripcion === inputValue
                );
                if (!matchedOption) {
                  formik.setFieldValue("bank", "");
                }
              }}
              noOptionsText={t("common.empty")}
              renderInput={(params) => (
                <InputField
                  {...params}
                  name="bank"
                  label={t("bankInformation.selectBank")}
                  formik={formik}
                  noFormik
                  disabled={getUser.isLoading}
                  placeholder={bank.length === 0 ? "" : "Seleccione"}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: !!selectedBank, // Si hay una selección, el campo será de solo lectura
                  }}
                />
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, xl: 4 }}>
            <InputField
              name="type_account"
              formik={formik}
              label={t("bankInformation.typeAccount")}
              type="select"
              options={typeAccountOptions}
              disabled={getUser.isLoading}
            />
          </Grid>
          <Grid size={{ xs: 12, xl: 4 }}>
            <InputField
              name="number_account"
              formik={formik}
              label={t("bankInformation.numberAccount")}
              disabled={getUser.isLoading}
              maxLength={20}
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault(); // Prevenir la entrada si no es un número
                }
              }}
            />
          </Grid>
          <Box>
            <Box style={{ color: colors.red, fontSize: 14 }}>
              <strong>{t("confirmLoan.important")}:</strong>{" "}
              {t("bankInformation.importantText")}
            </Box>
          </Box>
        </Grid>

        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          marginTop={8}
        >
          <ColorButton
            sx={{
              width: "fit-content",
              padding: "15px 40px",
              fontSize: 16,
            }}
            type="submit"
            onClick={() => {
              formik.handleSubmit();
            }}
            disabled={getUser.isLoading}
          >
            {t("common.continue")}
          </ColorButton>
        </Box>
      </Box>
    </>
  );
}
