import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { colors, sizes } from "../styles/theme.tsx";
import i18n from "../constants/translations/i18n.js";
import { useTranslation } from "react-i18next";
import { getValueStorage, wipeStorage } from "../common/storage.ts";
import { IUserAuth } from "../constants/types.tsx";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function Header() {
  const navigation = useNavigate();
  const authSession: IUserAuth = getValueStorage("authSession");
  const { t } = useTranslation();
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [openSubLoginMenuMobile, setOpenSubLoginMenuMobile] =
    React.useState(false);
  const [openAboutUsSubMenuMobile, setOpenAboutUsSubMenuMobile] =
    React.useState(false);

  const [anchorElAboutUs, setAnchorElAboutUs] =
    React.useState<null | HTMLElement>(null);
  const [anchorElLogin, setAnchorElLogin] = React.useState<null | HTMLElement>(
    null
  );

  const handleSignOut = () => {
    wipeStorage();
    navigation("/");
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setOpenSubLoginMenuMobile(false); // Cerrar el submenú cuando se cierra el menú principal
    setOpenAboutUsSubMenuMobile(false); // Cerrar el submenú de "Sobre nosotros"
  };

  // Alternar el submenú de "Sobre nosotros" en Desktop
  const handleToggleAboutUsSubMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAboutUs(anchorElAboutUs ? null : event.currentTarget);
  };

  // Alternar el submenú de "Ingresar" en Desktop
  const handleToggleLoginMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLogin(anchorElLogin ? null : event.currentTarget);
  };

  const isActive = (path: string) => location.pathname === path;

  const handleToggleSubLoginMenuMobile = () => {
    setOpenSubLoginMenuMobile(!openSubLoginMenuMobile);
    setOpenAboutUsSubMenuMobile(false);
  };

  const handleToggleAboutUsSubMenuMobile = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setOpenAboutUsSubMenuMobile(!openAboutUsSubMenuMobile); // Alternar el submenú mobile de "Sobre nosotros"
    setOpenSubLoginMenuMobile(false); // Cerrar el otro submenú si está abierto
  };

  // Cerrar menú de Ingresar
  const handleCloseLoginMenu = () => {
    setAnchorElLogin(null);
  };

  // Cerrar menú de Sobre Nosotros
  const handleCloseAboutUsMenu = () => {
    setAnchorElAboutUs(null);
  };

  //Cambiar el idioma de la pagina
  const handleToggleLanguage = (target: any) => {
    i18n.changeLanguage(target.checked ? "en" : "es");
  };

  return (
    <AppBar
      position="static"
      sx={{
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 1000,
        background: colors.primary,
        padding: {
          xs: "5px",
          md: "0px 0px 0px 48px",
        },
        height: sizes.header,
      }}
    >
      <Container
        sx={{
          maxWidth: "100% !important",
          padding: "0 !important",
          height: "100%",
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            justifyContent: { xs: "space-between", md: "inherit" },
            height: "100%",
            minHeight: "100% !important",
          }}
        >
          {/* Mobile */}
          <Box
            sx={{ display: { xs: "flex", md: "none" }, position: "absolute" }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                "& ul": {
                  width: "260px",
                },
              }}
            >
              <MenuItem
                onClick={handleCloseNavMenu}
                component={Link}
                to="/"
                style={{
                  textDecoration: "none",
                  color: isActive("/") ? colors.primary : colors.black,
                }}
              >
                <Typography sx={{ textAlign: "center" }}>
                  {t("header.home")}
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={handleCloseNavMenu}
                component={Link}
                to="/our-services"
                style={{
                  textDecoration: "none",
                  color: isActive("/our-services")
                    ? colors.primary
                    : colors.black,
                }}
              >
                <Typography sx={{ textAlign: "center" }}>
                  {t("header.ourServices")}
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleToggleAboutUsSubMenuMobile}>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: colors.black,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {t("header.aboutUs")}{" "}
                  {openAboutUsSubMenuMobile ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </Typography>
              </MenuItem>
              {openAboutUsSubMenuMobile && (
                <>
                  <MenuItem
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to="/culture-organization"
                    style={{
                      textDecoration: "none",
                      color: isActive("/culture-organization")
                        ? colors.primary
                        : colors.black,
                      paddingLeft: "20px",
                    }}
                  >
                    <Typography>{t("header.aboutUsOption1")}</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to={"/who-we-are"}
                    style={{
                      textDecoration: "none",
                      color: isActive("/who-we-are")
                        ? colors.primary
                        : colors.black,
                      paddingLeft: "20px",
                    }}
                  >
                    <Typography>{t("header.aboutUsOption2")}</Typography>
                  </MenuItem>
                </>
              )}
              <MenuItem
                onClick={handleCloseNavMenu}
                component={Link}
                to="/customer-service"
                style={{
                  textDecoration: "none",
                  color: isActive("/customer-service")
                    ? colors.primary
                    : colors.black,
                }}
              >
                <Typography sx={{ textAlign: "center" }}>
                  {t("header.customerService")}
                </Typography>
              </MenuItem>
              {authSession?.login ? (
                <>
                  <MenuItem
                    onClick={handleCloseNavMenu}
                    component={Link}
                    to="/sign-up"
                    style={{
                      textDecoration: "none",
                      color: isActive("/sign-up")
                        ? colors.primary
                        : colors.black,
                    }}
                  >
                    <Typography sx={{ textAlign: "center" }}>
                      {authSession?.isOld
                        ? t("common.continueProcess")
                        : t("common.continueSignUp")}
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleSignOut}>
                    <Typography
                      sx={{
                        textAlign: "center",
                        color: colors.black,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      {t("common.signOut")}
                    </Typography>
                  </MenuItem>
                </>
              ) : (
                <MenuItem onClick={handleToggleSubLoginMenuMobile}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: colors.black,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {t("common.getInto")}{" "}
                    {openSubLoginMenuMobile ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </Typography>
                </MenuItem>
              )}
              {openSubLoginMenuMobile && (
                <>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Link
                      to="/sign-in"
                      style={{
                        textDecoration: "none",
                        color: isActive("/sign-in")
                          ? colors.primary
                          : colors.black,
                        paddingLeft: "20px",
                      }}
                    >
                      <Typography>{t("common.signIn")}</Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Link
                      to="/sign-up"
                      style={{
                        textDecoration: "none",
                        color: isActive("/sign-up")
                          ? colors.primary
                          : colors.black,
                        paddingLeft: "20px",
                      }}
                    >
                      <Typography>{t("common.signUp")}</Typography>
                    </Link>
                  </MenuItem>
                </>
              )}
            </Menu>
          </Box>

          {/* Logo */}
          <Box
            sx={{
              width: { xs: "100%", md: "auto" },
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* Logo */}
            <Box
              sx={{
                width: { xs: 80, xl: 130 },
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: { xs: "80px", xl: "120px" },
                  height: { xs: "35px", xl: "55px" },
                }}
              >
                <Link to="/" style={{ textDecoration: "none" }}>
                  <img
                    src={require("../assets/image/LogoHorizontalBlanco.webp")}
                    alt={""}
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Link>
              </Box>
            </Box>
            {/* Switch */}
            <Box
              sx={{
                position: { xs: "absolute", md: "relative" },
                right: { xs: 5, md: -22 },
                top: "50%",
                transform: {
                  xs: "translateY(-50%)",
                  md: "translateY(18%)",
                  xl: "translateY(28%)",
                },
              }}
            >
              <label
                className="ui-switch"
                onClick={(e) => handleToggleLanguage(e.target)}
              >
                <input type="checkbox" defaultChecked={i18n.language === "en"} id="switch_language" />
                <div className="slider">
                  <div className="circle"></div>
                </div>
              </label>
            </Box>
          </Box>

          {/* Desktop */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "end",
              alignItems: "center",
              height: "100%",
              ml: 4,
            }}
          >
            <Button
              onClick={handleCloseNavMenu}
              component={Link}
              to="/"
              sx={{
                px: { md: 2.5, lg: 4, xl: 6 },
                height: "100%",
                textTransform: "none",
                display: "flex",
                fontWeight: "bold",
                color: colors.white,
                lineHeight: 1,
                textAlign: "center",
                textDecoration: isActive("/") ? "underline" : "none",
              }}
            >
              {t("header.home")}
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              component={Link}
              to="/our-services"
              sx={{
                px: { md: 2.5, lg: 4, xl: 6 },
                height: "100%",
                textTransform: "none",
                display: "flex",
                fontWeight: "bold",
                color: colors.white,
                lineHeight: 1,
                textAlign: "center",
                textDecoration: isActive("/our-services")
                  ? "underline"
                  : "none",
              }}
            >
              {t("header.ourServices")}
            </Button>
            {/* Sobre nosotros */}
            <>
              <Button
                onClick={handleToggleAboutUsSubMenu}
                sx={{
                  px: { md: 2.5, lg: 4, xl: 6 },
                  height: "100%",
                  fontWeight: "bold",
                  color: colors.white,
                  lineHeight: 1,
                  textAlign: "center",
                  textTransform: "none",
                  display: "flex",
                }}
                endIcon={
                  anchorElAboutUs ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                }
              >
                {t("header.aboutUs")}
              </Button>
              <Menu
                anchorEl={anchorElAboutUs}
                open={Boolean(anchorElAboutUs)}
                onClose={handleCloseAboutUsMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <MenuItem onClick={handleCloseAboutUsMenu}>
                  <Link
                    to="/culture-organization"
                    style={{
                      textDecoration: "none",
                      color: isActive("/culture-organization")
                        ? colors.primary
                        : colors.black,
                    }}
                  >
                    {t("header.aboutUsOption1")}
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleCloseAboutUsMenu}>
                  <Link
                    to="/who-we-are"
                    style={{
                      textDecoration: "none",
                      color: isActive("/who-we-are")
                        ? colors.primary
                        : colors.black,
                    }}
                  >
                    {t("header.aboutUsOption2")}
                  </Link>
                </MenuItem>
              </Menu>
            </>
            <Button
              onClick={handleCloseNavMenu}
              component={Link}
              to="/customer-service"
              sx={{
                px: { md: 2.5, lg: 4, xl: 6 },
                height: "100%",
                textTransform: "none",
                display: "flex",
                fontWeight: "bold",
                color: colors.white,
                lineHeight: 1,
                textAlign: "center",
                textDecoration: isActive("/customer-service")
                  ? "underline"
                  : "none",
              }}
            >
              {t("header.customerService")}
            </Button>
            {authSession?.login ? (
              <>
                <Button
                  component={Link}
                  to="/sign-up"
                  sx={{
                    px: { md: 2.5, lg: 4, xl: 6 },
                    height: "100%",
                    textTransform: "none",
                    display: "flex",
                    fontWeight: "bold",
                    color: colors.white,
                    lineHeight: 1,
                    textAlign: "center",
                    textDecoration: isActive("/sign-up") ? "underline" : "none",
                  }}
                >
                  {authSession?.isOld
                    ? t("common.continueProcess")
                    : t("common.continueSignUp")}
                </Button>
                <Button
                  onClick={handleSignOut}
                  sx={{
                    px: { md: 2.5, lg: 4, xl: 6 },
                    height: "100%",
                    fontWeight: "bold",
                    color: colors.primary,
                    lineHeight: 1,
                    textAlign: "center",
                    background: colors.white,
                    display: "flex",
                    borderRadius: "initial",
                    fontSize: { md: 15, lg: 17 },
                  }}
                >
                  {t("common.signOut")}
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={handleToggleLoginMenu}
                  sx={{
                    px: { md: 2.5, lg: 4, xl: 6 },
                    height: "100%",
                    fontWeight: "bold",
                    color: colors.primary,
                    lineHeight: 1,
                    textAlign: "center",
                    background: colors.white,
                    display: "flex",
                    borderRadius: "initial",
                    fontSize: { md: 15, lg: 17 },
                  }}
                  endIcon={
                    anchorElLogin ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )
                  }
                >
                  {t("common.getInto")}
                </Button>
                <Menu
                  anchorEl={anchorElLogin}
                  open={Boolean(anchorElLogin)}
                  onClose={handleCloseLoginMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    "& ul": {
                      px: { md: 0, lg: 1.75, xl: 3.75 },
                    },
                    "& .MuiPaper-root": {
                      right: 0,
                      width: "fit-content",
                      left: "initial !important",
                      borderRadius: 0,
                      boxShadow: "-8px 11px 16px -11px rgba(0,0,0,0.38)",
                    },
                  }}
                >
                  <MenuItem onClick={handleCloseLoginMenu}>
                    <Link
                      to="/sign-in"
                      style={{
                        textDecoration: "none",
                        textTransform: "uppercase",
                        color: isActive("/sign-in")
                          ? colors.primary
                          : colors.black,
                      }}
                    >
                      {t("common.signIn")}
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseLoginMenu}>
                    <Link
                      to="/sign-up"
                      style={{
                        textDecoration: "none",
                        textTransform: "uppercase",
                        color: isActive("/sign-up")
                          ? colors.primary
                          : colors.black,
                      }}
                    >
                      {t("common.signUp")}
                    </Link>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
