import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./ScrollToTop.tsx";
import AppRoutes from "./AppRoutes.tsx";
import Lottie from "lottie-react";
import { colors } from "./styles/theme.tsx";
import { AuthContext } from "./hooks/useAuth.tsx";
import DashboardRoutes from "./pages/Dashboard/DashboardRoutes.tsx";

function App() {
  const { getUser } = React.useContext(AuthContext);
  const resizeObserverErrorHandler = (e) => {
    if (e.message === "ResizeObserver loop completed with undelivered notifications.") {
      e.stopImmediatePropagation();
    }
  };
  
  window.addEventListener("error", resizeObserverErrorHandler);
  

  return (
    <Router>
      {getUser.isLoading && (
        <div
          id="loading_container"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: colors.loading,
            zIndex: 1071,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Lottie
            style={{
              width: 350,
              height: 350,
              marginBottom: 15,
            }}
            loop
            autoPlay
            animationData={require("./assets/animations/cargando.json")}
          />
        </div>
      )}
      <ScrollToTop />
      {
        getUser.dashboard ?
          <DashboardRoutes />
          :
          <AppRoutes />
      }
    </Router>
  );
}

export default App;
