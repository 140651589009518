import * as Yup from "yup";

export const yupContactValidations = Yup.object().shape({
  home_address: Yup.string()
    .required("El campo es obligatorio")
    .matches(
      /^(?! )[a-zA-Z0-9#\-.,/()]+(?: [a-zA-Z0-9#\-.,/()]+)*$/,
      "Formato de dirección inválida"
    )
    .test(
      "no-prueba-admin",
      "El campo no puede contener 'prueba' o 'admin'",
      (value) => {
        return (
          !value ||
          !value
            .split(" ")
            .some(
              (word) =>
                word.toLowerCase() === "prueba" ||
                word.toLowerCase() === "admin"
            )
        );
      }
    ),
  home_address_description: Yup.string()
    .required("El campo es obligatorio")
    .matches(
      /^(?! )[a-zA-Z0-9#\-.,/()]+(?: [a-zA-Z0-9#\-.,/()]+)*$/,
      "Valida los caracteres y espacios"
    )
    .test(
      "no-prueba-admin",
      "El campo no puede contener 'prueba' o 'admin'",
      (value) => {
        return (
          !value ||
          !value
            .split(" ")
            .some(
              (word) =>
                word.toLowerCase() === "prueba" ||
                word.toLowerCase() === "admin"
            )
        );
      }
    ),
  house_type: Yup.string().required("Selecciona una opción"),
  house_tenure: Yup.string().required("Selecciona una opción"),
  department: Yup.string().required("Selecciona una opción"),
  city: Yup.string().when(["department"], ([department], schema) => {    
    // Si no se selcciono algun departamento, no se muestra el error    
    return department === undefined
      ? schema.notRequired()
      : schema.required("Selecciona una opción");
  }),
  neighborhood: Yup.string().when(
    ["city", "stateNeigh"],
    ([city, stateNeigh], schema) => {      
      // Si no se selcciono algun ciudad, neighborhood no es requerido
      if (city === undefined ) {
        return schema.notRequired();
      }

      // Si ciudad tiene un valor y stateNeigh está activo, pero no hay barrios disponibles
      if (stateNeigh) {
        return schema.required("No hay barrios para esta ciudad");
      }

      // Si city tiene un valor y stateNeigh no está activo
      return schema.required("Selecciona una opción");
    }
  ),
});
