import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import { ColorButton } from "../../../components/Button.tsx";
import "../../../index.css";
import InputField from "../../../components/InputField.tsx";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid2";
import {
  IPanelAll,
  IRegisterProcessSelfieId,
} from "../../../constants/types.tsx";
import {
  getValueStorage,
  saveValueStorage,
  wipeValueStorage,
} from "../../../common/storage.ts";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import { yupProfile } from "../../../common/validations/yupProfileValidations.ts";
import { useTranslation } from "react-i18next";
import { errorAlert } from "../../../common/alerts.tsx";
import { useNavigate } from "react-router-dom";
import i18n from "../../../constants/translations/i18n.js";
import {
  JSONToForm,
  requestSquematic,
} from "../../../common/CommonFunctions.tsx";
import { IRegisterStep, IRegisterStepState } from "../RegisterComponent.tsx";
import { KeyboardBackspaceIcon } from "../../Dashboard/assets/iconsMui.tsx";

interface IPersonal {
  birth_date: string;
  lastname_1: string;
  lastname_2: string;
  marital_status: string;
  name: string;
  sex_indicator: string;
}

//Convertir imagen de base64 a File
export const convertBase64ToFile = (base64: string, fileName: string) => {
  const base64Response = base64.split(",")[1];
  const byteCharacters = atob(base64Response);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new File([byteArray], fileName, { type: "image/jpg" });
};

export default function PersonalInformation({ setRegisterStep }: IRegisterStepState) {
  const navigate = useNavigate();
  const dataPanel: IPanelAll = getValueStorage("panelData");
  const dataRegister: IRegisterProcessSelfieId = getValueStorage(
    "registerProcessData"
  );
  const { t } = useTranslation();
  const { getUser, setIsLoading, signIn } = useContext(AuthContext);

  // Inicializar variables
  const formik = useFormik({
    initialValues: {
      name: "",
      lastname_1: "",
      lastname_2: "",
      sex_indicator: "0",
      marital_status: "0",
      birth_date: "",
    },
    validationSchema: yupProfile,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  // Mapea las opciones
  const sexOptions = dataPanel?.all_labels_third?.sex_indicator.map((item) => ({
    value: item.c006_valor,
    label: item.c006_descripcion,
  }));

  const maritalOptions = dataPanel?.all_labels_third?.marital_status.map(
    (item) => ({
      value: item.c006_valor,
      label: item.c006_descripcion,
    })
  );

  //Validamos que el dataRegister este lleno
  const validateDataRegister = async () => {
    let response = true;
    if (
      !dataRegister ||
      dataRegister.id === "" ||
      dataRegister.cedulaF === ""
    ) {
      response = false;
      await errorAlert(
        "Error",
        "El proceso de registro no se ha completado correctamente, por favor, vuelva a intentarlo.",
        ""
      );
      wipeValueStorage("registerProcessData");
      saveValueStorage("registerStep", {
        prevStep: 4,
        nextStep: 3,
        desc: "se devuelve al paso anterior para que suba las fotos",
      });
      navigate("/sign-up");
    }
    return response;
  };

  const handleSubmit = async (dataPersonal: IPersonal) => {
    let validate = await validateDataRegister();
    if (!validate) return true;
    setIsLoading(true);

    const objToSend = {
      c120_id: dataRegister.id,
      c120_nit: dataRegister.id,
      c120_fecha_expedicion_cedula: new Date(
        dataRegister.date_expedition
      ).toISOString(),
      c120_id_tipo_ident: dataRegister.type_id,
      c120_razon_social: `${dataPersonal.name.trim()} ${dataPersonal.lastname_1.trim()} ${dataPersonal.lastname_2.trim()}`,
      c120_apellido1: dataPersonal.lastname_1.trim(),
      c120_apellido2: dataPersonal.lastname_2.trim(),
      c120_nombres: dataPersonal.name.trim(),
      c120_fecha_nacimiento: new Date(dataPersonal.birth_date).toISOString(),
      photo: convertBase64ToFile(
        dataRegister.selfie,
        `${dataRegister.id}_selfie.jpg`
      ),
      cedulaF: convertBase64ToFile(
        dataRegister.cedulaF,
        `${dataRegister.id}_cedulaF.jpg`
      ),
      cedulaA: convertBase64ToFile(
        dataRegister.cedulaA,
        `${dataRegister.id}_cedulaA.jpg`
      ),
      c120_ind_sexo: dataPersonal.sex_indicator,
      c120_ind_estado_civil: dataPersonal.marital_status,
    };
    const formData = JSONToForm(objToSend);

    const res = await requestSquematic(
      "POST",
      "/api/app/third/create",
      formData,
      getUser.token
    );

    if (res) {
      signIn({
        ...getUser,
        hasThird: true,
      });
      let obj = {
        prevStep: 4,
        nextStep: 5,
        desc: "se crea el tercero",
      };
      saveValueStorage("registerStep", obj);
      if (setRegisterStep) setRegisterStep(obj);
    }
    setIsLoading(false);
  };

  //Volver atras
  const handleBack = () => {
    if (setRegisterStep) {
      const registerStep: { nextStep: number; prevStep: number; desc: string } = getValueStorage("registerStep");
      let objectStep: IRegisterStep = {
        prevStep: 31,
        nextStep: registerStep.prevStep,
        desc: "pasa a ver los referidos",
      };
      saveValueStorage("registerStep", objectStep);
      setRegisterStep(objectStep);
    }
  };

  return (
    <>
      <div style={{ marginBottom: 30 }} className="relative">
        <button
          className="absolute flex flex-row gap-1 items-center text-secondary-800 hover:opacity-80 dark:text-primary top-0 left-[-10px]"
          onClick={handleBack}
        >
          <KeyboardBackspaceIcon />
          <p className="text-sm ">{t("common.back")}</p>
        </button>
      </div>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: colors.black,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("common.form")}
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("common.forRequestCredit")}
        </Typography>
      </Box>
      <Box display={"flex"} flexDirection={"column"} alignItems={"left"} mt={5}>
        <Box>
          <Box style={{ color: colors.darkGray, fontSize: 13 }}>
            {t("common.completeAllFields")}
          </Box>
          <Box
            className="text-primary"
            style={{
              fontSize: 15,
              fontWeight: 700,
              marginTop: 0,
              color: colors.primary,
              textTransform: "uppercase",
              lineHeight: 1,
            }}
          >
            {t("personalInformation.title")}
          </Box>
        </Box>
        <Grid container spacing={2} columns={12} mt={2.5}>
          <Grid size={{ xs: 12, md: 6 }}>
            <InputField
              name="name"
              label={t("personalInformation.names")}
              formik={formik}
              maxLength={150}
              disabled={getUser.isLoading}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <InputField
              name="lastname_1"
              label={t("personalInformation.firstLastName")}
              formik={formik}
              maxLength={150}
              disabled={getUser.isLoading}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <InputField
              name="lastname_2"
              label={t("personalInformation.secondLastName")}
              formik={formik}
              maxLength={150}
              disabled={getUser.isLoading}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <InputField
              name="sex_indicator"
              formik={formik}
              label={t("personalInformation.sex")}
              type="select"
              options={sexOptions}
              disabled={getUser.isLoading}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <InputField
              type="date"
              name="birth_date"
              label={t("personalInformation.birthDate")}
              formik={formik}
              maxLength={150}
              disabled={getUser.isLoading}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <InputField
              name="marital_status"
              formik={formik}
              label={t("personalInformation.civilStatus")}
              type="select"
              options={maritalOptions}
              disabled={getUser.isLoading}
            />
          </Grid>
        </Grid>

        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          marginTop={8}
        >
          <ColorButton
            sx={{
              width: "fit-content",
              padding: "15px 40px",
              fontSize: 16,
            }}
            type="submit"
            onClick={() => formik.handleSubmit()}
            disabled={getUser.isLoading}
          >
            {t("common.continue")}
          </ColorButton>
        </Box>
      </Box>
    </>
  );
}
