import React from "react";
import { IUserAuth, PropItemsLoanInit } from "../../../../constants/types";
import { useTranslation } from "react-i18next";
import { formatDateTime, formatNumberPoint, requestSquematic } from "../../../../common/CommonFunctions.tsx";
import { FormControl, FormControlLabel, Radio, TextField, Typography } from "@mui/material";
import WebViewWompi from "./WebViewWompi.tsx";
import { Modal, MySwal } from "../../../../common/alerts.tsx";
import { getValueStorage } from "../../../../common/storage.ts";
import { calcSaldoMora } from "../../HomeDashboard.tsx";

export interface doPayProps {
  setIsLoading: (isLoading: boolean) => void;
  getUser: IUserAuth;
  loanLocal: PropItemsLoanInit | null;
  signOut: () => void;
}

export default function DoPay({ getUser, setIsLoading, loanLocal, signOut }: doPayProps) {
  const { t } = useTranslation();
  const [valueToPay, setValueToPay] = React.useState<number>(0);
  const [seeOtherValue, setSeeOtherValue] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');
  const [isBlackTheme, setIsBlackTheme] = React.useState<boolean>(false);
  const [optionSelected, setOptionSelected] = React.useState<string>("");
  const [mora, setMora] = React.useState<number>(0);

  React.useEffect(() => {
    const loan = getValueStorage("loan");
    const loanPayment = getValueStorage("loans");
    if (loanPayment && loan) setMora(calcSaldoMora(loanPayment, loan));
    setIsBlackTheme(document.body.classList.contains("dark"));
  }, []);

  //Funcion para cambiar el valor a pagar
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptionSelected(event.target.name);
    if (event.target.value === "otro") {
      setSeeOtherValue(true);
      setValueToPay(parseInt(loanLocal?.minDue ?? '0'));
    } else {
      setSeeOtherValue(false);
      setError('');
      setValueToPay(parseInt(event.target.value));
    }
  }

  //Funcion para enviar al webview de wompi
  const handlePay = async () => {
    setError('');
    if (seeOtherValue && valueToPay < parseInt(loanLocal?.minDue ?? '0')) {
      setError(t("doPay.error1"));
      return;
    }
    if (valueToPay === 0) {
      setError(t("doPay.error2"));
      return;
    }
    setIsLoading(true);
    const res = await requestSquematic(
      "POST",
      "/api/agreement/wompi/generate_transaction",
      {
        c200_rowid: loanLocal?.c200_rowid,
        amount: valueToPay,
      },
      getUser.token
    );

    if (res && res.results) {
      MySwal.close();
      let urlWebView = res.results['url'];
      Modal(
        <WebViewWompi url={urlWebView} setIsLoading={setIsLoading} getUser={getUser} loanLocal={loanLocal} signOut={signOut} />,
        {
          title: "",
          width: 800,
          showCloseButton: true,
          showConfirmButton: false
        }
      );
    }
    setIsLoading(false);
  }

  return (
    <div className="p-2 lg:p-8">
      <p className="mb-8 text-md md:text-xl lg:text-2xl font-bold uppercase text-primary">
        {t("doPay.title")}
      </p>
      <div className="grid grid-cols-1 gap-5 mb-5">
        <div>
          <p className="text-md md:text-md font-bold text-primary">
            {t("doPay.nextPayDue")}
          </p>
          <p className="text-base md:text-lg dark:text-white">
            {formatDateTime(loanLocal?.limitDate + ' 00:00:00', t)}
          </p>
        </div>
      </div >
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mb-5">
        <div>
          <p className="text-base md:text-lg text-primary">
            {t("doPay.dueValue")}
          </p>
          <p className="text-base md:text-md dark:text-white">
            ${formatNumberPoint(parseInt(loanLocal?.fullDue ?? '0'))}
          </p>
        </div>
        <div>
          <p className="text-base md:text-lg text-primary leading-none">
            {t("doPay.penaltieInt")}
          </p>
          <p className="text-base md:text-md dark:text-white">
            ${formatNumberPoint(parseInt(loanLocal?.plan_payment_penalty?.c211_valor_sancion ?? '0'))}
          </p>
        </div>
        <div>
          <p className="text-base md:text-lg text-primary leading-none">
            {t("doPay.collectionInt")}
          </p>
          <p className="text-base md:text-md dark:text-white">
            ${formatNumberPoint(parseInt(loanLocal?.plan_payment_penalty?.c211_gastos_cobranza ?? '0'))}
          </p>
        </div>
        <div>
          <p className="text-base md:text-lg text-primary leading-none">
            {t("doPay.days")}
          </p>
          <p className="text-base md:text-md dark:text-white">
            {loanLocal?.plan_payment_penalty?.c211_dias_atraso ?? 0}
          </p>
        </div>
        <div>
          <p className="text-base md:text-lg text-primary leading-none">
            {t("doPay.date")}
          </p>
          <p className="text-base md:text-md dark:text-white">
            {formatDateTime(loanLocal?.date + ' 00:00:00', t)}
          </p>
        </div>
        <div>
          <p className="text-base md:text-lg text-primary leading-none">
            {t("doPay.loanDue")}
          </p>
          <p className="text-base md:text-md dark:text-white">
            ${formatNumberPoint(parseInt(loanLocal?.monto ?? '0'))}
          </p>
        </div>
      </div>
      <hr className="border-primary" />
      {/* SELECCIONAR MONTO A PAGAR */}
      <p className="my-3 text-base md:text-md text-left leading-none dark:text-white">
        {t("doPay.select")}
      </p>
      <div className="flex flex-col lg:px-10">
        <FormControl component="fieldset" className="flex gap-2">
          <FormControlLabel
            id="option1"
            value={mora}
            control={
              <Radio
                name="option1"
                sx={{
                  color: isBlackTheme ? 'white' : 'black', // Cambia el color por el que prefieras
                  '&.Mui-checked': {
                    color: 'green', // Cambia el color cuando está seleccionado
                  },
                }}
                checked={optionSelected === "option1"}
                onChange={handleChange}
              />
            }
            label={
              <p className="text-base text-left md:text-md dark:text-white">
                {t("doPay.penaltieCant")}:{" "}
                <span className="font-bold">
                  ${formatNumberPoint(mora)}
                </span>
              </p>
            }
          />
          <FormControlLabel
            key="option2"
            value={parseInt(loanLocal?.totalDue ?? '0')}
            control={
              <Radio
                name="option2"
                sx={{
                  color: isBlackTheme ? 'white' : 'black', // Cambia el color por el que prefieras
                  '&.Mui-checked': {
                    color: 'green', // Cambia el color cuando está seleccionado
                  },
                }}
                checked={optionSelected === "option2"}
                onChange={handleChange}
              />
            }
            label={
              <p className="text-base text-left md:text-md dark:text-white">
                {t("doPay.penaltieCantNext")}:{" "}
                <span className="font-bold">
                  ${formatNumberPoint(parseInt(loanLocal?.totalDue ?? '0'))}
                </span>
              </p>
            }
          />
          <FormControlLabel
            key="option3"
            value={parseInt(loanLocal?.totalCredit ?? '0')}
            control={
              <Radio
                name="option3"
                sx={{
                  color: isBlackTheme ? 'white' : 'black', // Cambia el color por el que prefieras
                  '&.Mui-checked': {
                    color: 'green', // Cambia el color cuando está seleccionado
                  },
                }}
                checked={optionSelected === "option3"}
                onChange={handleChange}
              />
            }
            label={
              <p className="text-base text-left md:text-md dark:text-white">
                {t("doPay.total")}:{" "}
                <span className="font-bold">
                  ${formatNumberPoint(parseInt(loanLocal?.totalCredit ?? '0'))}
                </span>
              </p>
            }
          />
          <FormControlLabel
            key="option4"
            value="otro"
            control={
              <Radio
                name="option4"
                sx={{
                  color: isBlackTheme ? 'white' : 'black', // Cambia el color por el que prefieras
                  '&.Mui-checked': {
                    color: 'green', // Cambia el color cuando está seleccionado
                  },
                }}
                checked={optionSelected === "option4"}
                onChange={handleChange}
              />
            }
            label={<p className="text-base text-left md:text-md dark:text-white">{t("doPay.other")}</p>}
          />
        </FormControl>
        {
          seeOtherValue &&
          <TextField
            placeholder="Ingrese el monto"
            id="value_to_pay"
            type="number"
            value={valueToPay}
            onChange={({ target }) => setValueToPay(parseInt(target.value))}
            inputProps={{ min: valueToPay }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "input": {
                  color: isBlackTheme ? "white" : "black",
                },
                "fieldset": {
                  borderColor: isBlackTheme ? "white" : "black",
                },
                "&:hover fieldset": {
                  borderColor: isBlackTheme ? "white" : "black",  // Cambia el color del borde al hacer hover
                },
                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                  borderColor: "red",  // Cambia el color del borde si hay un error
                },
              },
            }}
            error={error !== ''}
          />
        }
      </div>
      {
        error &&
        <Typography sx={{ color: "red", fontSize: 15, marginTop: 1, fontWeight: "bold", textAlign: "left" }}>{error}</Typography>
      }
      <button className="w-content rounded-lg bg-primary mt-5 py-3 px-8 text-white font-bold text-base" onClick={handlePay}>{t("doPay.pay")}</button>
    </div>
  );
}