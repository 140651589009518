import React from "react";
import { Box, Typography } from "@mui/material";
import { colors, sizes, shadow } from "../../styles/theme.tsx";
import ConfirmVc from "./RegisterSubComponents/ConfirmVc.tsx";
import CallScheduled from "./RegisterSubComponents/CallScheduled.tsx";
import CalendarVc from "./RegisterSubComponents/CalendarVc.tsx";
import RequestPreAproved from "./RegisterSubComponents/RequestPreAproved.tsx";
import LoadingRequest from "../LoadingRequest.tsx";
import ConfirmLoan from "./RegisterSubComponents/ConfirmLoan.tsx";
import { useTranslation } from "react-i18next";
import EmailValidate from "./RegisterSubComponents/EmailValidate.tsx";
import PhoneValidate from "./RegisterSubComponents/PhoneValidate.tsx";
import PasswordValidate from "./RegisterSubComponents/PasswordValidate.tsx";
import PersonalInformation from "./RegisterSubComponents/PersonalInformation.tsx";
import IdUpload from "./RegisterSubComponents/IdUpload.tsx";
import ContactInformation from "./RegisterSubComponents/ContactInformation.tsx";
import { getValueStorage, wipeValueStorage } from "../../common/storage.ts";
import FinancialInformation from "./RegisterSubComponents/FinancialInformation.tsx";
import BankInformation from "./RegisterSubComponents/BankInformation.tsx";
import References from "./RegisterSubComponents/References.tsx";
import CancelVC from "./RegisterSubComponents/CancelVC.tsx";
import NoResponse from "./RegisterSubComponents/NoResponse.tsx";
import Rejected from "./RegisterSubComponents/Rejected.tsx";
import IncommingVC from "./RegisterSubComponents/IncommingVC.tsx";
import Waiting from "./RegisterSubComponents/Waiting.tsx";
import ActiveVC from "./RegisterSubComponents/ActiveVC.tsx";
import EndedVC from "./RegisterSubComponents/EndedVC.tsx";
import RescheduleVC from "./RegisterSubComponents/RescheduleVC.tsx";
import WorkDocs from "./RegisterSubComponents/WorkDocs.tsx";
import ValidationWorkDocs from "./RegisterSubComponents/ValidationWorkDocs.tsx";
import WorkVideo from "./RegisterSubComponents/WorkVideo.tsx";
import Validation from "./RegisterSubComponents/Validation.tsx";
import Simulator from "../../components/simulators/Simulator.tsx";
import NoCredits from "./RegisterSubComponents/NoCredits.tsx";
import Referrals from "./RegisterSubComponents/Referrals/Referrals.tsx";
import { AuthContext } from "../../hooks/useAuth.tsx";
import { useNavigate } from "react-router-dom";
import RenovationHousePanning from "../Dashboard/pages/Renovations/RenovationHousePanning.tsx";
import { VerifyAddress } from "../Dashboard/pages/Renovations/VerifyAddress.tsx";
import { RenovationContactInformation } from "../Dashboard/pages/Renovations/RenovationContactInformation.tsx";
import { VerifyLaboral } from "../Dashboard/pages/Renovations/VerifyLaboral.tsx";
import { RenovationFinancialnformation } from "../Dashboard/pages/Renovations/RenovationFinancialnformation.tsx";
import RenovationVideoError from "../Dashboard/pages/Renovations/RenovationVideoError.tsx";
import ScheduleWork from "./RegisterSubComponents/ScheduleWork.tsx";
import { IUserAuth } from "../../constants/types.tsx";

export interface IRegisterStep {
  nextStep: number;
  prevStep: number | string;
  desc: string;
}

export interface IRegisterStepState {
  setRegisterStep?: React.Dispatch<React.SetStateAction<IRegisterStep>>;
}

export default function RegisterComponent() {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { setIsLoading } = React.useContext(AuthContext);
  const [registerStep, setRegisterStep] = React.useState<IRegisterStep>(
    getValueStorage("registerStep")
  );
  const [userAuth, setuserAuth] = React.useState<IUserAuth | null>(null);

  let paddingsFather = {
    xs: "40px 30px 80px 30px",
    sm: "40px 70px 80px 70px",
  };

  if (registerStep && registerStep.nextStep + "" === "14") {
    paddingsFather = {
      xs: "40px 30px 80px 30px",
      sm: "40px 40px 80px 40px",
    };
  }

  React.useEffect(() => {
    navigation("/sign-up");
    // Volver al home
    if (registerStep && registerStep.nextStep + "" === "-1") {
      navigation("/");
      wipeValueStorage("registerStep");
    }
  }, [registerStep, setRegisterStep]);

  React.useEffect(() => {
    if (getValueStorage("authSession")) {
      setuserAuth(getValueStorage("authSession"));
    }
  }, []);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          flex: "1",
          flexGrow: 1,
          marginTop: `${sizes.header}px`,
          paddingTop:
            registerStep && ["21", "24"].includes(registerStep.nextStep + "")
              ? "0"
              : "60px",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            flexGrow: 1,
            width: { xs: "95%", md: 865, lg: 850, xl: 960 },
            margin: "0 auto",
          }}
        >
          {/* Titulo */}
          {!userAuth?.loanActive?.c200_rowid && (
            <Box
              sx={{
                padding: { xs: "50px 50px 50px 35px", sm: "50px" },
                position: "relative",
                display:
                  registerStep &&
                  ["21", "24"].includes(registerStep.nextStep + "")
                    ? "none"
                    : "flex",
                margin: "0 auto",
              }}
            >
              <div
                style={{
                  width: 10,
                  height: 120,
                  background: colors.primary,
                  position: "absolute",
                  top: "50%",
                  left: 0,
                  borderRadius: 2,
                  transform: "translateY(-50%)",
                }}
              />

              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: 28, sm: 32, md: 43 },
                    color: colors.primary,
                    textTransform: "uppercase",
                  }}
                  lineHeight={1}
                >
                  {t("common.signUpHere")}
                </Typography>

                <Typography
                  sx={{
                    fontSize: { xs: 16, md: 18 },
                    color: colors.gray,
                    marginTop: "10px",
                    lineHeight: 1.3,
                  }}
                >
                  {t("common.signUpHereDesc")}
                </Typography>
              </Box>
            </Box>
          )}

          {/* CONTENIDO */}
          <Box mt={5} width={"100%"}>
            <Box
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
              sx={{ flexGrow: 1 }}
            >
              {registerStep && registerStep.nextStep + "" === "10" ? (
                <Box
                  sx={{
                    background: colors.white,
                    width: "fit-content",
                    borderRadius: "18px",
                    boxShadow: shadow.black,
                    display: "flex",
                    marginBottom: 10,
                  }}
                >
                  <Simulator
                    seeTitleForm={true}
                    setRegisterStep={setRegisterStep}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    background: colors.white,
                    width: { xs: "95%", sm: 500, xl: 600 },
                    borderRadius: "18px",
                    boxShadow: shadow.black,
                    padding: paddingsFather,
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 10,
                  }}
                >
                  {/* {registerStep && registerStep.nextStep + '' === '-1' && <Home />} */}
                  {(!registerStep || (registerStep && registerStep.nextStep + '' === '0')) && <EmailValidate setRegisterStep={setRegisterStep} />}
                  {registerStep && registerStep.nextStep + '' === '1' && <PhoneValidate setRegisterStep={setRegisterStep} />}
                  {registerStep && registerStep.nextStep + '' === '2' && <PasswordValidate setRegisterStep={setRegisterStep} />}
                  {registerStep && registerStep.nextStep + '' === '3' && <IdUpload setRegisterStep={setRegisterStep} />}
                  {registerStep && registerStep.nextStep + '' === '4' && <PersonalInformation setRegisterStep={setRegisterStep} />}
                  {registerStep && registerStep.nextStep + '' === '5' && <ContactInformation setRegisterStep={setRegisterStep} />}
                  {registerStep && registerStep.nextStep + '' === '6' && <FinancialInformation setRegisterStep={setRegisterStep} />}
                  {registerStep && registerStep.nextStep + '' === '7' && <BankInformation setRegisterStep={setRegisterStep} />}
                  {registerStep && registerStep.nextStep + '' === '8' && <References setRegisterStep={setRegisterStep} isFamiliar={true} />}
                  {registerStep && registerStep.nextStep + '' === '9' && <References setRegisterStep={setRegisterStep} isFamiliar={false} />}
                  {registerStep && registerStep.nextStep + '' === '11' && <ConfirmLoan setRegisterStep={setRegisterStep} />}
                  {registerStep && registerStep.nextStep + '' === '12' && <LoadingRequest setRegisterStep={setRegisterStep} setIsLoading={setIsLoading} />}
                  {registerStep && registerStep.nextStep + '' === '13' && <RequestPreAproved setRegisterStep={setRegisterStep} setIsLoading={setIsLoading} />}
                  {registerStep && registerStep.nextStep + '' === '14' && <CalendarVc setRegisterStep={setRegisterStep} setIsLoading={setIsLoading} />}
                  {registerStep && registerStep.nextStep + '' === '15' && <ConfirmVc setRegisterStep={setRegisterStep} setIsLoading={setIsLoading} />}
                  {registerStep && registerStep.nextStep + '' === '16' && <CallScheduled setRegisterStep={setRegisterStep} setIsLoading={setIsLoading} />}
                  {registerStep && registerStep.nextStep + '' === '17' && <CancelVC setRegisterStep={setRegisterStep} setIsLoading={setIsLoading} />}
                  {registerStep && registerStep.nextStep + '' === '19' && <NoResponse setRegisterStep={setRegisterStep} setIsLoading={setIsLoading} />}
                  {registerStep && registerStep.nextStep + '' === '20' && <Rejected />}
                  {registerStep && registerStep.nextStep + '' === '21' && <IncommingVC setRegisterStep={setRegisterStep} />}
                  {registerStep && registerStep.nextStep + '' === '22' && <ActiveVC setRegisterStep={setRegisterStep} />}
                  {registerStep && registerStep.nextStep + '' === '23' && <EndedVC setRegisterStep={setRegisterStep} />}
                  {registerStep && registerStep.nextStep + '' === '24' && <Waiting setRegisterStep={setRegisterStep} setIsLoading={setIsLoading} />}
                  {registerStep && registerStep.nextStep + '' === '25' && <RescheduleVC setRegisterStep={setRegisterStep} setIsLoading={setIsLoading} />}
                  {registerStep && registerStep.nextStep + '' === '26' && <ValidationWorkDocs setIsLoading={setIsLoading} setRegisterStep={setRegisterStep} />}
                  {registerStep && registerStep.nextStep + '' === '27' && <WorkDocs setRegisterStep={setRegisterStep} setIsLoading={setIsLoading} />}
                  {registerStep && registerStep.nextStep + '' === '28' && <WorkVideo setRegisterStep={setRegisterStep} setIsLoading={setIsLoading} />}
                  {registerStep && registerStep.nextStep + '' === '29' && <Validation setRegisterStep={setRegisterStep} />}

                  { /* VISTAS PARA LA SOLICITUD DE UN NUEVO CREDITO DE UN CLIENTE ANTIGUO */}
                  {registerStep && registerStep.nextStep + '' === '30' && <NoCredits setRegisterStep={setRegisterStep} />}
                  {registerStep && registerStep.nextStep + '' === '31' && <Referrals setRegisterStep={setRegisterStep} />}
                  {registerStep && registerStep.nextStep + '' === '32' && <RenovationHousePanning setIsLoading={setIsLoading} setRegisterStep={setRegisterStep} />}
                  {registerStep && registerStep.nextStep + '' === '33' && <VerifyAddress setRegisterStep={setRegisterStep} setIsLoading={setIsLoading} />}
                  {registerStep && registerStep.nextStep + '' === '34' && <RenovationContactInformation setRegisterStep={setRegisterStep} setIsLoading={setIsLoading} />}
                  {registerStep && registerStep.nextStep + '' === '35' && <VerifyLaboral setRegisterStep={setRegisterStep} setIsLoading={setIsLoading} />}
                  {registerStep && registerStep.nextStep + '' === '36' && <RenovationFinancialnformation setRegisterStep={setRegisterStep} setIsLoading={setIsLoading} />}
                  {registerStep && registerStep.nextStep + '' === '37' && <RenovationVideoError setRegisterStep={setRegisterStep} setIsLoading={setIsLoading} />}
                  { /* FIN VISTAS PARA LA SOLICITUD DE UN NUEVO CREDITO DE UN CLIENTE ANTIGUO */}

                  {registerStep && registerStep.nextStep + '' === '38' && <ScheduleWork setIsLoading={setIsLoading} setRegisterStep={setRegisterStep} />}

                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
