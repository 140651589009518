import React from "react";

import UploadFile from "../../../components/UploadFile.tsx";
import { Box, Typography, Zoom } from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import { IRegisterStepState } from "../RegisterComponent.tsx";
import { workDocsProps } from "./WorkDocs.tsx";

export default function ValidationWorkDocs({
  setIsLoading,
  setRegisterStep,
}: workDocsProps) {
  const { t } = useTranslation();
  const { getUser } = React.useContext(AuthContext);

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Box
          sx={{
            width: 220,
            marginBottom: 2,
          }}
        >
          <Zoom in={true}>
            <img
              src={require("../../../assets/image/AnilisisDocumentos.webp")}
              alt={""}
              loading="lazy"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Zoom>
        </Box>
      </Box>
      {/* Verifica */}
      <Box display={"flex"} flexDirection={"column"} marginTop={5}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 17,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "20px",
            textAlign: "center",
          }}
          lineHeight={1}
        >
          {t("validationWorkDocs.title")}
        </Typography>
        <Typography
          sx={{
            fontSize: 16,
            color: colors.black,
            marginBottom: "20px",
            lineHeight: 1.3,
            textAlign: "",
          }}
        >
          {t("validationWorkDocs.desc")}
        </Typography>
      </Box>
      {/* Motivo de revalidacion */}
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"left"}
        marginTop={2}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 16,
            color: colors.primary,
            marginBottom: 1,
          }}
          lineHeight={1}
        >
          {t("validationWorkDocs.errorsFound")}
        </Typography>
        <Typography
          sx={{
            fontSize: 16,
            color: colors.black,
            lineHeight: 1.3,
            "::first-letter": {
              textTransform: "uppercase",
            },
          }}
        >
          {getUser.revalidateLaborDocs?.c110_notas ?? ""}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "fit-content",
          borderBottom: `2px solid ${colors.primary}`,
          textAlign: "center",
          margin: "0 auto",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 17,
            color: colors.primary,
            textTransform: "uppercase",
            mt: 5.5,
          }}
          lineHeight={1}
        >
          {t("validationWorkDocs.documentsUpload")}
        </Typography>
      </Box>
      <Box mt={-3}>
        <UploadFile setIsLoading={setIsLoading} setRegisterStep={setRegisterStep} />
      </Box>
    </>
  );
}
