import React from "react";
import { handleReSchedule } from "./CallScheduled.tsx";
import { ColorButton } from "../../../components/Button.tsx";
import { Box, Typography, Zoom } from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getValueStorage, saveValueStorage } from "../../../common/storage.ts";
import { IRegisterStep, IRegisterStepState } from "../RegisterComponent.tsx";
import { handleCancelVc } from "./NoResponse.tsx";
import { navigationModals } from "../../../constants/types.tsx";
import { useUpdatePasoRenovacion } from "../../../common/CommonFunctions.tsx";

type RescheduleVCProps = IRegisterStepState & navigationModals;

export default function RescheduleVC({
  setRegisterStep,
  setIsLoading,
}: RescheduleVCProps) {
  const { getUser } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const { updatePasoRenovacion, parseRenovacion } = useUpdatePasoRenovacion();
  const registerStep: IRegisterStep = getValueStorage("registerStep");

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Box
          sx={{
            width: 220,
            marginBottom: 2,
          }}
        >
          <Zoom in={true}>
            <img
              src={require("../../../assets/image/Agenda.webp")}
              alt={""}
              loading="lazy"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Zoom>
        </Box>
      </Box>
      {/* Importante */}
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"left"}
        marginTop={5}
        mx={setRegisterStep ? 0 : 3}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 17,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "20px",
            textAlign: "center",
          }}
          lineHeight={1}
        >
          {t("rescheduleVC.title")}
        </Typography>
        <Typography
          className="dark:text-white"
          sx={{
            fontSize: 16,
            color: colors.black,
            marginBottom: "20px",
            lineHeight: 1.3,
            textAlign:"left"
          }}
        >
          {t("rescheduleVC.desc1").split("-")[0]}
          <strong>{t("rescheduleVC.desc1").split("-")[1]}</strong>
          {t("rescheduleVC.desc2").split("-")[0]}
          <strong>{t("rescheduleVC.desc2").split("-")[1]}</strong>
        </Typography>
      </Box>
      {/* motivo de reagendamiento */}
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"left"}
        marginTop={2}
        textAlign={"left"}
        mx={setRegisterStep ? 0 : 3}

      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 16,
            color: colors.primary,
            marginBottom: 1,
          }}
          lineHeight={1}
        >
          {t("rescheduleVC.reasonTitle")}
        </Typography>
        <Typography
          className="dark:text-white"
          sx={{
            fontSize: 16,
            color: colors.black,
            lineHeight: 1.3,
          }}
        >
          {getValueStorage("authSession").hasVideoCall.calcel_reason
            ?.c157_motivo ?? ""}
        </Typography>
      </Box>

      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        marginTop={8}
      >
        <ColorButton
          sx={{
            width: "fit-content",
            padding: { xs: "15px 60px", sm: "15px 80px" },
            fontSize: 16,
          }}
          onClick={() =>
            handleReSchedule({
              getUser,
              prevStep: 25,
              nextStep: 14,
              desc: "El usuario debe reagendar la vc",
              setRegisterStep,
              setIsLoading,
              parseRenovacion,
              updatePasoRenovacion,
            })
          }
          disabled={getUser.isLoading}
        >
          {t("videoCall.reschedule")}
        </ColorButton>
        <ColorButton
          sx={{
            width: "fit-content",
            padding: "8px 20px",
            fontSize: 13,
            mt: 1,
            background: colors.blueGray,
            borderColor: colors.blueGray,
            borderRadius: "13px !important",
          }}
          onClick={() =>
            handleCancelVc({
              prevStep: registerStep && registerStep.nextStep,
              desc: "Cancelar solicitud desde rescheduleVC",
              setRegisterStep,
              setIsLoading,
            })
          }
          disabled={getUser.isLoading}
        >
          {t("common.cancelRequest")}
        </ColorButton>
      </Box>
    </>
  );
}
