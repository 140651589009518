import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default function FormsInputMoney({
  nameParam,
  setChanges,
  id,
  isLoading,
  setValids,
}) {
  const { t } = useTranslation();
  const [value, setValue] = React.useState<string>("");

  React.useEffect(() => {
    setValids((state: any) => ({ ...state, [id]: false }));
  }, []);

  // Input para convertir a miles
  const handleChange = (elementValue: string) => {
    // Remover cualquier caracter que no sea un dígito
    const inputValue = parseInt(elementValue.replace(/\D/g, ""));
    const valueLocal = inputValue ?? 0;
    let element = document.getElementById(`errorM_${id}`);

    setChanges((state: any) => ({ ...state, [id]: valueLocal }));
    setValids((state: any) => ({ ...state, [id]: valueLocal > 100000 }));

    // Formatear a pesos de miles
    const formattedValue = new Intl.NumberFormat("es-CO").format(inputValue);
    setValue(inputValue > 0 ? formattedValue : "0");

    if (element && valueLocal < 100000) {
      element.style.display = "block";
      element.innerHTML = "Ingrese un valor mayor a $100.000";
    } else if (element && valueLocal > 100000) {
      element.style.display = "none";
    }
  };

  return (
    <Box sx={{ marginTop: 1 }}>
      <label
        style={{
          fontSize: 13,
          fontWeight: 600,
          textWrap: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
        htmlFor={id}
      >
        {t(nameParam)}
      </label >
      <TextField
        name={id}
        sx={{
          width: "100%"
        }}
        value={value}
        placeholder={`Cambia tus ${t(nameParam)}`}
        onChange={({ target }) => handleChange(target.value)}
        disabled={isLoading}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">$</InputAdornment>
          ),
        }}
      />
      <Typography id={`errorM_${id}`} sx={{ color: "red", fontSize: 13, marginTop: 1, fontWeight: "bold", display: "none" }}></Typography>
    </Box>
  );
};