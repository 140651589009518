import { Box, Typography, Divider } from "@mui/material";
import React from "react";
import { AuthContext } from "../../../../hooks/useAuth.tsx";
import { useTranslation } from "react-i18next";
import { colors } from "../../../../styles/theme.tsx";
import { ColorButton } from "../../../../components/Button.tsx";
import {
  IFinantialInformation_selects,
  IGetFinancialDataByThird,
  navigationModals,
} from "../../../../constants/types.tsx";
import { getValueStorage, saveValueStorage } from "../../../../common/storage.ts";
import {
  requestSquematic,
  useUpdatePasoRenovacion,
} from "../../../../common/CommonFunctions.tsx";
import { errorAlert, ModalDashboard } from "../../../../common/alerts.tsx";
import renovationStep from "../../services/RenovationStep.tsx";
import RequestPreAproved from "../../../User/RegisterSubComponents/RequestPreAproved.tsx";

export const VerifyLaboral = ({ onClose, setIsLoading, setRegisterStep }: navigationModals) => {
  const { t } = useTranslation();
  const { getUser } = React.useContext(AuthContext);
  const { updatePasoRenovacion, parseRenovacion } = useUpdatePasoRenovacion();
  const [dataFinancial, setDataFinancial] = React.useState({
    c126_periodo_pago: "",
    c126_rowid_estadolaboral: "",
  });

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);
      const getUserTemp = getValueStorage("authSession");
      try {
        const res: IFinantialInformation_selects = await requestSquematic(
          "GET",
          "/api/app/financial_data/get_selects",
          {},
          getUserTemp.token
        );

        if (res) {
          const IFinantialInformation_selects = res;

          const foundLaboral =
            IFinantialInformation_selects.results!.laboral_status.find(
              (item) =>
                item.c019_rowid ===
                Number(getUserTemp.financialData.c126_rowid_estadolaboral)
            );

          const foundPeriodPay =
            IFinantialInformation_selects.results!.period_types.find(
              (item) =>
                item.c054_rowid ===
                parseInt(getUserTemp.financialData.c126_periodo_pago)
            );

          setDataFinancial({
            c126_periodo_pago: foundPeriodPay
              ? foundPeriodPay.c054_descripcion
              : "",
            c126_rowid_estadolaboral: foundLaboral
              ? foundLaboral.c019_descripcion
              : "",
          });
        }
      } catch (error) {
        console.error("Error fetching financial data:", error);
        const errorMessage =
          error instanceof Error ? error.message : String(error);
        errorAlert("Error", errorMessage, "Ok");
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const handleContinue = async () => {
    if (parseRenovacion().tipo_videollamada === "1") {
      await updatePasoRenovacion("5", setIsLoading);
      ModalDashboard({
        element: <RequestPreAproved setIsLoading={setIsLoading} />,
      });
    } else {
      if (setRegisterStep) {
        let obj = {
          prevStep: 35,
          nextStep: 32,
          desc: "pasa a renovar el video de la casa",
        }
        saveValueStorage("registerStep", obj);
        setRegisterStep(obj);
      } else {
        await updatePasoRenovacion("3", setIsLoading);
        let dinamicElement = renovationStep({
          onClose: () => onClose(),
          setIsLoading: setIsLoading,
          parseRenovacion: parseRenovacion,
        });
        if (dinamicElement) {
          ModalDashboard({
            element: dinamicElement,
          });
        }
      }
    }
  };

  const handleEditFinancial = async () => {
    setIsLoading(true);
    await updatePasoRenovacion("N3", setIsLoading);
    let dinamicElement = renovationStep({
      onClose: () => onClose(),
      setIsLoading: setIsLoading,
      parseRenovacion: parseRenovacion,
    });
    if (dinamicElement) {
      ModalDashboard({
        element: dinamicElement,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <p className="text-primary uppercase font-bold text-base mx-6 leading-5 text-center">
        {t("verifyLaboral.title")}
      </p>
      <Divider className="dark:bg-white" sx={{ mt: 1.5, mx: 1.5 }} />
      <Box className="w-[98%] pt-5 overflow-x-hidden mx-auto px-3 relative text-center flex flex-col">
        {/* Inputs y maps */}
        <Box className="flex flex-col text-left mt-3">
          <Box>
            <p className="font-bold text-base text-gray-900 dark:text-secondary-600">
              {t("verifyLaboral.situation")}
            </p>
            <p className="text-base -mt-0.5 text-secondary-800 dark:text-white">
              {dataFinancial.c126_rowid_estadolaboral}
            </p>
          </Box>

          <Box className="mt-5">
            <p className="font-bold text-base text-gray-900 dark:text-secondary-600">
              {t("financialInformation.company")}
            </p>
            <p className="text-base -mt-0.5 text-secondary-800 dark:text-white">
              {getValueStorage("authSession").financialData.c126_empresa}
            </p>
          </Box>
          <Box className="mt-5">
            <p className="font-bold text-base text-gray-900 dark:text-secondary-600">
              {t("verifyLaboral.companyPhone")}
            </p>
            <p className="text-base -mt-0.5 text-secondary-800 dark:text-white">
              {getValueStorage("authSession").financialData.c126_num_empresa}
            </p>
          </Box>
          <Box className="mt-5">
            <p className="font-bold text-base text-gray-900 dark:text-secondary-600">
              {t("financialInformation.companyDir")}
            </p>
            <p className="text-base -mt-0.5 text-secondary-800 dark:text-white">
              {
                getValueStorage("authSession").financialData
                  .c126_direccion_trabajo
              }
            </p>
          </Box>
          <Box className="mt-5">
            <p className="font-bold text-base text-gray-900 dark:text-secondary-600">
              {t("financialInformation.charge")}
            </p>
            <p className="text-base -mt-0.5 text-secondary-800 dark:text-white">
              {getValueStorage("authSession").financialData.c126_cargo}
            </p>
          </Box>
          <Box className="mt-5">
            <p className="font-bold text-base text-gray-900 dark:text-secondary-600">
              {t("verifyLaboral.paymentPeriod")}
            </p>
            <p className="text-base -mt-0.5 text-secondary-800 dark:text-white">
              {dataFinancial.c126_periodo_pago}
            </p>
          </Box>
        </Box>
        <Box className="flex flex-col my-16 text-primary dark:text-white font-bold">
          {/* ¿Tu situación laboral es la misma actualmente? */}
          <p className="leading-5	text-lg">{t("verifyLaboral.same")}</p>
          <div className="grid grid-cols-2 mt-7 gap-7 sm:gap-5">
            <div className="flex justify-end">
              <ColorButton
                background={colors.blueGray}
                sx={{
                  width: "140px",
                  padding: "10px 40px",
                  fontSize: 15,
                  borderRadius: 3,
                  margin: 0,
                }}
                disabled={getUser.isLoading}
                onClick={handleContinue}
              >
                {t("common.yes")}
              </ColorButton>
            </div>
            <div className="flex justify-start">
              <ColorButton
                sx={{
                  width: "140px",
                  padding: "10px 40px",
                  fontSize: 15,
                  borderRadius: 3,
                  margin: 0,
                }}
                disabled={getUser.isLoading}
                onClick={handleEditFinancial}
              >
                {t("common.no")}
              </ColorButton>
            </div>
          </div>
        </Box>
        {/* Importante */}
        <Box textAlign={"center"} sx={{ marginX: { xs: 1 } }}>
          <Typography
            className="text-lg"
            sx={{
              fontWeight: "bold",
              color: colors.primary,
              textTransform: "uppercase",
              marginBottom: "5px",
            }}
            lineHeight={1}
          >
            {t("verifyAddress.important")}
          </Typography>

          <Typography
            className="text-secondary-800 dark:text-white"
            sx={{
              fontSize: 15,
              color: colors.black,
              lineHeight: 1.2,
              marginBottom: "20px",
              textAlign: "justify",
              mt: 2,
            }}
          >
            {t("verifyAddress.textImportant")}
            <strong> {t("verifyAddress.textImportant2")} </strong>
            {t("verifyAddress.textImportant3")}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
