import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import Lottie from "lottie-react";
import {
  convertTo12HourFormat,
  formatDateTime,
  useUpdatePasoRenovacion,
} from "../../../common/CommonFunctions.tsx";
import { useTranslation } from "react-i18next";
import { ColorButton } from "../../../components/Button.tsx";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import { getValueStorage, saveValueStorage } from "../../../common/storage.ts";
import { IRegisterStepState } from "../RegisterComponent.tsx";
import { navigationModals } from "../../../constants/types.tsx";
import renovationStep from "../../Dashboard/services/RenovationStep.tsx";
import { ModalDashboard } from "../../../common/alerts.tsx";

type ConfirmVcProps = IRegisterStepState & navigationModals;

export default function ConfirmVc({
  setIsLoading,
  setRegisterStep,
}: ConfirmVcProps) {
  const { t } = useTranslation();
  const { updatePasoRenovacion, parseRenovacion } = useUpdatePasoRenovacion();
  const { getUser } = React.useContext(AuthContext);

  React.useEffect(() => {
    (async () => {
      if (
        getValueStorage("authSession").inProcessRenovation
          ?.inProcessRenovation ||
        getValueStorage("authSession").isOld
      ) {
        await updatePasoRenovacion("0", setIsLoading, "0");
      }
    })();
  }, []);

  //Continuar al recordatorio
  const handleContinue = () => {
    let obj = {
      prevStep: 15,
      nextStep: 16,
      desc: "el usuario pasa a recordacion",
    };
    saveValueStorage("registerStep", obj);
    if (setRegisterStep) {
      setRegisterStep(obj);
    } else {
      // Conserva el paso de renovacion 0
      let dinamicElement = renovationStep({
        setIsLoading: setIsLoading,
        parseRenovacion: parseRenovacion,
      });
      if (dinamicElement) {
        ModalDashboard({
          element: dinamicElement,
        });
      }
    }
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Lottie
          style={{
            width: 150,
            height: 150,
            marginBottom: 15,
          }}
          loop={false}
          autoPlay
          animationData={require("../../../assets/animations/check.json")}
        />
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 17,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("confirmVc.videoCallConfirm")}
        </Typography>
        <Typography
          className="dark:text-white"
          sx={{
            fontWeight: "bold",
            fontSize: 18,
            color: colors.black,
            textAlign: "center",
          }}
          lineHeight={1}
        >
          {formatDateTime(
            getValueStorage("authSession").hasVideoCall
              ?.c155_fecha_agendamiento ?? "1996-08-14 00:00:00",
            t
          )}
          <br />
          {convertTo12HourFormat(
            getValueStorage("authSession").hasVideoCall
              ?.c155_fecha_agendamiento &&
              getValueStorage("authSession")
                .hasVideoCall?.c155_fecha_agendamiento.split(" ")[1]
                .split(".")[0]
          )}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"left"}
        marginTop={{ xs: 10, md: "60px" }}
      >
        {getValueStorage("authSession").hasVideoCall?.c155_tipo === "1" ? (
          <Box sx={{ textAlign: "left", mx: setRegisterStep ? 0 : 2 }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 17,
                color: colors.primary,
                textTransform: "uppercase",
                marginBottom: "20px",
              }}
              lineHeight={1}
            >
              {t("confirmVc.neccesaryPoints")}
            </Typography>

            <Typography
              className="dark:text-white"
              sx={{
                fontSize: 16,
                color: colors.black,
                marginBottom: "20px",
                lineHeight: 1.3,
              }}
            >
              · {t("confirmVc.point1")}{" "}
              <strong> {t("confirmVc.point1_2")}</strong>
              {t("confirmVc.point1_3")}
            </Typography>

            <Typography
              className="dark:text-white"
              sx={{
                fontSize: 16,
                color: colors.black,
                lineHeight: 1.3,
                marginBottom: "20px",
              }}
            >
              · {t("confirmVc.point2")}
              <strong>
                {t("confirmVc.point2_2") + t("confirmVc.point2_2Home")}
              </strong>
              {t("confirmVc.point2_3")}
            </Typography>
          </Box>
        ) : (
          <Box sx={{ textAlign: "left", mx: setRegisterStep ? 0 : 2 }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 17,
                color: colors.primary,
                textTransform: "uppercase",
                marginBottom: "20px",
              }}
              lineHeight={1}
            >
              {t("confirmLoan.important")}
            </Typography>

            <Typography
              className="dark:text-white"
              sx={{
                fontSize: 16,
                color: colors.black,
                lineHeight: 1.3,
                marginBottom: "20px",
              }}
            >
              · {t("confirmVc.point2")}
              <strong>{t("scheduleWork.subMoment")}</strong>
              {t("scheduleWork.afterMoment")}
            </Typography>
          </Box>
        )}
        <Box sx={{ textAlign: "left", mx: setRegisterStep ? 0 : 2 }}>
          <Typography
            className="dark:text-white"
            sx={{
              fontSize: 16,
              color: colors.black,
              lineHeight: 1.3,
            }}
          >
            · {t("confirmVc.point3").split("-")[0]}{" "}
            <strong>{t("confirmVc.point3").split("-")[1]}</strong>
          </Typography>
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        marginTop={{ xs: 5, md: "50px" }}
      >
        <ColorButton
          sx={{
            width: "fit-content",
            padding: "15px 40px",
            fontSize: 16,
          }}
          onClick={handleContinue}
          disabled={getUser.isLoading}
        >
          {t("common.continue")}
        </ColorButton>
      </Box>
    </>
  );
}
