import React from "react";
import { ICongelado } from "../../../constants/types.tsx";
import { formatNumberPoint, stringToDate } from "../../../common/CommonFunctions.tsx";
import { MySwal } from "../../../common/alerts.tsx";
import { useTranslation } from "react-i18next";

export const ModalCong = ({ congelado }: { congelado: ICongelado | null }) => {
  const { t } = useTranslation();
  return (
    <div className="pt-8 pb-2 overflow-x-hidden">
      <p className="text-[30px] font-bold text-primary">{t("condCongNotiModal.title")}</p>
      <p>{t("condCongNotiModal.subtitle")}</p>
      <div className="flex items-center justify-center mt-2">
        <img src={require("../assets/images/muneco.webp")} alt="muneco_congelado" style={{ width: 300, marginBottom: 15 }} />
      </div>
      <p className="text-xl sm:text-2xl text-secondary-800 dark:text-white">{t("condCongNotiModal.yourCredit")}</p>
      <p className="text-[25px] font-bold text-primary uppercase mb-4">{t("condCongNotiModal.congName")}</p>
      <p className="text-xl sm:text-2xl text-secondary-800 dark:text-white">{t("condCongNotiModal.valueToPay")}</p>
      <p className="text-[30px] font-bold text-primary uppercase">${formatNumberPoint(parseInt(congelado?.valor_pagar ?? '0'))}</p>
      <div className="mt-6 text-left">
        <p>{t("condCongNotiModal.limitDate")}:</p>
        <p className="font-bold">
          {stringToDate(congelado?.fecha_limite_pago, {
            day: "2-digit",
            month: "long",
            year: "numeric",
          })}
        </p>
      </div>
      <div className="flex flex-col items-center justify-center mt-2">
        <p className="text-[25px] font-bold text-primary uppercase mb-4">Ten en cuenta</p>
        <p className="text-md">
          {t("condCongNotiModal.haveDescCond1").split("-")[0]}<strong>{t("condCongNotiModal.haveDescCond1").split("-")[1]}</strong>
          {t("condCongNotiModal.haveDescCong2").split("-")[0]}<strong>{t("condCongNotiModal.haveDescCong2").split("-")[1]}</strong>
          {t("condCongNotiModal.haveDescCond3").split("-")[0]}<strong>{t("condCongNotiModal.haveDescCond3").split("-")[1]}</strong>
          {t("condCongNotiModal.haveDescCond4").split("-")}
        </p>
      </div>
      <div className="flex flex-col items-center justify-center mt-2">
        <button className="w-content rounded-lg bg-primary py-3 px-6 text-white font-bold text-base mt-6" onClick={() => MySwal.close()}>{t("condCongNotiModal.understand")}</button>
      </div>
    </div>
  );
};