import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import { CssTextField } from "../../../../components/CssTextField.tsx";
import { AuthContext } from "../../../../hooks/useAuth.tsx";
import { IReferredUserReferrals, IUserAuth } from "../../../../constants/types.tsx";
import { useTranslation } from "react-i18next";
import { requestSquematic } from "../../../../common/CommonFunctions.tsx";
import SearchIcon from '@mui/icons-material/Search';
import { getValueStorage } from "../../../../common/storage.ts";
import { isAccepted } from "./Referrals.tsx";

const returnObjectStatusLoan = (c200_ind_estado: string) => {
  let response = { color: "#8c8c8c", text: "Pendiente" };
  if (isAccepted(c200_ind_estado)) {
    response = { color: "#00FF00", text: "Aceptado" };
  } else if (c200_ind_estado === "4") {
    response = { color: "#FF0000", text: "Rechazado" };
  }
  return response;
}

export default function MyReferrals() {
  const { t } = useTranslation();
  const { getUser, setIsLoading, signOut } = React.useContext(AuthContext);
  const authSession: IUserAuth = getValueStorage("authSession");
  const [data, setData] = React.useState<IReferredUserReferrals[]>([]);
  const [originalData, setOriginalData] = React.useState<IReferredUserReferrals[]>([]);
  const [filter, setFilter] = React.useState("");

  React.useEffect(() => {
    fetchData();
  }, []);

  //Obtener listado de referidos
  const fetchData = async () => {
    setIsLoading(true);

    const data = await requestSquematic(
      "GET",
      "/api/app/referred/referrals",
      {},
      authSession.token,
      signOut
    );

    if (data) {
      setData(data.results!);
      setOriginalData(data.results!);
    }

    setIsLoading(false);
  };

  const handleFilter = (filter: string) => {
    setData(
      originalData.filter(
        ({ user_referred }) =>
          user_referred.c001_correo_electronico.includes(filter) ||
          user_referred.third?.c120_razon_social.includes(filter)
      )
    );
  }

  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} marginTop={2}>
      <p className="my-3 text-xl font-bold dark:text-white">
        {t("myReferrals.desc")}
      </p>
      <Box>
        {/* ESTADOS DE LOS REFERIDOS */}
      </Box>
      <Box sx={{ width: "100%", marginTop: 2 }}>
        <CssTextField
          name="filter"
          value={filter}
          placeholder="Buscar referido"
          onChange={({ target }) => setFilter(target.value)}
          sx={{ width: "100%", backgroundColor: "#ffffff" }}
          slotProps={{
            input: {
              startAdornment: <SearchIcon sx={{ marginRight: 1 }} />
            }
          }}
          inputProps={{
            maxLength: 100,
            type: "text"
          }}
          disabled={getUser.isLoading || data.length === 0}
        />
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableCell align="center" className="dark:!bg-navy-800 dark:text-white">NOMBRE</TableCell>
            <TableCell align="center" className="dark:!bg-navy-800 dark:text-white">ESTADO CRÉDITO</TableCell>
          </TableHead>
          <TableBody>
            {
              data.length === 0 ?
                <TableRow>
                  <TableCell align="center" colSpan={2} className="dark:!bg-navy-800 dark:text-white">{t("myReferrals.tableEmpty")}</TableCell>
                </TableRow>
                :
                data.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className="dark:!bg-navy-800 dark:text-white">{item.user_referred.third?.c120_razon_social}</TableCell>
                    <TableCell className="dark:!bg-navy-800 dark:text-white">
                      <div className="flex flex-row gap-1 items-center">
                        <div style={{
                          width: 15,
                          height: 15,
                          borderRadius: "50%",
                          backgroundColor: item.user_referred.third?.first_loan?.c200_ind_estado ? returnObjectStatusLoan(item.user_referred.third?.first_loan?.c200_ind_estado).color : "#ffffff",
                        }}></div>
                        <div>{item.user_referred.third?.first_loan?.c200_ind_estado ? returnObjectStatusLoan(item.user_referred.third?.first_loan?.c200_ind_estado).text : "n/a"}</div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}