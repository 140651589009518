import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../../../styles/theme.tsx";
import { Calendar } from "@natscale/react-calendar";
import {
  formatDateToDBString,
  requestSquematic,
  convertTo12HourFormat,
  formatDateTime,
  convertTimeTo24HourFormat,
  useUpdatePasoRenovacion,
} from "../../../common/CommonFunctions.tsx";
import moment from "moment-timezone";
import "@natscale/react-calendar/dist/main.css";
import { ColorButton } from "../../../components/Button.tsx";
import {
  ISimulatorData,
  monthsSpanishLabel,
  navigationModals,
  weekDaysLabel,
} from "../../../constants/types.tsx";
import { ModalDashboard, successAlert } from "../../../common/alerts.tsx";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import { getValueStorage, saveValueStorage } from "../../../common/storage.ts";
import Grid from "@mui/material/Grid2";
import { IRegisterStep } from "../RegisterComponent.tsx";
import ConfirmVc from "./ConfirmVc.tsx";
import renovationStep from "../../Dashboard/services/RenovationStep.tsx";

interface ICalendarVcParams {
  setRegisterStep?: React.Dispatch<React.SetStateAction<IRegisterStep>>;
}

type CalendarVcProps = ICalendarVcParams & navigationModals;

export default function CalendarVc({
  setIsLoading,
  setRegisterStep,
}: CalendarVcProps) {
  const { parseRenovacion, updatePasoRenovacion } = useUpdatePasoRenovacion();
  const { signOut } = useContext(AuthContext);
  const { t } = useTranslation();
  const [valueCalendar, setValueCalendar] = useState<Date>(new Date());
  const [times, setTimes] = useState<string[] | null>(null);
  const [concatenateTime, setConcatenateTime] = useState<string>("");
  const [timeSelected, setTimeSelected] = useState<string>("");
  const simulationData: ISimulatorData = getValueStorage("simulationData");

  let today = moment(formatDateToDBString(new Date())).tz("America/Bogota");
  let maxDateCalendar = moment(today.format("YYYY-MM-DD HH:mm:ss"));
  const theme = useTheme();
  const matches_xs = useMediaQuery(theme.breakpoints.down("md"));
  const matches_md = useMediaQuery(theme.breakpoints.up("md"));
  const matches_xl = useMediaQuery(theme.breakpoints.up("xl"));

  //sumar 15 dias sin tener en cuenta los domingos
  let addedDays = 0;
  while (addedDays < 15) {
    maxDateCalendar = maxDateCalendar.add(1, "days");
    if (maxDateCalendar.day() !== 0) {
      addedDays++;
    }
  }

  const fetchData = async () => {
    setIsLoading(true);
    let getHour = "";
    let dateSelected = moment(formatDateToDBString(valueCalendar)).tz(
      "America/Bogota"
    );

    if (dateSelected.isAfter(today)) {
      //quiere decir que la fecha es posterior le cambiamos la hora
      getHour = "07:50:00";
    } else if (
      dateSelected.format("YYYY-MM-DD") === today.format("YYYY-MM-DD")
    ) {
      //quiere decir que la fecha seleccionada es igual a la actual
      getHour = today.format("YYYY-MM-DD HH:mm:ss").split(" ")[1];
    }

    const res = await requestSquematic(
      "POST",
      "/api/panel/vc_validations/get_schedule",
      { currentDate: dateSelected.format("YYYY-MM-DD") + " " + getHour },
      ""
    );

    if (res) {
      const availableTimes = Object.keys(res.results).map((time: string) =>
        convertTo12HourFormat(time)
      );
      setTimes(availableTimes);
      setConcatenateTime("");
    } else {
      setTimes([]);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    if (!simulationData) {
      saveValueStorage("simulationData", {
        c200_rowid:
          getValueStorage("authSession").loanRequest?.c200_rowid ?? "",
        c200_valor: "",
        c200_rowid_ciiu: "",
        c200_plazo: "",
        c200_rowid_concepto: "",
        c200_rowid_periodo: "",
        date_first_due: "",
        due_value: "",
        total_due_to_pay: "",
      });
    }
  }, [valueCalendar]);

  const disableDates = useCallback((date: Date) => {
    //si es domingo
    if (date.getDay() === 0) {
      return true;
    }

    //que el mes de la fecha que se esta renderizando sea menor a la fecha actual
    if (date.getMonth() < today.get("month")) {
      return true;
    }

    //si el mes es el mismo de la fecha que se esta renderizando tenemos en cuenta que no sea mayor al dia actual
    if (
      date.getMonth() === today.get("month") &&
      date.getDate() < parseInt(today.format("D"))
    ) {
      return true;
    }

    //si el mes de la fecha maxima es menor al mes que se renderiza
    if (maxDateCalendar.get("month") < date.getMonth()) {
      return true;
    }

    //si es el mismo mes de la fecha maxima y el dia que se renderiza es mayor o igual a la fecha maxima
    if (
      date.getMonth() === maxDateCalendar.get("month") &&
      date.getDate() > parseInt(maxDateCalendar.format("D"))
    ) {
      return true;
    }
    return false;
  }, []);

  // Seleccionar la fecha
  const selectDate = (date: Date) => {
    // Si es mobile, se desplaza hacia abajo para ver las horas disponibles.
    const scrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;

    if (scrollPosition < 800 && matches_xs) {
      window.scrollTo({
        top: 700,
        behavior: "smooth",
      });
    }
    setValueCalendar(date);
    setTimeSelected("");
  };

  // Seleccionar la hora
  const changeTime = async (timeSelected: string) => {
    // Se desplaza hacia abajo para ver la fecha seleccionada.
    const scrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;

    if (scrollPosition < 800) {
      window.scrollTo({
        top: 700,
        behavior: "smooth",
      });
    }
    setTimeSelected(timeSelected);
    setConcatenateTime(
      moment(
        formatDateToDBString(valueCalendar).split(" ")[0] +
          " " +
          convertTimeTo24HourFormat(timeSelected)
      ).format("YYYY-MM-DD HH:mm:ss")
    );
  };

  //guardar la videollamada
  const saveVideoCall = async () => {
    setIsLoading(true);

    // Constante para saber si el tipo de video call esta en renovacion
    const typeVcRenovation =
      getValueStorage("authSession").inProcessRenovation?.inProcessRenovation &&
      parseRenovacion().tipo_videollamada !== "0"
        ? parseRenovacion().tipo_videollamada
        : null;

    let objToSend: any = {
      c200_rowid: simulationData.c200_rowid,
      horainicio: concatenateTime,
      c155_tipo: typeVcRenovation
        ? typeVcRenovation
        : getValueStorage("authSession").hasVideoCall.c155_tipo
        ? getValueStorage("authSession").hasVideoCall.c155_tipo
        : "1"
    };

    if(getValueStorage("authSession").hasVideoCall.c155_rowid_estado && getValueStorage("authSession").hasVideoCall.c155_rowid_estado + '' === '39'){
      objToSend = {
        ...objToSend,
        c155_rowid_estado: 39
      }
    }

    const res = await requestSquematic(
      "POST",
      "/api/panel/vc_validations/save_videocall",
      objToSend,
      getValueStorage("authSession").token
    );

    if (res && res.results) {
      saveValueStorage("authSession", {
        ...getValueStorage("authSession"),
        hasVideoCall: res.results,
      });
      let obj = {
        prevStep: 14,
        nextStep: 15,
        desc: "el usuario agendo la videollamada",
      };
      saveValueStorage("registerStep", obj);
      if (setRegisterStep) {
        setRegisterStep(obj);
      } else {
        ModalDashboard({
          element: <ConfirmVc setIsLoading={setIsLoading} />,
        });
      }
    }
    setIsLoading(false);
  };

  //Volver atras
  const handleGoBack = async () => {
    if (setRegisterStep) {
      let obj = {
        prevStep: 14,
        nextStep: getValueStorage("registerStep")
          ? getValueStorage("registerStep").prevStep
          : signOut(),
        desc: "el usuario sale del calendario",
      };
      saveValueStorage("registerStep", obj);
      setRegisterStep(obj);
    } else {
      await updatePasoRenovacion("0", setIsLoading, "0");
      let dinamicElement = renovationStep({
        setIsLoading: setIsLoading,
        parseRenovacion: parseRenovacion,
      });
      if (dinamicElement) ModalDashboard({ element: dinamicElement });
    }
  };
  
  return (
    <Box>
      {/* Calendario */}
      <Grid
        container
        sx={{
          flexDirection: { xs: "column", md: "row" },
          my: { xs: 5, md: setRegisterStep ? 8 : 5 },
          mx: { xs: setRegisterStep ? 0 : 3 },
          height: { md: setRegisterStep ? 400 : "auto" },
        }}
        display={"flex"}
      >
        <Grid
          size={{ xs: 12, md: setRegisterStep ? 7 : 12 }}
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              lineHeight: 1,
              color: colors.primary,
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: 32,
            }}
          >
            {t("calendar.select")}
          </Typography>
          <Typography
            sx={{
              color: colors.primary,
              lineHeight: 1,
              fontSize: 20,
              marginBottom: "20px",
              textTransform: "uppercase",
            }}
          >
            {t("calendar.selectDate")}
          </Typography>
          <Box
            sx={{
              display: { xs: "flex", md: "block" },
              justifyContent: { xs: "center", md: "unset" },
              alignItems: { xs: "center", md: "unset" },
              width: "100%",
            }}
          >
            <Calendar
              size={matches_xl ? 280 : matches_md ? 220 : 250}
              isDisabled={disableDates}
              value={valueCalendar}
              onChange={(e) => selectDate(new Date(e.toString()))}
              monthsLabel={monthsSpanishLabel}
              weekDaysLabel={weekDaysLabel}
              startOfWeek={1}
            ></Calendar>
          </Box>
        </Grid>
        <Grid
          size={{ xs: 12, md: setRegisterStep ? 5 : 12 }}
          sx={{
            marginTop: { xs: "40px", md: setRegisterStep ? 0 : 5 },
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {times && times.length > 0 ? (
            <>
              <Typography
                className="dark:text-white"
                sx={{
                  color: colors.black,
                  lineHeight: 1,
                  fontSize: 20,
                  marginBottom: "15px",
                  fontWeight: "bold",
                }}
              >
                {t("calendar.availableHours")}
              </Typography>
              <Box
                sx={{
                  maxHeight: { xs: 250, md: setRegisterStep ? 370 : 150 },
                  overflow: "clip scroll",
                }}
              >
                {times.map((item) => (
                  <Box
                    key={item} // Agregar key único para cada elemento
                    className="calendar-hour-container"
                    sx={{
                      padding: {
                        xs: "20px",
                        md: "15px 9px",
                        xl: "10px 15px",
                      },
                      backgroundColor:
                        timeSelected === item ? colors.primary : "transparent",
                    }}
                    onClick={() => changeTime(item)}
                  >
                    <div className="calendar-circle-container">
                      <div
                        id="circle"
                        style={{
                          background: timeSelected === item ? colors.white : "",
                        }}
                      ></div>
                      <span
                        className="dark:!text-white"
                        id="text"
                        style={{
                          lineHeight: 1,
                          fontSize: 15,
                          color:
                            timeSelected === item ? colors.white : "inherit",
                        }}
                      >
                        {item}
                      </span>
                    </div>
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </Box>
                ))}
              </Box>
            </>
          ) : times && times.length === 0 ? (
            <Typography
              className="dark:text-white"
              sx={{
                color: colors.black,
                lineHeight: 1,
                fontSize: 15,
                marginBottom: "15px",
                fontWeight: "bold",
              }}
            >
              {t("calendar.noTimes")}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      {/* Fecha */}
      {concatenateTime && (
        <Box
          sx={{
            marginBottom: { xs: "40px", md: "30px" },
          }}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            sx={{
              lineHeight: 1,
              color: colors.primary,
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: 20,
              marginTop: setRegisterStep ? "17px" : "12px",
            }}
          >
            {t("calendar.dateSelected")}
          </Typography>
          <Typography
            className="dark:text-white"
            sx={{
              fontWeight: "bold",
              fontSize: 18,
              color: colors.black,
              textAlign: "center",
              mt:0.5
            }}
            lineHeight={1}
          >
            {formatDateTime(concatenateTime, t)}
            <br />
            {convertTo12HourFormat(concatenateTime.split(" ")[1].split(".")[0])}
          </Typography>
        </Box>
      )}
      {/* Botones */}
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <ColorButton
          sx={{
            width: "fit-content",
            padding: "15px 40px",
            fontSize: 16,
            lineHeight: 1,
          }}
          disabled={!concatenateTime}
          onClick={saveVideoCall}
        >
          {t("calendar.scheduleV")}
        </ColorButton>
        {(getValueStorage("authSession").hasVideoCall ||
          setRegisterStep ||
          parseRenovacion().paso !== "5") && (
          <ColorButton
            sx={{
              width: "fit-content",
              padding: "15px 40px",
              fontSize: 16,
              marginTop: 2,
              backgroundColor: colors.disabled,
              color: colors.white,
              borderColor: colors.disabled,
              lineHeight: 1,
            }}
            onClick={handleGoBack}
          >
            {t("common.goBack")}
          </ColorButton>
        )}
      </Box>
    </Box>
  );
}
