import * as Yup from "yup";
import i18n from "../../constants/translations/i18n";

export const yupWorkVideoValidations = Yup.object().shape({
  type: Yup.string().required(() => i18n.t("yup.select")),
  description: Yup.string()
    .when(["type"], ([type], schema) => {
      // Si getUser.hasVideoCall.c155_rowid_estado + "" === "4"
      return type
        ? schema.required(i18n.t("yup.required"))
        : schema.notRequired();
    })
    .min(3, () => i18n.t("yup.minLength", { count: 3 }))
    .matches(
      /^(?! )[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/,
      () => i18n.t("yup.onlyLetters")
    )
    .test(
      "no-prueba-admin",
      () => i18n.t("yup.noPruebaAdmin"),
      (value) => {
        return (
          !value ||
          !value
            .split(" ")
            .some(
              (word) =>
                word.toLowerCase() === "prueba" ||
                word.toLowerCase() === "admin"
            )
        );
      }
    )
    .required(() => i18n.t("yup.required")),
  video: Yup.string().required(() => i18n.t("yup.video")),
});
