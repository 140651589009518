import React from "react";
import { Modal, MySwal } from "../../../../common/alerts.tsx";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import { ChooseCredit } from "./ChooseCredit.tsx";
import { navigationModals } from "../../../../constants/types.tsx";
import { wipeValueStorage } from "../../../../common/storage.ts";

export const Renovation = ({
  onClose,
  setIsLoading,
}: navigationModals) => {
  const { t } = useTranslation();
  
  React.useEffect(() => {
    // Borro los localstorage
    wipeValueStorage("renovationSimulation");
  }, [])
  

  const handleContinue = async () => {
    MySwal.close();
    Modal(<ChooseCredit onClose={onClose} setIsLoading={setIsLoading} />, {
      title: "",
      width: "auto",
      showCloseButton: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        popup: "bg-snow dark:bg-navy-800 px-0 !w-max sm:!w-[380px]",
        htmlContainer: "!px-3 !overflow-x-hidden",
      },
    });
  };

  return (
    <div className="pt-8  pb-2 overflow-x-hidden">
      <p className="mb-0 text-xl sm:text-2xl text-secondary-800 font-medium dark:text-white">
        {t("renovation.theRenew")}
      </p>
      <p className="-mt-1 mb-4 text-xl sm:text-2xl text-primary font-semibold	">
        {t("renovation.hasEnabled")}
      </p>

      <div className=" w-[100%] h-[320px]">
        <Lottie
          style={{ width: "100%", height: "100%" }}
          loop
          autoPlay
          animationData={require("../../assets/animations/bolsaDinero.json")}
        />
      </div>

      <button
        className="w-64 rounded-lg bg-primary py-3 px-2 text-white font-bold text-base hover:opacity-80 "
        onClick={handleContinue}
      >
        {t("simulator.request")}
      </button>
    </div>
  );
};
