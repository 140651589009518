import React from "react";
import { ColorButton } from "../../../components/Button.tsx";
import {
  Box,
  ClickAwayListener,
  FormHelperText,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import { useTranslation } from "react-i18next";
import InputField from "../../../components/InputField.tsx";
import { useFormik } from "formik";
import { yupWorkVideoValidations } from "../../../common/validations/yupWorkVideoValidations.ts";
import { ModalVideo } from "../../../components/Modals.tsx";
import {
  confirmationAlert,
  errorAlert,
  Modal,
  ModalDashboard,
  MySwal,
} from "../../../common/alerts.tsx";
import { VideoScreen } from "../../../components/VideoScreen.tsx";
import {
  blobToFile,
  detectDevice,
  JSONToForm,
  requestSquematic,
  useUpdatePasoRenovacion,
} from "../../../common/CommonFunctions.tsx";
import FadeAnim from "../../../components/animations/FadeAnim.tsx";
import { getValueStorage, saveValueStorage } from "../../../common/storage.ts";
import InfoIcon from "@mui/icons-material/Info";
import { IRegisterStep } from "../RegisterComponent.tsx";
import renovationStep from "../../Dashboard/services/RenovationStep.tsx";

interface IWorkVideoProps {
  setIsLoading: (isLoading: boolean) => void;
  setRegisterStep?: React.Dispatch<React.SetStateAction<IRegisterStep>>;
  videoUrlParam?: { select: string; videoUrl: string; text: string } | ""; // Parametro para recuperar datos en los modales
}

export default function WorkVideo({
  setRegisterStep,
  videoUrlParam,
  setIsLoading,
}: IWorkVideoProps) {
  const { getUser } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const { updatePasoRenovacion, parseRenovacion } = useUpdatePasoRenovacion();
  const [recordVideo, setRecortdVideo] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState(
    videoUrlParam ? videoUrlParam?.videoUrl : ""
  );
  const [open, setOpen] = React.useState(false);

  const getUserTemp = getValueStorage("authSession");

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const formik = useFormik({
    initialValues: {
      type: videoUrlParam ? videoUrlParam?.select : "",
      description: videoUrlParam ? videoUrlParam?.text : "",
      video: "",
    },
    validationSchema: yupWorkVideoValidations,
    onSubmit: async (values) => {
      setIsLoading(true);
      let idVideoCall = "";
      let idLoan =
        getUserTemp.inProcessRenovation?.inProcessRenovation ||
        getUserTemp.isOld
          ? getUserTemp.loanRequest!.c200_rowid
            ? getUserTemp.loanRequest!.c200_rowid
            : ""
          : getUserTemp.hasVideoCall.c155_rowid_prestamo;

      if (getUserTemp?.hasVideoCallThird?.c155_rowid) {
        idVideoCall = getUserTemp.hasVideoCallThird.c155_rowid;
      } else {
        idVideoCall = getUserTemp.hasVideoCall.c155_rowid;
      }

      const file = await blobToFile(values.video, `${idLoan}_negocio`);

      const objToSend = {
        c155_rowid: idVideoCall,
        c200_rowid: idLoan,
        c110_rowid: getUserTemp.revalidateLaborVideo.c110_rowid ?? "",
        c126_videocompany_url: file ?? "",
        c126_videocompany_desc: values.description,
        c155_rowid_estado: "6",
        c126_videocompany_lat:
          getUserTemp.getLocation?.latitude!.toString() ?? "",
        c126_videocompany_lon:
          getUserTemp.getLocation?.longitude!.toString() ?? "",
      };

      const formData = JSONToForm(objToSend);

      try {
        const res = await requestSquematic(
          "POST",
          "/api/app/contact_details/save_laboral_documents",
          formData,
          getUserTemp.token
        );

        if (res) {
          // Si esta en proceso de renovacion o si es un usuario antiguo, y no tiene solicitud de video de laboral entonces cambia los pasos
          if (
            !getUserTemp.revalidateLaborVideo?.c110_json &&
            (getUserTemp.inProcessRenovation?.inProcessRenovation ||
              getUserTemp.isOld)
          ) {
            await updatePasoRenovacion("0", setIsLoading, "0", "0");
            if (setRegisterStep) {
              let obj = {
                prevStep: 28,
                nextStep: 24,
                desc: "Desde Workvideo a Waiting",
              };
              saveValueStorage("registerStep", obj);
              setRegisterStep(obj);
            } else {
              let dinamicElement = renovationStep({
                setIsLoading: setIsLoading,
                parseRenovacion: parseRenovacion,
              });
              if (dinamicElement) {
                ModalDashboard({
                  element: dinamicElement,
                });
              }
            }
          } else {
            saveValueStorage("authSession", {
              ...getUserTemp,
              revalidateLaborVideo: {
                c110_fecha_actualizacion: "",
                c110_fecha_creacion: "",
                c110_ind_estado: "",
                c110_json: "",
                c110_notas: "",
                c110_rowid: "",
                c110_rowid_usuario: "",
                c110_usuario_creacion: "",
              },
              hasVideoCall: {
                ...getUserTemp.hasVideoCall,
                c155_rowid_estado: "6",
              },
              getLocation: {
                latitude: null,
                longitude: null,
              },
            });

            if (getUserTemp.hasValidations) {
              // Ir a revalidacion de datos
              if (setRegisterStep) {
                let obj = {
                  prevStep: 28,
                  nextStep: 29,
                  desc: "El usuario ha subido los documentos y se solicita validacion de datos",
                };
                saveValueStorage("registerStep", obj);
                setRegisterStep(obj);
              }
            } else {
              await updatePasoRenovacion("0", setIsLoading, "0", "0");
              //Ir a espera
              if (setRegisterStep) {
                let obj = {
                  prevStep: 28,
                  nextStep: 24,
                  desc: "El usuario ha subido los documentos",
                };
                saveValueStorage("registerStep", obj);
                setRegisterStep(obj);
              } else {
                let dinamicElement = renovationStep({
                  setIsLoading: setIsLoading,
                  parseRenovacion: parseRenovacion,
                });
                if (dinamicElement) {
                  ModalDashboard({
                    element: dinamicElement,
                  });
                }
              }
            }
          }
        }
      } catch (error) {
        errorAlert("Error", "Ocurrió un error al enviar los datos.", "");
      } finally {
        setIsLoading(false);
      }
    },
  });

  const options = [
    { label: "Local", value: "0" },
    { label: "Herramientas de Trabajo", value: "1" },
    { label: "Puesto", value: "2" },
  ];

  const videos: { [key: string]: string } = {
    "0": "https://www.youtube.com/embed/7qmNIp_XCrQ",
    "1": "https://www.youtube.com/embed/S1lrXHEpyig",
    "2": "https://www.youtube.com/embed/nNzhnGqZyAE",
  };

  const wathVideo = () => {
    Modal(
      <ModalVideo
        url={videos[formik.values.type.toString()]}
        onClose={() => MySwal.close()}
      />,
      {
        title: "",
        showCloseButton: true,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        width: 800,
      }
    );
  };

  // Si se cargo el video, se actualiza el campo del formulario
  React.useEffect(() => {
    formik.setFieldValue("video", videoUrl);
  }, [videoUrl]);

  // Lanzar advertencia para repetir el video
  const restoreVideo = async (
    select: string,
    videoUrl: string,
    text: string
  ) => {
    const res = await confirmationAlert(
      t("workVideo.alertTitle"),
      t("workVideo.alertMessage"),
      t("workVideo.alertRepeat")
    );
    if (res) {
      // Repetir
      if (setRegisterStep) {
        setVideoUrl("");
        setRecortdVideo(true);
      } else {
        ModalDashboard({
          element: (
            <WorkVideo
              setRegisterStep={setRegisterStep}
              setIsLoading={setIsLoading}
              videoUrlParam=""
            />
          ),
        });
      }
    } else {
      // Cancelar
      if (!setRegisterStep) {
        ModalDashboard({
          element: (
            <WorkVideo
              setRegisterStep={setRegisterStep}
              setIsLoading={setIsLoading}
              videoUrlParam={{ select: select, videoUrl: videoUrl, text: text }}
            />
          ),
        });
      }
    }
  };

  return (
    <>
      {recordVideo ? (
        <VideoScreen
          setIsLoading={setIsLoading}
          setVideoUrl={setVideoUrl}
          onClose={setRecortdVideo}
        />
      ) : (
        <>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Box
              sx={{
                width: 220,
                marginBottom: 2,
              }}
            >
              <Zoom in={true}>
                <img
                  src={require("../../../assets/image/Negocio.webp")}
                  alt={""}
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Zoom>
            </Box>
          </Box>
          {/* Cuentanos de tu actividad */}
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"left"}
            marginTop={2}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 17,
                color: colors.primary,
                textTransform: "uppercase",
                marginBottom: "20px",
                textAlign: "center",
              }}
              lineHeight={1}
            >
              {t("workVideo.activity")}
            </Typography>
            <Typography
              className="dark:text-white"
              sx={{
                fontSize: 16,
                color: colors.black,
                marginBottom: "20px",
                lineHeight: 1.3,
              }}
            >
              {t("workVideo.subActivity")}
              <strong>{t("workVideo.afterActivity")}</strong>
              {t("workVideo.endActivity")}
              <strong>{t("workVideo.finalActivity")}</strong>
              {t("workVideo.ultimateActivity")}
            </Typography>
          </Box>
          {/* Atencion */}
          {detectDevice() === "Desktop" && (
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"left"}
              marginTop={2}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: 17,
                  color: colors.red,
                  textTransform: "uppercase",
                  marginBottom: "20px",
                }}
                lineHeight={1}
              >
                ¡{t("videoCall.attention")}!
              </Typography>
              <Typography
                className="dark:text-white"
                sx={{
                  fontSize: 16,
                  color: colors.black,
                  marginBottom: "20px",
                  lineHeight: 1.3,
                }}
              >
                {t("workVideo.attention")}
                <strong> {t("videoCall.attentionPointP2")}</strong>
                {t("workVideo.attentionPointP3")}
              </Typography>
            </Box>
          )}

          {detectDevice() !== "Desktop" && (
            <>
              {/* ¿Qué tipo de negocio tienes? */}
              <Box mt={2}>
                <InputField
                  name="type"
                  formik={formik}
                  label={t("workVideo.haveBusiness")}
                  type="select"
                  options={options}
                  disabled={getUser.isLoading}
                />
              </Box>
              {formik.values.type !== "" && (
                <>
                  <Box sx={{ margin: "0 auto" }}>
                    <ColorButton
                      sx={{
                        width: "fit-content",
                        padding: "8px 20px",
                        fontSize: 13,
                        mt: 3.5,
                        borderRadius: "13px !important",
                      }}
                      onClick={wathVideo}
                      disabled={getUser.isLoading}
                    >
                      {t("workVideo.textGuide")}
                    </ColorButton>
                  </Box>
                  {videoUrl ? (
                    <Box my={6}>
                      <video controls src={videoUrl} disablePictureInPicture />
                      <FadeAnim>
                        <button
                          disabled={getUser.isLoading}
                          style={{ width: "100%" }}
                          onClick={() =>
                            restoreVideo(
                              formik.values.type,
                              videoUrl,
                              formik.values.description
                            )
                          }
                        >
                          <Typography
                            sx={{
                              color: colors.slateGray,
                              textAlign: "center",
                            }}
                            lineHeight={1.2}
                            mt={2}
                            mx={{ xs: "20%", sm: 12, xl: 15 }}
                          >
                            {t("uploadFile.pressHere")}
                            <strong>{t("uploadFile.subPressHere")}</strong>
                            {t("workVideo.retake")}
                          </Typography>
                        </button>
                      </FadeAnim>
                    </Box>
                  ) : (
                    <Box my={8}>
                      <button
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          width: "100%",
                          cursor: "pointer",
                        }}
                        onClick={() => setRecortdVideo(true)}
                        disabled={getUser.isLoading}
                      >
                        <img
                          style={{
                            width: 100,
                            height: 100,
                            objectFit: "contain",
                          }}
                          src={require("../../../assets/image/Video.webp")}
                          alt=""
                        />
                        <Typography
                          className="dark:text-white"
                          sx={{
                            color: colors.slateGray,
                            mt: -1,
                            textAlign: "center",
                            fontSize: 12,
                          }}
                          lineHeight={1.2}
                        >
                          {t("workVideo.maxVideo")}
                        </Typography>

                        <Typography
                          className="dark:text-white"
                          sx={{ color: colors.slateGray, textAlign: "center" }}
                          marginTop={1}
                          lineHeight={1.2}
                          mx={{ xs: "20%", sm: 12, xl: 15 }}
                        >
                          {t("uploadFile.pressHere")}
                          <strong>{t("uploadFile.subPressHere")}</strong>
                          {t("workVideo.record")}
                        </Typography>
                        {formik.touched.video && formik.errors.video && (
                          <FormHelperText
                            style={{
                              color: colors.red,
                              fontSize: 11,
                              width: "100%",
                              textAlign: "center",
                              lineHeight: 1.1,
                              marginTop: 10,
                              whiteSpace: "wrap",
                            }}
                          >
                            * {formik.errors.video}
                          </FormHelperText>
                        )}
                      </button>
                    </Box>
                  )}
                  {/* Tooltip de placeholder */}
                  <Box position={"relative"} textAlign={"left"}>
                    <InputField
                      name="description"
                      label={t("workVideo.business")}
                      formik={formik}
                      maxLength={150}
                      placeholder={t("workVideo.placeholderBusiness")}
                      disabled={getUser.isLoading}
                      type="textarea"
                    />
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <div>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={handleTooltipClose}
                          open={open}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title={t("workVideo.placeholderBusiness")}
                        >
                          <button
                            onClick={handleTooltipOpen}
                            style={{
                              position: "absolute",
                              right: 3,
                              top: 24,
                              minWidth: "fit-content",
                            }}
                            disabled={getUser.isLoading}
                          >
                            <InfoIcon fontSize="small" color="success" />
                          </button>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                  </Box>
                </>
              )}

              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                marginTop={8}
              >
                <ColorButton
                  sx={{
                    width: "fit-content",
                    padding: "15px 40px",
                    fontSize: 16,
                  }}
                  onClick={() => {
                    // Marca el campo "video" como tocado y ejecuta handleSubmit
                    formik.setFieldTouched("video", true, true);
                    formik.handleSubmit();
                  }}
                  disabled={getUser.isLoading}
                >
                  {t("common.continue")}
                </ColorButton>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
}
