import React from "react";
import { Box, Typography, Zoom } from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import {
  convertTo12HourFormat,
  formatDateTime,
  useUpdatePasoRenovacion,
} from "../../../common/CommonFunctions.tsx";
import { useTranslation } from "react-i18next";
import { ColorButton } from "../../../components/Button.tsx";
import { getValueStorage, saveValueStorage } from "../../../common/storage.ts";
import {
  ISimulatorData,
  IUserAuth,
  navigationModals,
} from "../../../constants/types.tsx";
import { IRegisterStep, IRegisterStepState } from "../RegisterComponent.tsx";
import renovationStep from "../../Dashboard/services/RenovationStep.tsx";
import { ModalDashboard } from "../../../common/alerts.tsx";
import { handleCancelVc } from "./NoResponse.tsx";

interface IReSchedule {
  getUser: IUserAuth;
  prevStep: number;
  nextStep: number;
  desc: string;
  setRegisterStep?: React.Dispatch<React.SetStateAction<IRegisterStep>>;
  setIsLoading?: (isLoading: boolean) => void;
  parseRenovacion?: () => any;
  updatePasoRenovacion?: (
    paso: string,
    setIsLoading?: ((loading: boolean) => void) | null,
    tipoVideollamada?: string,
    estadoLaboral?: string,
    esIndependiente?: boolean,
    cambioEmpresa?: boolean
  ) => Promise<void>;
}

//Solo navegar al calendario
export const handleReSchedule = async ({
  getUser,
  prevStep,
  nextStep,
  desc,
  setRegisterStep,
  setIsLoading,
  parseRenovacion,
  updatePasoRenovacion,
}: IReSchedule) => {
  const simulationData: ISimulatorData = getValueStorage("simulationData");

  let vcInfoLoan = "";
  if (getUser.hasVideoCall) {
    vcInfoLoan = getUser.hasVideoCall.c155_rowid_prestamo;
  }

  saveValueStorage("simulationData", {
    c200_rowid: simulationData ? simulationData.c200_rowid : vcInfoLoan,
    c200_valor: simulationData ? simulationData.c200_valor : "",
    c200_rowid_ciiu: simulationData ? simulationData.c200_rowid_ciiu : "",
    c200_plazo: simulationData ? simulationData.c200_plazo : "",
    c200_rowid_concepto: simulationData
      ? simulationData.c200_rowid_concepto
      : "",
    c200_rowid_periodo: simulationData ? simulationData.c200_rowid_periodo : "",
    date_first_due: simulationData ? simulationData.date_first_due : "",
    due_value: simulationData ? simulationData.due_value : "",
    total_due_to_pay: simulationData ? simulationData.total_due_to_pay : "",
  });
  saveValueStorage("registerStep", { prevStep, nextStep, desc });
  if (setRegisterStep) {
    setRegisterStep({ prevStep, nextStep, desc });
  } else {
    if (parseRenovacion && updatePasoRenovacion && setIsLoading) {
      await updatePasoRenovacion(
        "5",
        setIsLoading,
        parseRenovacion().tipoVideollamada
      );
      let dinamicElement = renovationStep({
        setIsLoading: setIsLoading,
        parseRenovacion: parseRenovacion,
      });
      if (dinamicElement) {
        ModalDashboard({
          element: dinamicElement,
        });
      }
    }
  }
};

type ConfirmVcProps = IRegisterStepState & navigationModals;

export default function CallScheduled({
  setIsLoading,
  setRegisterStep,
}: ConfirmVcProps) {
  const { t } = useTranslation();
  const { updatePasoRenovacion, parseRenovacion } = useUpdatePasoRenovacion();
  const registerStep: IRegisterStep = getValueStorage("registerStep");

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Box
          sx={{
            width: 220,
            marginBottom: 2,
          }}
        >
          <Zoom in={true}>
            <img
              src={require("../../../assets/image/Agenda.webp")}
              alt={""}
              loading="lazy"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Zoom>
        </Box>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 17,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("videoCall.confirmed")}
        </Typography>
        <Typography
          className="dark:text-white"
          sx={{
            fontWeight: "bold",
            fontSize: 18,
            color: colors.black,
            textAlign: "center",
          }}
          lineHeight={1}
        >
          {formatDateTime(
            getValueStorage("authSession").hasVideoCall
              ?.c155_fecha_agendamiento ?? "1996-08-14 00:00:00",
            t
          )}
          <br />
          {convertTo12HourFormat(
            getValueStorage("authSession").hasVideoCall
              ?.c155_fecha_agendamiento &&
              getValueStorage("authSession")
                .hasVideoCall?.c155_fecha_agendamiento.split(" ")[1]
                .split(".")[0]
          )}
        </Typography>
      </Box>

      <Box sx={{ mx: setRegisterStep ? 0 : 2 }}>
        {/* Atencion */}
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"left"}
          marginTop={{ xs: 10, md: "40px" }}
          textAlign={"left"}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: 17,
              color: colors.red,
              textTransform: "uppercase",
              marginBottom: "10px",
            }}
            lineHeight={1}
          >
            ¡{t("videoCall.attention")}!
          </Typography>
          <Typography
            className="dark:text-white"
            sx={{
              fontSize: 16,
              color: colors.black,
              lineHeight: 1.3,
            }}
          >
            · {t("videoCall.attentionPointP1")}
            <strong>{t("videoCall.attentionPointP2")}</strong>
            {t("videoCall.attentionPointP3")}
          </Typography>
        </Box>
        {/* Importante */}
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"left"}
          marginTop={5}
          textAlign={"left"}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: 17,
              color: colors.primary,
              textTransform: "uppercase",
              marginBottom: "10px",
            }}
            lineHeight={1}
          >
            {t("confirmLoan.important")}
          </Typography>
          <Typography
            className="dark:text-white"
            sx={{
              fontSize: 16,
              color: colors.black,
              marginBottom: "10px",
              lineHeight: 1.3,
            }}
          >
            · {t("videoCall.importantPoint1")}
            {getValueStorage("authSession").hasVideoCall?.c155_tipo === "1" ? (
              <>
                <strong>{t("videoCall.importantPoint1_2")}</strong>
                {t("videoCall.importantPoint1_3")}
              </>
            ) : (
              <>
                {" "}
                <strong>{t("videoCall.importantPoint1_2Business")}</strong>
                {t("videoCall.importantPoint1_3Business")}
              </>
            )}
          </Typography>
          <Typography
            className="dark:text-white"
            sx={{
              fontSize: 16,
              color: colors.black,
              marginBottom: "10px",
              lineHeight: 1.3,
            }}
          >
            · {t("videoCall.importantPoint2")}
            <strong>{t("videoCall.importantPoint2_2")}</strong>
            {t("videoCall.importantPoint2_3")}
          </Typography>
          <Typography
            className="dark:text-white"
            sx={{
              fontSize: 16,
              color: colors.black,
              lineHeight: 1.3,
            }}
          >
            · {t("videoCall.importantPoint3").split("-")[0]}
            <strong>{t("videoCall.importantPoint3").split("-")[1]}</strong>
          </Typography>
        </Box>
        {/* Puntos necesarios */}
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"left"}
          marginTop={5}
          textAlign={"left"}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: 17,
              color: colors.primary,
              textTransform: "uppercase",
              marginBottom: "10px",
            }}
            lineHeight={1}
          >
            {t("confirmVc.neccesaryPoints")}
          </Typography>
          {getValueStorage("authSession").hasVideoCall?.c155_tipo === "1" ? (
            <>
              <Typography
                className="dark:text-white"
                sx={{
                  fontSize: 16,
                  color: colors.black,
                  marginBottom: "10px",
                  lineHeight: 1.3,
                }}
              >
                · {t("confirmVc.point1")}
                <strong> {t("confirmVc.point1_2")}</strong>
                {t("confirmVc.point1_3")}
              </Typography>

              <Typography
                className="dark:text-white"
                sx={{
                  fontSize: 16,
                  color: colors.black,
                  lineHeight: 1.3,
                  marginBottom: "10px",
                }}
              >
                · {t("confirmVc.point2")}
                <strong>
                  {t("confirmVc.point2_2") + t("confirmVc.point2_2Home")}
                </strong>
                {t("confirmVc.point2_3")}
              </Typography>
            </>
          ) : (
            <>
              <Box sx={{ textAlign: "left" }}>
                <Typography
                  className="dark:text-white"
                  sx={{
                    fontSize: 16,
                    color: colors.black,
                    lineHeight: 1.3,
                    marginBottom: "20px",
                  }}
                >
                  {t("confirmVc.point2")}
                  <strong>{t("scheduleWork.subMoment")}</strong>
                  {t("scheduleWork.afterMoment")}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>

      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        marginTop={5}
      >
        <ColorButton
          sx={{
            width: "fit-content",
            padding: { xs: "15px 60px", sm: "15px 80px" },
            fontSize: 16,
          }}
          onClick={() =>
            handleReSchedule({
              getUser: getValueStorage("authSession"),
              prevStep: 16,
              nextStep: 14,
              desc: "El usuario quiere reagendar la vc",
              setRegisterStep,
              setIsLoading,
              parseRenovacion,
              updatePasoRenovacion,
            })
          }
        >
          {t("videoCall.reschedule")}
        </ColorButton>
        <ColorButton
          sx={{
            width: "fit-content",
            padding: "8px 20px",
            fontSize: 13,
            mt: 1,
            background: colors.blueGray,
            borderColor: colors.blueGray,
            borderRadius: "13px !important",
          }}
          onClick={() =>
            handleCancelVc({
              prevStep: registerStep && registerStep.nextStep,
              desc: "Cancelar solicitud desde callsheduled",
              setRegisterStep,
              setIsLoading,
            })
          }
        >
          {t("common.cancelRequest")}
        </ColorButton>
      </Box>
    </>
  );
}
