import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import { useTranslation } from "react-i18next";
import { ColorButton } from "../../../components/Button.tsx";
import { AuthContext } from "../../../hooks/useAuth.tsx";

export default function Rejected() {
  const { t } = useTranslation();

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        {/* Lo sentimos */}
        <Box mt={3} textAlign={"center"}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 28, sm: 31 },
              color: colors.primary,
              textTransform: "uppercase",
            }}
            lineHeight={1}
            textAlign={"center"}
          >
            {t("rejected.title")}
          </Typography>
          <Typography
            sx={{
              color: colors.dimGray,
              fontSize: 14.5,
            }}
            lineHeight={1}
          >
            {t("rejected.sub")}
          </Typography>
        </Box>
        <Box
          sx={{
            width: { xs: 300, sm: 250 },
            marginBottom: 1,
          }}
        >
          <img
            src={require("../../../assets/image/Rechazado.webp")}
            alt={""}
            loading="lazy"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Box>

        {/* Fue rechazada  */}
        <Box mt={3} textAlign={"center"}>
          <Typography
            sx={{
              color: colors.black,
              fontSize: 14.5,
            }}
            lineHeight={1}
          >
            {t("rejected.title2")}
          </Typography>
          <Typography
            my={0.5}
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 28, sm: 31 },
              color: colors.primary,
              textTransform: "uppercase",
            }}
            lineHeight={1}
            textAlign={"center"}
          >
            {t("rejected.sub2")}
          </Typography>
        </Box>
      </Box>

      {/* Pero no te preocupes */}
      <Box
        textAlign={"center"}
        mt={8}
        sx={{ marginX: { xs: 3, xl:  5 } }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 17,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
        >
          {t("rejected.worry")}
        </Typography>

        <Typography
          sx={{
            fontSize: 16,
            color: colors.black,
            lineHeight: 1.3,
            marginBottom: "20px",
            textAlign: "justify",
            mt: 1,
          }}
        >
          {t("rejected.worry2")}
          <strong>  {t("rejected.worry3")} </strong>
          {t("rejected.worry4")}
        </Typography>
      </Box>

 
    </>
  );
}
