import React from "react";
import { Box, Button, InputBase, Typography, Zoom } from "@mui/material";
import { colors, sizes, shadow } from "../../styles/theme.tsx";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid2";
import { IpqrsData } from "../../constants/types.tsx";
import { AuthContext } from "../../hooks/useAuth.tsx";
import { removeAccent, requestSquematic } from "../../common/CommonFunctions.tsx";
import { ColorButton } from "../../components/Button.tsx";
import { useTranslation } from "react-i18next";
import i18n from "../../constants/translations/i18n.js";

export default function FrequentsQuestions() {
  let counterQuestions = 4;
  const { getUser, setIsLoading } = React.useContext(AuthContext);
  const [questions, setQuestions] = React.useState<IpqrsData[]>([]);
  const [questionsFiltered, setQuestionsFiltered] = React.useState<IpqrsData[]>([]);
  const [search, setSearch] = React.useState<string>("");
  const [allVisible, setAllVisible] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const fetchData = async () => {
    setIsLoading(true);

    const res = await requestSquematic(
      "GET",
      "/api/website/pqrs/get_all",
      {},
      getUser.token
    );

    if (res && res.results) {
      setQuestions(res.results);
      setQuestionsFiltered(
        (res.results as IpqrsData[]).slice(0, counterQuestions)
      );
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  // Funcion para buscar en las preguntas y respuestas
  const searchQuestion = (value: string) => {
    setSearch(value);
    const valueToSearch = removeAccent(value).toLowerCase();

    if (value === "") {
      setQuestionsFiltered(questions.slice(0, counterQuestions));
      setAllVisible(false); // Restablecer el botón de "ver más"
    } else {
      if (i18n.language === "en") {
        const filtered = questions.filter(
          (p) =>
            removeAccent(p.pregunta_ingles).toLowerCase().includes(valueToSearch) ||
            removeAccent(p.respuesta_ingles).toLowerCase().includes(valueToSearch)
        );
        setQuestionsFiltered(filtered);
      } else {
        const filtered = questions.filter(
          (p) =>
            removeAccent(p.pregunta).toLowerCase().includes(valueToSearch) ||
            removeAccent(p.respuesta).toLowerCase().includes(valueToSearch)
        );
        setQuestionsFiltered(filtered);
      }
    }
  };

  // Funcion para mostrar mas preguntas
  const handleVerMas = () => {
    const nextVisibleCount = questionsFiltered.length + counterQuestions;
    setQuestionsFiltered(questions.slice(0, nextVisibleCount));

    if (nextVisibleCount >= questions.length) {
      setAllVisible(true);
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          flex: "1",
          flexGrow: 1,
          marginTop: `${sizes.header}px`,
          paddingTop: "60px",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            flexGrow: 1,
            width: { xs: "95%", md: 865, lg: 850, xl: 960 },
            margin: "0 auto",
            marginBottom: 10,
          }}
        >
          {/* Titulo */}
          <Box
            sx={{
              padding: { xs: "50px 50px 50px 35px", sm: "50px" },
              position: "relative",
              display: "flex",
              margin: "0 auto",
            }}
          >
            <div
              style={{
                width: 10,
                height: 120,
                background: colors.primary,
                position: "absolute",
                top: "50%",
                left: 0,
                borderRadius: 2,
                transform: "translateY(-50%)",
              }}
            />

            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: 28, sm: 32, md: 43 },
                  color: colors.primary,
                  textTransform: "uppercase",
                }}
                lineHeight={1}
              >
                {t("frequentsQuestions.title")}
              </Typography>

              <Typography
                sx={{
                  fontSize: { xs: 16, md: 18 },
                  color: colors.gray,
                  marginTop: "10px",
                  lineHeight: 1.3,
                }}
              >
                {t("frequentsQuestions.desc")}
              </Typography>
            </Box>
          </Box>

          {/* Buscador */}
          <Box mt={2}>
            <Grid
              container
              width={"100%"}
              spacing={{ xs: 2, sm: 1, md: "" }}
              maxHeight={{ xs: "110px", sm: "64px" }}
            >
              <Grid
                size={{ xs: 12, sm: search ? 10.5 : 12 }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                  transition: "all 0.3s ease-in-out",
                }}
              >
                <InputBase
                  sx={{
                    py: 2,
                    px: { xs: 2, sm: 5 },
                    flex: 1,
                    background: "white",
                    borderRadius: 3,
                    width: "100%",
                    boxShadow: shadow.black,
                  }}
                  value={search}
                  onChange={(e) => searchQuestion(e.target.value)}
                  placeholder={t("frequentsQuestions.placeholder")}
                />
                <Box
                  sx={{
                    width: 100,
                    height: 8,
                    background: colors.primary,
                    position: "absolute",
                    top: "0",
                    left: "50%",
                    borderRadius: 1,
                    transform: "translateX(-50%)",
                  }}
                />
              </Grid>
              {search && (
                <Zoom
                  key={"deleteSearchButton"}
                  style={{
                    transitionDelay: `300ms`, //Cuanto demora en iniciar la animacion
                  }}
                  in={true}
                  mountOnEnter
                  unmountOnExit
                >
                  <Grid
                    size={{ xs: 12, sm: search ? 1.5 : 0 }}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      type="button"
                      sx={{
                        p: { xs: 2, sm: 2 },
                        background: colors.primary,
                        borderRadius: 3,
                        color: colors.white,
                      }}
                      aria-label="delete"
                      onClick={() => {
                        setSearch("");
                        searchQuestion("");
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </Grid>
                </Zoom>
              )}
            </Grid>
          </Box>

          {/* Lista de preguntas */}
          <Box mt={5} width={"100%"} px={{ xs: 2, lg: 0 }}>
            {questionsFiltered.map((item: IpqrsData) => {
              if (i18n.language === "en") {
                return (
                  <Box sx={{ marginBottom: 4 }} key={item.id}>
                    <Typography sx={{ fontWeight: "bold", fontSize: "25px" }} lineHeight={1.1}>
                      {item.pregunta_ingles}
                    </Typography>
                    <Typography sx={{ fontSize: "20px" }} lineHeight={1.2} mt={1.5}>
                      {item.respuesta_ingles}
                    </Typography>
                  </Box>
                );
              } else {
                return (
                  <Box sx={{ marginBottom: 4 }} key={item.id}>
                    <Typography sx={{ fontWeight: "bold", fontSize: "25px" }} lineHeight={1.1}>
                      {item.pregunta}
                    </Typography>
                    <Typography sx={{ fontSize: "20px" }} lineHeight={1.2} mt={1.5}>
                      {item.respuesta}
                    </Typography>
                  </Box>
                );
              }
            })}
          </Box>

          {/* Boton ver más */}
          {!allVisible && search === "" && (
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              marginTop={{ xs: "40px", md: "50px" }}
            >
              <ColorButton
                onClick={handleVerMas}
                sx={{
                  width: "fit-content",
                  padding: "15px 40px",
                  fontSize: 16,
                }}
              >
                {t("frequentsQuestions.moreQuestions")}
              </ColorButton>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
