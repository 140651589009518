import React from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { colors, sizes, shadow } from "../styles/theme.tsx";
import { ColorButton } from "../components/Button.tsx";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Simulator from "../components/simulators/Simulator.tsx";

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const simulatorRef = React.useRef<HTMLDivElement>(null);
  const location = useLocation();

  const theme = useTheme();
  const matches_xs = useMediaQuery(theme.breakpoints.down("lg"));
  const matches_lg = useMediaQuery(theme.breakpoints.up("lg"));

  React.useEffect(() => {
    if (location.hash === "#simulator" && simulatorRef.current) {
      simulatorRef.current.scrollIntoView({ behavior: "smooth" });
      // Desplazamiento ancla segun el tamaño de la pantalla
      setTimeout(() => {
        if (matches_xs) {
          window.scrollBy({ top: -200, behavior: "smooth" }); //hasta lg
        } else if (matches_lg) {
          window.scrollBy({ top: -250, behavior: "smooth" }); //arriba de lg
        }
      }, 500);
    }
  }, [location]);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
        backgroundColor: colors.bone,
      }}
    >
      <Box
        sx={{
          flex: "1",
          flexGrow: 1,
          marginTop: `${sizes.header}px`,
        }}
      >
        {/* Banner */}
        <Grid
          container
          spacing={{ xs: 8, md: 10 }}
          sx={{
            display: "flex",
            padding: { xs: "50px 20px", sm: 10 },
            width: { xs: "95%", sm: 600, md: 900, lg: 1200, xl: 1536 },
            margin: "0 auto",
          }}
        >
          <Grid
            size={{ xs: 12, md: 7 }}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                marginBottom: { xs: "15px", md: "40px", xl: "40px" },
                fontSize: { xs: 28, sm: 30, md: 33, lg: 45, xl: 52 },
                fontWeight: "bold",
                color: colors.primary,
                lineHeight: "1.1",
                textTransform: "uppercase",
              }}
            >
              {t("home.title")}
            </Typography>
            <Box display={"flex"} flexDirection={"column"}>
              <Typography
                sx={{
                  fontSize: { xs: "16px", sm: 14, md: 15, lg: 20, xl: 22 },
                  fontWeight: "bold",
                  color: colors.gray,
                  lineHeight: "1",
                  textTransform: "uppercase",
                }}
              >
                {t("home.description")}
              </Typography>
              <Typography
                sx={{
                  marginBottom: 5,
                  fontSize: { xs: 28, sm: 31, md: 34, lg: 46, xl: 53 },
                  fontWeight: "bold",
                  color: colors.gray,
                  lineHeight: "1",
                  mt: 0.5,
                }}
              >
                $5.000.000
              </Typography>
            </Box>
            <Box>
              <ColorButton
                sx={{
                  width: "fit-content",
                  padding: { xs: "15px 25px", md: "15px 40px" },
                  fontSize: { xs: 15, lg: 16 },
                  borderRadius: "11px",
                }}
                onClick={() => navigate("/sign-up")}
              >
                {t("common.requestHere")}
              </ColorButton>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 5 }}>
            <Box
              sx={{
                margin: "0 auto",
                width: { xs: "75%", sm: 350, md: "100%" },
              }}
            >
              <img
                style={{ width: "100%", height: "100%" }}
                src={require("../assets/image/ManosBilletes.webp")}
                alt="Logo"
              />
            </Box>
          </Grid>
        </Grid>

        {/* Simulador */}
        <Box position={"relative"} my={30} sx={{ background: colors.primary }}>
          <Box
            sx={{
              margin: "0 auto",
              height: 250,
              width: "100%",
              position: "absolute",
              top: -247,
            }}
          >
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={require("../assets/image/OndaArriba.webp")}
              alt=""
            />
          </Box>
          <Grid
            container
            sx={{
              display: "flex",
              padding: { xs: "50px 20px", sm: 10 },
              width: { xs: "95%", sm: 600, md: 900, lg: 1200, xl: 1536 },
              margin: "0 auto",
              flexDirection: "column",
              mb: 10,
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: 28, md: 33, lg: 38 },
                color: colors.white,
                textTransform: "uppercase",
                marginBottom: "5px",
                marginTop: 1.5,
              }}
              lineHeight={1}
              textAlign={"center"}
            >
              {t("home.simulatorTitle").replaceAll("-", " ")}
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: 25, md: 30, lg: 35 },
                color: colors.white,
                textTransform: "uppercase",
                marginBottom: "5px",
                marginTop: 0.5,
              }}
              lineHeight={1}
              textAlign={"center"}
            >
              {t("common.simulateCredit")}
            </Typography>
            <Grid
              container
              mt={{ xs: 5, lg: 10 }}
              spacing={12}
              width={"100%"}
              sx={{ height: "auto", alignItems: "stretch" }}
              ref={simulatorRef}
              id="simulator"
            >
              {/* Simulador */}
              <Grid
                size={{ xs: 12, lg: 6, xl: 5.5 }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid
                  container
                  sx={{
                    background: colors.bone,
                    position: "relative",
                    borderRadius: "18px",
                    boxShadow: shadow.black,
                    width: { xs: "95%", md: "auto" },
                  }}
                >
                  <div
                    style={{
                      width: 10,
                      height: 136,
                      background: colors.darkGreen,
                      position: "absolute",
                      top: "20%",
                      left: -3,
                      borderRadius: 2,
                      transform: "translateY(-50%)",
                    }}
                  />
                  <Simulator isSimulationWebsite={true} />
                </Grid>
              </Grid>
              {/* Paso a paso */}
              <Grid
                size={{ xs: 12, lg: 6, xl: 6.5 }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: 25, md: 30, lg: 35 },
                    color: colors.white,
                    marginBottom: "5px",
                    marginTop: 0.5,
                  }}
                  lineHeight={1}
                  textAlign={"center"}
                >
                  {t("home.stepTitle")}
                </Typography>
                {/* Tarjetas */}
                <Grid
                  container
                  spacing={2}
                  mt={3}
                  sx={{ height: "auto", alignItems: "stretch" }}
                >
                  <Grid
                    size={12}
                    sx={{
                      background: colors.bone,
                      minHeight: 110,
                      borderRadius: "18px",
                      boxShadow: shadow.black,
                      padding: { xs: "10px 20px", md: "10px" },
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: 5,
                        height: 73,
                        background: colors.darkGreen,
                        position: "absolute",
                        top: "50%",
                        left: -3,
                        borderRadius: 2,
                        transform: "translateY(-50%)",
                      }}
                    />

                    <Grid
                      container
                      width={"100%"}
                      height={"100%"}
                      spacing={{ xs: 2, md: 0 }}
                    >
                      <Grid
                        size={{ xs: 2, md: 1 }}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: { xs: 19, md: 21 },
                            color: colors.primary,
                          }}
                          lineHeight={1}
                        >
                          1.
                        </Typography>
                      </Grid>
                      <Grid
                        size={{ xs: 10, md: 11 }}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Box sx={{ flex: "1" }}>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: { xs: 19, md: 21 },
                              color: colors.primary,
                            }}
                            lineHeight={1}
                          >
                            {t("home.stepTitle1")}
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: { xs: 16, md: 15 },
                              color: colors.gray,
                              marginTop: "6px",
                            }}
                            lineHeight={1}
                          >
                            {t("home.stepDescription1")}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    size={12}
                    sx={{
                      background: colors.bone,
                      minHeight: 110,
                      borderRadius: "18px",
                      boxShadow: shadow.black,
                      padding: { xs: "10px 20px", md: "10px" },
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: 5,
                        height: 73,
                        background: colors.darkGreen,
                        position: "absolute",
                        top: "50%",
                        left: -3,
                        borderRadius: 2,
                        transform: "translateY(-50%)",
                      }}
                    />

                    <Grid
                      container
                      width={"100%"}
                      height={"100%"}
                      spacing={{ xs: 2, sm: 3, md: 0 }}
                    >
                      <Grid
                        size={{ xs: 2, md: 1 }}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: { xs: 19, md: 21 },
                            color: colors.primary,
                          }}
                          lineHeight={1}
                        >
                          2.
                        </Typography>
                      </Grid>
                      <Grid
                        size={{ xs: 10, md: 11 }}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Box sx={{ flex: "1" }}>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: { xs: 19, md: 21 },
                              color: colors.primary,
                            }}
                            lineHeight={1}
                          >
                            {t("home.stepTitle2")}
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: { xs: 16, md: 15 },
                              color: colors.gray,
                              marginTop: "6px",
                            }}
                            lineHeight={1}
                          >
                            {t("home.stepDescription2")}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    size={12}
                    sx={{
                      background: colors.bone,
                      minHeight: 110,
                      borderRadius: "18px",
                      boxShadow: shadow.black,
                      padding: { xs: "10px 20px", md: "10px" },
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: 5,
                        height: 73,
                        background: colors.darkGreen,
                        position: "absolute",
                        top: "50%",
                        left: -3,
                        borderRadius: 2,
                        transform: "translateY(-50%)",
                      }}
                    />

                    <Grid
                      container
                      width={"100%"}
                      height={"100%"}
                      spacing={{ xs: 2, sm: 3, md: 0 }}
                    >
                      <Grid
                        size={{ xs: 2, md: 1 }}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: { xs: 19, md: 21 },
                            color: colors.primary,
                          }}
                          lineHeight={1}
                        >
                          3.
                        </Typography>
                      </Grid>
                      <Grid
                        size={{ xs: 10, md: 11 }}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Box sx={{ flex: "1" }}>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: { xs: 19, md: 21 },
                              color: colors.primary,
                            }}
                            lineHeight={1}
                          >
                            {t("home.stepTitle3")}
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: { xs: 16, md: 15 },
                              color: colors.gray,
                              marginTop: "6px",
                            }}
                            lineHeight={1}
                          >
                            {t("home.stepDescription3")}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    size={12}
                    sx={{
                      background: colors.bone,
                      minHeight: 110,
                      borderRadius: "18px",
                      boxShadow: shadow.black,
                      padding: { xs: "10px 20px", md: "10px" },
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: 5,
                        height: 73,
                        background: colors.darkGreen,
                        position: "absolute",
                        top: "50%",
                        left: -3,
                        borderRadius: 2,
                        transform: "translateY(-50%)",
                      }}
                    />

                    <Grid
                      container
                      width={"100%"}
                      height={"100%"}
                      spacing={{ xs: 2, sm: 3, md: 0 }}
                    >
                      <Grid
                        size={{ xs: 2, md: 1 }}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            fontSize: { xs: 19, md: 21 },
                            color: colors.primary,
                          }}
                          lineHeight={1}
                        >
                          4.
                        </Typography>
                      </Grid>
                      <Grid
                        size={{ xs: 10, md: 11 }}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Box sx={{ flex: "1" }}>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: { xs: 19, md: 21 },
                              color: colors.primary,
                            }}
                            lineHeight={1}
                          >
                            {t("home.stepTitle4")}
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: { xs: 16, md: 15 },
                              color: colors.gray,
                              marginTop: "6px",
                            }}
                            lineHeight={1}
                          >
                            {t("home.stepDescription4")}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Box
            sx={{
              margin: "0 auto",
              height: 250,
              width: "100%",
              position: "absolute",
              bottom: -247,
            }}
          >
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={require("../assets/image/OndaAbajo.webp")}
              alt=""
            />
          </Box>
        </Box>
        {/* Mas beneficios */}
        <Grid
          container
          sx={{
            display: "flex",
            padding: { xs: "50px 20px", sm: 10 },
            width: { xs: "95%", sm: 600, md: 900, lg: 1200, xl: 1536 },
            margin: "0 auto",
            flexDirection: "column",
            mb: 10,
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 22, sm: 25, md: 31 },
              color: colors.primary,
              textTransform: "uppercase",
              textAlign: "center",
              marginBottom: 0.5,
            }}
            lineHeight={1}
          >
            {t("home.benefitsTitle")}
          </Typography>

          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 16, md: 20 },
              color: colors.gray,
              textAlign: "center",
            }}
            lineHeight={1}
          >
            {t("home.benefitsDescription")}
          </Typography>

          {/* Tarjetas */}
          <Grid
            container
            mt={{ xs: 5, lg: 10 }}
            spacing={1}
            width={"100%"}
            sx={{ height: "auto", alignItems: "stretch" }}
          >
            <Grid
              size={{ md: 6, lg: 4 }}
              sx={{
                minHeight: { xs: 200, md: 250 },
                borderRadius: "18px",
                padding: { xs: "20px", sm: "30px", md: "50px" },
                position: "relative",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  margin: { xs: "0 auto", md: "unset" },
                }}
              >
                <img
                  src={require("../assets/image/WhatsApp.webp")}
                  alt={""}
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Box sx={{ flex: "1" }}>
                <Typography
                  mt={"18px"}
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: 16, md: 20 },
                    color: colors.gray,
                  }}
                >
                  {t("home.cardTitle1")}
                </Typography>

                <Typography
                  sx={{
                    fontSize: { xs: 14, md: 16 },
                    color: colors.gray,
                    marginTop: "6px",
                  }}
                >
                  {t("home.cardDescription1")}
                </Typography>
              </Box>
            </Grid>
            <Grid
              size={{ md: 6, lg: 4 }}
              sx={{
                minHeight: { xs: 200, md: 250 },
                borderRadius: "18px",

                padding: { xs: "20px", sm: "30px", md: "50px" },
                position: "relative",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  margin: { xs: "0 auto", md: "unset" },
                }}
              >
                <img
                  src={require("../assets/image/Fiador.webp")}
                  alt={""}
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Box sx={{ flex: "1" }}>
                <Typography
                  mt={"18px"}
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: 16, md: 20 },
                    color: colors.gray,
                  }}
                >
                  {t("home.cardTitle2")}
                </Typography>

                <Typography
                  sx={{
                    fontSize: { xs: 14, md: 16 },
                    color: colors.gray,
                    marginTop: "6px",
                  }}
                >
                  {t("home.cardDescription2")}
                </Typography>
              </Box>
            </Grid>
            <Grid
              size={{ md: 6, lg: 4 }}
              sx={{
                minHeight: { xs: 200, md: 250 },
                borderRadius: "18px",

                padding: { xs: "20px", sm: "30px", md: "50px" },
                position: "relative",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  margin: { xs: "0 auto", md: "unset" },
                }}
              >
                <img
                  src={require("../assets/image/Desembolso.webp")}
                  alt={""}
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Box sx={{ flex: "1" }}>
                <Typography
                  mt={"18px"}
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: 16, md: 20 },
                    color: colors.gray,
                  }}
                >
                  {t("home.cardTitle3")}
                </Typography>

                <Typography
                  sx={{
                    fontSize: { xs: 14, md: 16 },
                    color: colors.gray,
                    marginTop: "6px",
                  }}
                >
                  {t("home.cardDescription3")}
                </Typography>
              </Box>
            </Grid>
            <Grid
              size={{ md: 6, lg: 4 }}
              sx={{
                minHeight: { xs: 200, md: 250 },
                borderRadius: "18px",

                padding: { xs: "20px", sm: "30px", md: "50px" },
                position: "relative",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  margin: { xs: "0 auto", md: "unset" },
                }}
              >
                <img
                  src={require("../assets/image/Cedula.webp")}
                  alt={""}
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Box sx={{ flex: "1" }}>
                <Typography
                  mt={"18px"}
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: 16, md: 20 },
                    color: colors.gray,
                  }}
                >
                  {t("home.cardTitle4")}
                </Typography>

                <Typography
                  sx={{
                    fontSize: { xs: 14, md: 16 },
                    color: colors.gray,
                    marginTop: "6px",
                  }}
                >
                  {t("home.cardDescription4")}
                </Typography>
              </Box>
            </Grid>
            <Grid
              size={{ md: 6, lg: 4 }}
              sx={{
                minHeight: { xs: 200, md: 250 },
                borderRadius: "18px",

                padding: { xs: "20px", sm: "30px", md: "50px" },
                position: "relative",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  margin: { xs: "0 auto", md: "unset" },
                }}
              >
                <img
                  src={require("../assets/image/Subida.webp")}
                  alt={""}
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Box sx={{ flex: "1" }}>
                <Typography
                  mt={"18px"}
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: 16, md: 20 },
                    color: colors.gray,
                  }}
                >
                  {t("home.cardTitle5")}
                </Typography>

                <Typography
                  sx={{
                    fontSize: { xs: 14, md: 16 },
                    color: colors.gray,
                    marginTop: "6px",
                  }}
                >
                  {t("home.cardDescription5")}
                </Typography>
              </Box>
            </Grid>
            <Grid
              size={{ md: 6, lg: 4 }}
              sx={{
                minHeight: { xs: 200, md: 250 },
                borderRadius: "18px",

                padding: { xs: "20px", sm: "30px", md: "50px" },
                position: "relative",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: 40,
                  height: 40,
                  margin: { xs: "0 auto", md: "unset" },
                }}
              >
                <img
                  src={require("../assets/image/Celular.webp")}
                  alt={""}
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Box sx={{ flex: "1" }}>
                <Typography
                  mt={"18px"}
                  sx={{
                    fontWeight: "bold",
                    fontSize: { xs: 16, md: 20 },
                    color: colors.gray,
                  }}
                >
                  {t("home.cardTitle6")}
                </Typography>

                <Typography
                  sx={{
                    fontSize: { xs: 14, md: 16 },
                    color: colors.gray,
                    marginTop: "6px",
                  }}
                >
                  {t("home.cardDescription6")}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Home;
