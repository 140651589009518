import React from "react";
import { VerifyLaboral } from "../pages/Renovations/VerifyLaboral.tsx";
import { IUserAuth, navigationModals } from "../../../constants/types";
import { VerifyAddress } from "../pages/Renovations/VerifyAddress.tsx";
import { getValueStorage, saveValueStorage } from "../../../common/storage.ts";
import RenovationVideoError from "../pages/Renovations/RenovationVideoError.tsx";
import { RenovationContactInformation } from "../pages/Renovations/RenovationContactInformation.tsx";
import { RenovationFinancialnformation } from "../pages/Renovations/RenovationFinancialnformation.tsx";
import { IRegisterStep } from "../../User/RegisterComponent.tsx";
import RenovationHousePanning from "../pages/Renovations/RenovationHousePanning.tsx";
import CalendarVc from "../../User/RegisterSubComponents/CalendarVc.tsx";
import {
  formatDateToDBString,
  getCurrentFormattedDate,
} from "../../../common/CommonFunctions.tsx";
import CallScheduled from "../../User/RegisterSubComponents/CallScheduled.tsx";
import NoResponse from "../../User/RegisterSubComponents/NoResponse.tsx";
import Waiting from "../../User/RegisterSubComponents/Waiting.tsx";
import WorkVideo from "../../User/RegisterSubComponents/WorkVideo.tsx";
import ScheduleWork from "../../User/RegisterSubComponents/ScheduleWork.tsx";
import RescheduleVC from "../../User/RegisterSubComponents/RescheduleVC.tsx";

// Funcion booleana para determinar si la (fecha de agendamiento + 10min) es menor a la fecha actual del dispositivo.
const timeDifference = () => {
  if (getValueStorage("authSession").hasVideoCall.c155_fecha_agendamiento) {
    const dateSheduled = new Date(
      getValueStorage(
        "authSession"
      ).hasVideoCall.c155_fecha_agendamiento.replaceAll(" ", "T")
    );

    // Sumamos 10 min
    dateSheduled.setMinutes(dateSheduled.getMinutes() + 10);

    // Navega a la pantalla NoResponde cuando es true, de lo contrario a Recordatorio
    const value =
      formatDateToDBString(dateSheduled) < getCurrentFormattedDate();
    return value;
  }
};

export default function renovationStep({
  onClose,
  setIsLoading,
  parseRenovacion,
  setRegisterStep,
}: navigationModals): React.JSX.Element | null {
  const getUser: IUserAuth = getValueStorage("authSession");
  let step: IRegisterStep = getValueStorage("registerStep");

  if (!getUser.inProcessRenovation?.inProcessRenovation) {
    //no credits con credito en solicitud
    if (parseRenovacion && parseRenovacion()) {
      renovationStepSwitch({
        parseRenovacion,
        onClose,
        setIsLoading,
        setRegisterStep,
      });
    }
  } else {
    if (
      getUser.inProcessRenovation?.contactDetails
        ?.c125_descripcion_de_direccion &&
      getUser.inProcessRenovation?.contactDetails?.c125_direccion1 &&
      getUser.inProcessRenovation?.contactDetails?.c125_lat_barrio &&
      getUser.inProcessRenovation?.contactDetails?.c125_lon_barrio
    ) {
      // Validar primero si existe paso de renovacion en el localstorage
      if (parseRenovacion && parseRenovacion()) {
        return renovationStepSwitch({
          parseRenovacion,
          onClose,
          setIsLoading,
          setRegisterStep,
        });
      } else {
        return (
          <VerifyAddress
            onClose={() => onClose()}
            setIsLoading={setIsLoading}
          />
        );
      }
    } else {
      return (
        <RenovationContactInformation
          onClose={() => onClose()}
          setIsLoading={setIsLoading}
        />
      );
    }
  }

  return null;
}

interface renovationStepSwitch {
  onClose: any;
  parseRenovacion: () => any;
  setIsLoading: (isLoading: boolean) => void;
  setRegisterStep?: React.Dispatch<React.SetStateAction<IRegisterStep>>;
}

const renovationStepSwitch = ({
  parseRenovacion,
  onClose,
  setIsLoading,
  setRegisterStep,
}: renovationStepSwitch): React.JSX.Element | null => {
  switch (parseRenovacion().paso) {
    case "0":
      if (
        ["1", "2"].includes(
          getValueStorage("authSession").hasVideoCall?.c155_rowid_estado + ""
        ) &&
        !timeDifference() &&
        !getValueStorage("authSession").hasVideoCall.c155_url &&
        getValueStorage("authSession").loanActive?.c200_rowid
      ) {
        return <CallScheduled setIsLoading={setIsLoading} />;
      } else if (
        (!timeDifference() && getValueStorage("authSession").hasVideoCall?.c155_rowid_estado + "" === "5") ||
        (timeDifference() && getValueStorage("authSession").hasVideoCall?.c155_rowid_estado + "" === "5") ||
        (["1", "2"].includes(
          getValueStorage(
            "authSession"
          )?.hasVideoCall.c155_rowid_estado?.toString()
        ) &&
        timeDifference())
      ) {
        return <NoResponse setIsLoading={setIsLoading} />;
      } else if (
        getValueStorage("authSession").hasVideoCall.c155_rowid_estado + "" ===
          "9" &&
        getValueStorage("authSession").hasVideoCall.calcel_reason?.c157_motivo
      ) {
        console.log("log 0.2 ");
        return <RescheduleVC setIsLoading={setIsLoading} />;
      } else if (
        getValueStorage("authSession").hasVideoCall.c155_tipo + "" === "2" &&
        getValueStorage("authSession").hasVideoCall.c155_rowid_estado + "" ===
          "9"
      ) {
        if (setRegisterStep) {
          let objectStep: IRegisterStep = {
            prevStep: "login",
            nextStep: 38,
            desc: "dentro de renovationstep case 0 a scheduleWork",
          };
          saveValueStorage("registerStep", objectStep);
          setRegisterStep(objectStep);
        } else {
          return (
            <ScheduleWork
              setRegisterStep={setRegisterStep}
              setIsLoading={setIsLoading}
            />
          );
        }
      } else {
        console.log("log waiting");
        if (setRegisterStep) {
          //Lo enviamos al paso 24 osea Waiting
          let objectStep: IRegisterStep = {
            prevStep: 30,
            nextStep: 24,
            desc: "dentro de renovationstep case 0 a Waiting",
          };
          saveValueStorage("registerStep", objectStep);
          setRegisterStep(objectStep);
        } else {
          return <Waiting setIsLoading={setIsLoading} />;
        }
      }
      break;
    case "1":
    case "2":
      console.log("log 1-2");
      if (
        getValueStorage("authSession").financialData.c126_rowid_ocupacion &&
        getValueStorage("authSession").financialData.c126_cargo &&
        getValueStorage("authSession").financialData.c126_empresa &&
        getValueStorage("authSession").financialData.c126_periodo_pago &&
        getValueStorage("authSession").financialData.c126_direccion_trabajo &&
        getValueStorage("authSession").financialData.c126_rowid_estadolaboral &&
        getValueStorage("authSession").financialData.c126_num_empresa
      ) {
        if (setRegisterStep) {
          let objectStep: IRegisterStep = {
            prevStep: 34,
            nextStep: 35,
            desc: "dentro de renovationstep case 1-2 if",
          };
          saveValueStorage("registerStep", objectStep);
          setRegisterStep(objectStep);
        } else {
          return (
            <VerifyLaboral
              onClose={() => onClose()}
              setIsLoading={setIsLoading}
            />
          );
        }
      } else {
        if (setRegisterStep) {
          let objectStep: IRegisterStep = {
            prevStep: -1,
            nextStep: 36,
            desc: "dentro de renovationstep case 1-2 else",
          };
          saveValueStorage("registerStep", objectStep);
          setRegisterStep(objectStep);
        } else {
          return (
            <RenovationFinancialnformation
              onClose={() => onClose()}
              setIsLoading={setIsLoading}
            />
          );
        }
      }
      break;
    case "N1":
      console.log("case N1");
      if (setRegisterStep) {
        let step: IRegisterStep = getValueStorage("registerStep");
        let objectStep: IRegisterStep = {
          prevStep: step.nextStep,
          nextStep: 34,
          desc: "dentro de renovationstep case N1",
        };
        saveValueStorage("registerStep", objectStep);
        setRegisterStep(objectStep);
      } else {
        return (
          <RenovationContactInformation
            onClose={() => onClose()}
            setIsLoading={setIsLoading}
          />
        );
      }
    case "3":
    case "4":
      // Si no tiene video de casa se envia a verificar los datos
      if (getValueStorage("authSession").loanRequest!.c200_rowid!.toString()) {
        return <RenovationHousePanning setIsLoading={setIsLoading} />;
      }
      console.log("case 3-4");
      break;
    case "N3":
      console.log("case N3");
      return (
        <RenovationFinancialnformation
          onClose={() => onClose()}
          setIsLoading={setIsLoading}
        />
      );
    case "5":
      console.log("case 5");
      return <CalendarVc setIsLoading={setIsLoading} />;
    case "6":
      console.log("N/A");
      break;
    case "7":
      console.log("case 7");
      if (setRegisterStep) {
        let step: IRegisterStep = getValueStorage("registerStep");
        let objectStep: IRegisterStep = {
          prevStep: step.nextStep,
          nextStep: 37,
          desc: "dentro de renovationstep case 7",
        };
        saveValueStorage("registerStep", objectStep);
        setRegisterStep(objectStep);
      } else {
        return (
          <RenovationVideoError
            onClose={() => onClose()}
            setIsLoading={setIsLoading}
          />
        );
      }
    case "8":
      console.log("case 8");
      return <WorkVideo setIsLoading={setIsLoading} />;
      break;
    default:
      console.log("log waiting default");
      if (setRegisterStep) {
        //Lo enviamos al paso 24 osea Waiting
        let objectStep: IRegisterStep = {
          prevStep: 30,
          nextStep: 24,
          desc: "dentro de renovationstep case default a Waiting",
        };
        saveValueStorage("registerStep", objectStep);
        setRegisterStep(objectStep);
      } else {
        return <Waiting setIsLoading={setIsLoading} />;
      }
  }
  return null;
};
