import React from "react";
import { useTranslation } from "react-i18next";
import { Box, TextField, Typography } from "@mui/material";
import moment from "moment";

export default function FormsInputDate({
  nameParam,
  setChanges,
  id,
  isLoading,
  setValids,
  condition
}) {
  const { t } = useTranslation();
  const [date, setDate] = React.useState<any>("");

  React.useEffect(() => {
    const resCondition = condition(date);
    setChanges((state: any) => ({
      ...state,
      [id]: date,
    }));
    setValids((state: any) => ({ ...state, [id]: resCondition }));
    if (date !== '') {
      let element = document.getElementById(`errorM_${id}`);
      if (element) {
        element.style.display = "none";
      }
    }
  }, [setDate, date]);

  return (
    <Box sx={{ marginTop: 1, display: "flex", flexDirection: "column" }}>
      <label
        style={{
          fontSize: 13,
          fontWeight: 600,
          textWrap: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
        htmlFor={id}
      >
        {t(nameParam)}
      </label >
      <TextField
        type="date"
        id={id}
        name={id}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          max: id === "8" ? new Date(moment().subtract(18, 'years').format('YYYY-MM-DD')).toISOString().split("T")[0] : new Date().toISOString().split("T")[0],
        }}
        onChange={({ target }) => setDate(target.value)}
      />
      <Typography id={`errorM_${id}`} sx={{ color: "red", fontSize: 13, marginTop: 1, fontWeight: "bold", display: "none" }}></Typography>
    </Box>
  );
};