import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { colors } from "../styles/theme.tsx";
import { useTranslation } from "react-i18next";
import { ColorButton } from "./Button.tsx";
import Grid from "@mui/material/Grid2";
import { envProps } from "../common/envProps.ts";

// Modal de mensaje de permisos negados
export const PermissionModal = ({ type }) => {
  return (
    <>
      <Box
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          fontWeight: "bold",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: 20,
            color: colors.primary,
            fontWeight: "bold",
            textTransform: "uppercase",
            lineHeight: 1,
          }}
        >
          Permisos de {type} requeridos
        </Typography>
        <Typography
          sx={{ fontSize: 14, marginTop: -0.5, lineHeight: 1, mt: 4 }}
        >
          Los permisos de {type} son necesarios para continuar con el proceso{" "}
          <del></del>
          crédito.
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            marginTop: -0.5,
            lineHeight: 1,
            mt: 5,
            fontWeight: "bold",
          }}
        >
          Por favor, ortorge los permisos y recargue la página.
        </Typography>
      </Box>
    </>
  );
};

// Modal de capptura de imagen videollamda
export const CaptureModal = ({ img, onClose, setIsLoading }) => {
  const { t } = useTranslation();

  React.useEffect(() => {
    // Actualiza el estado con la altura de la pantalla
    const updateHeight = () => {
      onClose(); // Llama a onClose cuando hay un cambio en la altura de la pantalla, para que se cierre el modal
    };

    // Escucha cambios de tamaño en la ventana
    window.addEventListener("resize", updateHeight);

    // Limpia el event listener al desmontar el componente
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  // "Enviar" la imagen capturada
  const sendPhoto = (e: any) => {
    // Cerrar el modal
    onClose();
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Box>
      <Box
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          fontWeight: "bold",
          position: "relative",
        }}
      >
        <img
          src={img}
          alt=""
          style={{
            width: "100%",
            maxHeight: 500,
            height: "auto",
            objectFit: "contain",
            transform: "scaleX(-1)",
          }}
        />
        <Grid
          container
          sx={{ width: "100%", justifyContent: "center", bottom: -20 }}
          position={"absolute"}
          spacing={2}
        >
          <Grid size={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box display={"flex"} sx={{ width: { xs: "90%", sm: 180 } }}>
              <ColorButton
                sx={{
                  width: "100%",
                  padding: "10px 20px",
                  fontSize: 13,
                  marginTop: 1,
                  backgroundColor: colors.primary,
                  color: colors.white,
                  textTransform: "uppercase",
                  borderRadius: `10px !important`,
                  lineHeight: 1.2,
                }}
                onClick={sendPhoto}
              >
                {t("activeVC.send")}
              </ColorButton>
            </Box>
          </Grid>
          <Grid size={6} sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Box display={"flex"} sx={{ width: { xs: "90%", sm: 180 } }}>
              <ColorButton
                sx={{
                  width: "100%",
                  padding: "10px 20px",
                  fontSize: 13,
                  marginTop: 1,
                  backgroundColor: colors.red,
                  color: colors.white,
                  textTransform: "uppercase",
                  borderRadius: `10px !important`,
                  lineHeight: 1.2,
                }}
                hoverColor={colors.red}
                hoverBackground={colors.bone}
                onClick={handleClose}
              >
                {t("activeVC.reject")}
              </ColorButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

// Modal preview de documentos
export const ModalPreview = ({ state, onClose }) => {
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    // Actualiza el estado con la altura de la pantalla
    const updateHeight = () => {
      onClose(); // Llama a onClose cuando hay un cambio en la altura de la pantalla, para que se cierre el modal
    };

    // Escucha cambios de tamaño en la ventana
    window.addEventListener("resize", updateHeight);

    // Limpia el event listener al desmontar el componente
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  return (
    <Box
      sx={{
        minHeight: 500,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        px:1
      }}
    >
      {isLoading && (
        <Box sx={{ position: "absolute" }}>
          <CircularProgress color="success" />
        </Box>
      )}
      <iframe
        src={state ? envProps.URL_AWS + "/" + state : ""} // deacuerdo al tipo de documento, se muestra el url del state
        width="100%"
        height="500px"
        style={{ border: "none" }}
        title={"Visualizar documento"}
        onLoad={() => setIsLoading(false)}
      />
    </Box>
  );
};

// Modal ver videos
export const ModalVideo = ({ url, onClose }) => {
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    // Actualiza el estado con la altura de la pantalla
    const updateHeight = () => {
      onClose(); // Llama a onClose cuando hay un cambio en la altura de la pantalla, para que se cierre el modal
    };

    // Escucha cambios de tamaño en la ventana
    window.addEventListener("resize", updateHeight);

    // Limpia el event listener al desmontar el componente
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  return (
    <Box
      sx={{
        minHeight: 500,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        px:1
      }}
    >
      {isLoading && (
        <Box sx={{ position: "absolute" }}>
          <CircularProgress color="success" />
        </Box>
      )}
      <iframe
        src={url ? url : ""}
        width="100%"
        height="500px"
        style={{ border: "none" }}
        title={"Visualizar video"}
        onLoad={() => setIsLoading(false)}
        allow="autoplay; encrypted-media"
        sandbox="allow-scripts allow-same-origin"
      />
    </Box>
  );
};
