import React from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormHelperText,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { colors, sizes, shadow } from "../../styles/theme.tsx";
import { useTranslation } from "react-i18next";
import { CssTextField } from "../../components/CssTextField.tsx";
import { ColorButton } from "../../components/Button.tsx";
import { useFormik } from "formik";
import { yupContactUs } from "../../common/validations/yupContactUs.ts";
import { useNavigate } from "react-router-dom";
import { requestSquematic } from "../../common/CommonFunctions.tsx";
import { successAlert } from "../../common/alerts.tsx";

export default function ContactUs() {
  const { t } = useTranslation();
  const [send, setSend] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      city: "",
      nit: "",
      phone: "",
      message: "",
      agreedTerms: false,
    },
    validationSchema: yupContactUs,
    onSubmit: () => handleSendEmail(),
  });

  //Enviar el coreo con la informacion del usuario
  const handleSendEmail = async () => {
    setIsLoading(true);

    const res = await requestSquematic(
      'POST',
      '/api/website/pqrs/send_email',
      formik.values
    );

    if (res && res.results) {
      setSend(true);
    }

    setIsLoading(false);
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
      }}
    >
      <Box
        sx={{
          flex: '1',
          flexGrow: 1,
          marginTop: `${sizes.header}px`,
          paddingTop: '60px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            width: { xs: '95%', lg: '60%', xl: '50%' },
            margin: '0 auto',
          }}
        >
          {/* Titulo */}
          <Box
            sx={{
              padding: { xs: "50px 50px 50px 35px", sm: "50px" },
              position: "relative",
              display: "flex",
              width: { md: "90%" },
              margin: "0 auto",
            }}
          >
            <div
              style={{
                width: 10,
                height: 120,
                background: colors.primary,
                position: "absolute",
                top: "50%",
                left: 0,
                borderRadius: 2,
                transform: "translateY(-50%)",
              }}
            />

            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: 28, sm: 32, md: 43 },
                  color: colors.primary,
                  textTransform: "uppercase",
                }}
                lineHeight={1}
              >
                {t("contactUs.title")}
              </Typography>

              <Typography
                sx={{
                  fontSize: { xs: 16, md: 18 },
                  color: colors.gray,
                  marginTop: "10px",
                  lineHeight: 1.3,
                }}
              >
                {t("contactUs.description")}
              </Typography>
            </Box>
          </Box>

          {/* Formulario */}

          <Box mt={5} width={"100%"}>
            <Box
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
              flexDirection={"column"}
              sx={{ flexGrow: 1 }}
            >
              <Box
                sx={{
                  background: colors.white,
                  height: "auto",
                  borderRadius: "18px",
                  boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
                  padding: {
                    xs: "40px 20px 100px 20px",
                    sm: "40px 70px 100px 70px",
                  },
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: { xs: 10, md: 20 },
                }}
              >
                <div
                  style={{
                    width: 136,
                    height: 10,
                    background: colors.primary,
                    position: "absolute",
                    left: "50%",
                    top: 0,
                    transform: "translatex(-50%)",
                  }}
                />

                {send ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-around",
                      height: "100%",
                    }}
                    className="zoom-in"
                  >
                    <Box>
                      <Box
                        sx={{
                          width: { xs: 180, lg: 235 },
                          height: { xs: 180, lg: 235 },
                          margin: "0 auto",
                        }}
                      >
                        <img
                          src={require("../../assets/image/check.webp")}
                          alt={""}
                          loading="lazy"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </Box>
                      <Typography style={{ textAlign: "center" }}>
                        <Typography
                          sx={{
                            color: "#1DA76C",
                            lineHeight: 0.9,
                            fontWeight: "bold",
                            letterSpacing: -1,
                            fontSize: {
                              xs: "1.5rem",
                              lg: "1.6rem",
                              xl: "2rem",
                            },
                          }}
                        >
                          ¡GRACIAS POR TU MENSAJE!
                        </Typography>
                        <Typography
                          style={{
                            textAlign: "center",
                            color: "#616161",
                            fontSize: 19,
                          }}
                          mt={0.5}
                        >
                          ¡Tu solicitud ha sido enviada!
                        </Typography>
                      </Typography>
                    </Box>

                    <Typography style={{ textAlign: "center" }} mt={10} mb={12}>
                      <Typography
                        style={{
                          textAlign: "center",
                          color: "#616161",
                          fontSize: 19,
                          lineHeight: 1.3,
                        }}
                        mx={8}
                        mt={0.5}
                      >
                        Pronto nuestros asesores se pondrán en contacto contigo
                      </Typography>
                    </Typography>
                    <ColorButton
                      variant="contained"
                      sx={{ fontSize: 20 }}
                      onClick={() => {
                        navigate("/");
                        setSend(false);
                      }}
                    >
                      Finalizar
                    </ColorButton>
                  </Box>
                ) : (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: 19, md: 21.8 },
                        color: colors.black,
                        textAlign: "center",
                        marginBottom: "37px",
                      }}
                    >
                      {t("formLabels.writeUs")}
                    </Typography>
                    <Grid container spacing={1} width={"100%"}>
                      <Grid size={{ xs: 12, md: 6 }}>
                        <CssTextField
                          label={t("formLabels.nameComplete")}
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          isError={
                            formik.touched.name && Boolean(formik.errors.name)
                          }
                          helperText={
                            formik.touched.name && formik.errors.name ? (
                              <FormHelperText
                                style={{
                                  color: colors.red,
                                  fontSize: 11,
                                  width: "100%",
                                  marginLeft: 0,
                                  marginRight: 0,
                                  lineHeight: 1,
                                  marginBottom: 5,
                                }}
                              >
                                * {formik.errors.name}
                              </FormHelperText>
                            ) : (
                              ""
                            )
                          }
                          disabled={isLoading}
                        />
                      </Grid>
                      <Grid size={{ xs: 12, md: 6 }}>
                        <CssTextField
                          label={t("formLabels.email")}
                          name="email"
                          type="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          isError={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email ? (
                              <FormHelperText
                                style={{
                                  color: colors.red,
                                  fontSize: 11,
                                  width: "100%",
                                  marginLeft: 0,
                                  marginRight: 0,
                                  lineHeight: 1,
                                  marginBottom: 5,
                                }}
                              >
                                * {formik.errors.email}
                              </FormHelperText>
                            ) : (
                              ""
                            )
                          }
                          disabled={isLoading}
                        />
                      </Grid>
                      <Grid size={{ xs: 12, md: 6 }}>
                        <CssTextField
                          label={t("formLabels.city")}
                          name="city"
                          value={formik.values.city}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          isError={
                            formik.touched.city && Boolean(formik.errors.city)
                          }
                          disabled={isLoading}
                          helperText={
                            formik.touched.city && formik.errors.city ? (
                              <FormHelperText
                                style={{
                                  color: colors.red,
                                  fontSize: 11,
                                  width: "100%",
                                  marginLeft: 0,
                                  marginRight: 0,
                                  lineHeight: 1,
                                  marginBottom: 5,
                                }}
                              >
                                * {formik.errors.city}
                              </FormHelperText>
                            ) : (
                              ""
                            )
                          }
                        />
                      </Grid>
                      <Grid size={{ xs: 12, md: 6 }}>
                        <CssTextField
                          label={t("formLabels.nit")}
                          name="nit"
                          onKeyPress={(e) => {
                            // Evitar que se ingresen caracteres que no son números
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          inputProps={{
                            maxLength: 10, // Máximo de caracteres
                            inputMode: "numeric", // Ayuda en dispositivos móviles
                          }}
                          value={formik.values.nit}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          isError={
                            formik.touched.nit && Boolean(formik.errors.nit)
                          }
                          disabled={isLoading}
                          helperText={
                            formik.touched.nit && formik.errors.nit ? (
                              <FormHelperText
                                style={{
                                  color: colors.red,
                                  fontSize: 11,
                                  width: "100%",
                                  marginLeft: 0,
                                  marginRight: 0,
                                  lineHeight: 1,
                                  marginBottom: 5,
                                }}
                              >
                                * {formik.errors.nit}
                              </FormHelperText>
                            ) : (
                              ""
                            )
                          }
                        />
                      </Grid>
                      <Grid size={12}>
                        <CssTextField
                          label={t("formLabels.contactNumber")}
                          name="phone"
                          onKeyPress={(e) => {
                            // Evitar que se ingresen caracteres que no son números
                            if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          inputProps={{
                            maxLength: 10, // Máximo de caracteres
                            inputMode: "numeric", // Ayuda en dispositivos móviles
                          }}
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          isError={
                            formik.touched.phone && Boolean(formik.errors.phone)
                          }
                          disabled={isLoading}
                          helperText={
                            formik.touched.phone && formik.errors.phone ? (
                              <FormHelperText
                                style={{
                                  color: colors.red,
                                  fontSize: 11,
                                  width: "100%",
                                  marginLeft: 0,
                                  marginRight: 0,
                                  lineHeight: 1,
                                  marginBottom: 5,
                                }}
                              >
                                * {formik.errors.phone}
                              </FormHelperText>
                            ) : (
                              ""
                            )
                          }
                        />
                      </Grid>
                      <Grid size={12}>
                        <CssTextField
                          id="outlined-multiline-static"
                          label={t("formLabels.messageText")}
                          name="message"
                          multiline
                          rows={4}
                          value={formik.values.message}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          isError={
                            formik.touched.message &&
                            Boolean(formik.errors.message)
                          }
                          inputProps={{
                            maxLength: 150,
                          }}
                          disabled={isLoading}
                          helperText={
                            formik.touched.message && formik.errors.message ? (
                              <FormHelperText
                                style={{
                                  color: colors.red,
                                  fontSize: 11,
                                  width: "100%",
                                  marginLeft: 0,
                                  marginRight: 0,
                                  lineHeight: 1,
                                  marginBottom: 5,
                                }}
                              >
                                * {formik.errors.message}
                              </FormHelperText>
                            ) : (
                              ""
                            )
                          }
                          sx={{
                            "& .MuiInputBase-input": {
                              resize: "none !important", // También asegúrate de incluir esto para los estilos internos
                            },
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: 12,
                            color: colors.disabled,
                            textAlign: "right"
                          }}
                        >
                          Cantidad de caracteres: {formik.values.message.length} / 150
                        </Typography>
                      </Grid>
                    </Grid>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      mt={2.5}
                      mb={
                        formik.touched.agreedTerms && formik.errors.agreedTerms
                          ? 0
                          : 4
                      }
                    >
                      <Checkbox
                        name="agreedTerms"
                        checked={formik.values.agreedTerms}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={isLoading}
                        sx={{
                          color:
                            formik.touched.agreedTerms &&
                              formik.errors.agreedTerms
                              ? colors.red
                              : "inherit",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: 13,
                          color: colors.black,
                        }}
                      >
                        {t("formLabels.agree").split("-")[0]}{" "}
                        <span style={{ color: colors.primary }}>
                          {t("formLabels.agree").split("-")[1]}
                        </span>
                      </Typography>
                    </Box>
                    {formik.touched.agreedTerms &&
                      formik.errors.agreedTerms && (
                        <FormHelperText
                          style={{
                            color: colors.red,
                            fontSize: 11,
                            width: "100%",
                            marginLeft: 0,
                            marginRight: 0,
                            lineHeight: 1,
                            marginBottom: 30,
                            textAlign: "center",
                          }}
                        >
                          * {formik.errors.agreedTerms}
                        </FormHelperText>
                      )}

                    <ColorButton
                      variant="contained"
                      sx={{ fontSize: 20 }}
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                    >
                      {isLoading ? (
                        <CircularProgress
                          style={{ width: 35, height: 35, padding: 5 }}
                          color="inherit"
                        />
                      ) : (
                        t("common.send")
                      )}
                    </ColorButton>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
