import React from "react";
import { useTranslation } from "react-i18next";
import { ChevronRightIcon } from "../../assets/iconsMui.tsx";

export const ChannelTypes = () => {
  const { t } = useTranslation();

  return (
    <>
      <p className="text-primary font-bold text-lg uppercase">Tipo de canal </p>
      <p className="leading-4 text-sm font-semibold text-secondary-800 dark:text-white mt-6 mb-5">
        Selecciona tu forma de pago
      </p>
      <div className="grid gap-3">
        <button className="flex grid grid-cols-12 bg-white dark:!bg-navy-600 w-[98%] mx-auto py-3 px-4 rounded-lg shadow-md dark:text-white dark:shadow-none ">
          <div className="h-full flex flex-col text-left col-span-3 justify-center items-center ">
            <img
              className="w-[50px] h-[50px] rounded-full"
              src={require("../../assets/images/pse_logo.png")}
              alt=""
            />
          </div>
          <div className="h-full col-span-7 flex flex-col items-start justify-center ml-3">
            <p className="text-sm font-semibold leading-4	dark:text-secondary-500">
              Via PSE
            </p>
          </div>
          <div className="h-full col-span-2 flex items-center justify-end">
            <div className={` w-3 h-3 rounded-full`} />

            <ChevronRightIcon />
          </div>
        </button>
        <button className="flex grid grid-cols-12 bg-white dark:!bg-navy-600 w-[98%] mx-auto py-3 px-4 rounded-lg shadow-md dark:text-white dark:shadow-none ">
          <div className="h-full flex flex-col text-left col-span-3 justify-center items-center ">
            <img
              className="w-[50px] h-[50px] rounded-full"
              src={require("../../assets/images/pse_logo.png")}
              alt=""
            />
          </div>
          <div className="h-full col-span-7 flex flex-col items-start justify-center ml-3">
            <p className="text-sm font-semibold leading-4	dark:text-secondary-500">
              Via PSE
            </p>
          </div>
          <div className="h-full col-span-2 flex items-center justify-end">
            <div className={` w-3 h-3 rounded-full`} />

            <ChevronRightIcon />
          </div>
        </button>
        <button className="flex grid grid-cols-12 bg-white dark:!bg-navy-600 w-[98%] mx-auto py-3 px-4 rounded-lg shadow-md dark:text-white dark:shadow-none ">
          <div className="h-full flex flex-col text-left col-span-3 justify-center items-center ">
            <img
              className="w-[50px] h-[50px] rounded-full"
              src={require("../../assets/images/pse_logo.png")}
              alt=""
            />
          </div>
          <div className="h-full col-span-7 flex flex-col items-start justify-center ml-3">
            <p className="text-sm font-semibold leading-4	dark:text-secondary-500">
              Via PSE
            </p>
          </div>
          <div className="h-full col-span-2 flex items-center justify-end">
            <div className={` w-3 h-3 rounded-full`} />

            <ChevronRightIcon />
          </div>
        </button>
      </div>
    </>
  );
};
