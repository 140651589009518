import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enJson from "./en.json";
import enDJson from "./enD.json";
import esJson from "./es.json";
import esDJson from "./esD.json";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      es: { translation: esJson },
      en: { translation: enJson },
    },
    lng: "es", // idioma por defecto
    fallbackLng: "en", // idioma alternativo en caso de que falte una traducción
    //debug: true, // Habilita el modo de depuración
    interpolation: {
      escapeValue: false, // React ya hace el escape de las variables
    },
  });


i18n.addResourceBundle('es', 'translation', esDJson, true);
i18n.addResourceBundle('en', 'translation', enDJson, true);
export default i18n;
