import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import VerificationInput from "react-verification-input";
import { useTranslation } from "react-i18next";
import { ColorButton } from "../../../components/Button.tsx";
import "../../../index.css";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import { requestSquematic } from "../../../common/CommonFunctions.tsx";
import { errorAlert } from "../../../common/alerts.tsx";
import { getValueStorage, saveValueStorage } from "../../../common/storage.ts";
import { IUserInfoLocalStorage } from "../../../constants/types.tsx";
import { handleSigInService } from "../LoginServices.tsx";
import { useNavigate } from "react-router-dom";
import { IRegisterStepState } from "../RegisterComponent.tsx";

export default function PasswordValidate({
  setRegisterStep,
}: IRegisterStepState) {
  const navigate = useNavigate();
  const { setIsLoading, signIn } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const [password, setPassword] = React.useState<string>("");
  const [validatePassword, setValidatePassword] = React.useState<string>("");
  const userInfo: IUserInfoLocalStorage = getValueStorage("userInfo");

  const createAccount = async () => {
    if (password !== validatePassword) {
      errorAlert("Error", t("verifyCode.passwordError"), "");
      return true;
    }
    setIsLoading(true);

    const data = await requestSquematic("POST", "/api/app/user/create", {
      c001_clave: password,
      c001_correo_electronico: userInfo.user_email,
      c001_numero_celular: userInfo.user_phone,
      c001_token_dispositivo: null,
      c001_cod_lenguaje: t("common.lenguage"),
      codigo_referido: "",
      c001_email_otp: userInfo.otpEmail,
      c001_sms_otp: userInfo.otpPhone,
    });

    if (data) {
      let obj = {
        prevStep: 2,
        nextStep: 3,
        desc: "el usuario registro la contraseña y se creo en la base de datos",
      };
      saveValueStorage("registerStep", obj);
      if (setRegisterStep) setRegisterStep(obj);
      saveValueStorage("userInfo", {
        ...userInfo,
        token: data.results!.token,
        password: validatePassword,
      });
      handleSigInService(
        { value: userInfo.user_email, isValid: true },
        validatePassword,
        setIsLoading,
        signIn,
        navigate
      );
    }

    setIsLoading(false);
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: colors.black,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("verifyCode.title")}
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("verifyCode.subPassword")}
        </Typography>
      </Box>
      {/* Input */}
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"left"}
        mt={5}
        sx={{ marginX: { sm: 4, xl: 10 } }}
      >
        <Box sx={{ width: 90, height: 90, margin: "0 auto", mb: 3 }}>
          <img
            src={require("../../../assets/image/Candado.webp")}
            alt={""}
            loading="lazy"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          sx={{ marginTop: 5 }}
        >
          <VerificationInput
            length={4}
            validChars="0-9"
            placeholder=""
            passwordMode
            classNames={{
              container: "container-characters ",
              character: "characters",
              characterInactive: "character--inactive",
              characterSelected: "character--selected",
              characterFilled: "filled",
            }}
            autoFocus={true}
            onComplete={(e) => setPassword(e)}
            inputProps={{
              autoComplete: "new-password",
            }}
          />
        </Box>
      </Box>
      {/* Vuelve a confirmar */}
      <Box
        my={8}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("verifyCode.again")}
        </Typography>
      </Box>
      {/* Destino del codigo */}
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <VerificationInput
          length={4}
          validChars="0-9"
          placeholder=""
          passwordMode
          classNames={{
            container: "container-characters",
            character: "characters",
            characterInactive: "character--inactive",
            characterSelected: "character--selected",
            characterFilled: "filled",
          }}
          onComplete={(e) => setValidatePassword(e)}
          inputProps={{
            autoComplete: "new-password",
          }}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        marginTop={8}
      >
        <ColorButton
          sx={{
            width: "fit-content",
            padding: "15px 40px",
            fontSize: 16,
          }}
          disabled={!password || !validatePassword}
          onClick={createAccount}
        >
          {t("common.send")}
        </ColorButton>
      </Box>
    </>
  );
}
