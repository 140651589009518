import React, { createContext, FC, useState } from "react";
import { getValueStorage, saveValueStorage, wipeStorage } from "../common/storage.ts";
import { IFunctions, IUserAuth } from "../constants/types.tsx";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext<IFunctions>({
  signIn: () => console.warn("no auth provider"),
  signOut: () => console.warn("no auth provider"),
  setIsLoading: () => console.warn("cambiar el estado de carga"),
  getUser: {
    ...getValueStorage("authSession"),
    isLoading: false,
  }
});

export const AuthProvider: FC<{ children: any }> = ({ children }) => {
  const [authState, setAuthState] = useState<any>({
    ...getValueStorage("authSession"),
    isLoading: false
  });

  const signIn = (userAuth: IUserAuth) => {
    setAuthState({
      ...userAuth,
      login: true,
      isLoading: false,
    });
    saveValueStorage("authSession", userAuth);
  };

  const signOut = () => {
    setAuthState({
      login: false,
      token: "",
      isLoading: false,
    });
    wipeStorage();
  };

  const setIsLoading = (isLoading: boolean) => {
    setAuthState(prevState => ({ ...prevState, isLoading }));
  };

  const contextValue: IFunctions = {
    signIn,
    signOut,
    setIsLoading,
    getUser: authState
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
