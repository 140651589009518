import { i18n } from "i18next";
import { errorAlert } from "../../../common/alerts.tsx";
import { loans, PropItemsLoanInit } from "../../../constants/types";
import { stringToDate } from "../../../common/CommonFunctions.tsx";




export const mappingLoanData_Init = (
  loansInProcess: loans[],
  pie_diagram: {
    monto: string;
    concepto: string;
  }[],
  t: (key: string, options?: Record<string, any>) => string
) => {
  let loanLocal: PropItemsLoanInit[] = [];
  let total = 0;

  pie_diagram.forEach(({ monto }) => (total += parseFloat(monto)));

  loansInProcess.forEach(
    ({
      c200_fecha_creacion,
      c200_rowid,
      c200_plazo,
      concepto,
      description,
      monto,
      c200_fecha_de_desembolso,
      additionalInfo,
      c200_renovacion,
      c200_ind_estado,
      c200_rowid_periodo,
      c200_rowid_concepto,
      c200_rowid_nuevo_prestamo,
      industrialClassification,
      banking_transaction,
      payment_plan_penalites,
      last_loan_forgiveness_active,
      parcial_payment,
    }) => {
      try {
        const labelCuota = parseInt(c200_plazo);

        let totalCredit = 0;
        let valPerDue = 0;
        let complementSumPerDue = 0;
        let totalComplementSum = 0;
        let totalDue = 0;

        additionalInfo.complements.forEach(
          ({ c210_vlr_cuota }) =>
            (complementSumPerDue += parseInt(c210_vlr_cuota))
        );

        const debt = additionalInfo.debt.length !== 0;
        const limitDate = debt
          ? additionalInfo.debt[
            additionalInfo.debt.length - 1
          ].c2_c202_fecha_cuota.split(" ")[0]
          : additionalInfo.nextPayment
            ? additionalInfo.nextPayment.due.c202_fecha_cuota.split(" ")[0]
            : additionalInfo.info[0].c202_fecha_cuota.split(" ")[0];

        if (additionalInfo.debt.length > 0) {
          additionalInfo.debt.forEach(
            ({ c2_total, c2_c210_valor_complementos }) => {
              totalDue += parseInt(c2_total);
              totalComplementSum += parseInt(c2_c210_valor_complementos);
            }
          );
          totalCredit = parseInt(additionalInfo.debt[0].c2_total_paz_salvo);
        }

        if (totalCredit === 0) {
          if (additionalInfo.info[0]) {
            additionalInfo.info.forEach(
              ({ c202_vlr_cuota, c202_ind_estado, complements }) => {
                if (c202_ind_estado === "0") {
                  totalCredit += parseInt(c202_vlr_cuota);

                  complements.forEach(
                    ({ c210_vlr_cuota }) =>
                      (totalCredit += parseInt(c210_vlr_cuota))
                  );
                }
              }
            );

            totalCredit +=
              additionalInfo.nextPayment.due.c202_ind_estado !== "0"
                ? parseInt(additionalInfo.nextPayment.valueToPay)
                : 0;
          } else {
            totalCredit = parseInt(additionalInfo.nextPayment.valueToPay);
          }
        }

        valPerDue = parseInt(additionalInfo.normalDue);

        totalDue =
          totalDue === 0
            ? additionalInfo.nextPayment
              ? parseInt(additionalInfo.nextPayment.valueToPay)
              : valPerDue
            : totalDue;

        totalComplementSum =
          totalComplementSum === 0 ? complementSumPerDue : totalComplementSum;

        const penalties = additionalInfo.debt.filter(
          ({ c2_c211_sanciones, c2_c211_cobranza }) => {
            return (
              parseInt(c2_c211_sanciones) > 0 || parseInt(c2_c211_cobranza) > 0
            );
          }
        );

        const dueWithoutCollectionsAndArrears =
          parseFloat(totalDue.toString()) -
          (penalties[0]
            ? parseFloat(
              penalties[0].c2_c211_sanciones !== null
                ? penalties[0].c2_c211_sanciones
                : "0"
            ) +
            parseFloat(
              penalties[0].c2_c211_cobranza !== null
                ? penalties[0].c2_c211_cobranza
                : "0"
            )
            : 0);

        let minimalDue =
          (penalties[0]
            ? parseFloat(
              penalties[0].c2_c211_sanciones !== null
                ? penalties[0].c2_c211_sanciones
                : "0"
            ) +
            parseFloat(
              penalties[0].c2_c211_cobranza !== null
                ? penalties[0].c2_c211_cobranza
                : "0"
            )
            : 0) +
          (dueWithoutCollectionsAndArrears -
            (additionalInfo.vigency &&
              dueWithoutCollectionsAndArrears > valPerDue
              ? valPerDue
              : 0));

        loanLocal.push({
          first_pay_date:
            additionalInfo.info[0]?.c202_nro_cuota === "1"
              ? stringToDate(additionalInfo.info[0].c202_fecha_cuota, {
                weekday: "short",
                day: "2-digit",
                month: "short",
                year: "numeric",
              })
              : undefined,
          payment_plan_penalites,
          banking_transaction,
          industrial_classification: industrialClassification,
          c200_rowid_concepto,
          c200_fecha_creacion,
          c200_plazo,
          c200_rowid_periodo,
          c200_renovacion,
          c200_ind_estado,
          c200_rowid_nuevo_prestamo,
          description,
          complemetsPerDue: complementSumPerDue.toString(),
          complementsValue: totalComplementSum.toString(),
          c200_rowid,
          monto: monto,
          name: concepto,
          payTime: `${description} - ${labelCuota} ${t("homeDashboard.due")}${labelCuota > 1 ? "s" : ""
            }.`,
          total: monto,
          date: c200_fecha_de_desembolso.split(" ")[0],
          totalCredit: totalCredit.toString(),
          totalDue: totalDue.toString(),
          fullDue: valPerDue.toString(),
          debt: debt,
          minDue: String(
            minimalDue < valPerDue
              ? minimalDue
              : minimalDue * (parseFloat(additionalInfo.minDue) / 100)
          ),
          vigency: additionalInfo.vigency,
          limitDate: limitDate,
          initialDate: c200_fecha_creacion.split(" ")[0],
          currentDues: `${additionalInfo.info[0]
              ? additionalInfo.info.length.toString()
              : additionalInfo.nextPayment
                ? 1
                : 0
            } ${t("home.init.dues")}`,
          originalNumber: parseInt(
            additionalInfo.info[0]
              ? additionalInfo.info[0].c202_vlr_cuota
              : additionalInfo.nextPayment.due.c202_vlr_cuota
          ),
          penalties_to_pay:
            parseFloat(
              additionalInfo?.info[0]?.plan_payment_penalty
                ? additionalInfo?.info[0]?.plan_payment_penalty
                  ?.c211_valor_sancion + ""
                : "0"
            ) +
            parseFloat(
              additionalInfo?.info[0]?.plan_payment_penalty
                ? additionalInfo?.info[0]?.plan_payment_penalty
                  ?.c211_gastos_cobranza + ""
                : "0"
            ) +
            "",
          plan_payment_penalty: penalties[0]
            ? {
              c211_dias_atraso: penalties[0].c2_dias_atraso,
              c211_valor_total: "0",
              c211_valor_complementos: "0",
              c211_valor_cuota: "0",
              c211_valor_sancion: penalties[0].c2_c211_sanciones,
              c211_gastos_cobranza: penalties[0].c2_c211_cobranza,
            }
            : null,
          condonado:
            last_loan_forgiveness_active?.c250_ind_tipo_proceso.toString() ===
              "0"
              ? {
                valor_pagar:
                  last_loan_forgiveness_active.valor_pendiente_pagar ?? "0",
                valor_no_condonado: isNaN(
                  parseInt(monto) -
                  parseInt(last_loan_forgiveness_active.valor_condonado)
                )
                  ? "0"
                  : (
                    parseInt(monto) -
                    parseInt(last_loan_forgiveness_active.valor_condonado)
                  ).toString(),
                valor_condonado:
                  last_loan_forgiveness_active.valor_condonado ?? "0",
                fecha_proximo_pago: limitDate ?? "",
                fecha_limite_pago:
                  last_loan_forgiveness_active.c250_fecha_hasta ?? "",
                diffDias: last_loan_forgiveness_active.plazo_restante ?? "0",
                indefinido:
                  last_loan_forgiveness_active.c250_ind_indefinido ?? "0",
              }
              : null,
          congelado:
            last_loan_forgiveness_active?.c250_ind_tipo_proceso.toString() ===
              "1"
              ? {
                valor_pendiente:
                  last_loan_forgiveness_active.valor_pendiente_pagar ?? "0",
                valor_pagar:
                  last_loan_forgiveness_active.valor_pendiente_pagar ?? "0",
                fecha_limite_pago:
                  last_loan_forgiveness_active.c250_fecha_hasta ?? "",
                diffDias: last_loan_forgiveness_active.plazo_restante ?? "0",
                fecha_proximo_pago: limitDate ?? "",
                indefinido:
                  last_loan_forgiveness_active.c250_ind_indefinido ?? "0",
              }
              : null,
          totalSaldoEnMenor: parcial_payment
            ? parcial_payment.c260_ind_estado.toString() !== "9"
              ? parcial_payment.c260_valor_acumulado
              : "0"
            : "0",
        });
      } catch (error) {
        console.log("error: ", error);
        errorAlert(
          "Error MLDI: ",
          "Algo ocurrió, la información mostrada puede no ser precisa",
          "",
        );
      }
    }
  );

  loanLocal = loanLocal.sort((a, b) => (a.date > b.date ? 1 : -1));

  return { loanLocal, total };
};