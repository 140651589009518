import React from "react";
import { Box, Typography } from "@mui/material";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../../../hooks/useAuth.tsx";
import { getValueStorage } from "../../../../common/storage.ts";
import { colors } from "../../../../styles/theme.tsx";
import { ColorButton } from "../../../../components/Button.tsx";
import { navigationModals } from "../../../../constants/types.tsx";
import {
  requestSquematic,
  useUpdatePasoRenovacion,
} from "../../../../common/CommonFunctions.tsx";
import { errorAlert, ModalDashboard } from "../../../../common/alerts.tsx";
import renovationStep from "../../services/RenovationStep.tsx";

export default function RenovationApplyNow({ setIsLoading }: navigationModals) {
  const { getUser } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const { parseRenovacion, updatePasoRenovacion } = useUpdatePasoRenovacion();

  const handleContinue = async () => {
    setIsLoading(true);
    try {
      const objToSend = {
        c125_rowid_tercero:
          getValueStorage("authSession").hasFinancial.c126_rowid_tercero,
      };

      const dataLocation = await requestSquematic(
        "POST",
        "/api/app/contact_details/get_address",
        objToSend,
        getValueStorage("authSession").token
      );

      if (dataLocation) {
        let dinamicElement = renovationStep({
          setIsLoading: setIsLoading,
          parseRenovacion: parseRenovacion,
        });

        if (dinamicElement) {
          ModalDashboard({
            element: dinamicElement,
          });
        }
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : String(error);
      errorAlert("Error", errorMessage, "");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <img
          src={require("../../assets/images/manos.webp")}
          alt={""}
          loading="lazy"
          style={{
            width: "100%",
            height: "80%",
            objectFit: "contain",
          }}
        />

        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: { xs: 28, sm: 31 },
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
            marginTop: 3,
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("renovationApplyNow.title")}
        </Typography>

        <Typography
          className="text-secondary-800	dark:text-white"
          sx={{
            textTransform: "uppercase",
            fontSize: { xs: 13, sm: 17, xl: 19 },
            textAlign: "center",
            fontWeight: 700,
            marginTop: -0.5,
          }}
          lineHeight={1}
        >
          {t("renovationApplyNow.subtitle")}
        </Typography>
      </Box>
      {/* ¿Cuál es el siguiente paso? */}
      <Box textAlign={"center"} sx={{ marginX: { xs: 1, sm: 3 } }}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 17,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
            marginTop: 5,
          }}
          lineHeight={1}
        >
          {t("preAproved.whoNextStep")}
        </Typography>

        <Typography
          className="text-secondary-800	dark:text-white"
          sx={{
            fontSize: 16,
            lineHeight: 1,
            marginBottom: "20px",
            textAlign: "justify",
            mt: 2,
          }}
        >
          {t("renovationApplyNow.nextStep")}
          <strong> {t("renovationApplyNow.afterNextStep")}</strong>
          {t("renovationApplyNow.endNextStep")}
        </Typography>
      </Box>

      {/* ¿Qué beneficios tengo? */}
      <Box textAlign={"center"} sx={{ marginX: { xs: 1, sm: 3 } }}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 17,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
            marginTop: 5,
          }}
          lineHeight={1}
        >
          {t("renovationApplyNow.benefits")}
        </Typography>

        <Typography
          className="text-secondary-800	dark:text-white"
          sx={{
            fontSize: 16,
            lineHeight: 1,
            marginBottom: "20px",
            textAlign: "justify",
            mt: 2,
          }}
        >
          {t("renovationApplyNow.afterBenefits")}
          <strong> {t("renovationApplyNow.endBenefits")}</strong>
        </Typography>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        marginTop={{ xs: 5, md: "50px" }}
      >
        <ColorButton
          sx={{
            width: "fit-content",
            padding: "10px 40px",
            fontSize: 15,
          }}
          onClick={handleContinue}
          disabled={getUser.isLoading}
        >
          {t("common.continue")}
        </ColorButton>
      </Box>
    </>
  );
}
