import * as Yup from "yup";

export const yupIdValdations = Yup.object().shape({
  id: Yup.string()
    .min(10, "El número de la cédula debe tener al menos 10 caracteres")
    .matches(/^[0-9]+$/, "Solo se permiten numeros")
    .required("El número de la cédula es obligatorio"),
  id_verification: Yup.string()
    .oneOf([Yup.ref("id")], "El número de la cédula no coincide")
    .length(10, "El id debe tener 10 caracteres")
    .matches(/^[0-9]+$/, "Solo se permiten numeros")
    .required("La verificación de la cédula es obligatoria"),
  date_expedition: Yup.date()
    .required("La fecha de expedición es obligatoria")
    .test(
      "fecha-no-futura",
      "La fecha no puede ser superior a hoy",
      (value) => value && new Date(value) <= new Date()
    ),
  cedulaF: Yup.string().required("La cédula frontal es obligatoria"),
  cedulaA: Yup.string().required("La cédula trasera es obligatoria"),
  selfie: Yup.string().required("La foto de perfil es obligatoria"),
  agreedTerms: Yup.boolean()
    .oneOf([true], "Debes aceptar los términos y condiciones")
    .required("Debes aceptar los términos y condiciones"),
  riskCenter: Yup.boolean()
    .oneOf([true], "Debes aceptar la consulta en centrales de riesgo")
    .required("Debes aceptar la consulta en centrales de riesgo"),
});
