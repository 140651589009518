import React from "react";
import { useTranslation } from "react-i18next";
import { formatNumberPoint, requestSquematic, stringToDate } from "../../../../common/CommonFunctions.tsx";
import { IBankingTransaction, IUserAuth, PropItemsLoanInit } from "../../../../constants/types";

interface paymentHistoryProps {
  setIsLoading: (isLoading: boolean) => void;
  getUser: IUserAuth;
  loanLocal: PropItemsLoanInit | null;
}

export default function PaymentHistory({ setIsLoading, getUser, loanLocal }: paymentHistoryProps) {
  const { t } = useTranslation();
  const [data, setData] = React.useState<{ provider: string, value: string, date: string }[]>([]);

  const fetchData = async () => {
    const res = await requestSquematic(
      "POST",
      "/api/website/loan/get_payment_history",
      { c200_rowid: loanLocal?.c200_rowid },
      getUser.token
    );

    if (res && res.results) {
      setData(res.results.map((item: IBankingTransaction) => {
        return {
          provider: item.provider?.c007_nombre ?? '',
          value: (item.c231_valor + '').includes('.') ? (item.c231_valor + '').split('.')[0] : item.c231_valor,
          date: item.c231_fecha_transaccion
        }
      }));
    }
    setIsLoading(false);
  }

  React.useEffect(() => {
    fetchData();
  }, []);

  const classTH = "pb-2 text-sm md:text-md text-primary border-b font-semibold";

  return (
    <div className="p-2 lg:p-8">
       <p className="mb-5 text-md md:text-xl lg:text-2xl font-bold uppercase text-primary">
        {t("paymentHistory.title")}
      </p>
      <div className="overflow-x-auto max-w-full">
      <table className="w-[500px] lg:w-full text-sm">
        <thead>
          <tr>
            <th className={classTH}>{t("paymentHistory.type")}</th>
            <th className={classTH}>{t("paymentHistory.value")}</th>
            <th className={classTH}>{t("paymentHistory.date")}</th>
          </tr>
        </thead>
        <tbody>
          {
            data.length === 0 ?
              <tr>
                <td colSpan={3} className="border-b border-slate-200 p-4 pl-8 text-slate-500 dark:text-slate-400 dark:text-white ">
                  {t("paymentHistory.noData")}
                </td>
              </tr>
              :
              data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="border-b border-slate-200 p-4 pl-8 text-slate-500 dark:text-slate-400 dark:text-white ">{item.provider}</td>
                    <td className="border-b border-slate-200 p-4 pl-8 font-bold text-slate-500 dark:text-slate-400 dark:text-white ">
                      $ {formatNumberPoint(item.value)}
                    </td>
                    <td className="border-b border-slate-200 p-4 pl-8 text-slate-500 dark:text-slate-400 dark:text-white ">
                      {
                        stringToDate(item.date, {
                          weekday: "short",
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        })
                      }
                    </td>
                  </tr>
                );
              })
          }
        </tbody>
      </table>
      </div>
      
    </div>
  );
}