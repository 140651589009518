import React from "react";
import { useTranslation } from "react-i18next";
import {
  getValueStorage,
  saveValueStorage,
} from "../../../../common/storage.ts";
import {
  IGetLoansByThirdOfferedAgain_Result,
  IRequestLoan_Request,
  IRequestLoan_Result,
  loanResponse,
} from "../../../../constants/types.tsx";
import {
  formatNumberPoint,
  obtainIdLoanState,
  requestSquematic,
} from "../../../../common/CommonFunctions.tsx";
import { Box, Tooltip } from "@mui/material";
import { ColorButton } from "../../../../components/Button.tsx";
import { colors } from "../../../../styles/theme.tsx";
import { InfoOutlinedIcon } from "../../assets/iconsMui.tsx";
import {
  confirmationAlert,
  ModalDashboard,
  MySwal,
} from "../../../../common/alerts.tsx";
import HasOfferSimulator from "../../../../components/simulators/HasOfferSimulator.tsx";

type Props = {
  setIsLoading: (isLoading: boolean) => void;
  localData?: loanResponse & { name: string }; // Recibimos data como prop
};

export const HasOfferInitial: React.FC<Props> = ({
  setIsLoading,
  localData,
}) => {
  const { t } = useTranslation();
  const [data, setData] = React.useState<
    (loanResponse & { name: string }) | undefined
  >(localData);
  const [isShowed, setIsShowed] = React.useState<boolean>(false);
  const getUser = getValueStorage("authSession");

  React.useEffect(() => {
    (async () => {
      if (localData) return;

      setIsLoading(true);
      const res = await getValueStorage("profileData");
      const data: IGetLoansByThirdOfferedAgain_Result = await requestSquematic(
        "GET",
        "/api/app/loan/get_loans_by_third_offered_again",
        {},
        getUser.token
      );

      if (data) {        
        setData({ ...data.results!, name: res.c120_nombres });
      }
      setIsLoading(false);
    })();
  }, []);

  // No me interesa
  const handleRefuse = async () => {
    const res = await confirmationAlert(
      t("hasOfferInitial.areSureAlert.title"),
      t("hasOfferInitial.areSureAlert.message"),
      t("hasOfferInitial.areSureAlert.negative")
    );
    if (res) {
      setIsLoading(true);
      const objToSend: IRequestLoan_Request = {
        c200_rowid: parseInt(data!.loan_new.c200_rowid),
        c200_ind_estado: obtainIdLoanState("rejected"),
      };
      const res: IRequestLoan_Result = await requestSquematic(
        "POST",
        "/api/app/loan/set_loan_state",
        objToSend,
        getUser.token
      );

      if (res) {
        saveValueStorage("authSession", {
          ...getValueStorage("authSession"),
          hasOfferedAgain: false,
        });
        MySwal.close();
        window.location.reload();
      }
      setIsLoading(false);
    } else {
      // Cancelar
      ModalDashboard({
        element: (
          <HasOfferInitial setIsLoading={setIsLoading} localData={data} />
        ),
        close: false,
      });
    }
  };

  // Si me interesa
  const handleContinue = async () => {
    let obj = {
      c200_rowid_new: data!.loan_new.c200_rowid,
      c200_rowid_old: data!.c200_rowid,
      creditLine: data!.c200_rowid_concepto,
      maxAmount: data!.loan_new?.c200_valor,
      periodPay: data!.c200_rowid_periodo,
      personType: data!.c200_rowid_ciiu,
      dues: data!.c200_plazo,
      c200_renovacion: data!.c200_renovacion,
      totalbalancedue: data!.totalbalancedue,
    };
    saveValueStorage("HasOfferSimulation", obj);
    ModalDashboard({
      element: (
        <HasOfferSimulator
          setIsLoading={setIsLoading}
          localData={data} // Conservar la data
        />
      ),
      close: false,
    });
  };

  return (
    <div>
      <p className="text-primary font-bold text-lg uppercase">
        {t("hasOfferInitial.title")}
      </p>
      {data && (
        <>
          {" "}
          <div className="text-center mt-5">
            <p className="text-md text-secundary-800 dark:text-white ">
              <strong className="capitalize">¡{data?.name}! </strong>
              <span>{t("hasOfferInitial.has")}</span>
            </p>
            <p className="text-md text-primary font-bold">
              {t("hasOfferInitial.reoffer")}
            </p>
          </div>
          <div className="mt-4">
            <p className="text-base text-secundary-800 dark:text-white leading-4">
              {t("hasOfferInitial.requestedLoanValue")}
            </p>
            <p className="text-2xl text-primary dark:text-green-600 font-semibold mt-1">
              ${formatNumberPoint(parseInt(data?.c200_valor ?? "0"))}
            </p>
          </div>
          <div className="mt-8 ">
            <p className="text-base text-secundary-800 dark:text-white leading-4">
              {t("hasOfferInitial.ourTeamStudied")}
            </p>
            <p className="text-2xl text-green-600 dark:text-primary font-semibold mt-6 leading-4">
              ${formatNumberPoint(parseInt(data?.loan_new?.c200_valor ?? "0"))}
              <Tooltip
                title={t("hasOfferInitial.msg")}
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={() => setIsShowed(false)}
                open={isShowed}
                disableHoverListener
              >
                <button onClick={() => setIsShowed(true)}>
                  <InfoOutlinedIcon
                    sx={{
                      color: colors.primary,
                      mt: 1,
                      fontSize: 16,
                    }}
                  />
                </button>
              </Tooltip>
            </p>
            {data?.c200_renovacion === "1" && (
              <p className="text-base text-secundary-800 dark:text-white leading-4 mt-6">
                {t("hasOfferInitial.forYouRenovation")}
              </p>
            )}
          </div>
          <div className="text-center ">
            <p className="text-base text-secundary-800 dark:text-white leading-4 mt-12">
              {t("hasOfferInitial.areInterested")}
            </p>
          </div>
          <Box className="flex flex-col mt-5">
            <div className="grid grid-cols-2  gap-7 sm:gap-5">
              <div className="flex justify-end">
                <ColorButton
                  sx={{
                    width: "140px",
                    padding: "15px 10px",
                    fontSize: 12,
                    borderRadius: 3,
                    margin: 0,
                    lineHeight: 1,
                  }}
                  // disabled={getUser.isLoading}
                  onClick={handleContinue}
                >
                  {t("hasOfferInitial.yesIm")}
                </ColorButton>
              </div>
              <div className="flex justify-start">
                <ColorButton
                  background={colors.blueGray}
                  sx={{
                    width: "140px",
                    padding: "15px 10px",
                    fontSize: 12,
                    borderRadius: 3,
                    margin: 0,
                    lineHeight: 1,
                  }}
                  // disabled={getUser.isLoading}
                  onClick={handleRefuse}
                >
                  {t("hasOfferInitial.noImNot")}
                </ColorButton>
              </div>
            </div>
          </Box>
        </>
      )}
    </div>
  );
};
