import React from "react";
import {
  Box,
  FormHelperText,
  Typography,
} from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import VerificationInput from "react-verification-input";
import { useTranslation } from "react-i18next";
import { ColorButton } from "../../../components/Button.tsx";
import "../../../index.css";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import { requestSquematic } from "../../../common/CommonFunctions.tsx";
import {
  getValueStorage,
  saveValueStorage,
} from "../../../common/storage.ts";
import { errorAlert } from "../../../common/alerts.tsx";
import { IUserInfoLocalStorage } from "../../../constants/types.tsx";
import { IRegisterStepState } from "../RegisterComponent.tsx";
import { CssTextField } from "./EmailValidate.tsx";

export default function PhoneValidate({ setRegisterStep }: IRegisterStepState) {
  const { getUser, setIsLoading } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const [phone, setPhone] = React.useState<string>("");
  const [otpCode, setOtpCode] = React.useState<string>("");
  const [isOtpSend, setIsOtpSend] = React.useState<boolean>(false);
  const [isValidPhone, setIsValidPhone] = React.useState<boolean>(false);
  const userInfo: IUserInfoLocalStorage = getValueStorage("userInfo");

  // Al cambiar el email
  const handleChange = (value: string) => {
    setPhone(value);
    setIsValidPhone(value.length === 10);
    setIsOtpSend(false);
    setOtpCode("");
  };

  // Enviar codigo OTP
  const handleSendOtpPhone = async () => {
    setIsLoading(true);
    const data = await requestSquematic(
      "POST",
      "/api/app/validation_otp/send_otp_sms",
      {
        email: userInfo.user_email,
        phone: phone,
        code: "+57",
        c001_cod_lenguaje: t("common.lenguage"),
      }
    );

    if (data) {
      setIsOtpSend(true);
    }

    setIsLoading(false);
  };

  //Validamos el codigo OTP que ingresa el usuario contra el que se envio por SMS
  const handlePhoneValidation = async () => {
    if (!userInfo.user_email || !userInfo.otpEmail) {
      errorAlert(
        "Error",
        "No se encontró el correo electrónico o el código de verificación del correo electrónico",
        ""
      );
      return true;
    }
    setIsLoading(true);

    const data = await requestSquematic(
      "POST",
      "/api/app/validation_otp/validate_otp",
      {
        otp_code: otpCode,
        value: phone,
      }
    );

    if (data) {
      saveValueStorage("userInfo", {
        ...userInfo,
        user_phone: phone,
        otpPhone: otpCode,
      });
      let obj = {
        prevStep: 1,
        nextStep: 2,
        desc: "el usuario verifico el codigo otp del celular",
      };
      saveValueStorage("registerStep", obj);
      if (setRegisterStep) setRegisterStep(obj);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: colors.black,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("verifyCode.title")}
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("verifyCode.subPhone")}
        </Typography>
      </Box>
      {/* Input */}
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"left"}
        mt={5}
        sx={{ marginX: { sm: 4, xl: 10 } }}
      >
        <Box sx={{ width: 90, height: 90, margin: "0 auto", mb: 3 }}>
          <img
            src={require("../../../assets/image/Celular.webp")}
            alt={""}
            loading="lazy"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
        <CssTextField
          id="outlined-basic"
          label=""
          variant="outlined"
          onChange={({ target }) => {
            target.value = target.value.replace(/[^0-9]/g, "");
            handleChange(target.value);
          }}
          inputProps={{
            maxLength: 10, // Máximo de caracteres
            inputMode: "numeric", // Teclado numérico en dispositivos móviles
          }}
          onInput={(e: React.FormEvent<HTMLInputElement>) => {
            // Remueve cualquier carácter que no sea un numero
            const target = e.target as HTMLInputElement;
            target.value = target.value.replace(/[^0-9]/g, "");
          }}
          isError={!isValidPhone && phone.length !== 0}
        />
        {!isValidPhone && phone.length !== 0 && (
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <FormHelperText
              style={{
                color: colors.red,
                fontSize: 11,
                width: "100%",
                marginLeft: 0,
                marginRight: 0,
                lineHeight: 1,
                marginTop: 5,
              }}
            >
              * {t("verifyCode.phoneError")}
            </FormHelperText>
          </Box>
        )}
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          marginTop={5}
        >
          <ColorButton
            sx={{
              width: "fit-content",
              padding: "15px 40px",
              fontSize: 16,
            }}
            onClick={() => handleSendOtpPhone()}
            disabled={!isValidPhone || getUser.isLoading || isOtpSend}
          >
            {t("common.continue")}
          </ColorButton>
        </Box>
      </Box>
      {/* Se ha enviado */}
      {isOtpSend && (
        <>
          <Box
            mt={8}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 20,
                color: colors.black,
                textTransform: "uppercase",
                marginBottom: "5px",
              }}
              lineHeight={1}
              textAlign={"center"}
            >
              {t("verifyCode.message")}
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 20,
                color: colors.primary,
                textTransform: "uppercase",
                marginBottom: "5px",
              }}
              lineHeight={1}
              textAlign={"center"}
            >
              {t("verifyCode.code")}
            </Typography>
          </Box>
          {/* Destino del codigo */}
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            mt={5}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 20,
                color: colors.black,
                textTransform: "uppercase",
                marginBottom: 5,
              }}
              lineHeight={1}
              textAlign={"center"}
            >
              {t("verifyCode.toPhone")}
            </Typography>
            <VerificationInput
              length={4}
              validChars="0-9"
              placeholder=""
              classNames={{
                container: "container-characters",
                character: "characters",
                characterInactive: "character--inactive",
                characterSelected: "character--selected",
                characterFilled: "filled",
              }}
              autoFocus={true}
              onChange={(e) => {
                // Limpiamos el otp cuando se modifique
                setOtpCode("");
              }}
              onComplete={(e) => {
                // Se llena el otp cuando se completa los campos
                setOtpCode(e);
              }}
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            marginTop={5}
          >
            <ColorButton
              sx={{
                width: "fit-content",
                padding: "15px 40px",
                fontSize: 16,
              }}
              onClick={handlePhoneValidation}
              disabled={getUser.isLoading || !otpCode}
            >
              {t("common.send")}
            </ColorButton>
          </Box>
        </>
      )}
    </>
  );
}
