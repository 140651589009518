import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {
  IPanelAll,
  IUserAuth,
  ILoanMapping,
  loans,
} from "../constants/types.tsx";
import {
  getValueStorage,
  saveValueStorage,
  wipeValueStorage,
} from "./storage.ts";
import moment from "moment-timezone";
import { errorAlert } from "./alerts.tsx";
import { AuthContext } from "../hooks/useAuth.tsx";
import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";

export const DOMAIN = "https://server.lukiao-stg.com";
const MySwal = withReactContent(Swal);

export const detectDevice = () => {
  let response = "Desktop";
  if (navigator.userAgent.toLowerCase().includes("android")) {
    response = "Android";
  }
  if (navigator.userAgent.toLowerCase().includes("iphone")) {
    response = "iPhone";
  }
  return response;
};

export const getAge = (date: Date): number => {
  let today = new Date();
  let birthDate = date;
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

export const obtainIdLoanState = (
  option:
    | "simulated"
    | "requested"
    | "pending"
    | "approved"
    | "rejected"
    | "process"
    | "suspended"
    | "restructured"
    | "finished"
    | "cancelled"
): number => {
  const obj = {
    simulated: 0,
    requested: 1,
    pending: 2,
    approved: 3,
    rejected: 4,
    process: 5,
    suspended: 6,
    restructured: 7,
    finished: 9,
    cancelled: 10,
  };

  return obj[option as keyof object];
};

export const isATest = (text: string) =>
  text.toLocaleLowerCase().includes("prueba") ||
  text.toLocaleLowerCase().includes("admin");

export const isNumeric = (str: string) =>
  /^-?\d+$/.test(str) && !str.startsWith("-");

export const stringToDate = (
  date: string | undefined,
  options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }
): string => {
  if (date === undefined || date === null || date === "-") {
    return "-";
  }

  return new Date(date).toLocaleDateString("es-CO", options);
};

// Convertir formato de 24hrs a 12hrs
export const convertTo12HourFormat = (time: string): string => {
  const [hours, minutes] = time.split(":");
  let hour = parseInt(hours);
  const ampm = hour >= 12 ? "P.M." : "A.M.";
  hour = hour % 12 || 12;
  return `${hour}:${minutes} ${ampm}`;
};

// Convertir la fecha 12hrs a 24hrs
export const convertTimeTo24HourFormat = (time: string): string => {
  // Separar la hora y el período (AM/PM)
  const [hourMinute, period] = time.split(" ");
  const [hourStr, minuteStr] = hourMinute.split(":");
  let hour = parseInt(hourStr, 10);
  const minute = parseInt(minuteStr, 10);

  // Ajustar la hora si es PM
  if (period.toLocaleLowerCase() === "p.m." && hour < 12) {
    hour += 12;
  } else if (period.toLocaleLowerCase() === "a.m." && hour === 12) {
    hour = 0;
  }

  // Formatear la hora en formato HH:mm:ss
  const formattedTime = `${hour.toString().padStart(2, "0")}:${minute
    .toString()
    .padStart(2, "0")}:00`;

  return formattedTime;
};

// Obtener fecha actual tipo YYYY-mm-dd H:i:s
export const getCurrentFormattedDate = (): string => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Agrega cero inicial si es necesario
  const day = ("0" + currentDate.getDate()).slice(-2); // Agrega cero inicial si es necesario
  const hours = ("0" + currentDate.getHours()).slice(-2); // Agrega cero inicial si es necesario
  const minutes = ("0" + currentDate.getMinutes()).slice(-2); // Agrega cero inicial si es necesario
  const seconds = ("0" + currentDate.getSeconds()).slice(-2); // Agrega cero inicial si es necesario

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

// Formatear objeto Date al formato que lo devuelve la base de datos
export const formatDateToDBString = (date: Date) => {
  const anio = date.getFullYear();
  const mes = String(date.getMonth() + 1).padStart(2, "0"); // Meses van de 0-11, así que se suma 1
  const dia = String(date.getDate()).padStart(2, "0");
  const horas = String(date.getHours()).padStart(2, "0");
  const minutos = String(date.getMinutes()).padStart(2, "0");
  const segundos = String(date.getSeconds()).padStart(2, "0");
  return `${anio}-${mes}-${dia} ${horas}:${minutos}:${segundos}`;
};

// Convertir a formato de fecha "dia semana","dia numero" de "mes letras" de "año", "hora 12hrs"
export const formatDateTime = (
  date: string,
  t: (key: string) => string,
  noDay?: boolean, // Para no mostrar el dia
  noYear?: boolean // Para no mostrar el año
): string => {
  if (!date) return "";

  const dayNames = [
    t("calendar.dayNames.sunday"),
    t("calendar.dayNames.monday"),
    t("calendar.dayNames.tuesday"),
    t("calendar.dayNames.wednesday"),
    t("calendar.dayNames.thursday"),
    t("calendar.dayNames.friday"),
    t("calendar.dayNames.saturday"),
  ];

  const monthNames = [
    t("calendar.monthNames.january"),
    t("calendar.monthNames.february"),
    t("calendar.monthNames.march"),
    t("calendar.monthNames.april"),
    t("calendar.monthNames.may"),
    t("calendar.monthNames.june"),
    t("calendar.monthNames.july"),
    t("calendar.monthNames.august"),
    t("calendar.monthNames.september"),
    t("calendar.monthNames.october"),
    t("calendar.monthNames.november"),
    t("calendar.monthNames.december"),
  ];

  if (date) {
    // Obtiene todo, y lo pasa al formato de fecha asignado en el componente
    const fechaObjeto = new Date(date);
    const diaSemana = dayNames[fechaObjeto.getDay()];
    const dia = fechaObjeto.getDate();
    const mes = monthNames[fechaObjeto.getMonth()];
    const año = fechaObjeto.getFullYear();

    if (noYear) {
      return `${dia} de ${mes}`;
    } else if (noDay) {
      return `${dia} de ${mes} de ${año}`;
    }

    return `${diaSemana}, ${dia} de ${mes} de ${año}`;
  }

  return "";
};

export const errorHandler = (errors: string | any): string => {
  if (typeof errors === "string") {
    return errors;
  }

  const errs: string[] = [];

  Object.keys(errors).forEach((key) =>
    errs.push(...errors[key as keyof object])
  );

  return errs.join("\n\n");
};

export const requestSquematic = async (
  type: "GET" | "POST" | "DELETE" | "PUT",
  url: string,
  object: any,
  token?: string,
  onTokenError?: () => void,
  cancelToken?: AbortSignal
): Promise<any> => {
  try {
    let res: AxiosResponse<any>;
    const generalParams: AxiosRequestConfig<any> = {
      headers: { Authorization: `Bearer ${token}` },
      signal: cancelToken,
    };

    if (type === "GET") {
      res = await axios.get(DOMAIN + url, {
        ...generalParams,
        params: object,
      });
    } else {
      res = await axios.post(DOMAIN + url, object, generalParams);
    }

    const data = res.data;

    if (data.status === "OK") {
      return data;
    } else {
      const repeatNumber = data.errors?.includes(
        "Este numero de celular ya se encuentra registrado en el sistema"
      );
      if (!MySwal.isVisible()) {
        MySwal.fire({
          icon: "error",
          title: "¡Error!",
          text: "Typing problem...",
          footer: errorHandler(data.errors),
          confirmButtonText: "OK",
          allowOutsideClick: repeatNumber ? false : true,
          allowEscapeKey: repeatNumber ? false : true,
          allowEnterKey: repeatNumber ? false : true,
        }).then(() => {
          // Si existe un numero de celular ya registrado
          if (
            repeatNumber
          ) {
            // Borrar el valor de storage
            wipeValueStorage("registerStep");
            window.location.reload();
          }
        });
      }
    }
  } catch (error: any) {
    if (axios.isAxiosError(error)) {
      const err: AxiosError = error;

      if (err.response?.status === 401) {
        onTokenError?.();
        !MySwal.isVisible() &&
          MySwal.fire({
            icon: "error",
            title: "¡Error!",
            text: "Problema de sesión...",
            footer: "Tu sesión ha caducado, vuelve a iniciar...",
          });

        return;
      }
    } else if (error instanceof TypeError) {
      !MySwal.isVisible() &&
        MySwal.fire({
          icon: "error",
          title: "¡Error!",
          text: "Typing problem...",
          footer: error.message,
        });

      return;
    } else if (axios.isCancel(error)) {
      return;
    }

    !MySwal.isVisible() &&
      MySwal.fire({
        icon: "error",
        title: "¡Error!",
        text: "Server problem...",
        footer: error,
      });
  }
};

// Capitalizar primera letra des mes
export const formatDateWithCapitalizedMonth = (date: string) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "2-digit",
  };
  const formattedDate = new Date(date).toLocaleDateString("es-ES", options);

  // Dividir la fecha en partes (día, 'de', mes, 'de', año) y capitalizar el mes
  const dateParts = formattedDate.split(" ");
  if (dateParts.length > 2) {
    // Capitalizamos solo la primera letra del mes
    dateParts[2] = dateParts[2].charAt(0).toUpperCase() + dateParts[2].slice(1);
  }

  return dateParts.join(" ");
};

// Formatear un número y agregar puntos como separadores de miles.
export const formatNumberPoint = (num: number | string): string => {
  let numTemp = parseInt(num + "");
  return numTemp.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

// Función para calcular si la fecha seleccionada es mayor a 18 años
export const isAdult = (birthDate: Date) => {
  const today = new Date();
  const eighteenYearsAgo = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );
  return birthDate <= eighteenYearsAgo;
};

export const arrToObj = (arr: any[], key: string) => {
  const initialValue = {};

  return arr.reduce((obj, item) => {
    return {
      ...obj,
      [item[key as keyof object] as keyof object]: item,
    };
  }, initialValue);
};

export const isLoanDataEmpty = (
  getUserParam: any,
  signIn: (data: IUserAuth) => void
) => {
  // Desestructuración segura con valores predeterminados en caso de que alguna propiedad sea undefined
  const {
    loanActive = {},
    loanApproved = "",
    loanPending = {},
    loanRequest = {},
  } = getUserParam;

  // Validar si todos los campos están vacíos usando encadenamiento opcional
  const isLoanActiveEmpty =
    !loanActive?.c200_ind_estado &&
    !loanActive?.c200_rowid &&
    !loanActive?.c200_rowid_nuevo_prestamo;

  const isLoanPendingEmpty =
    !loanPending?.c200_ind_estado &&
    !loanPending?.c200_rowid &&
    !loanPending?.c200_rowid_nuevo_prestamo;

  const isLoanRequestEmpty =
    !loanRequest?.c200_ind_estado &&
    !loanRequest?.c200_rowid &&
    !loanRequest?.c200_rowid_nuevo_prestamo &&
    !loanRequest?.c204_paso_renovacion;

  const isLoanApprovedEmpty = !loanApproved;

  // Si todos los campos están vacíos, devolver true
  if (signIn) {
    let state = getUserParam.hasRejected
      ? false
      : isLoanActiveEmpty &&
      isLoanPendingEmpty &&
      isLoanRequestEmpty &&
      isLoanApprovedEmpty;
    signIn({
      ...getUserParam,
      loanEmpty: state === getUserParam.isOld,
    });
  }
};

export const JSONToForm = (obj: object): FormData => {
  const formData = new FormData();

  Object.keys(obj).forEach((key) =>
    formData.append(key, obj[key as keyof object])
  );

  return formData;
};

//Obtener la descripcion del periodo de pago segun el id
export const getPeriodPayDesc = (c200_rowid_periodo: string) => {
  const dataPanel: IPanelAll = getValueStorage("panelData");
  let response = "";

  dataPanel.all_period_type.forEach((item) => {
    if (item.c054_rowid + "" === c200_rowid_periodo)
      response = item.c054_descripcion;
  });
  return response;
};

//Calcular en tiempo real el tiempo faltante entre 2 fechas
export const calculateRemainingTime = (
  scheduling_date: string,
  setHours: boolean = true
) => {
  let utc = moment(moment.utc().format());

  // Definir las dos fechas
  let currentDate = moment(utc.format()).tz("America/Bogota");
  let endDate = moment(scheduling_date);

  if (currentDate.isAfter(endDate) && setHours) {
    return "00:00:00";
  }

  if (currentDate.isAfter(endDate) && !setHours) {
    return "00:00";
  }

  // Convertir milisegundos a segundos
  const difference = endDate.diff(currentDate);

  // Crear una duración a partir de la diferencia
  const duration = moment.duration(difference);

  // Extraer horas, minutos y segundos
  const hours = String(duration.hours()).padStart(2, "0");
  const mins = String(duration.minutes()).padStart(2, "0");
  const seconds = String(duration.seconds()).padStart(2, "0");

  if (setHours) {
    return `${hours}:${mins}:${seconds}`;
  } else {
    return `${mins}:${seconds}`;
  }
};

// Función para convertir el Blob a un archivo
export const blobToFile = async (
  blobUrl: string,
  fileName: string
): Promise<File> => {
  // Recuperar el contenido del Blob usando fetch
  const response = await fetch(blobUrl);
  const blob = await response.blob();

  // Crear un archivo a partir del Blob
  const file = new File([blob], `${fileName}.webm`, { type: "video/webm" });

  return file;
};

export const useUpdatePasoRenovacion = () => {
  const { getUser, signIn } = React.useContext(AuthContext);

  const authSession: IUserAuth = getValueStorage("authSession");
  // Funcion para actualizar el paso de renovacion y el tipo de videollamada
  const updatePasoRenovacion = async (
    paso: string,
    setIsLoading?: ((loading: boolean) => void) | null,
    tipoVideollamada: string = "",
    estadoLaboral: string = "",
    esIndependiente?: boolean,
    cambioEmpresa?: boolean
  ) => {
    if (setIsLoading) {
      setIsLoading(true);
    }

    try {
      const parsedTipoVideollamada = parseRenovacion()
        ? parseRenovacion().tipo_videollamada
        : "";
      const parsedEstadoLaboral = parseRenovacion()
        ? parseRenovacion().estado_laboral
        : "";

      const res = await requestSquematic(
        "POST",
        "/api/app/loan/update_paso_renovacion",
        {
          c200_rowid_nuevo_prestamo: authSession.loanRequest?.c200_rowid,
          c204_paso_renovacion: JSON.stringify({
            paso,
            tipo_videollamada: tipoVideollamada
              ? tipoVideollamada
              : parsedTipoVideollamada,
            estado_laboral: estadoLaboral ? estadoLaboral : parsedEstadoLaboral,
            es_independiente:
              typeof esIndependiente === "undefined"
                ? parseRenovacion()?.es_independiente ?? false
                : esIndependiente,
            cambio_empresa:
              typeof cambioEmpresa === "undefined"
                ? parseRenovacion()?.cambio_empresa ?? false
                : cambioEmpresa,
          }),
        },
        authSession.token
      );

      if (res) {
        saveValueStorage("authSession", {
          ...getValueStorage("authSession"),
          loanRequest: {
            ...getValueStorage("authSession").loanRequest,
            c204_paso_renovacion: JSON.stringify({
              paso: paso,
              tipo_videollamada: tipoVideollamada
                ? tipoVideollamada
                : parsedTipoVideollamada,
              estado_laboral: estadoLaboral
                ? estadoLaboral
                : parsedEstadoLaboral,
              es_independiente:
                typeof esIndependiente === "undefined"
                  ? parseRenovacion()?.es_independiente ?? false
                  : esIndependiente,
              cambio_empresa:
                typeof cambioEmpresa === "undefined"
                  ? parseRenovacion()?.cambio_empresa ?? false
                  : cambioEmpresa,
            }),
          },
        });
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : String(error);
      errorAlert("Error", errorMessage, "");
    } finally {
      if (setIsLoading) {
        setIsLoading(false);
      }
    }
  };

  // Funcion para parsear el paso de renovacion y el tipo de videollamada
  const parseRenovacion = () => {
    if (getValueStorage("authSession").loanRequest?.c204_paso_renovacion) {
      return JSON.parse(
        getValueStorage("authSession").loanRequest?.c204_paso_renovacion
      );
    } else {
      return null;
    }
  };

  return { updatePasoRenovacion, parseRenovacion };
};

/**
 * Obtener el porcentaje a pagar para poder solicitar una renovacion
 */
export const getPercentCredit = (loan: ILoanMapping, PERCENT_LIMIT: number) => {
  const loans: loans = getValueStorage("loans");

  //1. Valor total de la deuda
  const totalCapital =
    parseFloat(loan.valPerDue + "") * parseFloat(loan.dues + "");
  //2. Total pagado cuotas
  const payedQuota = loan.banking_transaction.reduce(
    (acum, { c230_valor_recaudo }) => acum + parseFloat(c230_valor_recaudo),
    0
  );

  //3. Total mora y cobranza
  const penaltiesToPay = parseFloat(loan.penalties_to_pay);
  //4. Caja parcial
  const parcialPayment =
    loans?.parcial_payment?.c260_ind_estado !== "9"
      ? parseFloat(loans?.parcial_payment?.c260_valor_acumulado ?? "0")
      : 0;

  //Calcular porcentaje pago
  const percentVal = parseFloat(((payedQuota / totalCapital) * 100).toFixed(2));

  //Calcular el valor a pagar para poder solicitar una renovacion (el total del capital por el porcentaje + la mora, menos el capital pagado y lo que tenga en caja parcial)
  const valToLimit =
    totalCapital * (PERCENT_LIMIT / 100) +
    penaltiesToPay -
    (payedQuota + parcialPayment);

  let response = {
    total: totalCapital, // deuda total del credito (cap int otros)
    totalPayed: payedQuota, // valor pagado en las cuotas
    percentVal, // porcentaje pagado segun la deuda total del credito
    valToLimit, // valor a pagar para solicitar la renovacion
    parcialPayment,
  };

  return response;
};

export const useMatches = () => {
  const theme = useTheme();

  const matchesXS = useMediaQuery("(max-width:599px)");
  const matchesLG = useMediaQuery(theme.breakpoints.up("lg"));

  return { matchesXS, matchesLG };
};

export const formattedDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const isWithin15Days = (getUser: any): boolean => {
  if (getUser.lastLoanFinished?.c231_fecha_transaccion) {
    // Sumar 15 días calendario a la fecha de la ultima transaccion
    const sumCalendar = new Date(
      getUser.lastLoanFinished.c231_fecha_transaccion
    );
    sumCalendar.setDate(sumCalendar.getDate() + 15);

    // Comparación de fechas
    // Si esta dentro de los 15 dias calendario
    return new Date() <= sumCalendar;
  }

  // Retornar false si no hay una fecha válida
  return false;
};

export const aproxValueWithZeros = (
  value: number,
  cantZero: number
): number => {
  const zerosCount = Math.pow(10, cantZero - 1);

  return Math.ceil(value / zerosCount) * zerosCount;
};

export const removeAccent = (text: string) => {
  return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};
