import { styled, TextField } from "@mui/material";
import { colors } from "../styles/theme.tsx";

export const CssTextField = styled(TextField)<{ isError?: boolean }>(
  ({ isError }) => ({
    "& label": {
      paddingLeft: "15px",
      transition: "padding-left 0.4s ease-in-out",
      color: isError ? `${colors.red} !important` : "inherit",
    },
    "& label.Mui-focused": {
      color: colors.primary,
      paddingLeft: "0px",
    },
    "& label.MuiInputLabel-shrink": {
      paddingLeft: "0px", // Elimina el padding cuando el label está shrinked (arriba)
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#B2BAC2",
      paddingLeft: "0px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E0E3E7",
        borderRadius: 10,
      },
      "&:hover fieldset": {
        borderColor: "#B2BAC2",
        borderRadius: 10,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.primary,
        borderRadius: 10,
      },
      "& .MuiInputBase-inputMultiline": {
        resize: "vertical",
      },
    },

    "& p.MuiFormHelperText-root": {
      color: `${colors.red} !important`,
    },
    "& fieldset": {
      borderColor: isError ? `${colors.red} !important` : colors.primary,
    },
    "&:hover fieldset": {
      borderColor: isError ? `${colors.red} !important` : colors.primary,
    },
    "&.Mui-focused fieldset": {
      borderColor: isError ? `${colors.red} !important` : colors.primary,
    },
    background: colors.lightGray,
    width: "100%",
    marginBottom: "12px",
    borderRadius: 10,
  })
);
