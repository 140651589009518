import * as Yup from "yup";

export const yupReferencesValidations = Yup.object().shape({
  phone: Yup.string()
    .required("El número de teléfono es obligatorio")
    .matches(/^[0-9]*$/, "Solo se permiten números")
    .min(7, "Número de teléfono debe ser minimo 7 digitos"),
  name: Yup.string()
    .min(3, "Minimo 3 caracteres")
    .matches(
      /^(?! )[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/,
      "Solo se permiten letras y sin espacios al final"
    )
    .test(
      "no-prueba-admin",
      "El campo no puede contener 'prueba' o 'admin'",
      (value) => {
        return (
          !value ||
          !value
            .split(" ")
            .some(
              (word) =>
                word.toLowerCase() === "prueba" ||
                word.toLowerCase() === "admin"
            )
        );
      }
    )
    .required("El campo es obligatorio"),
  lastname: Yup.string()
    .min(3, "Minimo 3 caracteres")
    .matches(
      /^(?! )[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/,
      "Solo se permiten letras y sin espacios al final"
    )
    .test(
      "no-prueba-admin",
      "El campo no puede contener 'prueba' o 'admin'",
      (value) => {
        return (
          !value ||
          !value
            .split(" ")
            .some(
              (word) =>
                word.toLowerCase() === "prueba" ||
                word.toLowerCase() === "admin"
            )
        );
      }
    )
    .required("El campo es obligatorio"),

  relationship: Yup.string().when(
    "state_refences",
    (state_refences, schema) => {
      // Si no es referencia familiar, no se muestra el error
      return state_refences[0]
        ? schema.required("Selecciona una opción")
        : schema.notRequired();
    }
  ),
  address: Yup.string()
    .required("El campo es obligatorio")
    .matches(
      /^(?! )[a-zA-Z0-9#\-.,/()]+(?: [a-zA-Z0-9#\-.,/()]+)*$/,
      "Formato de dirección inválida"
    )
    .test(
      "no-prueba-admin",
      "El campo no puede contener 'prueba' o 'admin'",

      (value) => {
        return (
          !value ||
          !value
            .split(" ")
            .some(
              (word) =>
                word.toLowerCase() === "prueba" ||
                word.toLowerCase() === "admin"
            )
        );
      }
    ),
  department: Yup.string().required("Selecciona una opción"),
  city: Yup.string().when(["department"], ([department], schema) => {
    // Si no se selcciono departamento, no se muestra el error
    return department === undefined
      ? schema.notRequired()
      : schema.required("Selecciona una opción");
  }),
});
