import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import {
  formatDateWithCapitalizedMonth,
  formatNumberPoint,
  getPeriodPayDesc,
  obtainIdLoanState,
  requestSquematic,
} from "../../../common/CommonFunctions.tsx";
import { ColorButton } from "../../../components/Button.tsx";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import { getValueStorage, saveValueStorage } from "../../../common/storage.ts";
import { ISimulatorData, IUserAuth } from "../../../constants/types.tsx";
import { LocationRequestComponent } from "../../../constants/permissions.tsx";
import { IRegisterStep } from "../RegisterComponent.tsx";

export default function ConfirmLoan({
  setRegisterStep,
}: {
  setRegisterStep?: React.Dispatch<React.SetStateAction<IRegisterStep>>;
}) {
  const { t } = useTranslation();
  const { getUser, setIsLoading, signIn } = React.useContext(AuthContext);
  const simulationData: ISimulatorData = getValueStorage("simulationData");
  const { registerForLocation } = LocationRequestComponent();

  const handleGoBack = () => {
    let objectStep: IRegisterStep = {
      prevStep: 11,
      nextStep: 10,
      desc: "el usuario volvio a la simulacion",
    };
    saveValueStorage("registerStep", objectStep);
    if (setRegisterStep) setRegisterStep(objectStep);
  };

  //Solicitar el credito
  const handleRequest = async () => {
    setIsLoading(true);
    const loc = await registerForLocation({ setIsLoading }); // Espera a obtener la ubicación
    if (loc) {
      //Hacemos de nuevo la simulacion para almacenar el prestamo
      const res = await requestSquematic(
        "POST",
        "/api/app/loan/loan_simulate",
        {
          c200_valor: simulationData.c200_valor,
          c200_rowid_concepto: simulationData.c200_rowid_concepto,
          c200_plazo: simulationData.c200_plazo,
          c200_rowid_periodo: simulationData.c200_rowid_periodo,
          c200_rowid_ciiu: simulationData.c200_rowid_ciiu,
        },
        getUser.token
      );
      if (res && res.results) {
        //Si todo sale bien actualizamos el estado del credito a solicitud
        const data = await requestSquematic(
          "POST",
          "/api/app/loan/set_loan_state",
          {
            c200_rowid: res.results.loan.c200_rowid,
            c200_ind_estado: obtainIdLoanState("requested"),
            c200_lat: loc.latitude,
            c200_lon: loc.longitude,
          },
          getUser.token
        );

        if (data) {
          const authSession: IUserAuth = getValueStorage("authSession");

          saveValueStorage("simulationData", {
            ...simulationData,
            c200_rowid: res.results.loan.c200_rowid,
          });

          if (getUser.loanEmpty) {
            let sessionTemp = {
              ...authSession,
              loanRequest: {
                ...authSession.loanRequest,
                c200_rowid: data.results.c204_rowid_prestamo + "",
                c200_ind_estado: data.results.c204_ind_estado_actual + "",
              },
              loanPending: {
                ...authSession.loanPending,
                c200_rowid: data.results.c204_rowid_prestamo + "",
                c200_ind_estado: data.results.c204_ind_estado_actual + "",
              },
            };
            signIn(sessionTemp);
            saveValueStorage("authSession", sessionTemp);
            //navega para LoadingRequest
            let objectStep: IRegisterStep = {
              prevStep: 11,
              nextStep: 12,
              desc: "el usuario creo el credito desde la simulacion",
            };
            saveValueStorage("registerStep", objectStep);
            if (setRegisterStep) setRegisterStep(objectStep);
          } else {
            let sessionTemp = {
              ...authSession,
              hasSimulate: true,
              loanRequest: {
                ...authSession.loanRequest,
                c200_rowid: data.results.c204_rowid_prestamo + "",
                c200_ind_estado: data.results.c204_ind_estado_actual + "",
              },
              loanPending: {
                ...authSession.loanPending,
                c200_rowid: data.results.c204_rowid_prestamo + "",
                c200_ind_estado: data.results.c204_ind_estado_actual + "",
              },
            };
            //navega para LoadingRequest
            let objectStep: IRegisterStep = {
              prevStep: 11,
              nextStep: 12,
              desc: "el usuario creo el credito desde la simulacion",
            };

            signIn(sessionTemp);
            saveValueStorage("authSession", sessionTemp);
            saveValueStorage("registerStep", objectStep);
            if (setRegisterStep) setRegisterStep(objectStep);
          }
        }
      }
    }

    setIsLoading(false);
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: colors.black,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("common.form")}
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("common.forRequestCredit")}
        </Typography>
      </Box>
      {/* Resumen del crédito */}
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"left"}
        marginTop={5}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: 20,
            color: colors.darkSlateGray,
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("confirmLoan.title")}
        </Typography>
      </Box>
      {/* Contenido */}
      <Box
        display={"flex"}
        flexDirection={"column"}
        marginTop={1}
        sx={{ marginX: { sm: 4, xl: 10 } }}
      >
        {/* Tarjetas */}
        <Grid
          container
          spacing={1.2}
          sx={{ height: "auto", alignItems: "stretch" }}
        >
          <Grid
            size={12}
            sx={{
              minHeight: 67,
              borderRadius: "18px",
              boxShadow: "0px 20px 27px 0px #0000000D",
              padding: { xs: "10px 20px", md: "10px 40px" },
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: 15,
                  color: colors.slateGray,
                }}
                lineHeight={1}
              >
                {t("confirmLoan.requestedValue")}
              </Typography>

              <Typography
                sx={{
                  fontSize: 20,
                  color: colors.primary,
                  marginTop: "2px",
                  fontWeight: 600,
                }}
                lineHeight={1}
              >
                $
                {formatNumberPoint(parseInt(simulationData?.c200_valor ?? "0"))}
              </Typography>
            </Box>
          </Grid>
          <Grid
            size={12}
            sx={{
              background: colors.white,
              minHeight: 67,
              borderRadius: "18px",
              boxShadow: "0px 20px 27px 0px #0000000D",
              padding: { xs: "10px 20px", md: "10px 40px" },
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: 15,
                  color: colors.slateGray,
                }}
                lineHeight={1}
              >
                {t("confirmLoan.dueValue")}
              </Typography>

              <Typography
                sx={{
                  fontSize: 20,
                  color: colors.primary,
                  marginTop: "2px",
                  fontWeight: 600,
                }}
                lineHeight={1}
              >
                ${formatNumberPoint(parseInt(simulationData?.due_value ?? "0"))}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {/* Importante */}
        <Box my={5}>
          <Typography
            sx={{
              fontSize: 16,
              color: colors.primary,
              fontWeight: "bold",
            }}
            lineHeight={1}
          >
            * {t("confirmLoan.important")}
          </Typography>
          <Typography
            sx={{
              fontSize: 15,
            }}
            mt={0.5}
            lineHeight={1}
          >
            {t("confirmLoan.importantDesc")}
          </Typography>
        </Box>
        <Divider />
        {/* Metodos */}
        <Box my={2.5}>
          <Box>
            <Typography
              sx={{
                fontSize: 15,
                color: colors.slateGray,
              }}
              lineHeight={1}
            >
              {t("simulator.paymentMethod")}
            </Typography>

            <Typography
              sx={{
                fontSize: 17,
                color: colors.black,
                marginTop: "3px",
                fontWeight: 600,
              }}
              lineHeight={1}
            >
              {getPeriodPayDesc(simulationData.c200_rowid_periodo)}
            </Typography>
          </Box>
          <Box mt={2.5}>
            <Typography
              sx={{
                fontSize: 15,
                color: colors.slateGray,
              }}
              lineHeight={1}
            >
              {t("confirmLoan.duesToPay")}
            </Typography>

            <Typography
              sx={{
                fontSize: 17,
                color: colors.black,
                marginTop: "3px",
                fontWeight: 600,
              }}
              lineHeight={1}
            >
              {simulationData?.c200_plazo ?? "n/a"}
            </Typography>
          </Box>
          <Box mt={2.5}>
            <Typography
              sx={{
                fontSize: 15,
                color: colors.slateGray,
              }}
              lineHeight={1}
            >
              {t("confirmLoan.debtValue")}
            </Typography>

            <Typography
              sx={{
                fontSize: 17,
                color: colors.black,
                marginTop: "3px",
                fontWeight: 600,
              }}
              lineHeight={1}
            >
              $
              {formatNumberPoint(
                parseInt(simulationData?.total_due_to_pay ?? "0")
              )}
            </Typography>
          </Box>
          <Box mt={2.5}>
            <Typography
              sx={{
                fontSize: 15,
                color: colors.slateGray,
              }}
              lineHeight={1}
            >
              {t("confirmLoan.nextPaymentDate")}
            </Typography>

            <Typography
              sx={{
                fontSize: 17,
                color: colors.primary,
                marginTop: "3px",
                fontWeight: 600,
              }}
              lineHeight={1}
            >
              {formatDateWithCapitalizedMonth(
                simulationData?.date_first_due ?? new Date().toISOString()
              )}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          marginTop={5}
        >
          <ColorButton
            sx={{
              width: "fit-content",
              padding: "15px 40px",
              fontSize: 16,
            }}
            onClick={handleRequest}
          >
            {t("simulator.request")}
          </ColorButton>
          <ColorButton
            sx={{
              width: "fit-content",
              padding: "10px 25px",
              fontSize: 14,
              mt: 2,
              backgroundColor: colors.disabled,
              color: colors.white,
              borderColor: colors.disabled,
            }}
            onClick={handleGoBack}
          >
            {t("common.goBack")}
          </ColorButton>
        </Box>
      </Box>
    </>
  );
}
