import { Step, StepProps, styled } from "@mui/material";
import { colors } from "../styles/theme.tsx";
import { min } from "moment";

interface CustomStepProps extends StepProps {
  isBlackTheme: boolean;
}

export const CustomStepH = styled(Step)<CustomStepProps>(
  ({ isBlackTheme }) => ({
    "& .MuiStepLabel-iconContainer .MuiSvgIcon-root": {
      color: colors.blueGray,
      zIndex: 2,
    },
    "& .MuiStepLabel-iconContainer .Mui-active": {
      color: colors.primary,
    },
    "& .MuiStepLabel-iconContainer .Mui-completed": {
      color: isBlackTheme ? colors.white : colors.primary,
    },
    "& .MuiStepLabel-labelContainer .MuiStepLabel-label": {
      marginTop: 10,
      color: isBlackTheme ? colors.white : "inherit",
    },
    "& .MuiStepLabel-labelContainer .Mui-active": {
      color: isBlackTheme ? colors.white : colors.primary,
      fontWeight: "bold",
    },
    "& .MuiStepConnector-root": {
      zIndex: 1,
    },
  })
);

export const CustomStepV = styled(Step)<CustomStepProps>(
  ({ isBlackTheme }) => ({
    "& .MuiStepLabel-iconContainer .MuiSvgIcon-root": {
      color: colors.blueGray,
      width: 25,
      height: 25,
      zIndex: 2,
    },
    "& .MuiStepLabel-iconContainer .Mui-active": {
      color: colors.primary,
    },
    "& .MuiStepLabel-iconContainer .Mui-completed": {
      color: colors.primary,
    },
    "& .MuiStepLabel-labelContainer .Mui-active": {
      color: colors.primary,
      fontWeight: "bold",
    },
    "& .MuiStepLabel-labelContainer .MuiStepLabel-label": {
      color: isBlackTheme ? colors.white : "inherit",
    },
    "& .MuiStepConnector-root": {
      top: 25,
      zIndex: 1,
    },
  })
);
