import React from "react";
import { navigationModals } from "../../../../constants/types.tsx";
import { useTranslation } from "react-i18next";
import { useUpdatePasoRenovacion } from "../../../../common/CommonFunctions.tsx";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../../styles/theme.tsx";
import { ColorButton } from "../../../../components/Button.tsx";
import { AuthContext } from "../../../../hooks/useAuth.tsx";
import { ModalDashboard } from "../../../../common/alerts.tsx";
import RenovationHousePanning from "./RenovationHousePanning.tsx";
import CalendarVc from "../../../User/RegisterSubComponents/CalendarVc.tsx";
import { handleCancelVc } from "../../../User/RegisterSubComponents/NoResponse.tsx";
import { IRegisterStep } from "../../../User/RegisterComponent.tsx";
import { getValueStorage } from "../../../../common/storage.ts";

export default function RenovationVideoError({
  setIsLoading,
  setRegisterStep,
}: navigationModals) {
  const { t } = useTranslation();
  const { updatePasoRenovacion, parseRenovacion } = useUpdatePasoRenovacion();
  const { getUser } = React.useContext(AuthContext);
  const registerStep: IRegisterStep = getValueStorage("registerStep");

  //Tomar videocasa de nuevo
  const handleVideoAgain = async () => {
    setIsLoading(true);
    await updatePasoRenovacion(parseRenovacion().paso, setIsLoading, "0", "0");
    if (setRegisterStep) {
      setRegisterStep({
        prevStep: 37,
        nextStep: 32,
        desc: "pasa a actualizar el video de la casa",
      });
    } else {
      ModalDashboard({
        element: (
          <RenovationHousePanning
            setIsLoading={setIsLoading}
            setRegisterStep={setRegisterStep}
          />
        ),
        widthParam: "480px",
      });
    }
  };

  //Reagendar videollamada
  const handleReschedule = () => {
    setIsLoading(true);
    if (setRegisterStep) {
      setRegisterStep({
        prevStep: 37,
        nextStep: 14,
        desc: "pasa al calendario",
      });
    } else {
      ModalDashboard({
        element: (
          <CalendarVc
            setIsLoading={setIsLoading}
            setRegisterStep={setRegisterStep}
          />
        ),
        widthParam: "480px",
      });
    }
  };

  return (
    <Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography
          sx={{
            fontSize: 28,
            color: colors.primary,
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {t("renovationVideoError.title")}
        </Typography>
        <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
          {t("renovationVideoError.subtitle")}
        </Typography>
        <Box sx={{ width: 300 }}>
          <img
            src={require("../../assets/images/ErrorVc.png")}
            alt={""}
            loading="lazy"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      </Box>
      <Box sx={{ textAlign: "center", marginY: 5 }}>
        <Typography sx={{ fontSize: 15 }}>
          {t("renovationVideoError.uploaded")}
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: 30,
            color: colors.primary,
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {t("renovationVideoError.afterUploaded")}
        </Typography>
        <Typography sx={{ fontWeight: "bold", fontSize: 16 }}>
          {t("renovationVideoError.endUploaded")}
        </Typography>
      </Box>
      <Box marginTop={8}>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: 16,
            color: colors.primary,
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {t("renovationVideoError.howCorrect")}
        </Typography>
        <Typography sx={{ fontSize: 14 }}>
          {t("renovationVideoError.makeSure")}{" "}
          <strong>{t("renovationVideoError.afeterMakeSure")}</strong>{" "}
          {t("renovationVideoError.endAfeterMakeSure")}
        </Typography>
      </Box>
      <Box sx={{ textAlign: "center", marginTop: 5 }}>
        <Typography
          sx={{
            fontSize: 16,
            color: colors.primary,
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {t("renovationVideoError.twoOptions")}
        </Typography>
        <Typography sx={{ fontSize: 14 }}>
          {t("renovationVideoError.subTitleTwoOptions")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 5,
        }}
      >
        <ColorButton onClick={handleVideoAgain} disabled={getUser.isLoading}>
          {t("renovationVideoError.videoAgain")}
        </ColorButton>
        <Box sx={{ marginTop: 5 }}>
          <Typography sx={{ fontSize: 14 }}>
            {t("renovationVideoError.noLonger")}{" "}
            <strong>{t("renovationVideoError.subNoLonger")}</strong>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            marginTop: 3,
          }}
        >
          <ColorButton onClick={handleReschedule} disabled={getUser.isLoading}>
            {t("calendar.scheduleV")}
          </ColorButton>
          <ColorButton
            onClick={() =>
              handleCancelVc({
                prevStep: registerStep && registerStep.nextStep,
                desc: "Cancelar solicitud desde RenovationVideoError",
                setRegisterStep,
                setIsLoading,
              })
            }
            disabled={getUser.isLoading}
          >
            {t("common.cancelRequest")}
          </ColorButton>
        </Box>
      </Box>
    </Box>
  );
}
