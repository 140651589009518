import * as Yup from "yup";

export const yupRenovationFinancialInformation = Yup.object().shape({
  laboral_status: Yup.string().required("Selecciona una opción"),
  occupation: Yup.string()
    /* .required("Selecciona una opción")
    .notOneOf([null, undefined], "Selecciona una opción válida") */
    .when("laboral_status", (laboral_status, schema) => {
      return ["3", "4", "5", "6"].includes(laboral_status + "")
        ? schema.required("Selecciona una opción")
        : schema.notRequired();
    }),
  company: Yup.string().when("laboral_status", (laboral_status, schema) => {
    return ["3", "5", "6"].includes(laboral_status + "")
      ? schema
          .required("El campo es obligatorio")
          .matches(
            /^(?! )[a-zA-Z0-9#\-.,/()]+(?: [a-zA-Z0-9#\-.,/()]+)*$/,
            "Valida los caracteres y espacios"
          )
          .test(
            "no-prueba-admin",
            "El campo no puede contener 'prueba' o 'admin'",
            (value) => {
              return (
                !value ||
                !value
                  .split(" ")
                  .some(
                    (word) =>
                      word.toLowerCase() === "prueba" ||
                      word.toLowerCase() === "admin"
                  )
              );
            }
          )
      : schema.notRequired();
  }),
  company_address: Yup.string().when(
    "laboral_status",
    (laboral_status, schema) => {
      return ["3", "5", "6"].includes(laboral_status + "")
        ? schema
            .required("El campo es obligatorio")
            .matches(
              /^(?! )[a-zA-Z0-9#\-.,/()]+(?: [a-zA-Z0-9#\-.,/()]+)*$/,
              "Formato de dirección inválida"
            )
            .test(
              "no-prueba-admin",
              "El campo no puede contener 'prueba' o 'admin'",

              (value) => {
                return (
                  !value ||
                  !value
                    .split(" ")
                    .some(
                      (word) =>
                        word.toLowerCase() === "prueba" ||
                        word.toLowerCase() === "admin"
                    )
                );
              }
            )
        : schema.notRequired();
    }
  ),
  company_phone: Yup.string().when(
    "laboral_status",
    (laboral_status, schema) => {
      return ["3", "5", "6"].includes(laboral_status + "")
        ? schema
            .required("El número de teléfono es obligatorio")
            .matches(/^[0-9]*$/, "Solo se permiten números")
            .min(7, "Número de teléfono debe ser minimo 7 digitos")
        : schema.notRequired();
    }
  ),
  charge: Yup.string().when("laboral_status", (laboral_status, schema) => {
    return ["3", "5", "6"].includes(laboral_status + "")
      ? schema
          .min(3, "Minimo 3 caracteres")
          .matches(
            /^(?! )[a-zA-Z]+(?: [a-zA-Z]+)*$/,
            "Solo se permiten letras y sin espacios al final"
          )
          .test(
            "no-prueba-admin",
            "El campo no puede contener 'prueba' o 'admin'",
            (value) => {
              return (
                !value ||
                !value
                  .split(" ")
                  .some(
                    (word) =>
                      word.toLowerCase() === "prueba" ||
                      word.toLowerCase() === "admin"
                  )
              );
            }
          )
          .required("El campo es obligatorio")
      : schema.notRequired();
  }),
  period_pay: Yup.string().when("laboral_status", (laboral_status, schema) => {
    return ["3", "5", "6", "9"].includes(laboral_status + "")
      ? schema.required("Selecciona una opción")
      : schema.notRequired();
  }),
});
