import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../styles/theme.tsx";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import {
  isWithin15Days,
  requestSquematic,
} from "../common/CommonFunctions.tsx";
import { AuthContext } from "../hooks/useAuth.tsx";
import { getValueStorage, saveValueStorage } from "../common/storage.ts";
import i18n from "../constants/translations/i18n.js";
import { useLocation, useNavigate } from "react-router-dom";
import { errorAlert, Modal, MySwal } from "../common/alerts.tsx";
import Rejected from "./User/RegisterSubComponents/Rejected.tsx";
import RenovationApplyNow from "./Dashboard/pages/Renovations/RenovationApplyNow.tsx";
import { IUserAuth, navigationModals } from "../constants/types.tsx";
import { IRegisterStep } from "./User/RegisterComponent.tsx";

export default function LoadingRequest({
  setRegisterStep,
  setIsLoading,
}: navigationModals) {
  const { t } = useTranslation();

  const authSession: IUserAuth = getValueStorage("authSession");

  React.useEffect(() => {
    // Función para manejar el evento de recarga o cierre
    const handleBeforeUnload = (event) => {
      // Evita la acción predeterminada
      event.preventDefault();
      event.returnValue = "";
    };

    // Agrega el listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Limpia el listener al desmontar el componente
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleValidateConfigUser = async () => {
    const res = await requestSquematic(
      "GET",
      "/api/app/user/validate_user_for_reject",
      {},
      authSession.token
    );

    if (res && res.results) {
      if (res.results.cumple !== "Si") {
        if (authSession.dashboard) {
          MySwal.close();
          Modal(<Rejected />, {
            title: "",
            width: "auto",
            showCloseButton: true,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
              popup: "bg-snow dark:bg-navy-800 px-0 !w-max sm:!w-[380px]",
              htmlContainer: "!px-5 !overflow-x-hidden",
            },
            didClose: () => {
              window.location.reload();
            },
          });
        } else {
          let objectStep: IRegisterStep = {
            prevStep: 12,
            nextStep: 20,
            desc: "el sistema rechazo el credito",
          };
          saveValueStorage("registerStep", objectStep);
          if (setRegisterStep) setRegisterStep(objectStep);
        }
      } else {
        // Tiene un credito finalizado?
        if (authSession.loanEmpty && !authSession.dashboard && authSession.isOld) {
          try {
            const objToSend = {
              c125_rowid_tercero: authSession.hasFinancial.c126_rowid_tercero,
            };

            const dataLocation = await requestSquematic(
              "POST",
              "/api/app/contact_details/get_address",
              objToSend,
              authSession.token
            );

            if (dataLocation) {
              const authSession: IUserAuth = getValueStorage("authSession");
              saveValueStorage("authSession", {
                ...authSession,
                inProcessRenovation: {
                  inProcessRenovation: false,
                  contactDetails: dataLocation.results,
                },
              });
              let objectStep: IRegisterStep = {
                prevStep: 12,
                nextStep: -1,
                desc: "",
              };
              if (isWithin15Days(authSession)) {
                if (
                  dataLocation.results.c125_descripcion_de_direccion &&
                  dataLocation.results.c125_direccion1 &&
                  dataLocation.results.c125_lat_barrio &&
                  dataLocation.results.c125_lon_barrio
                ) {
                  //Pregunta si vive en la misma direccion
                  objectStep.nextStep = 33;
                  objectStep.desc = "se envia a verificar la direccion";
                } else {
                  //Si esta dentro de los 15 dias pero le falta informacion de contacto lo OBLIGA a actualizarla
                  objectStep.nextStep = 34;
                  objectStep.desc =
                    "se envia a renovar la informacion de contacto";
                }
              } else {
                //Se envia a renovar la informacion de contacto OBLIGATORIAMENTE ya que no esta dentro de los 15 dias posteriores a finalizar el credito
                objectStep.nextStep = 34;
                objectStep.desc =
                  "se envia a renovar la informacion de contacto sin 15 dias";
              }

              if (objectStep.desc) {
                saveValueStorage("registerStep", objectStep);
                if (setRegisterStep) setRegisterStep(objectStep);
              }
            }
          } catch (error) {
            const errorMessage =
              error instanceof Error ? error.message : String(error);
            errorAlert("Error", errorMessage, "");
          }
        } else if (authSession.dashboard) {
          Modal(<RenovationApplyNow setIsLoading={setIsLoading} />, {
            title: "",
            width: "auto",
            showCloseButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
              popup: "bg-snow dark:bg-navy-800 px-0 !w-max sm:!w-[380px]",
              htmlContainer: "!px-3 !overflow-x-hidden",
            },
          });
        } else {
          let objectStep: IRegisterStep = {
            prevStep: 12,
            nextStep: 13,
            desc: "el usuario paso el analisis de datos",
          };
          saveValueStorage("registerStep", objectStep);
          if (setRegisterStep) setRegisterStep(objectStep);
        }
      }
    }
  };

  React.useEffect(() => {
  setIsLoading(false)
    setTimeout(() => {
      handleValidateConfigUser();
    }, 1000);
  }, []);


  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Box sx={{ width: { xs: 250, sm: 350 }, height: { xs: 250, sm: 350 } }}>
          <Lottie
            style={{
              width: "100%",
              height: "100%",
            }}
            loop
            autoPlay
            animationData={require("../assets/animations/relojArena.json")}
          />
        </Box>

        {/* Analizando datos */}
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: { xs: 23, sm: 30 },
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
            marginTop: -5,
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("analizing.title")}
        </Typography>

        <Typography
          sx={{
            color: colors.dimGray,
            textTransform: "uppercase",
            fontSize: { xs: 13, sm: 17 },
            textAlign: "center",
            fontWeight: 500,
            marginTop: -0.5,
          }}
          lineHeight={1}
        >
          {t("analizing.desc").split("-")[0]}
          <strong>{t("analizing.desc").split("-")[1]}</strong>
        </Typography>
      </Box>

      {/* Espera */}
      <Box mt={15} mb={5} textAlign={"center"}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: { xs: 23, sm: 30 },
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
            marginTop: -5,
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("analizing.wait")}
        </Typography>

        <Typography
          sx={{
            color: colors.dimGray,
            textTransform: "uppercase",
            fontSize: { xs: 13, sm: 17 },
            textAlign: "center",
            fontWeight: 500,
            marginTop: -0.5,
          }}
          lineHeight={1}
        >
          {t("analizing.oneMoment")}
        </Typography>
      </Box>
    </>
  );
}
