import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import { ColorButton } from "../../../components/Button.tsx";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import { isWithin15Days, requestSquematic, useUpdatePasoRenovacion } from "../../../common/CommonFunctions.tsx";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getValueStorage, saveValueStorage } from "../../../common/storage.ts";
import { IRegisterStep, IRegisterStepState } from "../RegisterComponent.tsx";
import { errorAlert } from "../../../common/alerts.tsx";
import renovationStep from "../../Dashboard/services/RenovationStep.tsx";
import { IUserAuth } from "../../../constants/types.tsx";

export default function NoCredits({ setRegisterStep }: IRegisterStepState) {
  const { t } = useTranslation();
  const { getUser, setIsLoading } = React.useContext(AuthContext);
  const [stateCredit, setStateCredit] = React.useState(true);
  const { parseRenovacion } = useUpdatePasoRenovacion();
  const authSession: IUserAuth = getValueStorage("authSession");

  React.useEffect(() => {
    (async () => {
      console.log("authsession", authSession);
      if (authSession.loanRequest?.c200_rowid) {
        setStateCredit(false);
      }
    })();
  }, []);

  //Solicitar un nuevo credito
  const handleRequestNewCredit = () => {
    //Lo enviamos al paso 10 osea el simulador
    let objectStep: IRegisterStep = {
      prevStep: 30,
      nextStep: 10,
      desc: "pasa a solicitar un nuevo credito",
    };
    saveValueStorage("registerStep", objectStep);
    if (setRegisterStep) setRegisterStep(objectStep);
  }

  //Funcion para ver mas segun el estado de la solicitud de credito
  const handleShowMore = async () => {
    if (isWithin15Days(authSession)) {
      console.log("dentro de los 15 dias");
      // Esta dentro de los 15 dias
      setIsLoading(true);
      try {
        const res = await requestSquematic(
          "POST",
          "/api/app/contact_details/get_address",
          { c125_rowid_tercero: authSession.hasFinancial.c126_rowid_tercero },
          authSession.token
        );
        if (res && res.results) {
          if (res.results.c125_descripcion_de_direccion && res.results.c125_direccion1 && res.results.c125_lat_barrio && res.results.c125_lon_barrio) {
            console.log("dentro del response de get_address");
            saveValueStorage("authSession", {
              ...authSession,
              inProcessRenovation: { inProcessRenovation: false, contactDetails: res.results }
            });
            renovationStep({ setIsLoading, parseRenovacion, setRegisterStep });
          } else {
            //Lo enviamos al paso 34 osea renovationContactInformation
            let objectStep: IRegisterStep = {
              prevStep: 30,
              nextStep: 34,
              desc: "pasa a renovar la informacion de contacto",
            };
            saveValueStorage("registerStep", objectStep);
            if (setRegisterStep) setRegisterStep(objectStep);
          }
        }
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : String(error);
        errorAlert("Error", errorMessage, "Ok");
      } finally {
        setIsLoading(false);
      }
    } else {
      console.log("fuera de los 15 dias");
      // Esta afuera de los 15 dias
      if (parseRenovacion()) {
        renovationStep({ setIsLoading, parseRenovacion, setRegisterStep });
      } else {
        //Lo enviamos al paso 10 osea renovationContactInformation
        let objectStep: IRegisterStep = {
          prevStep: 30,
          nextStep: 34,
          desc: "pasa a renovar la informacion de contacto",
        };
        saveValueStorage("registerStep", objectStep);
        if (setRegisterStep) setRegisterStep(objectStep);
      }
    }
  }

  //Ver modal con la informacion de referidos
  const handleReferrals = () => {
    let objectStep: IRegisterStep = {
      prevStep: 30,
      nextStep: 31,
      desc: "pasa a ver los referidos",
    };
    saveValueStorage("registerStep", objectStep);
    if (setRegisterStep) setRegisterStep(objectStep);
  }

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("noCredits.title")}
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 18,
            color: colors.gray,
            textAlign: "center",
            textTransform: "uppercase",
          }}
          lineHeight={1}
        >
          {t("noCredits.vigency")}
        </Typography>
        <Box
          sx={{
            width: 300,
            mt: 5,
            marginBottom: 2,
          }}
        >
          <img
            src={require("../../../assets/image/WaitingTime.webp")}
            alt={""}
            loading="lazy"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      </Box>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        {
          stateCredit ?
            <>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: 25,
                  color: colors.primary,
                  textTransform: "uppercase",
                  mt: 4,
                  marginBottom: "5px",
                }}
                lineHeight={1}
                textAlign={"center"}
              >
                {t("noCredits.what")}
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: 16,
                  color: colors.black,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
                lineHeight={1}
              >
                {t("noCredits.have")}
              </Typography>
              <ColorButton
                onClick={handleRequestNewCredit}
                sx={{
                  width: 279,
                  marginTop: 3,
                  paddingY: 1,
                  borderRadius: 10
                }}
                disabled={getUser.isLoading}
              >
                {t("noCredits.request")}
              </ColorButton>
              <ColorButton
                onClick={handleReferrals}
                sx={{
                  width: 279,
                  marginTop: 1,
                  paddingY: 1
                }}
                disabled={getUser.isLoading}
              >
                {t("noCredits.refferal")}
              </ColorButton>
            </>
            :
            <Box sx={{
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
              width: "100%",
              padding: 2,
            }}>
              <Typography
                sx={{
                  textTransform: "uppercase",
                  fontSize: 10
                }}
              >
                {t("noCredits.info")}
              </Typography>
              <Typography
                sx={{
                  color: colors.primary,
                  textTransform: "uppercase",
                  fontWeight: "bold"
                }}
              >
                {t("noCredits.process")}
              </Typography>
              <hr style={{ marginTop: 15, marginBottom: 15 }} />
              <Typography
                sx={{
                  color: colors.disabled
                }}
              >{t("noCredits.processDesc").split("-")[0]}<br /><strong>{t("noCredits.processDesc").split("-")[1]}</strong></Typography>
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  onClick={handleShowMore}
                  endIcon={<ArrowForwardIosIcon />}
                  sx={{ color: colors.primary, textTransform: "none" }}
                >{t("noCredits.more")}</Button>
              </Box>
            </Box>
        }
      </Box>
    </>
  );
}