import { Box, Button, IconButton, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { colors } from "../../../../styles/theme.tsx";
import { VisuallyHiddenInput } from "../IdUpload.tsx";

export default function FormInputProfile({
  id,
  isLoading,
  setChanges,
  setValids,
}) {
  const { t } = useTranslation();
  const [tempPhoto, setTempPhoto] = React.useState<string>("");

  React.useEffect(() => {
    setValids((state: any) => ({ ...state, [id]: false }));
  }, []);

  const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let file = e.target.files[0];
      if (file) {
        setTempPhoto(URL.createObjectURL(file));
        const reader = new FileReader();
        reader.onloadend = () => {
          setChanges((state: any) => ({ ...state, [id]: reader.result }));
          setValids((state: any) => ({ ...state, [id]: true }));
          let element = document.getElementById(`errorM_${id}`);
          if (element) {
            element.style.display = "none";
          }
        };
        reader.readAsDataURL(file); // Lee el archivo como una URL de datos */
      }
    }
  };

  return (
    <Box>
      <Typography>{t("validation.profilePhotoInfo")}</Typography>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <img src={require("../../../../assets/image/Rostro.png")} width={200} height={200} />
      </Box>
      <Box>
        {/* BLOQUE PARA SUBIR LA FOTO Y PREVISUALIZARLA */}
        <label
          style={{
            fontSize: 13,
            fontWeight: 600,
            textWrap: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
          htmlFor={"cedulaF"}
        >
          {t("validation.profilePhoto")}
        </label>
        {tempPhoto ? (
          <Box sx={{ position: "relative", mt: 1 }}>
            <img
              src={tempPhoto}
              style={{ width: "100%", objectFit: "contain" }}
            />
            <IconButton
              aria-label="delete"
              size="large"
              onClick={() => {
                setTempPhoto("");
                setChanges((state: any) => ({ ...state, [id]: "" }));
                setValids((state: any) => ({ ...state, [id]: false }));
              }}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                color: colors.white,
                backgroundColor: colors.primary,
                borderRadius: 0,
                "&:hover": {
                  border: `1px solid ${colors.primary}`,
                  backgroundColor: colors.lightGray,
                  "& .MuiSvgIcon-root": {
                    color: colors.primary,
                  },
                },
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ) : (
          <Button
            sx={{
              background: colors.primary,
              color: colors.white,
              fontSize: 16,
              mt: 1,
              lineHeight: 1.2,
              padding: 2,
              width: "100%",
            }}
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            {t("validation.profilePhotoButton")}
            <VisuallyHiddenInput
              type="file"
              onChange={(e) => handleChangeImage(e)}
              accept="image/*"
            />
          </Button>
        )}
      </Box>
      <Typography id={`errorM_${id}`} sx={{ color: "red", fontSize: 13, marginTop: 1, fontWeight: "bold", display: "none" }}></Typography>
    </Box>
  );
};