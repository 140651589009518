import { Button, ButtonProps, styled } from "@mui/material";
import { colors } from "../styles/theme.tsx";

interface ColorButtonProps extends ButtonProps {
  background?: string; // Color del background
  hoverBackground?: string; // Color del background hover
  hoverColor?: string; // Color del texto hover
}

export const ColorButton = styled(Button)<ColorButtonProps>(
  ({ hoverBackground, hoverColor, background }) => ({
    color: colors.white,
    backgroundColor: background ? background : colors.primary,
    border: `1px solid ${hoverColor ? hoverColor : background ? background : colors.primary}`,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: hoverBackground ? hoverBackground : colors.white,
      color: hoverColor ? hoverColor : colors.primary,
    },
    "&:disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
      color: colors.white,
    },
    borderRadius: 19,
    width: 245,
    fontWeight: 700,
    margin: "0 auto",
  })
);

export const ClearButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: colors.black,
  backgroundColor: colors.white,
  border: "none",
  cursor: "pointer",
  borderRadius: 19,
  width: "fit-content",
  margin: "0 auto",
  textTransform: "unset",
  padding:"5px 16px",
  "&:hover": {},
}));
