import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Dropdown from "./Dropdown.tsx";
import { useDarkMode } from "../hooks/DarkModeProvider.js";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import {
  DOMAIN,
  formatNumberPoint,
  getPercentCredit,
  requestSquematic,
  useUpdatePasoRenovacion,
} from "../../../common/CommonFunctions.tsx";
import {
  getValueStorage,
  saveValueStorage,
  wipeValueStorage,
} from "../../../common/storage.ts";
import i18n from "../../../constants/translations/i18n.js";
import { DinamicRoutes } from "./SidebarDashboard.tsx";
import {
  BedtimeIcon,
  ChecklistIcon,
  CurrencyExchangeIcon,
  DehazeIcon,
  NotificationsIcon,
  WbSunnyIcon,
} from "../assets/iconsMui.tsx";
import { ModalDashboard } from "../../../common/alerts.tsx";
import {
  IFinantialInformation_selects,
  IGetFinancialDataByThird,
  IHomeData_result,
  PropItemsLoanInit,
} from "../../../constants/types.tsx";
import { mappingLoanData_Init } from "../mapping/initMapping.tsx";
import { Renovation } from "../pages/Renovations/Renovation.tsx";
import { useTranslation } from "react-i18next";
import renovationStep from "../services/RenovationStep.tsx";
import { ModalCond } from "./ModalCond.tsx";
import { ModalCong } from "./ModalCong.tsx";
import { HasOfferInitial } from "../pages/Reoffer/HasOfferInitial.tsx";
import Waiting from "../../User/RegisterSubComponents/Waiting.tsx";

type NotificationProps = {
  id: number;
  icon: React.ReactNode;
  title: string;
  description: string;
  action?: () => void | null;
  info?: boolean; // Indicador si la notificación es informativa, y si se le pasa "true" entonces no se elimina, no se borra y no realiza la accion
};

// ID incremental para las notificaciones
let globalNotificationId = 0;

const generateNotificationId = () => {
  globalNotificationId += 1;
  return globalNotificationId;
}; // Para generar IDs únicos para las notificaciones:  const Id = generateNotificationId(); y pasarle el Id a la función addNotification y al Modal

export default function HeaderDashboard({
  onOpenSidenav,
  headertohome,
  removeFunction,
}) {
  const location = useLocation();
  const navigation = useNavigate();
  const routes = DinamicRoutes();
  const { parseRenovacion } = useUpdatePasoRenovacion();
  const { t } = useTranslation();
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  const { getUser, signOut, setIsLoading, signIn } =
    React.useContext(AuthContext);
  const [brandText, setBrandText] = React.useState<string>("");
  const [loan, setLoan] = React.useState<PropItemsLoanInit[]>([]);
  const [totalDate, setTotalDate] = React.useState<string>("-1");
  // Estado para las notificaciones
  const [notifications, setNotifications] = React.useState<NotificationProps[]>(
    []
  );
  // Estado para controlar si el Dropdown de notificaciones está abierto o cerrado
  const [isOpen, setIsOpen] = React.useState(false);

  const getUserTemp = getValueStorage("authSession");

  // Función para agregar una notificación
  const addNotification = ({
    id,
    icon,
    title,
    description,
    action = () => null,
    info = false,
  }: NotificationProps) => {
    const newNotification = {
      id,
      icon,
      title,
      description,
      action,
      info,
    };

    setNotifications((prevNotifications) => [
      ...prevNotifications,
      newNotification,
    ]);
  };

  // Función para eliminar una notificación
  const removeNotification = (id) => {
    setNotifications((prev) => prev.filter((notif) => notif.id !== id));
  };

  const fetchData = React.useCallback(async () => {
    setIsLoading(true);
    wipeValueStorage("updatedPendingLoans");
    const res: [
      IHomeData_result,
      IGetFinancialDataByThird,
      IFinantialInformation_selects
    ] = await Promise.all([
      requestSquematic(
        "GET",
        "/api/app/loan/loan_data",
        {},
        getUser.token,
        signOut
      ),
      requestSquematic(
        "GET",
        "/api/app/financial_data/get_financial_data_by_third",
        {},
        getUser.token,
        signOut
      ),
      requestSquematic(
        "GET",
        "/api/app/financial_data/get_selects",
        {},
        getUser.token,
        signOut
      ),
    ]);

    if (res) {
      const [IHomeData_result, IGetFinancialDataByThird] = res;

      const { loans, pie_diagram, pending_loans, contactDetails } =
        IHomeData_result.results!;

      const { loanLocal, total } = mappingLoanData_Init(loans, pie_diagram, t);

      const updatedPendingLoans = pending_loans.map((pending_loan) => ({
        ...pending_loan,
        hasFather: loans.some(
          (loan) => loan.c200_rowid_nuevo_prestamo === pending_loan.c200_rowid
        ),
      }));

      saveValueStorage("updatedPendingLoans", updatedPendingLoans);

      // Se crea el estado de inProcessRenovation para saber si esta en proceso de renovacion
      signIn({
        ...getUser,
        inProcessRenovation: {
          contactDetails: contactDetails,
          inProcessRenovation: updatedPendingLoans.length === 1,
        },
        financialData: {
          c126_rowid_ocupacion:
            IGetFinancialDataByThird.results!.c126_rowid_ocupacion,
          c126_cargo: IGetFinancialDataByThird.results!.c126_cargo,
          c126_empresa: IGetFinancialDataByThird.results!.c126_empresa,
          c126_periodo_pago:
            IGetFinancialDataByThird.results!.c126_periodo_pago,
          c126_direccion_trabajo:
            IGetFinancialDataByThird.results!.c126_direccion_trabajo,
          c126_rowid_estadolaboral:
            IGetFinancialDataByThird.results!.c126_rowid_estadolaboral,
          c126_num_empresa: IGetFinancialDataByThird.results!.c126_num_empresa,
        },
        loanEmpty:
          loan.length === 0 && pending_loans.length === 0 && totalDate !== "-1",
        isOld:
          loan.length === 0 && pending_loans.length === 0 && totalDate !== "-1",
      });

      setLoan(loanLocal);

      saveValueStorage("loans", loans.length > 0 ? loans[0] : {});
      saveValueStorage("loan", loanLocal.length > 0 ? loanLocal[0] : {});

      setTotalDate(formatNumberPoint(total));
      setIsLoading(false);

      const readyToRenovate: typeof loanLocal = [];
      const condonado: typeof loanLocal = [];
      const congelado: typeof loanLocal = [];

      loanLocal.map((loan) => {
        if (loan.c200_ind_estado === "5") {
          const rest = {
            value_to_paid: loan.totalCredit,
            loanId: loan.c200_rowid,
            personType: {
              id: loan.industrial_classification.c017_rowid,
              name: loan.industrial_classification.c017_descripcion,
            },
            creditLine: loan.c200_rowid_concepto,
            amount: parseInt(loan.monto),
            dues: parseInt(loan.c200_plazo),
            period: parseInt(loan.c200_rowid_periodo),
            valPerDue: parseInt(loan.fullDue),
            isRenovation: true,
            periodDescription: loan.description,
            c200_fecha_creacion: loan.c200_fecha_creacion,
            payment_plan_penalites: loan.payment_plan_penalites,
            banking_transaction: loan.banking_transaction,
            c200_ind_estado: loan.c200_ind_estado,
            minDue: loan.minDue,
            penalties_to_pay: loan.penalties_to_pay,
          };

          const { percentVal } = getPercentCredit(rest, 70);

          if (loan.condonado !== null) {
            condonado.push(loan);
          } else if (loan.congelado !== null) {
            congelado.push(loan);
          } else if (percentVal >= 70) {
            readyToRenovate.push(loan);
          }
        }
      });

      let notiExist = false;

      if (
        condonado.length !== 0 &&
        condonado[0].condonado?.indefinido === "0"
      ) {
        const Id = generateNotificationId();
        ModalDashboard({
          element: <ModalCond condonado={condonado[0]?.condonado} />,
          widthParam: "450px",
        });

        addNotification({
          id: Id,
          icon: <CurrencyExchangeIcon />,
          title: "Tu crédito se encuentra condonado",
          description:
            "Fecha de vencimiento: " +
            (condonado[0] ? condonado[0].condonado?.fecha_limite_pago : ""),
          action: () =>
            ModalDashboard({
              element: <ModalCond condonado={condonado[0]?.condonado} />,
              widthParam: "450px",
            }),
        });
      } else if (
        congelado.length !== 0 &&
        congelado[0].congelado?.indefinido === "0"
      ) {
        const Id = generateNotificationId();
        ModalDashboard({
          element: <ModalCong congelado={congelado[0]?.congelado} />,
          widthParam: "450px",
        });

        addNotification({
          id: Id,
          icon: <CurrencyExchangeIcon />,
          title: "Tu credito se encuentra congelado",
          description:
            "Fecha de vencimiento: " +
            (congelado[0] ? congelado[0].condonado?.fecha_limite_pago : ""),
          action: () =>
            ModalDashboard({
              element: <ModalCong congelado={congelado[0]?.congelado} />,
              widthParam: "450px",
            }),
        });
      } else if (getUserTemp.hasOfferedAgain) {
        ModalDashboard({
          element: <HasOfferInitial setIsLoading={setIsLoading} />,
          close: false,
        });
      } else if (readyToRenovate.length !== 0) {
        // Se habilita modal para solicitar renovacion
        notiExist = true;
        saveValueStorage("dataLoans", { loans: readyToRenovate });
        const Id = generateNotificationId();

        // Modal para mostrar los modales de parseRenovacion, cuando el usuario cierra cualquier modal de renovacion
        const renovationStepModal = () => {
          // Cerrar modal de renovacion habilitada cuando confirma hacer la renovacion
          removeNotification(Id);

          const IdReno = generateNotificationId();

          // Crear modal de parseRenovacion
          addNotification({
            id: IdReno,
            icon: <ChecklistIcon />,
            title: t("headerDashboard.complete"),
            description: t("headerDashboard.forContinue"),
            action: () => {
              let dinamicElement = renovationStep({
                setIsLoading: setIsLoading,
                parseRenovacion: parseRenovacion,
              });

              if (dinamicElement) {
                ModalDashboard({ element: dinamicElement });
              }
            },
          });

          // Eliminar la función de renovación, para desabilitar boton renovación del home
          removeFunction("renovation");
        };

        // Agregar la función de renovación en el array del padre
        headertohome("renovation", renovationStepModal);

        ModalDashboard({
          element: (
            <Renovation
              onClose={() => renovationStepModal()}
              setIsLoading={setIsLoading}
            />
          ),
        });

        addNotification({
          id: Id,
          icon: <CurrencyExchangeIcon />,
          title: t("headerDashboard.renovation"),
          description: t("headerDashboard.enabled"),
          action: () => {
            ModalDashboard({
              element: (
                <Renovation
                  onClose={() => renovationStepModal()}
                  setIsLoading={setIsLoading}
                />
              ),
            });
          },
        });
      } else if (
        (updatedPendingLoans.length !== 0 &&
          updatedPendingLoans[0].hasFather) ||
        (updatedPendingLoans.length !== 0 &&
          !updatedPendingLoans[0].hasFather &&
          updatedPendingLoans[0].c200_ind_estado + "" === "1")
      ) {
        notiExist = true;
        // Ya se solicito renovacion
        const Id = generateNotificationId();
        let dinamicElement = renovationStep({
          onClose: () => removeNotification(Id),
          setIsLoading: setIsLoading,
          parseRenovacion: parseRenovacion,
        });

        if (dinamicElement) {
          ModalDashboard({ element: dinamicElement });
        }

        addNotification({
          id: Id,
          icon: <ChecklistIcon />,
          title: t("headerDashboard.complete"),
          description: t("headerDashboard.forContinue"),
          action: () => {
            let dinamicElement = renovationStep({
              onClose: () => removeNotification(Id),
              setIsLoading: setIsLoading,
              parseRenovacion: parseRenovacion,
            });

            if (dinamicElement) {
              ModalDashboard({ element: dinamicElement });
            }
          },
        });
      }

      if (
        updatedPendingLoans.length !== 0 &&
        !updatedPendingLoans[0].hasFather &&
        !notiExist
      ) {
        // El usuario acepto la reoferta de la renovacion
        const Id = generateNotificationId();

        const openModal = () => {
          ModalDashboard({
            element: <Waiting setIsLoading={setIsLoading} />,
            widthParam: "480px",
            close: true,
          });
        };

        addNotification({
          id: Id,
          icon: <ChecklistIcon />,
          title: t("headerDashboard.stateRequest"),
          description: "",
          action: () => openModal(),
        });
      }
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  React.useEffect(() => {
    routes.map((route, index) => {
      if (location.pathname === route.path) {
        setBrandText(route.name);
      }
    });
  }, [location.pathname]);

  const handleDarkMode = () => {
    let dashboardConfig = getValueStorage("dashboardConfig");
    if (isDarkMode) {
      saveValueStorage("dashboardConfig", {
        ...dashboardConfig,
        isDarkMode: false,
      });
      document.body.classList.remove("dark");
      toggleDarkMode(false);
    } else {
      saveValueStorage("dashboardConfig", {
        ...dashboardConfig,
        isDarkMode: true,
      });
      document.body.classList.add("dark");
      toggleDarkMode(true);
    }
  };

  //Cerrar sesion
  const handleSignOut = () => {
    setIsLoading(true);

    navigation("/");
    signOut();

    // Remover modo oscuro
    document.body.classList.toggle("dark", false);
  };

  //Cambiar el idioma de la pagina
  const handleToggleLanguage = (target: any) => {
    i18n.changeLanguage(target.checked ? "en" : "es");
    routes.map((route, index) => {
      if (location.pathname === route.path) {
        setBrandText(t(route.labelLanguage));
      }
    });
  };

  return (
    <nav className="sticky top-4 z-40  rounded-xl bg-white/10 p-2 dark:bg-[#0b14374d]">
      <div className="absolute inset-0 z-0 bg-black/30 backdrop-blur-xl rounded-xl"></div>
      <div className="relative z-10 flex flex-row flex-wrap items-center justify-between">
        <div className="ml-[6px] mr-4">
          <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
            <Link
              to="#"
              className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
            >
              {brandText}
            </Link>
          </p>
        </div>

        <div className="relative flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[290px] xl:gap-2">
          <span
            className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
            onClick={onOpenSidenav}
          >
            <DehazeIcon className="h-5 w-5" />
          </span>
          {/* start Notification */}
          <Dropdown
            button={
              <div className="relative">
                <NotificationsIcon className="h-4 w-4 text-gray-600 dark:text-white" />
                {notifications.length > 0 && (
                  <span className="flex absolute h-2 w-2 top-0 right-0">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-600 opacity-75" />
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-red-600" />
                  </span>
                )}
              </div>
            }
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            animation="origin-[28%_0%] sm:origin-[40%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
            items={
              <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
                <div className="flex items-center justify-between">
                  <p className="text-base font-bold text-navy-700 dark:text-white">
                    {t("headerDashboard.notifications")}
                  </p>
                  {/* <p className="text-sm font-bold text-navy-700 dark:text-white">
                      Mark all read
                    </p> */}
                </div>
                {notifications.length === 0 ? (
                  <p className="text-sm text-gray-500">
                    {t("headerDashboard.emptyNotifications")}
                  </p>
                ) : (
                  <div className="max-h-96 overflow-y-auto">
                    {notifications.map((notif) => (
                      <button
                        key={notif.id}
                        className={`flex w-full my-2 items-center ${
                          notif.info ? "cursor-default" : "cursor-pointer"
                        }`}
                        onClick={() => {
                          // Si la notificación no es informativa, se ejecuta la acción y se elimina
                          if (!notif.info && notif.action) {
                            notif.action();
                            setIsOpen(false);
                          }
                        }}
                      >
                        <div className="flex h-10 w-14 items-center justify-center rounded-xl bg-primary dark:bg-secondary-600 py-4 text-2xl text-white dark:text-white">
                          {notif.icon}
                        </div>
                        <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                          <p className=" text-left text-base font-bold text-gray-900 dark:text-secondary-600">
                            {notif.title}
                          </p>
                          <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                            {notif.description}
                          </p>
                        </div>
                      </button>
                    ))}
                  </div>
                )}
              </div>
            }
            classNames={
              "py-2 top-4 -left-20 sm:-left-40 md:-left-[440px] w-max"
            }
          />
          {/* CAMBIO DE TEMA */}
          <div
            className="cursor-pointer text-gray-600"
            onClick={handleDarkMode}
          >
            {isDarkMode ? (
              <WbSunnyIcon className="h-4 w-4 text-gray-600 dark:text-white" />
            ) : (
              <BedtimeIcon className="h-4 w-4 text-gray-600 dark:text-white" />
            )}
          </div>
          {/* CAMBIO DE LANGUAGE */}
          <label
            className="ui-switch"
            onClick={(e) => handleToggleLanguage(e.target)}
          >
            <input type="checkbox" defaultChecked={i18n.language === "en"} />
            <div className="slider">
              <div className="circle"></div>
            </div>
          </label>

          {/* FOTO DE PERFIL Y DROPDOWN */}
          <Dropdown
            button={
              <img
                className="h-10 w-10 rounded-full"
                style={{objectFit: "contain"}}
                src={DOMAIN + "/" + getUserTemp.photo}
                onError={(e) => {
                  e.currentTarget.src = require("../../../assets/image/user.webp");
                }}
              />
            }
            items={
              <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                <div className="flex flex-col p-4">
                  <Link
                    to="/profile"
                    className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                  >
                    {t("headerDashboard.profile")}
                  </Link>
                  <a
                    href="/#"
                    className="mt-3 text-sm font-medium text-red-500 transition duration-150 ease-out hover:text-red-500 hover:ease-in"
                    onClick={handleSignOut}
                  >
                    {t("common.signOut")}
                  </a>
                </div>
              </div>
            }
            classNames={"py-2 top-8 -left-[180px] w-max"}
          />
        </div>
      </div>
    </nav>
  );
}
