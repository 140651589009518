import { Box, Typography, TextField, Divider } from "@mui/material";
import React from "react";
import { AuthContext } from "../../../../hooks/useAuth.tsx";
import { useTranslation } from "react-i18next";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { colors, shadow } from "../../../../styles/theme.tsx";
import { SVG_pointer } from "../../../../assets/svg/MapIcons.ts";
import { ColorButton } from "../../../../components/Button.tsx";
import { navigationModals } from "../../../../constants/types.tsx";
import { getValueStorage } from "../../../../common/storage.ts";
import { envProps } from "../../../../common/envProps.ts";
import { useUpdatePasoRenovacion } from "../../../../common/CommonFunctions.tsx";
import { ModalDashboard } from "../../../../common/alerts.tsx";
import renovationStep from "../../services/RenovationStep.tsx";

export const VerifyAddress = ({
  onClose,
  setIsLoading,
  setRegisterStep,
}: navigationModals) => {
  const { t } = useTranslation();
  const { getUser } = React.useContext(AuthContext);
  const { updatePasoRenovacion, parseRenovacion } = useUpdatePasoRenovacion();
  const [isBlackTheme, setIsBlackTheme] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsBlackTheme(document.body.classList.contains("dark"));
  }, []);

  //Mantener la direccion
  const handleContinue = async () => {
    // MySwal.close();
    setIsLoading(true);
    await updatePasoRenovacion("1", setIsLoading);
    if (setRegisterStep) {
      renovationStep({
        setIsLoading: setIsLoading,
        parseRenovacion: parseRenovacion,
        setRegisterStep: setRegisterStep,
      });
    } else {
      let dinamicElement = renovationStep({
        onClose: () => onClose(),
        setIsLoading: setIsLoading,
        parseRenovacion: parseRenovacion,
      });
      if (dinamicElement) {
        ModalDashboard({
          element: dinamicElement,
        });
      }
    }
  };

  //Cambiar la direccion
  const handleEditContact = async () => {
    setIsLoading(true);
    await updatePasoRenovacion("N1", setIsLoading);
    let dinamicElement = renovationStep({
      onClose: () => onClose(),
      setIsLoading: setIsLoading,
      parseRenovacion: parseRenovacion,
    });
    if (dinamicElement) {
      ModalDashboard({
        element: dinamicElement,
      });
    }
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: envProps.GOOGLE_KEY,
    libraries: ["geometry"],
  });

  const google = window.google;

  if (!isLoaded) {
    return null;
  }
  return (
    <>
      <p className="text-primary uppercase font-bold text-base mx-6 leading-5 text-center ">
        {t("verifyAddress.title")}
      </p>

      <Divider className="dark:bg-white" sx={{ mt: 1.5, mx: 1.5 }} />

      <Box className="w-[98%] pt-5 overflow-x-hidden mx-auto px-3 relative text-center flex flex-col">
        {/* Inputs y maps */}
        <Box className="flex flex-col">
          <label
            className="text-sm text-secondary-800 dark:text-white"
            style={{
              fontWeight: 600,
              textAlign: "left",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {t("contactInformation.address")}
          </label>
          <TextField
            label=""
            value={
              getValueStorage("authSession").inProcessRenovation.contactDetails
                .c125_direccion1
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                mt: 1,
                "& fieldset": {
                  borderColor: colors.primary,
                },
                "&:hover fieldset": {
                  borderColor: colors.primary,
                },
                "&.Mui-focused fieldset": {
                  borderColor: colors.primary,
                },
              },
              "& .MuiOutlinedInput-input": {
                color: isBlackTheme
                  ? `${colors.white} !important`
                  : `${colors.black} !important`,
                fontSize: "14px",
              },
            }}
            multiline={true}
            maxRows={3}
            minRows={1}
          />
          <label
            className="text-sm text-secondary-800 dark:text-white"
            style={{
              fontWeight: 600,
              textAlign: "left",
              textOverflow: "ellipsis",
              overflow: "hidden",
              marginTop: 10,
            }}
          >
            {t("contactInformation.dirDesc")}
          </label>
          <TextField
            label=""
            value={
              getValueStorage("authSession").inProcessRenovation.contactDetails
                .c125_descripcion_de_direccion
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                mt: 1,
                "& fieldset": {
                  borderColor: colors.primary,
                },
                "&:hover fieldset": {
                  borderColor: colors.primary,
                },
                "&.Mui-focused fieldset": {
                  borderColor: colors.primary,
                },
              },
              "& .MuiOutlinedInput-input": {
                color: isBlackTheme ? "white !important" : "black !important",
                fontSize: "14px",
              },
            }}
            multiline={true}
            maxRows={5}
            minRows={3}
          />

          {getValueStorage("authSession").inProcessRenovation.contactDetails
            .c125_lat_barrio &&
            getValueStorage("authSession").inProcessRenovation.contactDetails
              .c125_lon_barrio && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  width: { xs: "100%", xl: 300 },
                  margin: "0 auto",
                }}
              >
                <GoogleMap
                  mapContainerStyle={{
                    width: "100%",
                    height: 150,
                    borderRadius: 5,
                    marginTop: 20,
                    boxShadow: shadow.black,
                  }}
                  center={{
                    lat: parseFloat(
                      getValueStorage("authSession").inProcessRenovation
                        .contactDetails.c125_lat_barrio
                    ),
                    lng: parseFloat(
                      getValueStorage("authSession").inProcessRenovation
                        .contactDetails.c125_lon_barrio
                    ),
                  }}
                  onLoad={(map) => {
                    map.setCenter({
                      lat:
                        parseFloat(
                          getValueStorage("authSession").inProcessRenovation
                            .contactDetails.c125_lat_barrio
                        ) ?? 0,
                      lng:
                        parseFloat(
                          getValueStorage("authSession").inProcessRenovation
                            .contactDetails.c125_lon_barrio
                        ) ?? 0,
                    });
                    map.setZoom(17);
                  }}
                  options={{
                    disableDefaultUI: true, // Desactiva la UI predeterminada del mapa
                    draggable: false, // Desactiva el arrastre del mapa
                    scrollwheel: false, // Desactiva el desplazamiento con la rueda del mouse
                    zoomControl: false, // Desactiva el control de zoom
                    streetViewControl: false, // Desactiva el control de vista de calle
                    mapTypeControl: false, // Desactiva el control de tipo de mapa
                    clickableIcons: false, // Desactiva los iconos de clic
                    headingInteractionEnabled: false, // Desactiva la interacción con la cabecera
                    keyboardShortcuts: false, // Desactiva los atajos de teclado
                  }}
                >
                  <Marker
                    position={{
                      lat: parseFloat(
                        getValueStorage("authSession").inProcessRenovation
                          .contactDetails.c125_lat_barrio
                      ),
                      lng: parseFloat(
                        getValueStorage("authSession").inProcessRenovation
                          .contactDetails.c125_lon_barrio
                      ),
                    }}
                    icon={{
                      path: SVG_pointer,
                      scale: 0.075,
                      strokeWeight: 0.8,
                      fillColor: colors.primary,
                      fillOpacity: 1,
                      anchor: new google.maps.Point(200, 520),
                    }}
                  />
                </GoogleMap>
              </Box>
            )}
        </Box>
        <Box className="flex flex-col my-16 text-primary dark:text-white font-bold">
          {/* ¿Tu dirección es la misma actualmente? */}
          <p className="leading-5	text-lg">{t("verifyAddress.same")}</p>
          <div className="grid grid-cols-2 mt-7 gap-7 sm:gap-5">
            <div className="flex justify-end">
              <ColorButton
                background={colors.blueGray}
                sx={{
                  width: "140px",
                  padding: "10px 40px",
                  fontSize: 15,
                  borderRadius: 3,
                  margin: 0,
                }}
                disabled={getUser.isLoading}
                onClick={handleContinue}
              >
                {t("common.yes")}
              </ColorButton>
            </div>
            <div className="flex justify-start">
              <ColorButton
                sx={{
                  width: "140px",
                  padding: "10px 40px",
                  fontSize: 15,
                  borderRadius: 3,
                  margin: 0,
                }}
                disabled={getUser.isLoading}
                onClick={handleEditContact}
              >
                {t("common.no")}
              </ColorButton>
            </div>
          </div>
        </Box>
        {/* Importante */}
        <Box textAlign={"center"} sx={{ marginX: { xs: 1 } }}>
          <Typography
            className="text-lg"
            sx={{
              fontWeight: "bold",
              color: colors.primary,
              textTransform: "uppercase",
              marginBottom: "5px",
            }}
            lineHeight={1}
          >
            {t("verifyAddress.important")}
          </Typography>

          <Typography
            className="text-secondary-800 dark:text-white"
            sx={{
              fontSize: 15,
              color: colors.black,
              lineHeight: 1.2,
              marginBottom: "20px",
              textAlign: "justify",
              mt: 2,
            }}
          >
            {t("verifyAddress.textImportant")}
            <strong> {t("verifyAddress.textImportant2")} </strong>
            {t("verifyAddress.textImportant3")}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
