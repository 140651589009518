import React from "react";
import { Box, Typography } from "@mui/material";
import { colors, sizes, shadow } from "../../styles/theme.tsx";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import { ColorButton } from "../../components/Button.tsx";
import { CssTextField } from "../../components/CssTextField.tsx";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../hooks/useAuth.tsx";
import { getValueStorage } from "../../common/storage.ts";
import { useNavigate } from "react-router-dom";
import { IUserAuth } from "../../constants/types.tsx";
import { handleSigInService } from "./LoginServices.tsx";
import {
  RemoveRedEyeIcon,
  VisibilityOffIcon,
} from "../Dashboard/assets/iconsMui.tsx";

export default function Login() {
  const navigate = useNavigate();
  const { setIsLoading, signIn, getUser } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const [email, setEmail] = React.useState<{ value: string; isValid: boolean }>(
    { value: "", isValid: false }
  );
  const [password, setPassword] = React.useState<string>("");
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [visiblePassword, setVisiblePassword] = React.useState(false);

  //Validamos si estamos logeados
  React.useEffect(() => {
    const authSession: IUserAuth = getValueStorage("authSession");
    if (authSession && authSession.login) {
      navigate("/");
    }
  }, []);

  const handleChange = (value: string) => {
    setEmail({
      value,
      isValid: value !== "" && re.test(String(value).toLowerCase()),
    });
  };

  const handleSigIn = async () => {
    handleSigInService(email, password, setIsLoading, signIn, navigate);
  };

  //Cambiar la visibilidad del campo de contraseña
  const handleClickShowPassword = (visibility: boolean, idElement: string) => {
    if (idElement === "new_password") {
      setVisiblePassword(visibility);
    }
    let element = document.getElementById(idElement) as HTMLInputElement;
    if (element) {
      element.type = visibility ? "text" : "password";
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          flex: "1",
          flexGrow: 1,
          marginTop: `${sizes.header}px`,
          paddingTop: "60px",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            flexGrow: 1,
            width: { xs: "95%", md: 865, lg: 850, xl: 960 },
            margin: "0 auto",
          }}
        >
          {/* Titulo */}
          <Box
            sx={{
              padding: { xs: "50px 50px 50px 35px", sm: "50px" },
              position: "relative",
              display: "flex",
              margin: "0 auto",
              width: "100%",
            }}
          >
            <div
              style={{
                width: 10,
                height: 120,
                background: colors.primary,
                position: "absolute",
                top: "50%",
                left: 0,
                borderRadius: 2,
                transform: "translateY(-50%)",
              }}
            />

            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: 28, sm: 32, md: 43 },
                  color: colors.primary,
                  textTransform: "uppercase",
                }}
                lineHeight={1}
              >
                {t("common.signIn")}
              </Typography>
            </Box>
          </Box>

          {/* Formulario */}
          <Box mt={5} width={"100%"}>
            <Box
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
              sx={{ flexGrow: 1 }}
            >
              <Box
                sx={{
                  background: colors.white,
                  height: "auto",
                  width: { xs: "95%", sm: 500, xl: 600 },
                  borderRadius: "18px",
                  boxShadow: shadow.black,
                  padding: {
                    xs: "40px 30px 80px 30px",
                    sm: "40px 70px 80px 70px",
                  },
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 10,
                }}
                mt={10}
              >
                <Box
                  sx={{
                    width: { xs: 180, lg: 235 },
                    height: { xs: 180, lg: 235 },
                    margin: "0 auto",
                    marginTop: { xs: "-117px", sm: "-120px" },
                  }}
                >
                  <img
                    src={require("../../assets/image/user.webp")}
                    alt={""}
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>

                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  mt={6}
                >
                  <CssTextField
                    placeholder={"Correo electronico"}
                    id="custom-css-outlined-input"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    sx={{ width: { xs: "100%", xl: "70%" } }}
                    onChange={({ target }) => handleChange(target.value)}
                    isError={!email.isValid && email.value.length !== 0}
                    onKeyUp={(e) => {
                      if (
                        e.key === "Enter" &&
                        email.value &&
                        email.isValid &&
                        password.length >= 4
                      ) {
                        handleSigIn();
                      }
                    }}
                  />
                  <CssTextField
                    placeholder="Contraseña"
                    id="new_password"
                    type="password"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {visiblePassword ? (
                            <RemoveRedEyeIcon
                              onClick={() =>
                                handleClickShowPassword(false, "new_password")
                              }
                              className="cursor-pointer"
                            />
                          ) : (
                            <VisibilityOffIcon
                              onClick={() =>
                                handleClickShowPassword(true, "new_password")
                              }
                              className="cursor-pointer"
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    sx={{ width: { xs: "100%", xl: "70%" } }}
                    onChange={({ target }) => {
                      // Filtrar solo números
                      const filteredValue = target.value.replace(/[^0-9]/g, "");
                      target.value = filteredValue;
                      setPassword(filteredValue);
                    }}
                    onKeyUp={(e) => {
                      if (
                        e.key === "Enter" &&
                        email.value &&
                        email.isValid &&
                        password.length >= 4
                      ) {
                        handleSigIn();
                      }
                    }}
                    inputProps={{
                      maxLength: 4, // Establece el máximo de caracteres aquí
                    }}
                  />
                  <ColorButton
                    variant="contained"
                    sx={{ fontSize: 20, width: { xs: "100%", sm: 245 } }}
                    disabled={
                      !email.value ||
                      !email.isValid ||
                      password.length < 4 ||
                      getUser.isLoading
                    }
                    onClick={handleSigIn}
                  >
                    {t("common.getInto")}
                  </ColorButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
