import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './pages/Dashboard/dashboard.css';
import App from './App.tsx';
import './constants/translations/i18n.js'
import { AuthProvider } from './hooks/useAuth.tsx';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <AuthProvider>
    <App />
  </AuthProvider>
);


