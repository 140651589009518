import * as Yup from "yup";
import { isAdult } from "../CommonFunctions.tsx";

export const yupProfile = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimo 3 caracteres")
    .matches(
      /^(?! )[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/,
      "Solo se permiten letras y sin espacios al final"
    )
    .test(
      "no-prueba-admin",
      "El campo no puede contener 'prueba' o 'admin'",
      (value) => {
        return (
          !value ||
          !value
            .split(" ")
            .some(
              (word) =>
                word.toLowerCase() === "prueba" ||
                word.toLowerCase() === "admin"
            )
        );
      }
    )
    .required("El campo es obligatorio"),
  lastname_1: Yup.string()
    .min(3, "Minimo 3 caracteres")
    .matches(
      /^(?! )[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/,
      "Solo se permiten letras y sin espacios al final"
    )
    .test(
      "no-prueba-admin",
      "El campo no puede contener 'prueba' o 'admin'",
      (value) => {
        return (
          !value ||
          !value
            .split(" ")
            .some(
              (word) =>
                word.toLowerCase() === "prueba" ||
                word.toLowerCase() === "admin"
            )
        );
      }
    )
    .required("El campo es obligatorio"),
  lastname_2: Yup.string()
    .min(3, "Minimo 3 caracteres")
    .matches(
      /^(?! )[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(?: [a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/,
      "Solo se permiten letras y sin espacios al final"
    )
    .test(
      "no-prueba-admin",
      "El campo no puede contener 'prueba' o 'admin'",
      (value) => {
        return (
          !value ||
          !value
            .split(" ")
            .some(
              (word) =>
                word.toLowerCase() === "prueba" ||
                word.toLowerCase() === "admin"
            )
        );
      }
    )
    .required("El campo es obligatorio"),
  birth_date: Yup.date()
    .required("El campo es obligatorio")
    .test(
      "fecha-no-futura",
      "La fecha no puede ser superior a hoy",
      (value) => value && new Date(value) <= new Date()
    )
    .test(
      "edad-minima-18",
      "Debes ser mayor de edad",
      (value) => value && isAdult(new Date(value))
    ),
  sex_indicator: Yup.string().required("Selecciona una opción"),
  marital_status: Yup.string().required("Selecciona una opción"),
});
