import { Box, Button, IconButton, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { colors } from "../../../../styles/theme.tsx";
import { VisuallyHiddenInput } from "../IdUpload.tsx";


export default function FormsInputIds({
  isLoading,
  id,
  setChanges,
  setValids,
}) {
  const { t } = useTranslation();
  const [simpleInformation, setSimpleInformation] = React.useState({
    photoIdFront: "",
    photoIdBack: "",
  });

  React.useEffect(() => {
    setValids((state: any) => ({
      ...state,
      [id]:
        simpleInformation.photoIdFront !== "" &&
        simpleInformation.photoIdBack !== "",
    }));
    if (simpleInformation.photoIdFront !== '' && simpleInformation.photoIdBack !== '') {
      let element = document.getElementById(`errorM_${id}`);
      if (element) {
        element.style.display = "none";
      }
    }
  }, [simpleInformation, setSimpleInformation]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, idPhoto: string) => {
    if (e.target.files) {
      let file = e.target.files[0];
      if (file) {
        if (idPhoto === "f") {
          const reader = new FileReader();
          reader.onloadend = () => {
            setChanges((state: any) => ({ ...state, [id]: { ...state[id], photoIdFront: reader.result } }));
          };
          reader.readAsDataURL(file);
          setSimpleInformation({ ...simpleInformation, photoIdFront: URL.createObjectURL(file) });
        } else {
          const reader = new FileReader();
          reader.onloadend = () => {
            setChanges((state: any) => ({ ...state, [id]: { ...state[id], photoIdBack: reader.result } }));
          };
          reader.readAsDataURL(file);
          setSimpleInformation({ ...simpleInformation, photoIdBack: URL.createObjectURL(file) });
        }
      }
    }
  }

  const handleResetPhoto = (idPhoto: string) => {
    setValids((state: any) => ({ ...state, [id]: false }));
    if (idPhoto === "f") {
      setChanges((state: any) => ({ ...state, [id]: { ...state[id], photoIdFront: "" } }));
      setSimpleInformation({ ...simpleInformation, photoIdFront: "" })
    } else {
      setChanges((state: any) => ({ ...state, [id]: { ...state[id], photoIdBack: "" } }));
      setSimpleInformation({ ...simpleInformation, photoIdBack: "" })
    }
  }

  return (
    <>
      {/* FOTO FRONTAL */}
      <label
        style={{
          fontSize: 13,
          fontWeight: 600,
          textWrap: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
        htmlFor={"cedulaF"}
      >
        {t("validation.frontPart")}
      </label>
      {simpleInformation.photoIdFront ? (
        <Box sx={{ position: "relative", mt: 1 }}>
          <img
            src={simpleInformation.photoIdFront}
            style={{ width: "100%", height: 150, objectFit: "contain" }}
          />
          <IconButton
            aria-label="delete"
            size="large"
            onClick={() => handleResetPhoto("f")}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: colors.white,
              backgroundColor: colors.primary,
              borderRadius: 0,
              "&:hover": {
                border: `1px solid ${colors.primary}`,
                backgroundColor: colors.lightGray,
                "& .MuiSvgIcon-root": {
                  color: colors.primary,
                },
              },
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ) : (
        <Button
          sx={{
            background: colors.primary,
            color: colors.white,
            fontSize: 16,
            mt: 1,
            lineHeight: 1.2,
            padding: 2,
            width: "100%",
          }}
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          {t("validation.frontPartButton")}
          <VisuallyHiddenInput
            type="file"
            onChange={(e) => handleChange(e, "f")}
            accept="image/*"
          />
        </Button>
      )}

      {/* FOTO ATRAS */}
      <label
        style={{
          fontSize: 13,
          fontWeight: 600,
          textWrap: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
        htmlFor={"cedulaF"}
      >
        {t("validation.backPart")}
      </label>
      {simpleInformation.photoIdBack ? (
        <Box sx={{ position: "relative", mt: 1 }}>
          <img
            src={simpleInformation.photoIdBack}
            style={{ width: "100%", height: 150, objectFit: "contain" }}
          />
          <IconButton
            aria-label="delete"
            size="large"
            onClick={() => handleResetPhoto('b')}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: colors.white,
              backgroundColor: colors.primary,
              borderRadius: 0,
              "&:hover": {
                border: `1px solid ${colors.primary}`,
                backgroundColor: colors.lightGray,
                "& .MuiSvgIcon-root": {
                  color: colors.primary,
                },
              },
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ) : (
        <Button
          sx={{
            background: colors.primary,
            color: colors.white,
            fontSize: 16,
            mt: 1,
            lineHeight: 1.2,
            padding: 2,
            width: "100%",
          }}
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          {t("validation.backPartButton")}
          <VisuallyHiddenInput
            type="file"
            onChange={(e) => handleChange(e, "b")}
            accept="image/*"
          />
        </Button>
      )}
      <Typography id={`errorM_${id}`} sx={{ color: "red", fontSize: 13, marginTop: 1, fontWeight: "bold", display: "none" }}></Typography>
    </>
  );
}; 