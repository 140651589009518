import React from "react";
import { IPaymentPlans, navigationModals } from "../../../constants/types";
import { Divider, Tooltip } from "@mui/material";
import {
  formatDateTime,
  formatNumberPoint,
  requestSquematic,
} from "../../../common/CommonFunctions.tsx";
import { getValueStorage } from "../../../common/storage.ts";
import {
  ChevronRightIcon,
  InfoOutlinedIcon,
  KeyboardArrowDownIcon,
} from "../assets/iconsMui.tsx";
import { useTranslation } from "react-i18next";
import { colors } from "../../../styles/theme.tsx";

interface Dues {
  loan?: string;
}

type DuesDetailsProps = Dues & navigationModals;

export const DuesDetails = ({ loan, setIsLoading }: DuesDetailsProps) => {
  const [data, setData] = React.useState<IPaymentPlans[]>([]);
  const [penaltiesValue, setPenaltiesValue] = React.useState<number | null>(
    null
  );
  const [cantApproved, setCantApproved] = React.useState<number | null>(null);
  const { t } = useTranslation();

  const fetchData = async () => {
    setIsLoading(true);

    const res = await requestSquematic(
      "GET",
      "/api/app/loan/get_loan_dues_information",
      {
        c200_rowid: loan,
      },
      getValueStorage("authSession").token
    );

    if (res) {
      setCantApproved(
        res.results!.payment_plans.reduce(
          (acum, { c202_ind_estado }) =>
            acum + (c202_ind_estado.toString() === "1" ? 1 : 0),
          0
        )
      );

      setData(res.results!.payment_plans);
      setPenaltiesValue(
        res.results!.payment_plan_penalites.length !== 0
          ? parseInt(
              res.results!.payment_plan_penalites[0].c211_valor_sancion
            ) +
              parseInt(
                res.results!.payment_plan_penalites[0].c211_gastos_cobranza
              )
          : 0
      );
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <p className="text-primary font-bold text-lg uppercase">
        {t("duesDetails.title")}
      </p>
      <div className="grid grid-cols-2 mt-5">
        <div className="text-left ">
          <p className="uppercase text-sm font-medium text-secundary-800 dark:text-white leading-4 sm:leading-[inherit]">
            {t("duesDetails.overview")}
          </p>
          <p className="uppercase text-base font-semibold text-primary leading-4 sm:leading-[inherit]">
            {t("duesDetails.creditInstallments")}
          </p>
        </div>
        {cantApproved !== null && (
          <div className="text-right flex flex-col justify-center">
            <p className="text-sm text-secundary-800 dark:text-white font-bold ">
              {t("duesDetails.completed")}:{" "}
              <span className="font-normal">{cantApproved}</span>
            </p>

            <p className="text-sm text-secundary-800 dark:text-white font-bold ">
              {t("duesDetails.pending")}:{" "}
              <span className="font-normal">{data.length - cantApproved}</span>
            </p>
          </div>
        )}
      </div>
      <Divider className="bg-gray dark:bg-primary" sx={{ my: 1 }} />
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-2 sm:mx-28 md:mx-0 my-6">
        <div className="flex flex-row items-center gap-1.5 md:justify-center">
          <div className="bg-lime-500 w-3 h-3 rounded-full" />
          <div className="dark:text-white">{t("duesDetails.complete")}</div>
        </div>
        <div className="flex flex-row items-center gap-1.5 md:justify-center ">
          <div className="bg-warning w-3 h-3 rounded-full" />
          <div className="dark:text-white">{t("duesDetails.incomplete")}</div>
        </div>
        <div className="flex flex-row items-center gap-1.5 md:justify-center">
          <div className="bg-red-600 w-3 h-3 rounded-full" />
          <div className="dark:text-white">{t("duesDetails.noPay")}</div>
        </div>
        <div className="flex flex-row items-center gap-1.5 md:justify-center">
          <div className="bg-gray w-3 h-3 rounded-full" />
          <div className="dark:text-white">{t("duesDetails.next")}</div>
        </div>
      </div>
      <div className="mt-2 gap-2 flex flex-col overflow-y-auto max-h-96 pb-3">
        {data.map((item, index) => {
          return <Item item={item} penaltiesValues={penaltiesValue} />;
        })}
      </div>
    </div>
  );
};

const Item = React.memo<{
  item: IPaymentPlans;
  penaltiesValues: number | null;
}>(({ item, penaltiesValues }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isShowed, setIsShowed] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const state = React.useMemo(() => {
    if (item.c202_ind_estado === "1") {
      return { color: "bg-lime-500", label: t("duesDetails.complete"), id: 1 };
    }

    if (item.c202_ind_estado === "2") {
      return { color: "bg-warning", label: t("duesDetails.incomplete"), id: 2 };
    }

    const c202_fecha_cuota = new Date(item.c202_fecha_cuota.split(" ")[0]);

    if (c202_fecha_cuota < new Date()) {
      return { color: "bg-red-600", label: t("duesDetails.noPay"), id: 3 };
    }

    return { color: "bg-gray", label: t("duesDetails.next"), id: 0 };
  }, []);

  return (
    <>
      <button
        disabled={state.id === 0 || state.id === 3}
        className={`flex grid grid-cols-12 bg-white dark:!bg-navy-600 w-[98%] mx-auto py-3 px-4 rounded-lg shadow-md dark:text-white dark:shadow-none ${
          state.id === 0 || state.id === 3
            ? "hover:opacity-100"
            : isOpen
            ? "hover:opacity-100"
            : "hover:opacity-80"
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="h-full flex flex-col text-left col-span-3 justify-center ">
          <p className="text-center capitalize text-sm leading-4 dark:!text-white font-bold">
            {t("homeDashboard.due")} N° {item.c202_nro_cuota}
          </p>
        </div>
        <div className="h-full col-span-7 flex flex-col items-center justify-center">
          <p className="text-sm font-semibold	leading-4	dark:text-secondary-500">
            {t("duesDetails.installmentDate")}
          </p>
          <p className=" text-xs leading-4 text-primary font-semibold">
            {formatDateTime(item.c202_fecha_cuota, t, true)}
          </p>
        </div>
        <div className="h-full col-span-2 flex items-center justify-end">
          <div className={`${state.color} w-3 h-3 rounded-full`} />
          {isOpen ? (
            <KeyboardArrowDownIcon />
          ) : (
            <ChevronRightIcon
              sx={{
                color:
                  state.id === 0 || state.id === 3 ? "transparent" : "inherit",
              }}
            />
          )}
        </div>
      </button>
      {isOpen && (
        <>
          <div
            className={`-mt-4 hover:opacity-80flex bg-white dark:!bg-navy-600 w-[98%] mx-auto py-3 px-4 sm:px-11 rounded-br-lg rounded-bl-lg shadow-md dark:text-white dark:shadow-none `}
          >
            <div className="grid grid-cols-2 gap-2 my-1 w-full ">
              <div className="text-sm text-primary font-medium	text-start">
                {t("duesDetails.status")}:
              </div>
              <div className="text-sm dark:text-white text-end ">
                {state.label}
              </div>
            </div>
            {state.id === 1 && (
              <>
                <Divider className="bg-gray dark:bg-primary" sx={{ my: 1 }} />
                <div className="grid grid-cols-2 my-1 w-full flex items-center ">
                  <div>
                    <div className="text-sm text-primary font-medium	text-start leading-4">
                      {t("duesDetails.paymentDate")}:
                    </div>
                  </div>
                  <div>
                    <div className="text-sm dark:text-white text-end leading-4">
                      {formatDateTime(
                        item.baking_t[0].c230_fecha_recaudo,
                        t,
                        true
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {state.id === 2 && (
              <>
                <Divider className="bg-gray dark:bg-primary" sx={{ my: 1 }} />

                <div className="grid grid-cols-2  my-1 w-full flex items-center">
                  <div>
                    <div className="text-sm  font-medium	text-start ">
                      {t("duesDetails.paymentValue")}:
                    </div>
                    <div className="text-lg text-primary font-bold	text-start ">
                      $
                      {formatNumberPoint(
                        item.baking_t.reduce(
                          (acum, { c230_valor_recaudo }) =>
                            acum + parseInt(c230_valor_recaudo),
                          0
                        )
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="text-sm  font-medium text-end ">
                      {t("duesDetails.pendingValue")}:
                    </div>
                    <div className="text-lg text-red-600 font-bold text-end flex flex-row justify-end">
                      $
                      {formatNumberPoint(
                        parseInt(item.baking_t[0].c230_valor_pendiente) +
                          (penaltiesValues ?? 0)
                      )}
                      <div className="leading-4 text-left	">
                        <Tooltip
                          title={t("duesDetails.disclaimer")}
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={() => setIsShowed(false)}
                          open={isShowed}
                          disableHoverListener
                        >
                          <button onClick={() => setIsShowed(true)}>
                            <InfoOutlinedIcon
                              sx={{
                                color: colors.red,
                                mt: -0.5,
                                fontSize: 16,
                              }}
                            />
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>

                <Divider className="bg-gray dark:bg-primary" sx={{ my: 1 }} />
                <div className="grid grid-cols-2 my-1 w-full flex items-center ">
                  <div>
                    <div className="text-sm text-primary font-medium	text-start leading-4">
                      {t("duesDetails.paymentDate")}:
                    </div>
                  </div>
                  <div>
                    <div className="text-sm dark:text-white text-end  leading-4">
                      {formatDateTime(
                        item.baking_t[0].c230_fecha_recaudo,
                        t,
                        true
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
});
