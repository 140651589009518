import React from "react";
import { useTranslation } from "react-i18next";
import { IPanelAll } from "../../../constants/types";
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  Grid2 as Grid,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import { getValueStorage, saveValueStorage } from "../../../common/storage.ts";
import InputField from "../../../components/InputField.tsx";
import { useFormik } from "formik";
import { yupIdValdations } from "../../../common/validations/yupIdValidations.ts";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { ColorButton } from "../../../components/Button.tsx";
import { useNavigate } from "react-router-dom";
import { IRegisterStepState } from "../RegisterComponent.tsx";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import { requestSquematic } from "../../../common/CommonFunctions.tsx";
import { errorAlert } from "../../../common/alerts.tsx";

export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface IRegisterProcessData {
  agreedTerms: boolean;
  cedulaA: string;
  cedulaF: string;
  date_expedition: string;
  id: string;
  id_verification: string;
  riskCenter: boolean;
  selfie: string;
  type_id: string;
}

export default function IdUpload({ setRegisterStep }: IRegisterStepState) {
  const navigate = useNavigate();
  const { getUser, setIsLoading } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const dataPanel: IPanelAll = getValueStorage("panelData");
  const [cedulaF, setCedulaF] = React.useState<string>("");
  const [cedulaA, setCedulaA] = React.useState<string>("");
  const [selfie, setSelfie] = React.useState<string>("");
  const formik = useFormik({
    initialValues: {
      type_id: "C",
      id: "",
      id_verification: "",
      date_expedition: "",
      cedulaF: "",
      cedulaA: "",
      selfie: "",
      agreedTerms: false,
      riskCenter: false,
    },
    validationSchema: yupIdValdations,
    onSubmit: (values) => {
      saveValueStorage("registerProcessData", values);
      let obj = {
        prevStep: 3,
        nextStep: 4,
        desc: "el usuario sube las fotos de cedula y selfie",
      };
      saveValueStorage("registerStep", obj);
      if (setRegisterStep) setRegisterStep(obj);
      navigate("/sign-up");
    },
  });
  const typeIdOptions = dataPanel?.all_id_type.map((item) => ({
    value: item.c022_id.trim(),
    label: item.c022_descripcion.split(" (")[0],
  }));

  //Convertir base 64 a file
  const convertBase64ToFile = async (
    base64: string,
    fileName: string,
    setter: (value: React.SetStateAction<string>) => void
  ) => {
    const base64Response = base64.split(",")[1];
    const byteCharacters = atob(base64Response);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new File([byteArray], fileName, { type: "image/webp" });
    setter(URL.createObjectURL(file));
  };

  //Validar si el localstorage de registerProcessData esta lleno
  React.useEffect(() => {
    const registerProcessData: IRegisterProcessData = getValueStorage(
      "registerProcessData"
    );
    if (registerProcessData) {
      //Si esta lleno llenamos los campos del formulario con la informacion previa
      formik.setFieldValue("type_id", registerProcessData.type_id);
      formik.setFieldValue("id", registerProcessData.id);
      formik.setFieldValue(
        "id_verification",
        registerProcessData.id_verification
      );
      formik.setFieldValue(
        "date_expedition",
        registerProcessData.date_expedition
      );
      formik.setFieldValue("cedulaF", registerProcessData.cedulaF);
      formik.setFieldValue("cedulaA", registerProcessData.cedulaA);
      formik.setFieldValue("selfie", registerProcessData.selfie);
      formik.setFieldValue("agreedTerms", registerProcessData.agreedTerms);
      formik.setFieldValue("riskCenter", registerProcessData.riskCenter);

      // Crear el objectURL solo si el campo correspondiente no está vacío
      if (registerProcessData.cedulaF) {
        convertBase64ToFile(registerProcessData.cedulaF, "cedulaF", setCedulaF);
      }
      if (registerProcessData.cedulaA) {
        convertBase64ToFile(registerProcessData.cedulaA, "cedulaA", setCedulaA);
      }
      if (registerProcessData.selfie) {
        convertBase64ToFile(registerProcessData.selfie, "selfie", setSelfie);
      }
    }
  }, []);

  //Cada vez que cambie la imagen se muestra la previsualizacion y guardamos el valor en formik
  const handleChangeImage = (
    e: React.ChangeEvent<HTMLInputElement>,
    setter: (value: React.SetStateAction<string>) => void,
    fieldValue: string
  ) => {
    if (e.target.files) {
      const file = e.target.files[0];

      if (file) {
        // Validación de tipo
        const validTypes = ["image/png", "image/jpeg"];
        if (!validTypes.includes(file.type)) {
          errorAlert("Error", t("idUpload.onlyImg"), "");
          return;
        }
        // Validación de tamaño
        const maxSize = 1 * 1024 * 1024; // Tamaño máximo en bytes (1 MB)
        if (file.size > maxSize) {
          errorAlert("Error", t("idUpload.limit"), "");
          return;
        }

        // Procesamiento del archivo
        setter(URL.createObjectURL(file));
        const reader = new FileReader();
        reader.onloadend = () => {
          formik.setFieldValue(fieldValue, reader.result);
        };
        reader.readAsDataURL(file); // Lee el archivo como una URL de datos
      }
    }
  };
  //Validar si el id existe en la base de datos
  const handleVerifyId = async () => {
    formik.setTouched({ id: true });

    if (!formik.errors.id) {
      setIsLoading(true);
      const res = await requestSquematic(
        "POST",
        "/api/app/third/verificate_id",
        { id: formik.values.id },
        getUser.token
      );

      //Si falla la consulta quiere decir que la cedula ya existe entonces la borramos
      if (!res) {
        formik.setFieldValue("id", "");
        formik.setFieldValue("id_verification", "");
      }

      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: colors.black,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("common.form")}
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("common.forRequestCredit")}
        </Typography>
      </Box>
      <Box display={"flex"} flexDirection={"column"} alignItems={"left"} mt={5}>
        <Grid container spacing={2} columns={12}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <InputField
              name="type_id"
              formik={formik}
              label="Tipo de identificación"
              type="select"
              options={typeIdOptions}
              defaultValue={"C"}
              disabled={true}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <InputField
              name="id"
              label="Número de identificación"
              formik={formik}
              type="text"
              inputProps={{
                maxLength: 10, // Máximo de caracteres
                inputMode: "numeric", // Ayuda en dispositivos móviles
              }}
              onInput={(e: React.FormEvent<HTMLInputElement>) => {
                // Remueve cualquier carácter que no sea un numero
                const target = e.target as HTMLInputElement;
                target.value = target.value.replace(/[^0-9]/g, "");
              }}
              onBlur={handleVerifyId}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <InputField
              name="id_verification"
              label="Verificación de identificación"
              formik={formik}
              type="text"
              inputProps={{
                maxLength: 10, // Máximo de caracteres
                inputMode: "numeric", // Ayuda en dispositivos móviles
              }}
              onInput={(e: React.FormEvent<HTMLInputElement>) => {
                // Remueve cualquier carácter que no sea un numero
                const target = e.target as HTMLInputElement;
                target.value = target.value.replace(/[^0-9]/g, "");
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <InputField
              type="date"
              name="date_expedition"
              label="Fecha de expedición"
              formik={formik}
              maxLength={150}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <label
              style={{
                fontSize: 13,
                fontWeight: 600,
                textWrap: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                color:
                  formik.errors["cedulaF"] && formik.touched["cedulaF"]
                    ? "red"
                    : "black",
              }}
              htmlFor={"cedulaF"}
            >
              Cédula Frontal
            </label>
            {cedulaF ? (
              <Box sx={{ position: "relative", mt: 1 }}>
                <img
                  alt=""
                  src={cedulaF}
                  style={{ width: "100%", height: 150, objectFit: "contain" }}
                />
                <IconButton
                  aria-label="delete"
                  size="large"
                  onClick={() => {
                    setCedulaF("");
                    formik.setFieldValue("cedulaF", "");
                    saveValueStorage("registerProcessData", {
                      ...getValueStorage("registerProcessData"),
                      cedulaF: "",
                    });
                  }}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: colors.white,
                    backgroundColor: colors.primary,
                    borderRadius: 0,
                    "&:hover": {
                      border: `1px solid ${colors.primary}`,
                      backgroundColor: colors.lightGray,
                      "& .MuiSvgIcon-root": {
                        color: colors.primary,
                      },
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ) : (
              <Button
                sx={{
                  background: colors.primary,
                  color: colors.white,
                  fontSize: 16,
                  mt: 1,
                  lineHeight: 1.2,
                  padding: 2,
                  width: "100%",
                }}
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Cargar cédula frontal
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => handleChangeImage(e, setCedulaF, "cedulaF")}
                  accept=".png, .jpg, .jpeg"
                />
              </Button>
            )}
            {formik.errors["cedulaF"] && formik.touched["cedulaF"] ? (
              <FormHelperText
                style={{
                  color: colors.red,
                  fontSize: 11,
                  width: "100%",
                  marginLeft: 0,
                  marginRight: 0,
                  lineHeight: 1,
                  marginTop: 7,
                }}
              >
                *{" "}
                {typeof formik.errors["cedulaF"] === "string"
                  ? formik.errors["cedulaF"]
                  : "Archivo inválido"}
              </FormHelperText>
            ) : (
              ""
            )}
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <label
              style={{
                fontSize: 13,
                fontWeight: 600,
                textWrap: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                color:
                  formik.errors["cedulaA"] && formik.touched["cedulaA"]
                    ? "red"
                    : "black",
              }}
              htmlFor={"cedulaA"}
            >
              Cédula Trasera
            </label>
            {cedulaA ? (
              <Box sx={{ position: "relative", mt: 1 }}>
                <img
                  alt=""
                  src={cedulaA}
                  style={{ width: "100%", height: 150, objectFit: "contain" }}
                />
                <IconButton
                  aria-label="delete"
                  size="large"
                  onClick={() => {
                    setCedulaA("");
                    formik.setFieldValue("cedulaA", "");
                    saveValueStorage("registerProcessData", {
                      ...getValueStorage("registerProcessData"),
                      cedulaA: "",
                    });
                  }}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: colors.white,
                    backgroundColor: colors.primary,
                    borderRadius: 0,
                    "&:hover": {
                      border: `1px solid ${colors.primary}`,
                      backgroundColor: colors.lightGray,
                      "& .MuiSvgIcon-root": {
                        color: colors.primary,
                      },
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ) : (
              <Button
                sx={{
                  background: colors.primary,
                  color: colors.white,
                  fontSize: 16,
                  mt: 1,
                  lineHeight: 1.2,
                  padding: 2,
                  width: "100%",
                }}
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Cargar cédula trasera
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => handleChangeImage(e, setCedulaA, "cedulaA")}
                  accept=".png, .jpg, .jpeg"
                />
              </Button>
            )}
            {formik.errors["cedulaA"] && formik.touched["cedulaA"] ? (
              <FormHelperText
                style={{
                  color: colors.red,
                  fontSize: 11,
                  width: "100%",
                  marginLeft: 0,
                  marginRight: 0,
                  lineHeight: 1,
                  marginTop: 7,
                }}
              >
                *{" "}
                {typeof formik.errors["cedulaA"] === "string"
                  ? formik.errors["cedulaA"]
                  : "Archivo inválido"}
              </FormHelperText>
            ) : (
              ""
            )}
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <label
              style={{
                fontSize: 13,
                fontWeight: 600,
                textWrap: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                color:
                  formik.errors["selfie"] && formik.touched["selfie"]
                    ? "red"
                    : "black",
              }}
              htmlFor={"cedulaA"}
            >
              Foto de Perfil
            </label>
            {selfie ? (
              <Box sx={{ position: "relative", mt: 1 }}>
                <img
                  alt=""
                  src={selfie}
                  style={{ width: "100%", height: 150, objectFit: "contain" }}
                />
                <IconButton
                  aria-label="delete"
                  size="large"
                  onClick={() => {
                    setSelfie("");
                    formik.setFieldValue("selfie", "");
                    saveValueStorage("registerProcessData", {
                      ...getValueStorage("registerProcessData"),
                      selfie: "",
                    });
                  }}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    color: colors.white,
                    backgroundColor: colors.primary,
                    borderRadius: 0,
                    "&:hover": {
                      border: `1px solid ${colors.primary}`,
                      backgroundColor: colors.lightGray,
                      "& .MuiSvgIcon-root": {
                        color: colors.primary,
                      },
                    },
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ) : (
              <Button
                sx={{
                  background: colors.primary,
                  color: colors.white,
                  fontSize: 16,
                  mt: 1,
                  lineHeight: 1.2,
                  padding: 2,
                  width: "100%",
                }}
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Cargar foto de perfil
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) => handleChangeImage(e, setSelfie, "selfie")}
                  accept=".png, .jpg, .jpeg"
                />
              </Button>
            )}
            {formik.errors["selfie"] && formik.touched["selfie"] ? (
              <FormHelperText
                style={{
                  color: colors.red,
                  fontSize: 11,
                  width: "100%",
                  marginLeft: 0,
                  marginRight: 0,
                  lineHeight: 1,
                  marginTop: 7,
                }}
              >
                *{" "}
                {typeof formik.errors["selfie"] === "string"
                  ? formik.errors["selfie"]
                  : "Archivo inválido"}
              </FormHelperText>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid container columns={12} mt={2.5}>
          <Grid size={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={formik.values.agreedTerms}
                onClick={() =>
                  formik.setFieldValue(
                    "agreedTerms",
                    !formik.values.agreedTerms
                  )
                }
                sx={{
                  "&.Mui-checked": {
                    color: colors.primary,
                  },
                }}
              />
              <Typography sx={{ fontSize: "13px !important", lineHeight: 1.2 }}>
                Estoy de acuerdo con los <strong>Términos y condiciones</strong>
              </Typography>
            </Box>
            {formik.errors["agreedTerms"] && formik.touched["agreedTerms"] ? (
              <FormHelperText
                style={{
                  color: colors.red,
                  fontSize: 11,
                  width: "100%",
                  marginLeft: 0,
                  marginRight: 0,
                  lineHeight: 1,
                  marginTop: 2,
                  marginBottom: 5,
                }}
              >
                * {formik.errors["agreedTerms"]}
              </FormHelperText>
            ) : (
              ""
            )}
          </Grid>
          <Grid size={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={formik.values.riskCenter}
                onClick={() =>
                  formik.setFieldValue("riskCenter", !formik.values.riskCenter)
                }
                sx={{
                  "&.Mui-checked": {
                    color: colors.primary,
                  },
                }}
              />
              <Typography sx={{ fontSize: "13px !important", lineHeight: 1.2 }}>
                Estoy de acuerdo con{" "}
                <strong>Consulta y reporte en centrales de riesgo</strong>
              </Typography>
            </Box>
            {formik.errors["riskCenter"] && formik.touched["riskCenter"] ? (
              <FormHelperText
                style={{
                  color: colors.red,
                  fontSize: 11,
                  width: "100%",
                  marginLeft: 0,
                  marginRight: 0,
                  lineHeight: 1,
                  marginTop: 2,
                }}
              >
                * {formik.errors["riskCenter"]}
              </FormHelperText>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} columns={12} mt={2.5}>
          <Grid size={12} sx={{ display: "flex", justifyContent: "center" }}>
            <ColorButton
              sx={{
                width: "fit-content",
                padding: "15px 40px",
                fontSize: 16,
                marginTop: 5,
              }}
              onClick={() => formik.handleSubmit()}
            >
              {t("common.continue")}
            </ColorButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
