import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import { useTranslation } from "react-i18next";
import { ColorButton } from "../../../components/Button.tsx";
import { getValueStorage, saveValueStorage } from "../../../common/storage.ts";
import { IRegisterStepState } from "../RegisterComponent.tsx";
import { useUpdatePasoRenovacion } from "../../../common/CommonFunctions.tsx";
import { IUserAuth, navigationModals } from "../../../constants/types.tsx";
import renovationStep from "../../Dashboard/services/RenovationStep.tsx";
import { ModalDashboard } from "../../../common/alerts.tsx";

// Combinar las dos interfaces
type RequestPreAprovedProps = IRegisterStepState & navigationModals;

export default function ScheduleWork({
  setIsLoading,
  setRegisterStep,
}: RequestPreAprovedProps) {
  const { t } = useTranslation();

  const { updatePasoRenovacion, parseRenovacion } = useUpdatePasoRenovacion();
  const authSession: IUserAuth = getValueStorage("authSession");

  const handleContinue = async () => {
    let obj = {
      prevStep: 38,
      nextStep: 14,
      desc: "se va para el calendarVc",
    };
    saveValueStorage("registerStep", obj);

    if (
      authSession.inProcessRenovation?.inProcessRenovation ||
      authSession.isOld
    ) {
      if (authSession.hasVideoCall.c155_rowid_estado + "" === "9") {
        await updatePasoRenovacion(
          "5",
          setIsLoading,
          authSession.hasVideoCall.c155_tipo
        );
      }
      if (setRegisterStep) {
        setRegisterStep(obj);
      } else {
        let dinamicElement = renovationStep({
          setIsLoading: setIsLoading,
          parseRenovacion: parseRenovacion,
        });
        if (dinamicElement) {
          ModalDashboard({
            element: dinamicElement,
          });
        }
      }
    } else {
      if (setRegisterStep) {
        setRegisterStep(obj);
      }
    }
  };


  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Box
          sx={{
            width: { xs: 210, sm: 250 },
            marginBottom: 2,
          }}
        >
          <img
            src={require("../../../assets/image/Aprovado.webp")}
            alt={""}
            loading="lazy"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </Box>

        {/* Felicitaciones */}
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: { xs: 22, sm: 28 },
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
            marginTop: 1.5,
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("scheduleWork.getting")}
        </Typography>

        <Typography
          className="dark:!text-white"
          sx={{
            color: colors.dimGray,
            textTransform: "uppercase",
            fontSize: 14.5,
            textAlign: "center",
            fontWeight: 600,
          }}
          lineHeight={1}
        >
          {t("scheduleWork.receiving")}
        </Typography>
      </Box>

      {/* ¿Cuál es el siguiente paso? */}
      <Box
        textAlign={"center"}
        sx={{
          marginX: {
            xs: 3,
            sm: setRegisterStep ? 4 : 5,
            xl: setRegisterStep ? 8 : 5,
          },
          mt: 5,
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 17,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
        >
          {t("preAproved.whoNextStep")}
        </Typography>

        <Typography
          className="dark:!text-white"
          sx={{
            fontSize: 16,
            color: colors.black,
            lineHeight: 1.3,
            marginBottom: "20px",
            textAlign: "justify",
            mt: 1.5,
          }}
        >
          {t("preAproved.nextStepP1")}
          <strong>{t("videoCall.vc")} </strong>
          {t("preAproved.nextStepP2").split("-")[0]}
          <strong>{t("preAproved.nextStepP2").split("-")[1]}</strong>
        </Typography>
      </Box>

      {/* Importante */}
      <Box
        sx={{
          marginX: {
            xs: 3,
            sm: setRegisterStep ? 4 : 5,
            xl: setRegisterStep ? 8 : 5,
          },
          mt: 5,
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 17,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
        >
          {t("confirmLoan.important")}
        </Typography>

        <Typography
          className="dark:text-white"
          sx={{
            fontSize: 16,
            color: colors.black,
            lineHeight: 1.3,
            marginBottom: "20px",
            textAlign: "justify",
            mt: 1.5,
          }}
        >
          {t("confirmVc.point2")}
          <strong>{t("scheduleWork.subMoment")}</strong>
          {t("scheduleWork.afterMoment")}
        </Typography>
      </Box>

      {/* Beneficios */}
      <Box
        sx={{
          marginX: {
            xs: 3,
            sm: setRegisterStep ? 4 : 5,
            xl: setRegisterStep ? 8 : 5,
          },
          mt: 5,
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 17,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
        >
          {t("scheduleWork.benefits")}
        </Typography>

        <Typography
          className="dark:text-white"
          sx={{
            fontSize: 16,
            color: colors.black,
            lineHeight: 1.3,
            marginBottom: "20px",
            textAlign: "justify",
            mt: 1.5,
          }}
        >
          {t("scheduleWork.independent")}
          <strong>{t("scheduleWork.subIndependent")}.</strong>
        </Typography>
      </Box>

      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        marginTop={{ xs: 5, md: "40px" }}
      >
        <ColorButton
          sx={{ width: "fit-content", padding: "15px 40px", fontSize: 16 }}
          onClick={handleContinue}
        >
          {t("calendar.scheduleV")}
        </ColorButton>
      </Box>
    </>
  );
}
