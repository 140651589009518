import React from "react";
import {
  IFinantialInformation_selects,
  navigationModals,
  occupation_classification,
} from "../../../../constants/types";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../hooks/useAuth.tsx";
import {
  requestSquematic,
  useUpdatePasoRenovacion,
} from "../../../../common/CommonFunctions.tsx";
import { useFormik } from "formik";
import { errorAlert, ModalDashboard } from "../../../../common/alerts.tsx";
import { getValueStorage } from "../../../../common/storage.ts";
import { Autocomplete, Box, Divider } from "@mui/material";
import { colors } from "../../../../styles/theme.tsx";
import Grid from "@mui/material/Grid2";
import InputField from "../../../../components/InputField.tsx";
import { ColorButton } from "../../../../components/Button.tsx";
import renovationStep from "../../services/RenovationStep.tsx";
import { yupRenovationFinancialInformation } from "../../../../common/validations/yupRenovationFinancialInformation.ts";
import RequestPreAproved from "../../../User/RegisterSubComponents/RequestPreAproved.tsx";

export const RenovationFinancialnformation = ({
  onClose,
  setIsLoading,
}: navigationModals) => {
  const { t } = useTranslation();
  const { getUser } = React.useContext(AuthContext);
  const { updatePasoRenovacion, parseRenovacion } = useUpdatePasoRenovacion();
  const [laboralStatus, setLaboralStatus] = React.useState<any[]>([]);
  const [periodTypes, setPeriodTypes] = React.useState<any[]>([]);
  const [occupationClassification, setOccupationClassification] =
    React.useState<occupation_classification[]>([]);
  const [selectedOccupation, setSelectedOccupation] =
    React.useState<occupation_classification | null>(null);

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);

      const res: IFinantialInformation_selects = await requestSquematic(
        "GET",
        "/api/app/financial_data/get_selects",
        {},
        getValueStorage("authSession").token
      );

      if (res) {
        setLaboralStatus(
          res.results!.laboral_status.map((item: any) => ({
            id: item.c019_rowid + "",
            value: item.c019_descripcion,
          }))
        );
        setPeriodTypes(
          res
            .results!.period_types.filter((item) => item.c054_estado === "1")
            .map((item: any) => ({
              id: item.c054_rowid + "",
              value: item.c054_descripcion,
            }))
        );
        setOccupationClassification(res.results!.occupation_classification);
        const foundOccupation = res.results!.occupation_classification.find(
          (item) =>
            item.c016_rowid ===
            parseInt(
              getValueStorage("authSession").financialData.c126_rowid_ocupacion
            )
        );
        //Pintamos el select de autocomplete
        setSelectedOccupation(foundOccupation ? foundOccupation : null);
      }
      setIsLoading(false);
    })();
  }, []);

  // Mapea las opciones
  const laboralStatusOptions = laboralStatus.map((item) => ({
    value: item.id + "",
    label: item.value,
  }));

  const periodTypesOptions = periodTypes.map((item) => ({
    value: item.id + "",
    label: item.value,
  }));

  // Inicializar variables
  const formik = useFormik({
    initialValues: {
      laboral_status: "",
      occupation: "",
      company: "",
      company_address: "",
      company_phone: "",
      charge: "",
      period_pay: "",
    },
    validationSchema: yupRenovationFinancialInformation,
    onSubmit: (values) => {
      let valuesFormated = {
        laboral_status: values.laboral_status,
        occupation: "-",
        company: "-",
        company_address: "-",
        company_phone: "-",
        charge: "-",
        period_pay: "-",
      };

      //pensionado
      if (values.laboral_status === "9") {
        valuesFormated.period_pay = values.period_pay;
      }

      //Independiente sin negocio
      if (values.laboral_status === "4") {
        valuesFormated.occupation = values.occupation;
      }

      //Si es empleado o independiente con negocio
      if (["3", "5", "6"].includes(values.laboral_status)) {
        valuesFormated = {
          ...valuesFormated,
          occupation: values.occupation,
          company: values.company,
          company_address: values.company_address,
          company_phone: values.company_phone,
          charge: values.charge,
          period_pay: values.period_pay,
        };
      }

      handleSend();
    },
  });

  // Convierte los strings vacios a -
  const convertEmptyToNull = (value: string | null) =>
    value === "" ? "-" : value;

  const handleSend = async () => {
    setIsLoading(true);
    try {
      const objToSend = {
        c126_rowid_estadolaboral: Number(formik.values.laboral_status),
        c126_rowid_ocupacion:
          Number(formik.values.occupation) === 0
            ? "674"
            : Number(formik.values.occupation),
        c126_empresa: convertEmptyToNull(formik.values.company),
        c126_num_empresa: convertEmptyToNull(formik.values.company_phone),
        c126_direccion_trabajo: convertEmptyToNull(
          formik.values.company_address
        ),
        c126_cargo: convertEmptyToNull(formik.values.charge),
        c126_periodo_pago:
          Number(formik.values.period_pay) === 0
            ? "10"
            : formik.values.period_pay,
        c200_rowid: getUser.loanRequest?.c200_rowid ?? "",
      };

      let cambioEmpresa = false;
      let esIndependiente = false;

      //validar si cambio de estado laboral y paso a ser independiente
      if (
        getValueStorage("authSession").hasFinancial.c126_rowid_estadolaboral !==
          formik.values.laboral_status &&
        ["4", "5", "6"].includes(formik.values.laboral_status)
      ) {
        esIndependiente = true;
      }

      //validar si sigue siendo empleado y cambio de empresa
      if (
        formik.values.laboral_status === "3" &&
        (getValueStorage("authSession").hasFinancial.c126_empresa + "")
          .toLocaleLowerCase()
          .trim() !== (formik.values.company + "").toLocaleLowerCase().trim()
      ) {
        cambioEmpresa = true;
      }

      //   Enviamos cambioempresa y esindependiente para realizar las llamadas en /api/app/contact_details/update_info_laboral
      await updatePasoRenovacion(
        parseRenovacion().paso,
        null,
        parseRenovacion().tipo_videollamada,
        parseRenovacion().estado_laboral,
        esIndependiente,
        cambioEmpresa
      );

      const res: any = await requestSquematic(
        "POST",
        "/api/app/contact_details/update_info_laboral",
        objToSend,
        getValueStorage("authSession").token
      );

      if (res) {
        if (parseRenovacion().tipo_videollamada === "1") {
          // Si modifico los datos de contacto, sin importar si modifico el estado laboral, se agendara videollamdada tipo 1
          await updatePasoRenovacion(
            "5",
            setIsLoading,
            parseRenovacion().tipo_videollamada,
            parseRenovacion().estado_laboral,
            esIndependiente,
            cambioEmpresa
          );
          ModalDashboard({
            element: <RequestPreAproved setIsLoading={setIsLoading} />,
          });
        } else if (["5", "6", "4"].includes(formik.values.laboral_status)) {
          // Si No modifico los datos de contacto, y si es independiente se envia a videollamada laboral tipo 2
          await updatePasoRenovacion(
            "4",
            setIsLoading,
            "2",
            parseRenovacion().estado_laboral,
            esIndependiente,
            cambioEmpresa
          );
          let dinamicElement = renovationStep({
            onClose: () => onClose(),
            setIsLoading: setIsLoading,
            parseRenovacion: parseRenovacion,
          });

          if (dinamicElement) {
            ModalDashboard({
              element: dinamicElement,
            });
          }
        } else {
          // Si No modifico los datos de contacto y sin importar si modifico el estado laboral, se envia a subir el video de la casa
          await updatePasoRenovacion(
            "4",
            setIsLoading,
            "0",
            formik.values.laboral_status,
            esIndependiente,
            cambioEmpresa
          );
          let dinamicElement = renovationStep({
            onClose: () => onClose(),
            setIsLoading: setIsLoading,
            parseRenovacion: parseRenovacion,
          });

          if (dinamicElement) {
            ModalDashboard({
              element: dinamicElement,
            });
          }
        }
      }
    } catch (error) {
      errorAlert("Error!", "Al actualizar los datos", "");
    } finally {
      setIsLoading(false);
    }
  };

  // Limpiar los campos al cambiar el estado de laboral
  const cleanInputs = (values: any) => {
    // Limpiar los errors
    formik.setTouched({}, false);
    //pensaionado
    if (values === "9") {
      formik.setFieldValue("occupation", "");
      formik.setFieldValue("company", "");
      formik.setFieldValue("company_address", "");
      formik.setFieldValue("company_phone", "");
      formik.setFieldValue("charge", "");
      setSelectedOccupation(null);
    }

    //Independiente sin negocio
    if (values === "4") {
      formik.setFieldValue("company", "");
      formik.setFieldValue("company_address", "");
      formik.setFieldValue("company_phone", "");
      formik.setFieldValue("charge", "");
      formik.setFieldValue("period_pay", "");
    }

    //Si es empleado o independiente con negocio
    if (["7", "8", "11"].includes(values)) {
      formik.setFieldValue("occupation", "");
      formik.setFieldValue("company", "");
      formik.setFieldValue("company_address", "");
      formik.setFieldValue("company_phone", "");
      formik.setFieldValue("charge", "");
      formik.setFieldValue("period_pay", "");
      setSelectedOccupation(null);
    }
  };

  // Si vienen los datos, los pintamos
  React.useEffect(() => {
    formik.setFieldValue(
      "laboral_status",
      getValueStorage("authSession").financialData.c126_rowid_estadolaboral
    );
    formik.setFieldValue(
      "company",
      getValueStorage("authSession").financialData.c126_empresa
    );
    formik.setFieldValue(
      "company_address",
      getValueStorage("authSession").financialData.c126_direccion_trabajo
    );
    formik.setFieldValue(
      "company_phone",
      getValueStorage("authSession").financialData.c126_num_empresa
    );
    formik.setFieldValue(
      "charge",
      getValueStorage("authSession").financialData.c126_cargo
    );
    formik.setFieldValue(
      "period_pay",
      getValueStorage("authSession").financialData.c126_periodo_pago
    );
    formik.setFieldValue(
      "occupation",
      getValueStorage("authSession").financialData.c126_rowid_ocupacion
    );
  }, [setLaboralStatus, setPeriodTypes, setOccupationClassification]);

  // No utilizar en onchange, porque genera ciclos infinitos
  React.useEffect(() => {
    cleanInputs(formik.values.laboral_status);
  }, [formik.values.laboral_status]);

  // Mostrar confirmacion
  const allComplete =
    getValueStorage("authSession").financialData.c126_rowid_ocupacion &&
    getValueStorage("authSession").financialData.c126_cargo &&
    getValueStorage("authSession").financialData.c126_empresa &&
    getValueStorage("authSession").financialData.c126_periodo_pago &&
    getValueStorage("authSession").financialData.c126_direccion_trabajo &&
    getValueStorage("authSession").financialData.c126_rowid_estadolaboral &&
    getValueStorage("authSession").financialData.c126_num_empresa;

  return (
    <>
      <p className="text-primary uppercase font-bold text-base mx-6 leading-5 text-center">
        {t("renovationFinancialnformation.title")}
      </p>
      <Divider className="dark:bg-white" sx={{ mt: 1.5, mx: 1.5 }} />
      <Box className="w-[98%] pt-5 overflow-x-hidden mx-auto px-3 relative text-left flex flex-col">
        <Box display={"flex"} flexDirection={"column"} alignItems={"left"}>
          {allComplete && (
            <Box sx={{ textAlign: "center" }}>
              <Box
                className="text-base "
                style={{
                  color: colors.primary,
                  fontWeight: "bold",
                }}
              >
                {t("renovationFinancialnformation.confirm")}
              </Box>
              <Box className="text-sm text-secondary-800 dark:text-white">
                {t("renovationFinancialnformation.change")}
              </Box>
              <Box className="text-sm text-secondary-800 dark:text-white">
                {t("renovationFinancialnformation.doIt")}
              </Box>
            </Box>
          )}
        </Box>

        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"left"}
          mt={allComplete ? 3 : 0}
        >
          <Grid container spacing={2} columns={12}>
            <Grid size={{ xs: 12 }}>
              <InputField
                name="laboral_status"
                formik={formik}
                label={t("financialInformation.laboralStatus")}
                type="select"
                options={laboralStatusOptions}
                disabled={getUser.isLoading}
              />
            </Grid>
            <Grid
              size={{ xs: 12 }}
              sx={{
                display: !["3", "4", "5", "6"].includes(
                  formik.values.laboral_status
                )
                  ? "none"
                  : "block",
              }}
            >
              {/* OCUPACION */}
              <Autocomplete
                options={occupationClassification}
                getOptionLabel={(option) => option.c016_descripcion}
                value={selectedOccupation}
                onChange={(event, newValue) => {
                  setSelectedOccupation(newValue);
                  // Si se selecciona una opción válida, actualizar Formik; si no, establecer en cadena vacía
                  formik.setFieldValue(
                    "occupation",
                    newValue ? newValue.c016_rowid + "" : ""
                  );
                }}
                onInputChange={(event, inputValue) => {
                  // Si el valor ingresado no tiene una coincidencia, limpiar el campo en Formik
                  const matchedOption = occupationClassification.find(
                    (option) => option.c016_descripcion === inputValue
                  );
                  if (!matchedOption) {
                    formik.setFieldValue("occupation", "");
                  }
                }}
                noOptionsText={t("common.empty")}
                renderInput={(params) => (
                  <InputField
                    {...params}
                    name="occupation"
                    label={t("financialInformation.ocupation")}
                    formik={formik}
                    placeholder={
                      occupationClassification.length === 0 ? "" : "Seleccione"
                    }
                  />
                )}
              />
            </Grid>
            {["3", "5", "6"].includes(formik.values.laboral_status) && (
              <>
                <Grid size={{ xs: 12 }}>
                  {/* EMPRESA */}
                  <InputField
                    name="company"
                    formik={formik}
                    label={t("financialInformation.company")}
                    disabled={getUser.isLoading}
                  />
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <InputField
                    name="company_address"
                    formik={formik}
                    label={t("financialInformation.companyDir")}
                    disabled={getUser.isLoading}
                  />
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <InputField
                    name="company_phone"
                    formik={formik}
                    label={t("financialInformation.companyTel")}
                    disabled={getUser.isLoading}
                    maxLength={12}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault(); // Prevenir la entrada si no es un número
                      }
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <InputField
                    name="charge"
                    formik={formik}
                    label={t("financialInformation.charge")}
                    disabled={getUser.isLoading}
                  />
                </Grid>
              </>
            )}
            {["3", "5", "6", "9"].includes(formik.values.laboral_status) && (
              <Grid
                size={{
                  xs: 12,
                }}
              >
                <InputField
                  name="period_pay"
                  formik={formik}
                  label={t("financialInformation.periodPay")}
                  type="select"
                  options={periodTypesOptions}
                  disabled={getUser.isLoading}
                />
              </Grid>
            )}
          </Grid>

          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            marginTop={4}
          >
            <ColorButton
              sx={{
                width: "fit-content",
                padding: "15px 40px",
                fontSize: 16,
              }}
              type="submit"
              onClick={() => {
                formik.handleSubmit();
              }}
              disabled={getUser.isLoading}
            >
              {t("common.continue")}
            </ColorButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};
