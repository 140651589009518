import React from "react";
import { Modal, MySwal } from "../../../../common/alerts.tsx";
import HowWorks from "./HowWorks.tsx";
import { KeyboardBackspaceIcon } from "../../../Dashboard/assets/iconsMui.tsx";
import { useTranslation } from "react-i18next";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { colors } from "../../../../styles/theme.tsx";
import {
  formatNumberPoint,
  requestSquematic,
} from "../../../../common/CommonFunctions.tsx";
import { getValueStorage } from "../../../../common/storage.ts";
import { IUserAuth } from "../../../../constants/types.tsx";

export default function MoneyReferrals() {
  const { t } = useTranslation();
  const [tableData, setTableData] = React.useState<string[][]>([]);
  const authSession: IUserAuth = getValueStorage("authSession");
  const [tableLoading, setTableLoading] = React.useState<boolean>(false);

  const fetchData = async () => {
    setTableLoading(true);
    const data = await requestSquematic(
      "GET",
      "/api/app/referred/get_ranges_configurations",
      {},
      authSession.token
    );

    if (data) {      
      setTableData(
        data.results!.slice(2).map(
          ({ c161_rango_desde, c161_rango_hasta, c161_valor_unitario }) => [
            c161_rango_desde,
            c161_rango_hasta,
            c161_valor_unitario,
          ]
        )
      );
    }

    setTableLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  //Volver atras
  const handleBack = () => {
    MySwal.close();
    Modal(<HowWorks />, {
      title: "",
      showCloseButton: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      width: 800,
      customClass: {
        popup: `bg-snow dark:bg-navy-800 px-0 sm:w-auto`,
        htmlContainer: " !overflow-x-hidden",
      },
    });
  };

  return (
    <>
      <div className="relative">
        <button
          className="absolute flex flex-row gap-1 items-center text-secondary-800 hover:opacity-80 dark:text-primary top-0 left-0 "
          onClick={handleBack}
        >
          <KeyboardBackspaceIcon />
          <p className="text-sm ">{t("common.back")}</p>
        </button>
      </div>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            marginBottom: 1,
            textTransform: "uppercase",
            color: colors.primary,
            my:4,
            lineHeight: 1
          }}
        >
          {t("moneyReferrals.title")}
        </Typography>
        <Box
          sx={{
            width: "100%",
            textAlign: "left",
            marginBottom: 3,
            fontSize: 15,
          }}
        >
          <Typography sx={{ color: colors.primary }}>
            {t("moneyReferrals.remember")}
          </Typography>
          <p className="text-base mb-1 text-left dark:text-white">
            {t("moneyReferrals.rememberDesc")}
          </p>
        </Box>
        <Box
          sx={{
            width: "100%",
            textAlign: "left",
            marginBottom: 1,
            fontSize: 15,
          }}
        >
          <Typography
            sx={{
              color: colors.primary,
              textAlign: "center",
              fontWeight: "bold",
              fontSize: 18,
            }}
          >
            {t("moneyReferrals.values")}
          </Typography>
          <p className="text-base mb-2 text-left dark:text-white">
            {t("moneyReferrals.tableDesc")}
          </p>
          <Box sx={{ maxHeight: 450, overflowY: "auto", overflowX: "auto", maxWidth: "100%" }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                width: { xs: "500px", lg: "100%"}
              }}
            >
              <TableHead>
                <TableCell
                  className={`!text-primary !font-bold !text-center !bg-[${colors.lightPrimary}]`}
                >
                  {t("moneyReferrals.tableTitle1")}
                </TableCell>
                <TableCell
                  className={`!text-primary !font-bold !text-center !bg-[${colors.lightPrimary}]`}
                >
                  {t("moneyReferrals.tableTitle2")}
                </TableCell>
                <TableCell
                  className={`!text-primary !font-bold !text-center !bg-[${colors.lightPrimary}]`}
                >
                  {t("moneyReferrals.tableTitle3")}
                </TableCell>
              </TableHead>
              <TableBody>
                {tableLoading ? (
                  <TableRow>
                    <TableCell
                      colSpan={3}
                      className="!text-center dark:!bg-navy-800 dark:!text-white"
                    >
                      {t("moneyReferrals.loading")}
                    </TableCell>
                  </TableRow>
                ) : tableData.length === 0 ? (
                  <TableRow>
                    <TableCell
                      colSpan={3}
                      className="!text-center dark:!bg-navy-800 dark:!text-white"
                    >
                      {t("moneyReferrals.tableEmpty")}
                    </TableCell>
                  </TableRow>
                ) : (
                  tableData.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className="!text-center dark:!text-white">
                        ${formatNumberPoint(parseInt(item[0]))}
                      </TableCell>
                      <TableCell className="!text-center dark:!text-white">
                        ${formatNumberPoint(parseInt(item[1]))}
                      </TableCell>
                      <TableCell className="!text-center !font-bold dark:!text-white">
                        ${formatNumberPoint(parseInt(item[2]))}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Box>
    </>
  );
}
