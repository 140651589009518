import React from "react";
import Card from "./Card.tsx";

const Widget = ({ icon, title, subtitle, type = "text" }) => {
  return (
    <Card extra="!flex-row flex-grow items-center rounded-[20px] pr-4 h-full">
      <div className="ml-[18px] flex min-h-[95px] w-auto flex-row items-center">
        <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
          <span className="flex items-center text-brand-500 dark:text-primary">
            {icon}
          </span>
        </div>
      </div>

      <div className="h-50 ml-4 flex w-auto flex-col justify-center">
        <p className="font-dm text-sm text-darkGray font-bold">{title}</p>
        {(type === "number" || type === "text") && (
          <h4 className="text-2xl sm:text-3xl leading-8 font-bold text-secondary-800 dark:text-white">
            {subtitle}
          </h4>
        )}

        {type === "date" && (
          <p className="text-2xl sm:text-3xl leading-8 font-bold text-secondary-800 dark:text-white m-0">
             {subtitle}
          </p>
        )}

        {type === "money" && (
          <h4 className="text-2xl sm:text-3xl leading-8 font-bold text-secondary-800 dark:text-white">
            ${subtitle}
          </h4>
        )}

      </div>
    </Card>
  );
};

export default Widget;
