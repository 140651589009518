import React, { ChangeEvent, ReactNode } from "react";
import {
  styled,
  TextField,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  TextFieldProps,
} from "@mui/material";
import { colors } from "../styles/theme.tsx";

// Define los tipos válidos para el atributo `type` del componente `Input`
type InputTypes =
  | "text"
  | "email"
  | "password"
  | "number"
  | "tel"
  | "url"
  | "date"
  | "textarea"
  | "select"
  | "file";

interface CustomProps {
  name: string;
  formik: any;
  label?: string;
  disabled?: boolean;
  type?: InputTypes;
  style?: React.CSSProperties;
  maxLength?: number;
  options?: Array<{ value: string | number; label: string }>; // Opciones para el select
  defaultValue?: string | number; // Valor por defecto
  noFormik?: boolean; // Evitar actualizar el Formik en caso de que el padre sea un autocomplete
  labelStyle?: React.CSSProperties;
}

type InputFieldProps = CustomProps & TextFieldProps;

export const CssTextField = styled(TextField)<{
  isError: boolean;
  isBlackTheme: boolean;
}>(({ isError, isBlackTheme }) => ({
  "& .MuiOutlinedInput-root": {
    marginTop: 5,
    "& fieldset": {
      borderColor: isError ? `${colors.red} !important` : colors.primary,
    },
    "&:hover fieldset": {
      borderColor: isError ? `${colors.red} !important` : colors.primary,
    },
    "&.Mui-focused fieldset": {
      borderColor: isError ? `${colors.red} !important` : colors.primary,
    },
  },

  "& .MuiInputBase-root.Mui-disabled": {
    "& > fieldset": {
      borderColor: colors.primary,
    },
  },

  "& .MuiOutlinedInput-input": {
    color: isBlackTheme
      ? `${colors.white} !important`
      : `${colors.black} !important`,
  },

  "& .MuiSvgIcon-root": {
    color: isBlackTheme
      ? `${colors.white} !important`
      : `${colors.black} !important`,
  },

  "& .MuiFormHelperText-root": {
    color: colors.red,
    marginLeft: 0,
    whiteSpace: "wrap",
    lineHeight: 1.1,
  },
}));

const CustomSelect = styled(Select)<{
  isError: boolean;
  isBlackTheme: boolean;
}>(({ isError, isBlackTheme }) => ({
  marginTop: 5,
  "&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
    borderColor: isError ? `${colors.red} !important` : colors.primary,
  },
  "& fieldset.MuiOutlinedInput-notchedOutline": {
    borderColor: isError ? `${colors.red} !important` : `${colors.primary} `,
  },
  "&.MuiInputBase-root.MuiOutlinedInput-root": {
    color: isBlackTheme
      ? `${colors.white} !important`
      : `${colors.black} !important`,
  },
  "& .MuiSvgIcon-root": {
    color: isBlackTheme
      ? `${colors.white} !important`
      : `${colors.black} !important`,
  },
}));

// Input con validaciones de Yup
const InputField: React.FC<InputFieldProps> = ({
  name,
  formik,
  label,
  disabled,
  type = "text",
  style = {},
  maxLength,
  options = [],
  defaultValue = 0,
  onChange,
  noFormik,
  labelStyle = {},
  ...props
}) => {
  const isError = !!formik.errors[name] && formik.touched[name];
  const [isBlackTheme, setIsBlackTheme] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsBlackTheme(document.body.classList.contains("dark"));
  }, []);

  return (
    <Box>
      {type === "select" ? (
        <Box
          sx={{
            display: "flex",
            whiteSpace: "nowrap",
            flexDirection: "column",
          }}
        >
          {/* Renderizar un select con valor por defecto 0 */}
          {/* Mapea las opciones desde el  padre. */}
          {/*
            Ejemplo:
            const sexOptions = dataPanel?.all_labels_third?.sex_indicator.map((item) => ({
                value: item.c006_valor,
                label: item.c006_descripcion,
            }));  
            - Pasarle sexOptions como opciones
            */}
          {label && (
            <label
              className=" text-secondary-800 dark:text-white"
              style={{
                fontSize: 13,
                fontWeight: 600,
                textWrap: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                color:
                  formik.errors[name] && formik.touched[name] ? colors.red : "",
                ...labelStyle,
              }}
              htmlFor={name}
              title={label}
            >
              {label}
            </label>
          )}
          <CustomSelect
            fullWidth
            id={name}
            name={name}
            value={formik.values[name]}
            isBlackTheme={isBlackTheme}
            isError={isError}
            onChange={(e) => {
              formik.handleChange(e);

              // Ejecutar la función onChange pasada desde el padre si existe
              if (onChange && typeof onChange === "function") {
                onChange(
                  e as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                );
              }
            }}
            defaultValue={defaultValue}
            disabled={disabled}
            displayEmpty
            style={style}
            sx={{
              width: "100%",
              input: {
                "&::placeholder": {
                  color: isBlackTheme ? colors.bone : colors.silver,
                  opacity: 1,
                },
              },
              "&:hover": {
                "&& fieldset": {
                  borderColor: colors.primary,
                },
              },
            }}
            renderValue={(selected) => {
              // Placeholder con texto "Seleccione" que no aparece en la lista
              const selectedOption = options.find(
                (option) => option.value === selected
              );
              return (
                <span
                  style={{
                    color: selectedOption
                      ? "inherit"
                      : isBlackTheme
                      ? colors.bone
                      : colors.silver,
                  }}
                >
                  {selectedOption ? selectedOption.label : "Seleccione"}
                </span>
              );
            }}
          >
            {options?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </CustomSelect>
          {formik.errors[name] && formik.touched[name] ? (
            <FormHelperText
              style={{
                color: colors.red,
                fontSize: 11,
                width: "100%",
                marginLeft: 0,
                marginRight: 0,
                lineHeight: 1.1,
                marginTop: 5,
                whiteSpace: "wrap",
              }}
            >
              * {formik.errors[name]}
            </FormHelperText>
          ) : (
            ""
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            whiteSpace: "nowrap",
            flexDirection: "column",
          }}
        >
          {label && (
            <label
              className=" text-secondary-800 dark:text-white"
              style={{
                fontSize: 13,
                fontWeight: 600,
                color:
                  formik.errors[name] && formik.touched[name] ? colors.red : "",
                textOverflow: "ellipsis",
                overflow: "hidden",
                ...labelStyle,
              }}
              htmlFor={name}
              title={label}
            >
              {label}
            </label>
          )}
          <CssTextField
            fullWidth
            label=""
            type={type}
            id={name}
            name={name}
            value={formik.values[name] || ""}
            error={formik.touched[name] && Boolean(formik.errors[name])}
            disabled={disabled}
            onChange={(e) => {
              const inputValue = e.target.value;
              // Capitalizar la primera letra de cada palabra
              const capitalizedValue = inputValue
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
              // Si "noFormik" es falso,se pasa el valor a la función onChange
              if (!noFormik) {
                formik.setFieldValue(
                  name,
                  type === "textarea" ? inputValue : capitalizedValue
                );
              }
              // Ejecutar la función onChange pasada desde el padre si existe
              if (onChange) {
                onChange(e);
              }
            }}
            onBlur={formik.handleBlur}
            style={style}
            sx={{
              input: {
                "&::placeholder": {
                  color: isBlackTheme ? colors.bone : colors.silver,
                  opacity: 1,
                },
              },
            }}
            isError={isError}
            isBlackTheme={isBlackTheme}
            multiline={type === "textarea"}
            maxRows={3}
            minRows={2}
            inputProps={{
              maxLength: maxLength,
              ...(type === "date" && {
                max: new Date().toISOString().split("T")[0],
              }),
            }}
            helperText={
              formik.errors[name] && formik.touched[name] ? (
                <FormHelperText
                  style={{
                    color: colors.red,
                    fontSize: 11,
                    width: "100%",
                    marginLeft: 0,
                    marginRight: 0,
                    lineHeight: 1,
                  }}
                >
                  * {formik.errors[name]}
                </FormHelperText>
              ) : (
                ""
              )
            }
            {...(type === "date"
              ? { max: new Date().toISOString().split("T")[0] }
              : {})}
            {...props}
          />
        </Box>
      )}
    </Box>
  );
};

export default InputField;
