import { IRegisterStep } from "../pages/User/RegisterComponent";

export type reasonsVcResponse = { c157_motivo: string; c157_rowid: string };

export type mockSelectBasic = { id: string; name: string };

export const replacements = {
  á: "a",
  é: "e",
  í: "i",
  ó: "o",
  ú: "u",
  Á: "A",
  É: "E",
  Í: "I",
  Ó: "O",
  Ú: "U",
};

export const monthsSpanishLabel = {
  0: "Enero",
  1: "Febrero",
  2: "Marzo",
  3: "Abril",
  4: "Mayo",
  5: "Junio",
  6: "Julio",
  7: "Agosto",
  8: "Septiembre",
  9: "Octubre",
  10: "Noviembre",
  11: "Diciembre",
};

export const weekDaysLabel = {
  0: "DO",
  1: "LU",
  2: "MA",
  3: "MI",
  4: "JU",
  5: "VI",
  6: "SA",
};

export interface Iall_id_type {
  c022_descripcion: string;
  c022_id: string;
  c022_rowid: string;
  c022_rowid_cia: string;
}

export interface IPanelAll {
  all_labels_third: {
    marital_status: {
      c006_id_tipo: string;
      c006_id_lenguaje: string;
      c006_valor: string;
      c006_descripcion: string;
    }[];
    sex_indicator: {
      c006_id_tipo: string;
      c006_id_lenguaje: string;
      c006_valor: string;
      c006_descripcion: string;
    }[];
  };
  all_id_type: Iall_id_type[];
  all_labels_financial_data: ITypesTable[];
  all_banks: IBanks[];
  all_labels_relationship: IRelationship[];
  all_period_type: IPeriodTypes[];
}

export interface IPaymentPlan {
  c202_rowid: string;
  c202_rowid_prestamo: string;
  c202_nro_cuota: string;
  c202_fecha_cuota: string;
  c202_vlr_cuota: string;
  c202_vlr_interes: string;
  c202_vlr_capital: string;
  c202_vlr_cuota_extra: string;
  c202_vlr_saldo: string;
  c202_fecha_amortizacion: string;
  c202_rowid_cuota_extra: string;
  c202_fecha_amortizacion_ext: string;
  c202_ind_estado: string;
  c202_notas: string;
  c202_fecha_creacion: string;
  c202_usuario_creacion: string;
  additional_payment_plan: IAdditionalPaymentPlan[];
}

export interface IReason_status {
  c156_rowid: string;
  c156_descripcion_sub_estado: string;
}

export interface IPeriodTypes {
  c054_descripcion: string;
  c054_estado: string;
  c054_fecha_actualizacion: string;
  c054_id: string;
  c054_ind_periodicidad: string;
  c054_notas: string;
  c054_rowid: string;
  c054_usuario_actualizacion: string;
  c054_valor_porcentual: string;
}

export interface ITypesTable {
  c006_id_tipo: string;
  c006_id_lenguaje: string;
  c006_valor: string;
  c006_descripcion: string;
}

export interface IPanelAll_Result {
  results: {
    all_labels_third: {
      marital_status: ITypesTable[];
      sex_indicator: ITypesTable[];
    };
  };
  status: string;
  errors: string;
}

export interface IFunctions {
  signIn: (userAuth: IUserAuth) => void;
  signOut: () => void;
  setIsLoading: (isLoading: boolean) => void;
  getUser: IUserAuth;
}

export interface ILocation_get {
  latitude: number | null | undefined;
  longitude: number | null | undefined;
}

export interface IpqrsData {
  id: string;
  pregunta: string;
  respuesta: string;
  pregunta_ingles: string;
  respuesta_ingles: string;
}

export interface ISimulatorData {
  c200_rowid: string;
  c200_valor: string;
  c200_rowid_ciiu: string; // default 1
  c200_plazo: string;
  c200_rowid_concepto: string; // default 10
  c200_rowid_periodo: string;
  date_first_due: string;
  due_value: string;
  total_due_to_pay: string;
}

export interface IFormDataId {
  typeId: string;
  num_id: string;
  num_id_verification: string;
  date_expedition: string;
  cedulaF: string;
  cedulaA: string;
}

export interface IParseImage {
  uri: string;
  name: string;
  type: string;
}

export interface IUserInfoLocalStorage {
  user_email: string;
  user_phone: string;
  otpEmail: string;
  otpPhone: string;
  token: string;
  password: string;
}

export interface IFinancialDetails {
  c126_cargo: string;
  c126_direccion_trabajo: string;
  c126_desc_dir_company: string;
  c126_empresa: string;
  c126_fecha_creacion: string;
  c126_id_banco: string;
  c126_img_company: string;
  c126_ingresos: string;
  c126_lat: string | null;
  c126_lat_company: string;
  c126_lon: string | null;
  c126_lon_company: string;
  c126_nro_cuenta: string;
  c126_num_empresa: string;
  c126_periodo_pago: string;
  c126_photo_url: string | null;
  c126_rowid: string;
  c126_rowid_categoria_vivienda: string;
  c126_rowid_estadolaboral: string;
  c126_rowid_ocupacion: string;
  c126_rowid_tercero: string;
  c126_tipo_cuenta: string;
  c126_usuario_creacion: string;
  c126_video_url: string | null;
  c126_videocompany_desc: string;
  c126_videocompany_lat: string;
  c126_videocompany_lon: string;
  c126_videocompany_url: string;
}

export interface IVideoCall {
  c155_comentario: string;
  c155_fecha_actualizacion: string;
  c155_fecha_agendamiento: string;
  c155_fecha_creacion: string;
  c155_fecha_inicio: string;
  c155_fecha_fin: string;
  c155_lat: string;
  c155_lon: string;
  c155_motivo: string;
  c155_rowid: string;
  c155_rowid_estado: string;
  c155_rowid_prestamo: string;
  c155_tipo: string;
  c155_url: string;
  c155_usuario_asignado: string;
  c155_usuario_creacion: string;
  calcel_reason: {
    c157_motivo: string;
    c157_rowid: string;
  } | null;
}

export interface IWalletRenovation {
  c200_rowid?: string;
  c200_rowid_nuevo_prestamo?: string;
  c200_ind_estado?: string;
  c204_paso_renovacion?: string;
}

export interface IBankingTransaction {
  c231_rowid: string;
  c231_referencia_credito: string;
  c231_fecha_transaccion: string;
  c231_rowid_proveedor: string;
  c231_valor: string;
  provider?: IProvider;
}

export interface IProvider {
  c007_rowid: string;
  c007_nombre: string;
  c007_descripcion: string;
}

export interface IRevalidation_Request {
  c110_fecha_actualizacion: string;
  c110_fecha_creacion: string;
  c110_ind_estado: string;
  c110_json: string;
  c110_notas: string;
  c110_rowid: string;
  c110_rowid_usuario: string;
  c110_usuario_creacion: string;
}

export interface IWalletContactDetails {
  c125_descripcion_de_direccion: string;
  c125_direccion1: string;
  c125_lat_barrio: string;
  c125_lon_barrio: string;
  c125_url_video_casa: string;
}

export interface InProcessRenovation {
  contactDetails?: IWalletContactDetails;
  inProcessRenovation: boolean;
}

export interface IUserAuth {
  dashboard: boolean;
  login: boolean;
  email: string;
  phone: string;
  token: string;
  photo: string;
  active: number;
  notes: string;
  referral: string;
  hasThird: boolean;
  hasContact: boolean;
  hasFinancial: IFinancialDetails;
  hasSimulate: boolean;
  hasReferences: boolean;
  hasProduct: boolean;
  hasRejected: boolean;
  hasOfferedAgain: boolean;
  hasValidations: boolean;
  hasVideoCall: IVideoCall;
  loanApproved: IWalletRenovation;
  revalidateLaborDocs: IRevalidation_Request;
  revalidateLaborVideo: IRevalidation_Request;
  revalidateHouseVideo: IRevalidation_Request;
  videoCallOfferDate: string;
  isLoading: boolean;
  loanActive?: IWalletRenovation; // Estado de renovacion con c200_rowid_nuevo_prestamo parecido a c200_rowid anterior de loanRequest
  loanPending?: IWalletRenovation; // Si hay creditos finalizados envia null
  loanRequest?: IWalletRenovation; // c200_rowid anterior
  lastLoanFinished?: {
    last_banking_transaction_extension: IBankingTransaction;
  }; // Estado para consultar la ultima transaccion en credito finalizado
  loanEmpty?: boolean;
  getLocation?: ILocation_get;
  houseTenure?: string;
  inProcessRenovation?: InProcessRenovation; // Estado para saber si el usuario esta en proceso de renovacion
  financialData?: IFinantialInformation_Request; // Datos financieros del usuario
  hasVideoCallThird?: IVideoCall;
  userId?: number;
  isOld?: boolean; // Ha tenido creditos finalizados
  credits_finished?: number;
}

//Interface de la informacion almacenada en el proceso de registro parte inicial (fotos cedula y selfie)
export interface IRegisterProcessSelfieId {
  type_id: string;
  id: string;
  id_verification: string;
  date_expedition: string;
  cedulaF: string;
  cedulaA: string;
  selfie: string;
  agreedTerms: boolean;
  riskCenter: boolean;
}

export interface IRegisterFinancial {
  charge: string;
  company: string;
  company_address: string;
  company_phone: string;
  houseTenure: string;
  laboral_status: string;
  occupation: string;
  period_pay: string;
  salary: string;
}

//Interface para datos financieros
export interface bank {
  c021_id: string;
  c021_descripcion: string;
  c021_notas: string;
  c021_fecha_creacion: string;
  c021_usuario_creacion: string;
}

export interface period_types {
  c054_rowid: string | number;
  c054_id?: string;
  c054_descripcion: string;
  c054_ind_periodicidad?: string;
  c054_notas?: string;
  c054_fecha_actualizacion?: string;
  c054_usuario_actualizacion?: string;
  c054_estado?: string;
  c054_valor_porcentual?: string;
}

export interface accountTypes {
  c006_id_tipo: string;
  c006_id_lenguaje: string;
  c006_valor: string;
  c006_descripcion: string;
}

export interface Ilaboral_status {
  c019_descripcion: string;
  c019_rowid: string | number;
}

export interface occupation_classification {
  c016_rowid: number;
  c016_id: string;
  c016_descripcion: string;
  c016_notas: string;
  c016_fecha_creacion: string;
  c016_usuario_creacion: string;
}

export interface IBanks {
  c021_descripcion: string;
  c021_fecha_creacion: string;
  c021_id: string;
  c021_notas: string;
  c021_usuario_creacion: string;
}

export interface IRelationship {
  c006_descripcion: string;
  c006_id_lenguaje: string;
  c006_id_tipo: string;
  c006_valor: string;
}

export interface IFinantialInformation_selects {
  status: string;
  errors?: string;
  results?: {
    period_types: period_types[];
    banks: bank[];
    laboral_status: Ilaboral_status[];
    occupation_classification: occupation_classification[];
    account_type: accountTypes[];
    housing_category: any[];
    housing_type: any[];
  };
}

export interface IFinantialInformation_Request {
  c126_rowid_ocupacion: string | null;
  c126_cargo: string | null;
  c126_empresa: string | null;
  c126_ingresos?: string;
  c126_periodo_pago: string;
  c126_id_banco?: string;
  c126_nro_cuenta?: string;
  c126_tipo_cuenta?: string;
  c126_direccion_trabajo: string | null;
  c126_rowid_categoria_vivienda?: string | null;
  c126_rowid_estadolaboral: string;
  c126_num_empresa: string | null;
}

export interface IReferencesForm {
  phone: string;
  name: string;
  lastname: string;
  relationship: string;
  address: string;
  department: string;
  city: string;
  state_refences: boolean;
}

export interface IRequestDocuments_Request {
  c200_rowid: string;
  c110_rowid: string;
  c155_rowid: string;
  c126_direccion_trabajo: string;
  c126_desc_dir_company: string;
  c126_lat_company: string;
  c126_lon_company: string;
  c125_url_carta_laboral?: string | null;
  c125_url_camara_comercio?: string | null;
  c125_url_extracto_bancario?: string | null;
  c126_videocompany_url?: string | null;
  c126_videocompany_desc?: string | null;
  c155_rowid_estado: string | null;
}

export interface IParseImage {
  uri: string;
  name: string;
  type: string;
}

type debtLoan = {
  c2_c200_rowid: string;
  c2_c202_rowid: string;
  c2_c202_valor: string;
  c2_c210_valor_complementos: string;
  c2_c211_sanciones: string;
  c2_c211_cobranza: string;
  c2_dias_atraso: string;
  c2_c230_valor_pendiente: string;
  c2_total: string;
  c2_total_paz_salvo: string;
  c2_c202_fecha_cuota: string;
};

export type IAdditionalPaymentPlan = {
  c210_rowid: string;
  c210_rowid_prestamo: string;
  c210_rowid_plan_c202: string;
  c210_rowid_item_c051: string;
  c210_vlr_cuota: string;
  c210_ind_estado: string;
  c210_notas: string;
  c210_fecha_creacion: string;
  c210_usuario_creacion: string;
  c210_nro_cuota: string;
};

export type singleLoan = {
  c202_rowid: string;
  c202_rowid_prestamo: string;
  c202_nro_cuota: string;
  c202_fecha_cuota: string;
  c202_vlr_cuota: string;
  c202_vlr_interes: string;
  c202_vlr_capital: string;
  c202_vlr_cuota_extra: string;
  c202_vlr_saldo: string;
  c202_fecha_amortizacion: string;
  c202_rowid_cuota_extra: string;
  c202_fecha_amortizacion_ext: string;
  c202_ind_estado: string;
  c202_notas: string;
  c202_fecha_creacion: string;
  c202_usuario_creacion: string;
  complements: IAdditionalPaymentPlan[];
  industrial_classification: {
    c017_rowid: string;
    c017_id: string;
    c017_descripcion: string;
    c017_notas: string;
    c017_rowid_imagen: string;
    c017_fecha_creacion: string;
    c017_usuario_creacion: string;
    c017_order: string;
    c017_thumbnail: string;
  };
  concept: {
    c050_rowid: string;
    c050_id: string;
    c050_descripcion: string;
    c050_porc_interes: string;
    c050_vlr_unitario: string;
    c050_rowid_grupo_header: string;
    c050_rowid_grupo_body: string;
    c050_rowid_grupo_adjuntos: string;
    c050_notas: string;
    c050_fecha_creacion: string;
    c050_usuario_creacion: string;
    c050_rowid_empresa: string;
    c050_rowid_imagen: string;
    c050_thumbnail: string;
  };
  payment_plans: {
    c202_rowid: string;
    c202_rowid_prestamo: string;
    c202_nro_cuota: string;
    c202_fecha_cuota: string;
    c202_vlr_cuota: string;
    c202_vlr_interes: string;
    c202_vlr_capital: string;
    c202_vlr_cuota_extra: string;
    c202_vlr_saldo: string;
    c202_fecha_amortizacion: string;
    c202_rowid_cuota_extra: string;
    c202_fecha_amortizacion_ext: string;
    c202_ind_estado: string;
    c202_notas: string;
    c202_fecha_creacion: string;
    c202_usuario_creacion: string;
  }[];
  plan_payment_penalty: Penalties | null;
};

export type Penalties = {
  c211_rowid: string;
  c211_rowid_prestamo: string;
  c211_rowid_plan_c202: string;
  c211_valor_complementos: string;
  c211_nro_cuota: string;
  c211_valor_cuota: string;
  c211_fecha_cuota: string;
  c211_fecha_mora: string;
  c211_dias_atraso: string;
  c211_tasa_encontrada: string;
  c211_valor_sancion: string;
  c211_ind_estado: string;
  c211_notas: string;
  c211_fecha_creacion: string;
  c211_usuario_creacion: string;
  c211_valor_total: string;
  c211_gastos_cobranza: string;
};

type loan_description = {
  debt: debtLoan[];
  label: string;
  info: singleLoan[];
  normalDue: string;
  vigency: boolean;
  minDue: string;
  disbursedValue: string;
  nextPayment: {
    due: {
      c202_rowid: string;
      c202_rowid_prestamo: string;
      c202_nro_cuota: string;
      c202_fecha_cuota: string;
      c202_vlr_cuota: string;
      c202_vlr_interes: string;
      c202_vlr_capital: string;
      c202_vlr_cuota_extra: string;
      c202_vlr_saldo: string;
      c202_fecha_amortizacion: string;
      c202_rowid_cuota_extra: string;
      c202_fecha_amortizacion_ext: string;
      c202_ind_estado: string;
      c202_notas: string;
      c202_fecha_creacion: string;
      c202_usuario_creacion: string;
    };
    valueToPay: string;
  };
  complements: IAdditionalPaymentPlan[];
};

export type loans = {
  parcial_payment: {
    c260_rowid: string;
    c260_rowid_prestamo: string;
    c260_valor_acumulado: string;
    c260_ind_estado: string;
  };
  monto: string;
  concepto: string;
  NDP: string;
  c200_rowid: string;
  c200_rowid_periodo: string;
  c200_rowid_concepto: string;
  c200_rowid_nuevo_prestamo: string;
  industrialClassification: {
    c017_rowid: string;
    c017_descripcion: string;
  };
  c200_plazo: string;
  description: string;
  c200_fecha_creacion: string;
  c200_fecha_de_desembolso: string;
  c200_renovacion: string;
  c200_valor: string;
  additionalInfo: loan_description;
  c200_ind_estado: string;
  banking_transaction: {
    c230_rowid: string;
    c230_nro_cuota: string;
    c230_rowid_prestamo: string;
    c230_valor_recaudo: string;
    c230_valor_pendiente: string;
  }[];
  payment_plan_penalites: Penalties[];
  payment_plans: IPaymentPlan[];
  last_loan_forgiveness_active: {
    c250_rowid: string;
    c250_rowid_prestamo: string;
    c250_ind_tipo_proceso: string;
    c250_fecha_hasta: string;
    plazo_restante: string;
    valor_pendiente_pagar: string;
    valor_condonado: string;
    c250_ind_indefinido: string;
  };
};

export interface PersonType {
  id: string;
  name: string;
}

export interface LoanDetails {
  value_to_paid: string;
  loanId: string;
  personType: PersonType;
  creditLine: string;
  amount: number;
  dues: number;
  period: number;
  valPerDue: number;
  isRenovation: boolean;
  periodDescription: string;
  c200_fecha_creacion: string;
  payment_plan_penalites: any;
  banking_transaction: any;
  c200_ind_estado: string;
  c200_rowid_nuevo_prestamo: string;
  minDue: string;
  penalties_to_pay: string;
  loans: PropItemsLoanInit | null;
}

export interface Loan {
  loan: LoanDetails;
}

export interface IGetLoanRequestedOrApproved {
  c200_rowid: string;
  c200_valor: string;
  c200_plazo: string;
  c200_rowid_periodo: string;
  c200_ind_estado: string;
  c200_fecha_de_desembolso: string;
  register_status: {
    c204_rowid_prestamo: string;
    c204_ind_estado_actual: string;
    c204_fecha_creacion: string;
    c204_usuario_creacion: string;
  }[];
  concept: {
    c050_rowid: string;
    c050_descripcion: string;
  };
  horizontalPosition: string;
  verticalPosition: string;
}

export type IHomeData_result = {
  status: string;
  errors?: string;
  results?: {
    pie_diagram: {
      monto: string;
      concepto: string;
    }[];
    loans: loans[];
    pending_loans: IGetLoanRequestedOrApproved[];
    contactDetails: IWalletContactDetails;
  };
};

export interface IGetFinancialDataByThird {
  status: string;
  errors?: string;
  results?: {
    c126_rowid: string;
    c126_rowid_tercero: string;
    c126_rowid_ocupacion: string;
    c126_cargo: string;
    c126_empresa: string;
    c126_ingresos: string;
    c126_periodo_pago: string;
    c126_id_banco: string;
    c126_nro_cuenta: string;
    c126_tipo_cuenta: string;
    c126_fecha_creacion: string;
    c126_usuario_creacion: string;
    c126_direccion_trabajo: string | null;
    c126_rowid_categoria_vivienda?: string | null;
    c126_rowid_estadolaboral: string;
    c126_num_empresa: string | null;
  };
}

export interface IFinantialInformation_selects {
  status: string;
  errors?: string;
  results?: {
    period_types: period_types[];
    banks: bank[];
    laboral_status: Ilaboral_status[];
    occupation_classification: occupation_classification[];
    account_type: accountTypes[];
    housing_category: any[];
    housing_type: any[];
  };
}

export interface PropsPenalty {
  c211_dias_atraso: string;
  c211_valor_total: string;
  c211_valor_complementos: string;
  c211_valor_cuota: string;
  c211_valor_sancion: string;
  c211_gastos_cobranza: string;
}

export interface IReferrals {
  c160_rowid_usuario_referente: string;
  c160_rowid_usuario_referido: string;
  c160_ind_estado: string;
  c160_valor_pagado: string;
  c160_notas: string;
  c160_fecha_creacion: string;
  c160_fecha_actualizacion: string;
  c160_fecha_pago: string;
  c160_fecha_solicitud: string;
  c160_rowid: string;
  c160_ind_estado_solicitud: string;
}

export interface IThird {
  c120_rowid: string;
  c120_rowid_cia: string;
  c120_id: string;
  c120_nit: string;
  c120_id_tipo_ident: string;
  c120_ind_tipo_tercero: string;
  c120_razon_social: string;
  c120_apellido1: string;
  c120_apellido2: string;
  c120_nombres: string;
  c120_rowid_contacto: string;
  c120_fecha_nacimiento: string;
  c120_ind_estado: string;
  c120_rowid_foto: string;
  c120_ind_sexo: string;
  c120_ind_estado_civil: string;
  c120_rowid_usuario: string;
  c120_fecha_creacion: string;
  c120_usuario_creacion: string;
  c120_rowid_grupo_adjuntos: string;
  c012_ind_estado_inscripcion_bancaria: string;
  c012_fecha_ind_estado_inscripcion_bancaria: string;
  c120_fecha_expedicion_cedula: string;
}

export interface IWalletLoan {
  c200_rowid: string;
  c200_rowid_cia: string;
  c200_rowid_tercero: string;
  c200_rowid_concepto: string;
  c200_nro_prestamo: string;
  c200_fecha_prestamo: string;
  c200_valor: string;
  c200_tasa: string;
  c200_plazo: string;
  c200_ind_modo_pago: string;
  c200_rowid_periodo: string;
  c200_fecha_interes_desde: string;
  c200_fecha_primera_cuota: string;
  c200_ind_cuotas_extras: string;
  c200_nro_cuotas_extras: string;
  c200_vlr_cuotas_extras: string;
  c200_vlr_presente_cuo_ext: string;
  c200_vlr_cuota: string;
  c200_vlr_acum_cuotas_cap: string;
  c200_vlr_acum_extras_cap: string;
  c200_vlr_acum_intereses: string;
  c200_fecha_inactivacion: string;
  c200_usuario_inactivacion: string;
  c200_fecha_suspendido: string;
  c200_usuario_suspendido: string;
  c200_fecha_reestructuracion: string;
  c200_rowid_nuevo_prestamo: string;
  c200_usuario_reestructura: string;
  c200_fecha_castigo_cartera: string;
  c200_ind_estado: string;
  c200_notas: string;
  c200_fecha_creacion: string;
  c200_usuario_creacion: string;
  c200_ind_estado_desembolso: string;
  c200_ind_autor_desembolso: string;
  c200_rowid_ciiu: string;
  c200_rowid_usuario: string;
  c200_fecha_de_desembolso: string;
  loan_father: IWalletLoan;

  payment_plan_penalites: {
    c211_rowid: string;
    c211_rowid_prestamo: string;
    c211_rowid_plan_c202: string;
    c211_valor_complementos: string;
    c211_nro_cuota: string;
    c211_valor_cuota: string;
    c211_fecha_cuota: string;
    c211_fecha_mora: string;
    c211_dias_atraso: string;
    c211_tasa_encontrada: string;
    c211_valor_sancion: string;
    c211_ind_estado: string;
    c211_notas: string;
    c211_fecha_creacion: string;
    c211_usuario_creacion: string;
    c211_valor_total: string;
    c211_gastos_cobranza: string;
  }[];
  register_status: {
    c204_rowid: string;
    c204_rowid_prestamo: string;
    c204_ind_estado_anterior: string;
    c204_ind_estado_actual: string;
    c204_rowid_c180: string;
    c204_notas: string;
    c204_fecha_creacion: string;
    c204_usuario_creacion: string;
    c204_paso_renovacion: string;
  }[];
  disbursed_states: {
    c207_rowid: string;
    c207_rowid_prestamo: string;
    c207_ind_estado_anterior: string;
    c207_ind_estado_actual: string;
    c207_notas: string;
    c207_fecha_creacion: string;
    c207_usuario_creacion: string;
  }[];
  disbursed_value: {
    c240_rowid: string;
    c240_rowid_prestamo: string;
    c240_vlr_desembolso: string;
    c240_vlr_deuda_saldada: string;
    c240_notas: string;
    c240_fecha_creacion: string;
    c240_usuario_creacion: string;
    c240_vlr_cobranzas: string;
    c240_vlr_sancion: string;
    c240_vlr_cuotas_adeudadas: string;
    c240_vlr_complementos: string;
    c240_vlr_pen_parciales: string;
  };
  third: IThird & {
    is_old: IWalletLoan[];
    attached_file: {
      c120_rowid: string;
      c180_ruta: string;
    };
    user: IUser & {
      referral_user: IUser;
    };
    financial_data: {
      c126_rowid: string;
      c126_rowid_tercero: string;
      c126_rowid_ocupacion: string;
      c126_cargo: string;
      c126_empresa: string;
      c126_ingresos: string;
      c126_periodo_pago: string;
      c126_id_banco: string;
      c126_nro_cuenta: string;
      c126_tipo_cuenta: string;
      c126_fecha_creacion: string;
      c126_usuario_creacion: string;
      c126_direccion_trabajo: string;
    };
    contacts_details: {
      c125_descripcion_de_direccion: string;
      c125_rowid: string;
      c125_rowid_cia: string;
      c125_contacto: string;
      c125_direccion1: string;
      c125_direccion2: string;
      c125_id_pais: string;
      c125_id_depto: string;
      c125_id_ciudad: string;
      c125_id_barrio: string;
      c125_cod_postal: string;
      c125_estrato: string;
      c125_telefono: string;
      c125_celular: string;
      c125_fecha_creacion: string;
      c125_usuario_creacion: string;
      c125_rowid_tercero: string;
      c125_rowid_parentesco: string;
      country: {
        c101_id: string;
        c101_descripcion: string;
      };
      department: {
        c102_id_pais: string;
        c102_id: string;
        c102_descripcion: string;
      };
      city: {
        c103_id_pais: string;
        c103_id_depto: string;
        c103_id: string;
        c103_descripcion: string;
        c103_indicativo: string;
      }[];
      neighborhood: {
        c104_id_pais: string;
        c104_id_depto: string;
        c104_id_ciudad: string;
        c104_id: string;
        c104_descripcion: string;
      };
      relation_ship: {
        c006_descripcion: string;
        c006_valor: string;
        c006_id_lenguaje: string;
        c006_id_tipo: string;
      };
    }[];
  };
  get_complete_credit_information_to_date: {
    totalbalancedue: string;
    value_penaltie: string;
    value_collection: string;
    total: string;
  };
  plan_payment_penalty: {
    c211_rowid: string;
    c211_rowid_prestamo: string;
    c211_rowid_plan_c202: string;
    c211_valor_complementos: string;
    c211_nro_cuota: string;
    c211_valor_cuota: string;
    c211_fecha_cuota: string;
    c211_fecha_mora: string;
    c211_dias_atraso: string;
    c211_tasa_encontrada: string;
    c211_valor_sancion: string;
    c211_ind_estado: string;
    c211_notas: string;
    c211_fecha_creacion: string;
    c211_usuario_creacion: string;
    c211_valor_total: string;
    c211_gastos_cobranza: string;
  }[];
  get_complete_credit_information: {
    value_penaltie: string;
    value_collection: string;
    total: string;
    totalbalancedue: string;
  };
  payment_plans_paid: {
    baking_t: any[];
    c202_rowid: string;
    c202_rowid_prestamo: string;
    c202_nro_cuota: string;
    c202_fecha_cuota: string;
    c202_vlr_cuota: string;
    c202_vlr_interes: string;
    c202_vlr_capital: string;
    c202_vlr_cuota_extra: string;
    c202_vlr_saldo: string;
    c202_fecha_amortizacion: string;
    c202_rowid_cuota_extra: string;
    c202_fecha_amortizacion_ext: string;
    c202_ind_estado: string;
    c202_notas: string;
    c202_fecha_creacion: string;
    c202_usuario_creacion: string;
    plan_payment_penalty: {
      c211_rowid: string;
      c211_rowid_prestamo: string;
      c211_rowid_plan_c202: string;
      c211_valor_complementos: string;
      c211_nro_cuota: string;
      c211_valor_cuota: string;
      c211_fecha_cuota: string;
      c211_fecha_mora: string;
      c211_dias_atraso: string;
      c211_tasa_encontrada: string;
      c211_valor_sancion: string;
      c211_ind_estado: string;
      c211_notas: string;
      c211_fecha_creacion: string;
      c211_usuario_creacion: string;
      c211_valor_total: string;
      c211_gastos_cobranza: string;
    }[];
    additional_payment_plan: IAdditionalPaymentPlan[];
  }[];
  payment_plans: {
    baking_t: any[];
    c202_rowid: string;
    c202_rowid_prestamo: string;
    c202_nro_cuota: string;
    c202_fecha_cuota: string;
    c202_vlr_cuota: string;
    c202_vlr_interes: string;
    c202_vlr_capital: string;
    c202_vlr_cuota_extra: string;
    c202_vlr_saldo: string;
    c202_fecha_amortizacion: string;
    c202_rowid_cuota_extra: string;
    c202_fecha_amortizacion_ext: string;
    c202_ind_estado: string;
    c202_notas: string;
    c202_fecha_creacion: string;
    c202_usuario_creacion: string;
    plan_payment_penalty: {
      c211_rowid: string;
      c211_rowid_prestamo: string;
      c211_rowid_plan_c202: string;
      c211_valor_complementos: string;
      c211_nro_cuota: string;
      c211_valor_cuota: string;
      c211_fecha_cuota: string;
      c211_fecha_mora: string;
      c211_dias_atraso: string;
      c211_tasa_encontrada: string;
      c211_valor_sancion: string;
      c211_ind_estado: string;
      c211_notas: string;
      c211_fecha_creacion: string;
      c211_usuario_creacion: string;
      c211_valor_total: string;
      c211_gastos_cobranza: string;
    }[];
    additional_payment_plan: IAdditionalPaymentPlan[];
  }[];
  loan_new: IWalletLoan | null;
  period_type: {
    c054_rowid: string;
    c054_id: string;
    c054_descripcion: string;
    c054_ind_periodicidad: string;
    c054_notas: string;
    c054_fecha_actualizacion: string;
    c054_usuario_actualizacion: string;
    c054_valor_porcentual: string;
    c054_estado: string;
  };
  banking_transaction_extension: {
    c231_rowid: string;
    c231_rowid_proveedor: string;
    c231_sucursal: string;
    c231_valor: string;
    c231_fecha_transaccion: string;
    c231_referencia_credito: string;
    c231_notas: string;
    c231_ind_estado: string;
    c231_xml: string;
    c231_xml_reverso: string;
  }[];
  banking_transaction: {
    c230_rowid: string;
    c230_rowid_prestamo: string;
    c230_rowid_plan_c202: string;
    c230_nro_cuota: string;
    c230_valor_recaudo: string;
    c230_fecha_recaudo: string;
    c230_fecha_mora: string;
    c230_valor_mora: string;
    c230_canal_recepcion: string;
    c230_fecha_registro: string;
    c230_ind_estado: string;
    c230_notas: string;
    c230_fecha_creacion: string;
    c230_usuario_creacion: string;
    c230_rowid_tercero: string;
    c230_fecha_hasta: string;
    c230_valor_pendiente: string;
    c230_rowid_extension: string;
    c230_valor_pdte_capital: string;
  }[];
  next_dues: {
    c202_rowid: string;
    c202_rowid_prestamo: string;
    c202_nro_cuota: string;
    c202_fecha_cuota: string;
    c202_vlr_cuota: string;
    c202_vlr_interes: string;
    c202_vlr_capital: string;
    c202_vlr_cuota_extra: string;
    c202_vlr_saldo: string;
    c202_fecha_amortizacion: string;
    c202_rowid_cuota_extra: string;
    c202_fecha_amortizacion_ext: string;
    c202_ind_estado: string;
    c202_notas: string;
    c202_fecha_creacion: string;
    c202_usuario_creacion: string;
    baking_t: {
      c230_rowid: string;
      c230_rowid_prestamo: string;
      c230_rowid_plan_c202: string;
      c230_nro_cuota: string;
      c230_valor_recaudo: string;
      c230_fecha_recaudo: string;
      c230_fecha_mora: string;
      c230_valor_mora: string;
      c230_canal_recepcion: string;
      c230_fecha_registro: string;
      c230_ind_estado: string;
      c230_notas: string;
      c230_fecha_creacion: string;
      c230_usuario_creacion: string;
      c230_rowid_tercero: string;
      c230_fecha_hasta: string;
      c230_valor_pendiente: string;
      c230_rowid_extension: string;
      c230_valor_pdte_capital: string;
    }[];
    additional_payment_plan: IAdditionalPaymentPlan[];
  }[];
}

export interface IReferralsConfig {
  c161_fecha_actualizacion: string;
  c161_fecha_creacion: string;
  c161_notas: string;
  c161_rango_desde: string;
  c161_rango_hasta: string;
  c161_rowid: string;
  c161_valor_unitario: string;
  c161_tipo: string;
}

export interface IUser {
  c001_rowid: string;
  c001_esactivo: number;
  c001_correo_electronico: string;
  c001_numero_celular: string;
  c001_notas: string;
  c001_token_dispositivo: string;
  c001_role_id: string;
  c001_cod_lenguaje: string;
  c001_codigo_referido: string;
  c001_rowid_usuario_ref: string;
  c001_email_otp: string;
  c001_sms_otp: string;
  c001_fecha_creacion: string;
}

export interface ITipoIdent {
  c022_rowid: string;
  c022_rowid_cia: string;
  c022_id: string;
  c022_descripcion: string;
}

export type IWalletUser = IUser & {
  third?: IThird & {
    id_type: ITipoIdent;
    financial_data: {
      c126_rowid: string;
      c126_rowid_tercero: string;
      c126_rowid_ocupacion: string;
      c126_cargo: string;
      c126_empresa: string;
      c126_ingresos: string;
      c126_periodo_pago: string;
      c126_id_banco: string;
      c126_nro_cuenta: string;
      c126_tipo_cuenta: string;
      c126_fecha_creacion: string;
      c126_usuario_creacion: string;
      c126_direccion_trabajo: string;
    };
    first_loan: IWalletLoan | null;
  };
  referrals: (IReferrals & {
    user_referred: IUser & { first_loan: IWalletLoan | null };
  })[];
  referrer: IReferrals & {
    user_referrer: IUser & { third: IThird };
  };
};

export type IReferredUserReferrals = IReferrals & {
  user_referred: IWalletUser;
};

export interface PropItemsLoanInit {
  first_pay_date?: string;
  name: string;
  payTime: string;
  currentDues: string;
  total: string;
  date: string;
  limitDate: string;
  totalCredit: string;
  totalDue: string;
  fullDue: string;
  minDue: string;
  vigency: boolean;
  debt: boolean;
  initialDate: string;
  originalNumber: number;
  monto: string;
  c200_rowid: string;
  plan_payment_penalty: PropsPenalty | null;
  complementsValue: string;
  complemetsPerDue: string;
  c200_renovacion: string;
  c200_ind_estado: string;
  c200_plazo: string;
  c200_fecha_creacion: string;
  c200_rowid_periodo: string;
  c200_rowid_nuevo_prestamo: string;
  description: string;
  c200_rowid_concepto: string;
  industrial_classification: {
    c017_rowid: string;
    c017_descripcion: string;
  };
  banking_transaction: {
    c230_rowid: string;
    c230_rowid_prestamo: string;
    c230_valor_recaudo: string;
  }[];
  payment_plan_penalites: Penalties[];
  penalties_to_pay: string;
  condonado: ICondonado | null;
  congelado: ICongelado | null;
  totalSaldoEnMenor: string;
}

export interface ICondonado {
  valor_pagar: string;
  valor_no_condonado: string;
  valor_condonado: string;
  fecha_proximo_pago: string;
  fecha_limite_pago: string;
  diffDias: string;
  indefinido: string;
}

export interface ICongelado {
  valor_pendiente: string;
  valor_pagar: string;
  fecha_proximo_pago: string;
  fecha_limite_pago: string;
  diffDias: string;
  indefinido: string;
}

export interface ILoanMapping {
  amount: number;
  value_to_paid: string;
  loanId: string;
  personType: {
    id: string;
    name: string;
  };
  creditLine: string;
  dues: number;
  period: number;
  valPerDue: number;
  isRenovation: boolean;
  periodDescription: string;
  c200_fecha_creacion: string;
  banking_transaction: {
    c230_rowid_prestamo: string;
    c230_valor_recaudo: string;
  }[];
  payment_plan_penalites: Penalties[];
  c200_ind_estado: string;
  minDue: string;
  penalties_to_pay: string;
}

export interface navigationModals {
  onClose?: any;
  setIsLoading: (isLoading: boolean) => void;
  parseRenovacion?: () => any;
  setRegisterStep?: React.Dispatch<React.SetStateAction<IRegisterStep>>;
  parseRenovacionCustom?: IParseRenovation;
}

export interface IParseRenovation {
  paso: string;
  tipo_videollamada: string;
  estado_laboral: string;
  es_independiente: boolean;
  cambio_empresa: boolean;
}

export interface IPaymentPlans {
  additional_payment_plan: IAdditionalPaymentPlan[];
  baking_t: {
    c230_fecha_recaudo: string;
    c230_ind_estado: string;
    c230_rowid_plan_c202: string;
    c230_valor_pendiente: string;
    c230_valor_recaudo: string;
  }[];
  c202_fecha_cuota: string;
  c202_ind_estado: string;
  c202_rowid: string;
  c202_rowid_prestamo: string;
  c202_vlr_cuota: string;
  c202_nro_cuota: string;
}

export interface loanResponse {
  c200_rowid: string;
  c200_rowid_cia: string;
  c200_rowid_tercero: string;
  c200_rowid_concepto: string;
  c200_nro_prestamo: string;
  c200_fecha_prestamo: string;
  c200_valor: string;
  c200_tasa: string;
  c200_plazo: string;
  c200_ind_modo_pago: string;
  c200_rowid_periodo: string;
  c200_fecha_interes_desde: string;
  c200_fecha_primera_cuota: string;
  c200_ind_cuotas_extras: string;
  c200_nro_cuotas_extras: string;
  c200_vlr_cuotas_extras: string;
  c200_vlr_presente_cuo_ext: string;
  c200_vlr_cuota: string;
  c200_vlr_acum_cuotas_cap: string;
  c200_vlr_acum_extras_cap: string;
  c200_vlr_acum_intereses: string;
  c200_fecha_inactivacion: string;
  c200_usuario_inactivacion: string;
  c200_fecha_suspendido: string;
  c200_usuario_suspendido: string;
  c200_fecha_reestructuracion: string;
  c200_rowid_nuevo_prestamo: string;
  c200_usuario_reestructura: string;
  c200_fecha_castigo_cartera: string;
  c200_ind_estado: string;
  c200_notas: string;
  c200_fecha_creacion: string;
  c200_usuario_creacion: string;
  c200_ind_estado_desembolso: string;
  c200_ind_autor_desembolso: string;
  c200_rowid_ciiu: string;
  c200_rowid_usuario: string;
  c200_fecha_de_desembolso: string;
  c200_renovacion: string;
  totalbalancedue: string;
  loan_new: {
    c200_rowid: string;
    c200_rowid_cia: string;
    c200_rowid_tercero: string;
    c200_rowid_concepto: string;
    c200_nro_prestamo: string;
    c200_fecha_prestamo: string;
    c200_valor: string;
    c200_tasa: string;
    c200_plazo: string;
    c200_ind_modo_pago: string;
    c200_rowid_periodo: string;
    c200_fecha_interes_desde: string;
    c200_fecha_primera_cuota: string;
    c200_ind_cuotas_extras: string;
    c200_nro_cuotas_extras: string;
    c200_vlr_cuotas_extras: string;
    c200_vlr_presente_cuo_ext: string;
    c200_vlr_cuota: string;
    c200_vlr_acum_cuotas_cap: string;
    c200_vlr_acum_extras_cap: string;
    c200_vlr_acum_intereses: string;
    c200_fecha_inactivacion: string;
    c200_usuario_inactivacion: string;
    c200_fecha_suspendido: string;
    c200_usuario_suspendido: string;
    c200_fecha_reestructuracion: string;
    c200_rowid_nuevo_prestamo: string;
    c200_usuario_reestructura: string;
    c200_fecha_castigo_cartera: string;
    c200_ind_estado: string;
    c200_notas: string;
    c200_fecha_creacion: string;
    c200_usuario_creacion: string;
    c200_ind_estado_desembolso: string;
    c200_ind_autor_desembolso: string;
    c200_rowid_ciiu: string;
    c200_rowid_usuario: string;
    c200_fecha_de_desembolso: string;
  };
}

export interface ILines {
  c127_rowid_ciiu: string;
  c127_rowid_concepto: string;
  c127_fecha_creacion: string;
  c127_usuario_creacion: string;
  concept: {
    c050_rowid: string;
    c050_id: string;
    c050_descripcion: string;
    c050_porc_interes: string;
    c050_vlr_unitario: string;
    c050_rowid_grupo_header: string;
    c050_rowid_grupo_body: string;
    c050_rowid_grupo_adjuntos: string;
    c050_notas: string;
    c050_fecha_creacion: string;
    c050_usuario_creacion: string;
    attached_file: {
      c180_rowid: string;
      c180_foto: string;
      c180_ruta: string;
    };
    t_attached_file: {
      c180_rowid: string;
      c180_foto: string;
      c180_ruta: string;
    };
  };
}

export interface IGetLoansByThirdOfferedAgain_Result {
  status: string;
  errors?: string;
  results?: loanResponse;
}

export interface IRequestLoan_Request {
  c200_rowid: number;
  c200_ind_estado: number;
}

export type IRequestLoan_Result = {
  status: string;
  errors?: string | { c200_rowid: string[]; c200_ind_estado: string[] };
  results?: ILines[];
};
