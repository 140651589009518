import {
  Box,
  StepConnector,
  StepLabel,
  Stepper,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { BounceImage } from "../../../assets/animations/customAnimations.tsx";
import { colors } from "../../../styles/theme.tsx";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import { useTranslation } from "react-i18next";
import {
  formatNumberPoint,
  requestSquematic,
} from "../../../common/CommonFunctions.tsx";
import { ClearButton } from "../../../components/Button.tsx";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CustomStepH, CustomStepV } from "../../../components/Stepper.tsx";
import Lottie from "lottie-react";
import { IUserAuth, navigationModals } from "../../../constants/types.tsx";
import { getValueStorage, saveValueStorage } from "../../../common/storage.ts";
import { IRegisterStep } from "../RegisterComponent.tsx";

export default function Waiting({
  setIsLoading,
  setRegisterStep,
}: navigationModals) {
  const { t } = useTranslation();
  const { signIn } = React.useContext(AuthContext);
  const [horizontalPosition, setHorizontalPosition] = React.useState<
    number | null
  >(null);

  const [verticalPosition, setVerticalPosition] = React.useState(-1);
  const [value, setValue] = React.useState(0);
  const labels = [
    t("waiting.labelH1"),
    t("waiting.labelH2"),
    t("waiting.labelH3"),
    t("waiting.labelH4"),
  ];

  const authSession: IUserAuth = getValueStorage("authSession");

  const fetchData = async () => {
    setIsLoading(true);

    const data = await requestSquematic(
      "POST",
      "/api/app/loan/get_loan_request_status",
      { c200_rowid: authSession.loanRequest?.c200_rowid },
      authSession.token
    );

    if (data) {
      if (data.results!.c200_ind_estado) {
        // setHorizontalPosition(2);

        setHorizontalPosition(parseInt(data.results!.horizontalPosition));
        setVerticalPosition(parseInt(data.results!.verticalPosition));
        setValue(parseInt(data.results!.c200_valor));
      } else {
        let obj = {
          ...authSession,
          hasRejected: true,
        };
        signIn(obj);
        saveValueStorage("authSession", obj);
      }
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box sx={{ minHeight: 500 }}>
      {horizontalPosition !== null &&
        (horizontalPosition >= 2 ? (
          <AprovedScreen
            value={value}
            labels={labels}
            setRegisterStep={setRegisterStep}
            authSession={authSession}
          />
        ) : (
          <RequestScreen
            labels={labels}
            horizontalPosition={horizontalPosition}
            verticalPosition={verticalPosition}
            setRegisterStep={setRegisterStep}
            authSession={authSession}
          />
        ))}
    </Box>
  );
}

interface IAprovedScreen {
  value: number;
  labels: string[];
  setRegisterStep?: React.Dispatch<React.SetStateAction<IRegisterStep>>;
  authSession: IUserAuth;
}

function AprovedScreen({
  value,
  labels,
  setRegisterStep,
  authSession,
}: IAprovedScreen) {
  const { t } = useTranslation();
  const [isBlackTheme, setIsBlackTheme] = React.useState<boolean>(false);
  React.useEffect(() => {
    setIsBlackTheme(document.body.classList.contains("dark"));
  }, []);

  const matchesMobiles = useMediaQuery("(max-width:505px)");

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Box
          sx={{
            width: { xs: 210, sm: 250 },
            marginBottom: 2,
          }}
        >
          <Lottie
            autoPlay
            animationData={require("../../../assets/animations/getleman.json")}
          />
        </Box>
        <Box textAlign={"center"}>
          <Typography
            sx={{
              color: colors.primary,
              fontWeight: "bold",
              fontSize: 20,
              textTransform: "uppercase",
              textAlign: "center",
              lineHeight: 1,
            }}
          >
            {authSession.inProcessRenovation?.inProcessRenovation && authSession.loanApproved.c200_ind_estado + "" != "3"
              ? t("waiting.renew")
              : t("waiting.request")}{" "}
            {t("waiting.aprroved")}
          </Typography>
          <Typography
            className="dark:!text-white"
            sx={{
              fontWeight: "500",
              fontSize: 20,
              textTransform: "uppercase",
              textAlign: "center",
              lineHeight: 1,
            }}
          >
            {t("waiting.aprovedDesc")}
          </Typography>
        </Box>
        {/* Proceso de validacion (HORIZONTAL) */}
        <Box
          sx={{
            width: "100%",
            marginTop: 5,
            display: matchesMobiles ? "none" : "block",
          }}
        >
          <Stepper activeStep={2} alternativeLabel>
            {labels.map((label) => (
              <CustomStepH
                isBlackTheme={isBlackTheme}
                key={label}
                sx={{
                  "& .MuiStepLabel-iconContainer .MuiSvgIcon-root": {
                    width: { xs: 40, md: 50 },
                    height: { xs: 40, md: 50 },
                  },
                  "& .MuiStepConnector-root": {
                    top: { xs: 20, md: 25 },
                  },
                }}
              >
                <StepLabel>{label}</StepLabel>
              </CustomStepH>
            ))}
          </Stepper>
        </Box>

        {/* Solo Vertical para mobiles hasta 485px */}
        <Box
          sx={{
            width: "100%",
            marginTop: 0,
            justifyContent: "center",
            display: matchesMobiles ? "flex" : "none",
            mt: 5,
          }}
        >
          <Stepper
            activeStep={2}
            orientation="vertical"
            connector={<CustomConnector />}
          >
            {labels.map((step, index) => (
              <CustomStepV
                isBlackTheme={isBlackTheme}
                key={index}
                sx={{
                  "& .MuiStepLabel-labelContainer .MuiStepLabel-label": {
                    fontSize: 15,
                  },
                  "& .MuiStepLabel-iconContainer .MuiSvgIcon-root": {
                    width: 40,
                    height: 40,
                  },
                }}
              >
                <StepLabel>{step}</StepLabel>
              </CustomStepV>
            ))}
          </Stepper>
        </Box>

        <Box sx={{ width: "100%", marginTop: 5, textAlign: "center" }}>
          <Typography
            className="dark:!text-white"
            sx={{
              color: colors.darkSlateGray,
              fontWeight: "500",
              fontSize: 20,
              textTransform: "uppercase",
              lineHeight: 1,
            }}
          >
            {t("waiting.have")}
          </Typography>
          <Typography
            sx={{
              color: colors.primary,
              fontWeight: "bold",
              fontSize: { xs: 30, md: 35 },
              lineHeight: 1,
              marginTop: 1,
            }}
          >
            {formatNumberPoint(value)}
          </Typography>
        </Box>
        <Box sx={{ width: "100%", marginTop: 5, px: setRegisterStep ? 0 : 3 }}>
          <Typography
            className="dark:!text-white"
            sx={{
              fontSize: 18,
              lineHeight: 1,
              color: colors.gray,
            }}
          >
            {t("waiting.aprovedDesc1P1").split("-")[0]}{" "}
            <strong style={{ textDecoration: "underline" }}>
              {t("waiting.aprovedDesc1P1").split("-")[1]}
            </strong>{" "}
            {t("waiting.aprovedDesc1P2")}
          </Typography>
          {authSession.inProcessRenovation?.inProcessRenovation && (
            <Typography
              className="dark:!text-white"
              sx={{
                fontSize: 18,
                lineHeight: 1,
                color: colors.gray,
                marginTop: 2,
              }}
            >
              {t("waiting.thisIs")}
            </Typography>
          )}

          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: 17,
              color: colors.primary,
              textTransform: "uppercase",
              marginTop: 4,
            }}
            lineHeight={1}
          >
            {t("confirmLoan.important")}
          </Typography>
          <Typography
            className="dark:!text-white"
            sx={{
              fontSize: 18,
              lineHeight: 1,
              color: colors.gray,
              marginTop: 2,
            }}
          >
            {t("waiting.aprovedDesc2")}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

// Componente de linea vertical
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    marginLeft: 8,
  },
}));

interface IRequestScreen {
  labels: string[];
  horizontalPosition: number;
  verticalPosition: number;
  setRegisterStep?: React.Dispatch<React.SetStateAction<IRegisterStep>>;
  authSession: IUserAuth;
}

// En espera
function RequestScreen({
  labels,
  verticalPosition,
  horizontalPosition,
  setRegisterStep,
  authSession,
}: IRequestScreen) {
  const { t } = useTranslation();
  const [verProceso, setVerProceso] = React.useState<boolean>(false);
  const labelsProcess = [
    t("waiting.labelV1"),
    t("waiting.labelV2"),
    t("waiting.labelV3"),
    t("waiting.labelV4"),
  ];
  const [isBlackTheme, setIsBlackTheme] = React.useState<boolean>(false);
  React.useEffect(() => {
    setIsBlackTheme(document.body.classList.contains("dark"));
  }, []);

  const matchesMobiles = useMediaQuery("(max-width:505px)");

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        {authSession.inProcessRenovation?.inProcessRenovation ? (
          <Box
            sx={{ width: { xs: 200, sm: 250 }, height: { xs: 200, sm: 250 } }}
            textAlign={"center"}
          >
            <Lottie
              style={{
                width: "100%",
                height: "100%",
              }}
              loop
              autoPlay
              animationData={require("../../Dashboard/assets/animations/solicitudCohete.json")}
            />
          </Box>
        ) : (
          <Box
            sx={{
              width: { xs: 210, sm: 250 },
              marginBottom: 2,
            }}
          >
            <BounceImage
              src={require("../../../assets/image/Aprovado.webp")}
              alt="Aprovado"
              sx={{ width: "100%" }}
            />
          </Box>
        )}

        <Box textAlign={"center"}>
          <Typography
            className="dark:!text-white"
            sx={{
              fontWeight: "bold",
              fontSize: 20,
              textTransform: "uppercase",
              textAlign: "center",
              lineHeight: 1,
            }}
          >
            {authSession.inProcessRenovation?.inProcessRenovation ? (
              <>
                {t("waiting.renew").replace("¡", "")} {t("waiting.inProcess")}
              </>
            ) : (
              <>
                {horizontalPosition === 1 || horizontalPosition === 2
                  ? t("waiting.requestScreenOP1")
                  : t("waiting.requestScreenOP2")}
                <span style={{ color: colors.primary }}>
                  {labels[horizontalPosition]}
                </span>
              </>
            )}
          </Typography>
          {authSession.inProcessRenovation?.inProcessRenovation && (
            <Typography
              className="dark:!text-white"
              sx={{
                fontSize: 18,
                lineHeight: 1,
                color: colors.gray,
                marginTop: 1,
              }}
            >
              {t("waiting.yourCredit")}
            </Typography>
          )}
        </Box>

        {/* Proceso de validacion (HORIZONTAL) */}
        <Box
          sx={{
            width: "100%",
            marginTop: 5,
            display: matchesMobiles ? "none" : "block",
          }}
        >
          <Stepper activeStep={horizontalPosition} alternativeLabel>
            {labels.map((label) => (
              <CustomStepH
                isBlackTheme={isBlackTheme}
                key={label}
                sx={{
                  "& .MuiStepLabel-iconContainer .MuiSvgIcon-root": {
                    width: { xs: 40, md: 50 },
                    height: { xs: 40, md: 50 },
                  },
                  "& .MuiStepConnector-root": {
                    top: { xs: 20, md: 25 },
                  },
                }}
              >
                <StepLabel>{label}</StepLabel>
              </CustomStepH>
            ))}
          </Stepper>
        </Box>

        {/* Solo Vertical para mobiles hasta 485px */}
        <Box
          sx={{
            width: "100%",
            marginTop: 0,
            justifyContent: "center",
            display: matchesMobiles ? "flex" : "none",
            mt: 5,
          }}
        >
          <Stepper
            activeStep={horizontalPosition}
            orientation="vertical"
            connector={<CustomConnector />}
          >
            {labels.map((step, index) => (
              <CustomStepV
                isBlackTheme={isBlackTheme}
                key={index}
                sx={{
                  "& .MuiStepLabel-labelContainer .MuiStepLabel-label": {
                    fontSize: 15,
                  },
                  "& .MuiStepLabel-iconContainer .MuiSvgIcon-root": {
                    width: 40,
                    height: 40,
                  },
                }}
              >
                <StepLabel>{step}</StepLabel>
              </CustomStepV>
            ))}
          </Stepper>
        </Box>

        {authSession.inProcessRenovation?.inProcessRenovation ? (
          <>
            {/* Nuestro equipo */}
            <Box sx={{ marginY: 5 }}>
              <Typography
                className="dark:!text-white"
                sx={{
                  fontSize: 18,
                  lineHeight: 1.2,
                  color: colors.gray,
                  marginTop: 2,
                }}
              >
                {t("waiting.outTeam")}
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  lineHeight: 1.2,
                  color: colors.primary,
                  fontWeight: 700,
                }}
              >
                {t("waiting.outTeam2")}
              </Typography>

              <Typography
                className="dark:!text-white"
                sx={{
                  fontSize: 18,
                  lineHeight: 1,
                  color: colors.gray,
                  marginTop: 3,
                }}
              >
                {t("waiting.thisIs")}
              </Typography>
            </Box>
            {/* TEXTO FOOTER */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              mx={setRegisterStep ? 0 : 3}
            >
              <Typography
                sx={{
                  color: colors.primary,
                  fontWeight: "bold",
                  fontSize: 20,
                  textTransform: "uppercase",
                  textAlign: "left",
                  lineHeight: 1,
                }}
              >
                {t("confirmLoan.important")}
              </Typography>
              <Typography
                className="dark:!text-white"
                sx={{
                  fontSize: 18,
                  textAlign: "center",
                  lineHeight: 1.2,
                  marginTop: 2,
                }}
              >
                {t("waiting.oneRequest")}
                <strong> {t("waiting.oneRequest2")}</strong>
              </Typography>
            </Box>
          </>
        ) : (
          <>
            {/* Proceso de validacion (VERTICAL) */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginY: 5,
              }}
            >
              <ClearButton
                onClick={() => setVerProceso(!verProceso)}
                endIcon={
                  verProceso ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                }
              >
                {t("waiting.seeProcess")}
              </ClearButton>

              {verProceso && (
                <Box sx={{ width: "100%", marginTop: 0, alignItems: "center" }}>
                  <Stepper activeStep={verticalPosition} orientation="vertical">
                    {labelsProcess.map((step, index) => (
                      <CustomStepV
                        isBlackTheme={isBlackTheme}
                        key={index}
                        sx={{
                          "& .MuiStepLabel-labelContainer .MuiStepLabel-label":
                            {
                              fontSize: { xs: 11, md: 15 },
                            },
                        }}
                      >
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          <StepLabel>{step}</StepLabel>
                          {index <= verticalPosition && (
                            <CheckCircleIcon sx={{ color: colors.primary }} />
                          )}
                        </Box>
                      </CustomStepV>
                    ))}
                  </Stepper>
                </Box>
              )}
            </Box>

            {/* TEXTO FOOTER */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              mx={setRegisterStep ? 0 : 3}
            >
              <Typography
                sx={{
                  color: colors.primary,
                  fontWeight: "bold",
                  fontSize: { xs: 17, md: 20 },
                  textTransform: "uppercase",
                  textAlign: "center",
                  lineHeight: 1,
                }}
              >
                {t("waiting.wantAdvance")}
              </Typography>
              <Typography
                className="dark:!text-white"
                sx={{
                  fontWeight: 500,
                  fontSize: { xs: 15, md: 17 },
                  textAlign: "center",
                  lineHeight: 1.2,
                  marginTop: 2,
                }}
              >
                {t("waiting.wantAdvanceDescP1").split("-")[0]}{" "}
                <strong>{t("waiting.wantAdvanceDescP1").split("-")[1]}</strong>
                {t("waiting.wantAdvanceDescP2").split("-")[0]}{" "}
                <strong>{t("waiting.wantAdvanceDescP2").split("-")[1]}</strong>
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
