import { Box, Typography } from "@mui/material";
import React from "react";
import {
  LocationRequestComponent,
  VideoCameraRequestComponent,
} from "../constants/permissions.tsx";
import { AuthContext } from "../hooks/useAuth.tsx";
import { errorAlert } from "../common/alerts.tsx";
import { useSpring, animated } from "@react-spring/web";
import { colors } from "../styles/theme.tsx";
import { useTranslation } from "react-i18next";
import FadeAnim from "./animations/FadeAnim.tsx";
import { IRegisterStep } from "../pages/User/RegisterComponent.tsx";
import { IUserAuth } from "../constants/types.tsx";
import { getValueStorage, saveValueStorage } from "../common/storage.ts";

interface IVideoScreen {
  setVideoUrl: React.Dispatch<React.SetStateAction<string>>;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  setRegisterStep?: React.Dispatch<React.SetStateAction<IRegisterStep>>;
  setIsLoading?: (isLoading: boolean) => void;
}

export const VideoScreen = ({
  setVideoUrl,
  onClose,
  setRegisterStep,
  setIsLoading: setIsloadingExternal,
}: IVideoScreen) => {
  const [selectedDeviceId, setSelectedDeviceId] = React.useState<any>(null);
  const videoRef = React.useRef<any>(null);
  const mediaRecorderRef = React.useRef<any>(null);
  const chunksRef = React.useRef<any>([]);
  const [stream, setStream] = React.useState<any>(null);
  const { getUser, setIsLoading: setIsloadingInternal, signIn } = React.useContext(AuthContext);
  const [isRecording, setIsRecording] = React.useState<boolean>(false);
  const [moment, setMoment] = React.useState(false);
  const [time, setTime] = React.useState(0);
  const timerRef = React.useRef<NodeJS.Timeout | null>(null);
  const [loader, setLoader] = React.useState<Boolean | null>(null);
  const { t } = useTranslation();
  const { registerForVideoCamera } = VideoCameraRequestComponent();
  const { registerForLocation } = LocationRequestComponent();
  const [error, setError] = React.useState("");

  // Se envia solo `setIsloading` cuando es un modal, de lo contrario usamos el interno
  const setIsLoading = setIsloadingExternal || setIsloadingInternal;

  // Duracion maxima del video es de 5 minutos en segundos
  const duration = 300;

  const authSession: IUserAuth = getValueStorage("authSession");

  // Iniciar la cámara y el micrófono con el dispositivo seleccionado
  const startCamera = async (deviceId) => {
    try {
      const streamTemp = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: deviceId || selectedDeviceId },
        audio: true,
      });
      setStream(streamTemp);
      videoRef.current.srcObject = streamTemp;
    } catch (error) {
      if (setRegisterStep) {
        errorAlert("Error accediendo a la cámara o micrófono", error, "");
      } else {
        setError("Error accediendo a la cámara o micrófono");
      }
    }
  };

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);
      await registerForLocation({ setIsLoading }).then(async (loc) => {
        if (loc) {
          try {
            let obj = {
              ...authSession,
              getLocation: {
                latitude: loc.latitude,
                longitude: loc.longitude,
              },
            };
            signIn(obj);
            saveValueStorage("authSession", obj);
            setIsLoading(true);
            // Si se obtiene la ubicación, se envian los permisos de la camara y micrófono
            const stream = await registerForVideoCamera({setIsLoading});
            if (stream) {
              // Enumerar dispositivos de video disponibles
              const getDevices = async () => {
                const allDevices =
                  await navigator.mediaDevices.enumerateDevices();
                const videoDevices = allDevices.filter(
                  (device) => device.kind === "videoinput"
                );

                // Seleccionar el dispositivo adecuado dependiendo de la cantidad de cámaras
                if (videoDevices.length >= 2) {
                  // Si hay 2 o más cámaras, seleccionamos la última
                  setSelectedDeviceId(
                    videoDevices[videoDevices.length - 1].deviceId
                  );
                  startCamera(videoDevices[videoDevices.length - 1].deviceId);
                } else if (videoDevices.length === 1) {
                  // Si solo hay 1 cámara, seleccionamos la primera
                  setSelectedDeviceId(videoDevices[0].deviceId);
                  startCamera(videoDevices[0].deviceId);
                }
              };

              getDevices();
            }
          } catch (error) {
            if (setRegisterStep) {
              errorAlert("Error", "Ubicación fallida", "");
            } else {
              setError("Ubicación fallida");
            }
          }
        }
      });
    })();
  }, []);

  // Validacion de tiempo de grabacion
  React.useEffect(() => {
    if (moment) {
      // Si el tiempo de grabacion es mayor a 10 segundos
      if (time >= 10) {
        // Habilita la opcion para parar la grabacion
        setMoment(false);
      }
    }
  }, [moment, time]);

  const handleVideo = () => {
    // Validacion de estado de grabacion
    if (isRecording) {
      // Si el video está actualmente grabando
      if (moment) {
        // Si el tiempo de grabacion es menor a 10 segundos
        if (setRegisterStep) {
          errorAlert(
            `${t("videoScreen.wait")}`,
            `${t("videoScreen.subWait")}`,
            ""
          );
        } else {
          setError(`${t("videoScreen.wait")} \n${t("videoScreen.subWait")}`);
        }
      } else {
        // Detiene la grabación de video.
        stopRecording();
        clearInterval(timerRef.current!);
      }
    } else {
      // Si el video no está grabando
      // Inicia a grabar
      startRecording();
      // Deshabilita la opcion para parar la grabación
      setMoment(true);

      // Contador de segundos y finaliza automaticamente la grabación al llegar a 5min
      setTimeout(() => {
        timerRef.current = setInterval(() => {
          setTime((prevTime) => {
            if (prevTime >= duration) {
              clearInterval(timerRef.current!);
              stopRecording();
              return duration;
            }
            return prevTime + 1;
          });
        }, 1000);
      }, 0);
    }
  };

  // Al recargar la página, se detienen los flujos de video y audio
  React.useEffect(() => {
    return () => {
      if (stream) {
        // Detener los tracks de video y audio del stream
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [stream]);

  // Iniciar la grabación
  const startRecording = async () => {
    if (stream) {
      setIsRecording(true);

      chunksRef.current = [];

      // Determinar un mimeType compatible
      const mimeType = MediaRecorder.isTypeSupported("video/mp4")
        ? "video/mp4"
        : "video/webm";

      try {
        mediaRecorderRef.current = new MediaRecorder(stream, { mimeType });

        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) chunksRef.current.push(event.data);
        };

        // Al detener la grabación, crea la URL del video
        mediaRecorderRef.current.onstop = () => {
          const blob = new Blob(chunksRef.current, { type: mimeType });
          setVideoUrl(URL.createObjectURL(blob));
          onClose(false);
          chunksRef.current = [];
        };

        mediaRecorderRef.current.start();
      } catch (error) {
        console.error("Error al iniciar la grabación:", error);
        setIsRecording(false);
      }
    }
  };

  // Detener la grabación
  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      setIsRecording(false);
      mediaRecorderRef.current.stop();
    }

    // Detener los tracks de video y audio del stream
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  // Animación con react-spring para el padding
  const { padding, borderRadius, backgroundColor } = useSpring({
    padding: isRecording ? 10 : 0,
    borderRadius: isRecording ? 6 : 35,
    backgroundColor: colors.red,
    config: { duration: isRecording ? 200 : 100 },
  });

  // Formatea el tiempo en MM:SS
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <Box
      sx={{
        minHeight: 400,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {/* El anuncio cambia cuando faltan 30 segundos para alcanzar la duración máxima del video */}
      {time >= duration - 30 ? (
        <FadeAnim
          duration={800}
          center
          style={{
            backgroundColor: colors.red,
            marginBottom: 15,
            textAlign: "center",
            padding: "5px 10px",
            borderRadius: 5,
          }}
        >
          <Typography style={{ color: colors.white, lineHeight: 1.2 }}>
            {t("videoScreen.less")}
            {Math.max(duration - time, 0)}
            {t("videoScreen.subLess")}
          </Typography>
        </FadeAnim>
      ) : (
        loader !== null && (
          <FadeAnim
            center
            style={{
              marginBottom: 15,
              textAlign: "center",
              backgroundColor: colors.black,
              padding: "5px 10px",
              borderRadius: 5,
            }}
          >
            <Typography style={{ color: colors.white, lineHeight: 1.2 }}>
              {t("videoScreen.duration")}
            </Typography>
          </FadeAnim>
        )
      )}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {/* Indicador de tiempo de grabación */}
        {isRecording && (
          <FadeAnim
            center
            style={{
              position: "absolute",
              top: 5,
              alignSelf: "center",
              zIndex: 99,
              width: "auto",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: colors.black,
              padding: "5px 10px",
              borderRadius: 5,
              display: "flex",
              flexDirection: "row",
              gap: 5,
            }}
          >
            <div className="recordingCircle" />
            <div>
              <Typography style={{ color: colors.white }}>
                {formatTime(time)}
              </Typography>
            </div>
          </FadeAnim>
        )}
        {/* Previsualizacion de la grabacion */}
        <video
          ref={videoRef}
          autoPlay
          playsInline
          muted
          disablePictureInPicture
          onLoadedData={() => {
            setIsLoading(false);
            setLoader(false);
          }}
        />

        {/* Boton de grabar */}
        {loader !== null && (
          <button
            onClick={handleVideo}
            disabled={getUser.isLoading}
            style={{
              position: "absolute",
              alignSelf: "center",
              bottom: 15,
              background: "transparent",
              border: "none",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <animated.div
              style={{
                display: "flex",
                width: 50,
                height: 50,
                borderRadius: "50%",
                backgroundColor: "transparent",
                borderWidth: 5,
                borderColor: colors.white,
                borderStyle: "solid",
                padding,
              }}
            >
              <animated.div
                style={{
                  flex: 1,
                  borderRadius,
                  backgroundColor,
                }}
              />
            </animated.div>
          </button>
        )}
      </Box>
      {error !== "" && (
        <Typography
          sx={{
            fontSize: 16,
            color: colors.red,
            marginTop: 2,
            textAlign: "center",
            lineHeight: 1.2,
            whiteSpace: "pre-line",
          }}
        >
          {error}
        </Typography>
      )}
    </Box>
  );
};
