import { Box, Typography, Zoom } from "@mui/material";
import React from "react";
import { colors } from "../../../styles/theme.tsx";
import { useTranslation } from "react-i18next";
import InputField from "../../../components/InputField.tsx";
import { useFormik } from "formik";
import { yupCancelVcValidations } from "../../../common/validations/yupCancelVcValidations.ts";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import {
  getCurrentFormattedDate,
  requestSquematic,
  useUpdatePasoRenovacion,
} from "../../../common/CommonFunctions.tsx";
import { IReason_status, navigationModals } from "../../../constants/types.tsx";
import Grid from "@mui/material/Grid2";
import { ColorButton } from "../../../components/Button.tsx";
import { getValueStorage, saveValueStorage } from "../../../common/storage.ts";
import { IRegisterStep, IRegisterStepState } from "../RegisterComponent.tsx";
import renovationStep from "../../Dashboard/services/RenovationStep.tsx";
import { ModalDashboard, MySwal } from "../../../common/alerts.tsx";
import { useNavigate } from "react-router-dom";

type CancelVCProps = IRegisterStepState & navigationModals;

export default function CancelVC({
  setIsLoading,
  setRegisterStep,
}: CancelVCProps) {
  const { t } = useTranslation();
  const { getUser, signOut, signIn } = React.useContext(AuthContext);
  const { parseRenovacion, updatePasoRenovacion } = useUpdatePasoRenovacion();
  const [cancelReasons, setCancelReasons] = React.useState<any[]>([]);

  const getUserTemp = getValueStorage("authSession");

  const [isBlackTheme, setIsBlackTheme] = React.useState<boolean>(false);

  const navigate = useNavigate();


  React.useEffect(() => {
    setIsBlackTheme(document.body.classList.contains("dark"));
  }, []);

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await requestSquematic(
        "POST",
        "/api/panel/vc_validations/get_vc_status",
        { tipo_estado: "3" },
        getUserTemp.token
      );

      if (res && res.results) {
        setCancelReasons(
          res.results
            .filter((item: IReason_status) => item.c156_rowid != "29")
            .map((item: any) => ({
              id: item.c156_rowid,
              value: item.c156_descripcion_sub_estado,
            }))
        );
      }
      setIsLoading(false);
    })();
  }, []);

  const formik = useFormik({
    initialValues: {
      cancel_reason: "",
      comment: "",
    },
    validationSchema: yupCancelVcValidations,
    onSubmit: (values) => {
      handleCancel(values);
    },
  });

  const cancelReasonsOptions = cancelReasons.map((item) => ({
    value: item.id + "",
    label: item.value,
  }));

  const handleCancel = async (values: {
    cancel_reason: string;
    comment: string;
  }) => {
    setIsLoading(true);

    const res = await requestSquematic(
      "POST",
      "/api/panel/loan/set_loan_state",
      {
        c200_ind_estado: "4",
        c200_rowid: parseInt(getUserTemp.loanRequest?.c200_rowid ?? ""),
        c204_notas: "Cliente rechazo desde app",
      },
      getUserTemp.token
    );

    if (res && getUserTemp.hasVideoCall !== "") {
      getUserTemp.hasVideoCall.c155_comentario = values.comment;
      getUserTemp.hasVideoCall.c155_rowid_estado = values.cancel_reason;
      getUserTemp.hasVideoCall.c155_fecha_fin = getCurrentFormattedDate();

      const resVC = await requestSquematic(
        "POST",
        "/api/panel/vc_validations/update_video_call",
        getUserTemp.hasVideoCall,
        getUserTemp.token
      );
      if (resVC) {
        // Falta implementar logica de la reoferta
        if (setRegisterStep) {
          signOut();
          let obj = {
            prevStep: 17,
            nextStep: -1,
            desc: "Cancelar solicitud desde CancelVC",
          };
          saveValueStorage("registerStep", obj);
          setRegisterStep(obj);
        } else {
          saveValueStorage("authSession", {
            ...getValueStorage("authSession"),
            loanRequest: {
              ...getValueStorage("authSession").loanRequest,
              c204_paso_renovacion: null
            },
          });;
          MySwal.close();
          window.location.reload();
        }
      }
    }else{
      let objSession = {
        ...getUserTemp,
        dashboard: false,
      };
      if(getUserTemp.loanActive?.c200_rowid) {
        objSession.dashboard = true;
        signIn(objSession);
        saveValueStorage("authSession", objSession);
        navigate("/");
      }
    }

    setIsLoading(false);
  };

  //No cancelar la solicitud
  const handleNoCancel = async () => {
    if (setRegisterStep) {
      let obj = {
        prevStep: 17,
        nextStep: getValueStorage("registerStep")
          ? getValueStorage("registerStep").prevStep
          : signOut(),
        desc: "el usuario sale de la cancelacion de solicitud",
      };
      saveValueStorage("registerStep", obj);
      setRegisterStep(obj);
    } else {
      // Conserva los datos para saber a donde devolverse, ya que para CancelVc solo se navega
      await updatePasoRenovacion(
        parseRenovacion().paso,
        setIsLoading,
        parseRenovacion().tipoVideollamada,
        parseRenovacion().estadoLaboral,
        parseRenovacion().esIndependiente,
        parseRenovacion().cambioEmpresa
      );
      let dinamicElement = renovationStep({
        setIsLoading: setIsLoading,
        parseRenovacion: parseRenovacion,
      });
      if (dinamicElement) {
        ModalDashboard({
          element: dinamicElement,
        });
      }
    }
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Box
          sx={{
            width: 160,
            mt: setRegisterStep ? 0 : 5,
            marginBottom: 2,
          }}
        >
          <Zoom in={true}>
            <img
              src={require("../../../assets/image/TrianguloError.webp")}
              alt={""}
              loading="lazy"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Zoom>
        </Box>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 22,
            color: colors.red,
            textTransform: "uppercase",
            marginBottom: 2,
            marginTop: 1,
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("confirmLoan.important")}
        </Typography>
        <Typography
          className="dark:text-white"
          sx={{
            fontWeight: 500,
            fontSize: 17,
            color: colors.gray,
            textAlign: "center",
            mx: setRegisterStep ? 0 : 2,
          }}
          lineHeight={1}
        >
          {t("cancelVc.subtitle").split("-")[0]}{" "}
          <strong>{t("cancelVc.subtitle").split("-")[1]}</strong>
        </Typography>
        <Typography
          className="dark:text-white"
          sx={{
            fontWeight: 500,
            fontSize: 17,
            color: colors.gray,
            textAlign: "center",
            marginTop: 5,
          }}
          lineHeight={1}
        >
          {t("cancelVc.hasBeenRejected").split("-")[0]}{" "}
          <strong style={{ color: colors.red }}>
            {t("cancelVc.hasBeenRejected").split("-")[1]}
          </strong>
        </Typography>
        <Typography
          className="dark:text-white"
          sx={{
            fontSize: 17,
            textAlign: "center",
            marginTop: 5,
          }}
          lineHeight={1.1}
        >
          {t("cancelVc.wait1").split("-")[0]}{" "}
          <strong>{t("cancelVc.wait1").split("-")[1]}</strong>
          {t("cancelVc.wait2")}
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        columns={12}
        mt={5}
        textAlign={"left"}
        sx={{ mx: setRegisterStep ? 0 : 2 }}
      >
        <Grid size={12}>
          <InputField
            name="cancel_reason"
            formik={formik}
            label={t("cancelVc.label1")}
            type="select"
            options={cancelReasonsOptions}
            disabled={getUser.isLoading}
            labelStyle={{
              fontSize: 16,
              color: isBlackTheme ? colors.white : colors.gray,
              textTransform: "uppercase",
            }}
          />
        </Grid>
        <Grid size={12}>
          <InputField
            name="comment"
            label={t("cancelVc.label2")}
            formik={formik}
            maxLength={150}
            disabled={getUser.isLoading}
            type="textarea"
            labelStyle={{
              fontSize: 16,
              color: isBlackTheme ? colors.white : colors.gray,
              textTransform: "uppercase",
            }}
            placeholder="Escribe aquí tu comentario"
            sx={{
              textarea: {
                "&::placeholder": {
                  color: isBlackTheme ? colors.bone : colors.silver,
                  opacity: 0.8,
                },
              },
            }}
          />
        </Grid>
      </Grid>

      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        marginTop={8}
      >
        <ColorButton
          sx={{
            width: "fit-content",
            padding: { xs: "15px 60px", sm: "15px 80px" },
            fontSize: 16,
          }}
          onClick={handleNoCancel}
          disabled={getUser.isLoading}
        >
          {t("cancelVc.noCancel")}
        </ColorButton>
        <ColorButton
          sx={{
            width: "fit-content",
            padding: "8px 20px",
            fontSize: 13,
            mt: 1,
            background: colors.blueGray,
            borderColor: colors.blueGray,
            borderRadius: "13px !important",
          }}
          onClick={() => formik.handleSubmit()}
          disabled={getUser.isLoading}
        >
          {t("cancelVc.yesSure")}
        </ColorButton>
      </Box>
    </>
  );
}
