import React from "react";
import { colors } from "../styles/theme.tsx";
import Grid from "@mui/material/Grid2";
import { Box, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer
      style={{ backgroundColor: colors.white, padding: "60px 50px 25px" }}
    >
      <Grid
        container
        spacing={4}
        columns={{ xs: 12, lg: 15 }}
        width={"100%"}
        height={"100%"}
      >
        <Grid size={{ xs: 12, md: 6, lg: 3.5 }}>
          <Box sx={{ width: 121, height: "auto" }}>
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              <img
                src={require("../assets/image/LogoHorizontalVerde.webp")}
                alt={""}
                loading="lazy"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </Link>
          </Box>
          <div
            style={{
              width: 45,
              height: 2,
              background: colors.primary,
            }}
          />
          <Grid mt={"30px"} container spacing={2}>
            <Grid display={"flex"} alignItems={"center"}>
              <Box sx={{ width: 40, height: 40 }}>
                <img
                  src={require("../assets/image/headphones.webp")}
                  alt={""}
                  loading="lazy"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    filter:
                      "invert(75%) sepia(100%) saturate(300%) hue-rotate(85deg) brightness(100%)",
                  }}
                />
              </Box>
            </Grid>
            <Grid>
              <Typography
                sx={{
                  fontSize: { xs: 16, md: 18 },
                  color: colors.gray,
                  lineHeight: 1,
                }}
              >
                {t("footer.section1p1")}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: 16, md: 18 },
                  color: colors.gray,
                  lineHeight: 1,
                }}
              >
                {t("footer.section1p2")}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: 16, md: 18 },
                  fontWeight: "bold",
                  color: colors.primary,
                  lineHeight: 1,
                  marginTop: 1.5,
                }}
              >
                {t("footer.phone")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 3.5 }} mt={{ xs: 4, md: 0 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 19, md: 25 },
              color: colors.gray,
            }}
          >
            {t("footer.aboutUsTitle")}
          </Typography>
          <div
            style={{
              width: 59,
              height: 2,
              background: colors.primary,
              marginTop: "10px",
            }}
          />
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              color: colors.gray,
              lineHeight: 1,
              my: "18px",
            }}
          >
            <Link
              to="/who-we-are"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {t("footer.aboutUsOption1")}
            </Link>
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              color: colors.gray,
              lineHeight: 1,
              my: "18px",
            }}
          >
            <Link
              to="/culture-organization"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {t("footer.aboutUsOption2")}
            </Link>
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              color: colors.gray,
              lineHeight: 1,
              my: "18px",
            }}
          >
            {t("footer.aboutUsOption3")}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              color: colors.gray,
              lineHeight: 1,
              my: "18px",
            }}
          >
            {t("footer.aboutUsOption4")}
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 4, lg: 2 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 19, md: 25 },
              color: colors.gray,
            }}
          >
            {t("footer.intTitle")}
          </Typography>
          <div
            style={{
              width: 59,
              height: 2,
              background: colors.primary,
              marginTop: "10px",
            }}
          />
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              color: colors.gray,
              lineHeight: 1,
              my: "18px",
            }}
          >
            {t("footer.intOption1")}
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 4, lg: 3 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 19, md: 25 },
              color: colors.gray,
            }}
          >
            {t("footer.clientServiceTitle")}
          </Typography>
          <div
            style={{
              width: 59,
              height: 2,
              background: colors.primary,
              marginTop: "10px",
            }}
          />
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              color: colors.gray,
              lineHeight: 1,
              my: "18px",
            }}
          >
            <Link
              to="/customer-service"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {t("footer.clientServiceOption1")}
            </Link>
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              color: colors.gray,
              lineHeight: 1,
              my: "18px",
            }}
          >
            <Link
              to="/frequently-questions"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {t("footer.clientServiceOption2")}
            </Link>
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              color: colors.gray,
              lineHeight: 1,
              my: "18px",
            }}
          >
            <Link
              to="/customer-service"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {t("footer.clientServiceOption3")}
            </Link>
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, md: 4, lg: 3 }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 19, md: 25 },
              color: colors.gray,
            }}
          >
            {t("footer.incomeTitle")}
          </Typography>
          <div
            style={{
              width: 59,
              height: 2,
              background: colors.primary,
              marginTop: "10px",
            }}
          />
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              color: colors.gray,
              lineHeight: 1,
              my: "18px",
            }}
          >
            <Link
              to="/sign-in"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {t("common.signIn")}
            </Link>
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              color: colors.gray,
              lineHeight: 1,
              my: "18px",
            }}
          >
            <Link
              to="/sign-up"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {t("common.signUp")}
            </Link>
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 16, md: 18 },
              color: colors.gray,
              lineHeight: 1,
              my: "18px",
            }}
          >
            <Link to="/#simulator" style={{ textDecoration: "none", color: "inherit" }}>
              {t("common.simulateCredit")}
            </Link>
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ my: 3 }} />
      {/* Parte final */}
      <Grid
        container
        spacing={{ xs: 2, md: 6, lg: 7 }}
        columns={{ xs: 12, lg: 15 }}
        width={"100%"}
        height={"100%"}
      >
        <Grid size={{ xs: 12, md: "auto" }}>
          <Typography
            sx={{
              fontSize: 16,
              color: colors.gray,
              fontWeight: 600,
            }}
          >
            © Finova 2024
          </Typography>
        </Grid>
        <Grid size={{ sm: 5, md: "auto" }}>
          <Typography
            sx={{
              fontSize: 16,
              color: colors.gray,
              fontWeight: 600,
            }}
          >
            {t("footer.privatePolicy")}
          </Typography>
        </Grid>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{
            borderWidth: 1,
            background: colors.gray,
            display: { xs: "none", md: "block" },
          }}
        />
        <Grid>
          <Typography
            sx={{
              fontSize: 16,
              color: colors.gray,
              fontWeight: 600,
            }}
          >
            {t("footer.aboutUsOption3")}
          </Typography>
        </Grid>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{
            borderWidth: 1,
            background: colors.gray,
            display: { xs: "none", md: "block" },
          }}
        />
      </Grid>
    </footer>
  );
}
