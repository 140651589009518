export const saveValueStorage = (key: string, objValue: any) => {
  const rest = getValueStorage(key);
  const obj = JSON.stringify({ ...rest, ...objValue });

  localStorage.setItem(key, obj);
};

export const getValueStorage = (key: string): any => {
  const result = localStorage.getItem(key);
  const data = result ? JSON.parse(result) : null;

  return data;
};

export const wipeStorage = () => localStorage.clear();

export const wipeValueStorage = (key: string) => localStorage.removeItem(key);
