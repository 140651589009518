import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import { getValueStorage, saveValueStorage } from "../../../common/storage.ts";
import {
  JSONToForm,
  requestSquematic,
} from "../../../common/CommonFunctions.tsx";
import { errorAlert, Modal, MySwal } from "../../../common/alerts.tsx";
import {
  LocationRequestComponent,
  VideoCameraRequestComponent,
} from "../../../constants/permissions.tsx";
import { CaptureModal } from "../../../components/Modals.tsx";
import { IRegisterStepState } from "../RegisterComponent.tsx";

export default function ActiveVC({ setRegisterStep }: IRegisterStepState) {
  const { t } = useTranslation();
  const { setIsLoading } = React.useContext(AuthContext);
  const iframeRef = React.useRef(null);
  const [camera, setCamera] = React.useState(false);
  const [location, setLocation] = React.useState({
    latitude: "",
    longitude: "",
  });

  const getUser = getValueStorage("authSession");

  const { registerForVideoCamera } = VideoCameraRequestComponent();
  const { registerForLocation } = LocationRequestComponent();
  const fetchCameraAndSetupListener = async () => {
    setIsLoading(true);
    const stream = await registerForVideoCamera({setIsLoading});
    if (stream) {
      setCamera(true);
    }
  };

  React.useEffect(() => {
    setIsLoading(true);
    (async () => {
      await registerForLocation({ setIsLoading }).then(async (loc) => {
        if (loc) {
          try {
            setLocation({
              latitude: loc.latitude + "",
              longitude: loc.longitude + "",
            });
            // Si se obtiene la ubicación, se envian los permisos de la camara y micrófono
            fetchCameraAndSetupListener();
          } catch (error) {
            errorAlert("Error", "Ubicación fallida", "");
          }
        }
      });
    })();
  }, []);

  // Antes de enviar los mensajes, se debe verificar que el usuario tenga la ubicación
  React.useEffect(() => {
    // Agrega el listener cuando el componente se monta para resivir los mensajes
    window.addEventListener("message", handleMessage);

    // Remueve el listener cuando el componente desmonta
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [location]);

  const meetingID = getUser.hasVideoCall.c155_url;
  const url = `https://d32ifhfkfh157f.cloudfront.net/?meetingId=${meetingID}`;

  const handleMessage = (event) => {
    // Verifica el origen del mensaje para mayor seguridad
    if (event.origin + "/?meetingId=" + event.data.id !== url) return;

    const message = event.data.action;

    if (message === "camera_on") {
      // Esperamos que llegue el mensaje de que la cámara está encendida y esperamos 1 segundo
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else if (message.includes("salir")) {
      // Si la asesora finalizo la llamada
      let obj = {
        prevStep: 22,
        nextStep: 23,
        desc: "La asesora finalizo la llamada",
      };
      saveValueStorage("registerStep", obj);
      if (setRegisterStep) setRegisterStep(obj);
    } else if (message === "image") {
      // Si el usuario envio la foto
      convertBase64ToFile(
        event.data.data,
        getUser.hasVideoCall.c155_tipo === "1"
          ? `house_${getUser.hasVideoCall.c155_rowid_prestamo}.webp`
          : `company_${getUser.hasVideoCall.c155_rowid_prestamo}.webp`
      )
        .then(async (file) => {
          try {
            const formData = JSONToForm({
              c200_rowid: getUser.loanRequest?.c200_rowid,
              house_image: file,
            });

            let url =
              getUser.hasVideoCall.c155_tipo === "1"
                ? "/api/app/contact_details/save_house_img"
                : "/api/app/financial_data/save_company_img";

            const resVC = await requestSquematic(
              "POST",
              url,
              formData,
              getUser.token
            );
            if (resVC.status === "OK") {
              getUser.hasVideoCall.c155_lat = location.latitude ?? "";
              getUser.hasVideoCall.c155_lon = location.longitude ?? "";

              await requestSquematic(
                "POST",
                "/api/panel/vc_validations/save_latlon_videocall",
                getUser.hasVideoCall,
                getUser.token
              );
              setIsLoading(false);
            } else {
              setIsLoading(false);
              errorAlert(`${t("common.error")}`, `${t("activeVC.noSend")}`, "");
            }
          } catch (error) {
            setIsLoading(false);
            errorAlert(`${t("common.error")}`, `${t("activeVC.noSend")}`, "");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          const errorMessage =
            error instanceof Error ? error.message : String(error);
          errorAlert(`${t("common.error")}`, errorMessage, "");
        });
    }
  };

  // Convierte la imagen en base64 a un archivo
  const convertBase64ToFile = async (base64: string, fileName: string) => {
    const base64Response = base64.split(",")[1];
    const byteCharacters = atob(base64Response);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const file = new File([byteArray], fileName, { type: "image/webp" });

    // Genera una URL para el archivo
    const url = URL.createObjectURL(file);

    Modal(<CaptureModal img={url} onClose={() => MySwal.close()} setIsLoading={(setIsLoading)} />, {
      title: "",
      showCloseButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      background: "transparent",
      customClass: {
        popup: 'modalCapture'
      },
    });


    return file;
  };

  return (
    <Box>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        {/* Iframe */}
        <Box
          sx={{
            width: "100%",
            height: 500,
            background: colors.silver,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <iframe
            id="video"
            ref={iframeRef}
            src={camera ? url : ""}
            width="100%"
            height="100%"
            style={{ border: "none" }}
            title={t("activeVC.inProgress")}
            allow="camera; microphone"
          />
        </Box>

        <Typography
          sx={{
            fontSize: 12,
            color: colors.black,
            lineHeight: 1.3,
            marginBottom: "20px",
            textAlign: "justify",
            mt: 2,
          }}
        >
          {t("activeVC.info")}
          <strong> {t("activeVC.info2")} </strong>
        </Typography>
      </Box>
    </Box>
  );
}
