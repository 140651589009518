import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../hooks/useAuth.tsx";
import { getValueStorage, saveValueStorage } from "../../../../common/storage.ts";
import {
  ISimulatorData,
  IUserAuth,
  navigationModals,
} from "../../../../constants/types.tsx";
import { LocationRequestComponent } from "../../../../constants/permissions.tsx";
import {
  formatDateWithCapitalizedMonth,
  formatNumberPoint,
  getPeriodPayDesc,
  obtainIdLoanState,
  requestSquematic,
} from "../../../../common/CommonFunctions.tsx";
import { colors } from "../../../../styles/theme.tsx";
import { errorAlert, Modal, MySwal } from "../../../../common/alerts.tsx";
import RenovationSimulator from "../../../../components/simulators/RenovationSimulator.tsx";
import { KeyboardBackspaceIcon } from "../../assets/iconsMui.tsx";
import LoadingRequest from "../../../LoadingRequest.tsx";

export default function RenovationConfirmLoan({
  onClose,
  setIsLoading,
}: navigationModals) {
  const { t } = useTranslation();
  const { signOut, signIn } = React.useContext(AuthContext);
  const simulationData: ISimulatorData = getValueStorage(
    "renovationSimulation"
  );
  const { registerForLocation } = LocationRequestComponent();

  const handleGoBack = () => {
    MySwal.close();
    Modal(
      <RenovationSimulator onClose={onClose} setIsLoading={setIsLoading} />,
      {
        title: "",
        width: "auto",
        showCloseButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          popup: "bg-snow dark:bg-navy-800 px-0 !w-max sm:!w-[380px]",
          htmlContainer: "!px-3 !overflow-x-hidden",
        },
      }
    );
  };

  React.useEffect(() => {
    // Función para manejar el evento de recarga o cierre
    const handleBeforeUnload = (event) => {
      // Evita la acción predeterminada
      event.preventDefault();
      event.returnValue = "";
    };

    // Agrega el listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Limpia el listener al desmontar el componente
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  // Actualizar el token
  const authSession: IUserAuth = getValueStorage("authSession");

  //Solicitar el credito
  const handleRequest = async () => {
    setIsLoading(true);

    const loc = await registerForLocation({ setIsLoading }); // Espera a obtener la ubicación
    if (loc) {
      //Hacemos de nuevo la simulacion para almacenar el prestamo      
      const res = await requestSquematic(
        "POST",
        "/api/app/loan/loan_simulate",
        {
          c200_valor: simulationData.c200_valor,
          c200_rowid_concepto: simulationData.c200_rowid_concepto,
          c200_plazo: simulationData.c200_plazo,
          c200_rowid_periodo: simulationData.c200_rowid_periodo,
          c200_rowid_ciiu: simulationData.c200_rowid_ciiu,
        },
        authSession.token,
        signOut
      );

      if (res && res.results) {
        //Si todo sale bien actualizamos el estado del credito a solicitud
        const data = await requestSquematic(
          "POST",
          "/api/app/loan/set_loan_state",
          {
            c200_rowid: res.results.loan.c200_rowid,
            c200_ind_estado: obtainIdLoanState("requested"),
            c200_lat: loc.latitude,
            c200_lon: loc.longitude,
          },
          authSession.token,
          signOut
        );

        if (data) {
          try {
            const objToSendRenovation = {
              c200_rowid_new: res.results.loan.c200_rowid + "",
              c200_roiwd_old:
                getValueStorage("dataLoans").loans[0].c200_rowid + "",
            };

            const data = await requestSquematic(
              "POST",
              "/api/app/loan/renovate_loan",
              objToSendRenovation,
              authSession.token
            );

            if (data) {
              let obj = {
                ...authSession,
                loanActive: {
                  ...authSession.loanActive,
                  c200_rowid: objToSendRenovation.c200_roiwd_old,
                  c204_paso_renovacion: "",
                  c200_rowid_nuevo_prestamo: objToSendRenovation.c200_rowid_new,
                  c200_ind_estado: "11",
                },
                loanRequest: {
                  ...authSession.loanRequest,
                  c200_rowid: objToSendRenovation.c200_rowid_new,
                  c204_paso_renovacion: "",
                  c200_rowid_nuevo_prestamo: "",
                  c200_ind_estado: "1",
                },
                inProcessRenovation: {
                  ...authSession.inProcessRenovation,
                  inProcessRenovation: true,
                },
              };

              signIn(obj);
              saveValueStorage("authSession", obj);

              // Borro la notificacion
              await onClose();

              Modal(<LoadingRequest setIsLoading={setIsLoading} />, {
                title: "",
                width: "auto",
                showCloseButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                customClass: {
                  popup: "bg-snow dark:bg-navy-800 px-0 !w-max sm:!w-[380px]",
                  htmlContainer: "!px-3 !overflow-x-hidden",
                },
              });
            }
          } catch (error) {
            const errorMessage =
              error instanceof Error ? error.message : String(error);
            errorAlert("Error", errorMessage, "");
          } finally {
            setIsLoading(false);
          }
        }
      }
    }

    setIsLoading(false);
  };

  return (
    <>
      <div className="relative">
        <button
          className="flex flex-row gap-1 items-center text-secondary-800 hover:opacity-80 dark:text-primary  "
          onClick={handleGoBack}
        >
          <KeyboardBackspaceIcon />
          <p className="text-sm ">{t("common.back")}</p>
        </button>
      </div>

      {/* Resumen del crédito */}
      <div className="w-[98%] pt-8  overflow-x-hidden mx-auto px-4 relative">
        <Box display={"flex"} flexDirection={"column"} alignItems={"left"}>
          <Typography
            className="text-secondary-800	dark:text-white"
            sx={{
              fontWeight: 600,
              fontSize: 20,
              marginBottom: "5px",
            }}
            lineHeight={1}
            textAlign={"center"}
          >
            {t("confirmLoan.title")}
          </Typography>
        </Box>
        {/* Contenido */}
        <Box display={"flex"} flexDirection={"column"} marginTop={1}>
          {/* Tarjetas */}
          <Grid
            container
            spacing={1.2}
            sx={{ height: "auto", alignItems: "stretch" }}
          >
            <Grid
              size={12}
              className="bg-white dark:!bg-navy-600"
              sx={{
                minHeight: 67,
                borderRadius: "18px",
                boxShadow: "0px 20px 27px 0px #0000000D",
                padding: { xs: "10px 20px", md: "10px 40px" },
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Box>
                <Typography
                  className="text-secondary-800	dark:text-white"
                  sx={{
                    fontSize: 15,
                  }}
                  lineHeight={1}
                >
                  {t("confirmLoan.requestedValue")}
                </Typography>

                <Typography
                  sx={{
                    fontSize: 20,
                    color: colors.primary,
                    marginTop: "2px",
                    fontWeight: 600,
                  }}
                  lineHeight={1}
                >
                  $
                  {formatNumberPoint(
                    parseInt(simulationData?.c200_valor ?? "0")
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid
              size={12}
              className="bg-white dark:!bg-navy-600"
              sx={{
                minHeight: 67,
                borderRadius: "18px",
                boxShadow: "0px 20px 27px 0px #0000000D",
                padding: { xs: "10px 20px", md: "10px 40px" },
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Box>
                <Typography
                  className="text-secondary-800	dark:text-white"
                  sx={{
                    fontSize: 15,
                  }}
                  lineHeight={1}
                >
                  {t("confirmLoan.dueValue")}
                </Typography>

                <Typography
                  sx={{
                    fontSize: 20,
                    color: colors.primary,
                    marginTop: "2px",
                    fontWeight: 600,
                  }}
                  lineHeight={1}
                >
                  $
                  {formatNumberPoint(
                    parseInt(simulationData?.due_value ?? "0")
                  )}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {/* Importante */}
          <Box mt={5} mb={3}>
            <Typography
              sx={{
                fontSize: 16,
                color: colors.primary,
                fontWeight: "bold",
                textAlign: "left",
              }}
              lineHeight={1}
            >
              * {t("confirmLoan.important")}
            </Typography>
            <Typography
              className="text-secondary-800	dark:text-white"
              sx={{
                fontSize: 15,
                textAlign: "left",
              }}
              mt={0.5}
              lineHeight={1.1}
            >
              {t("confirmLoan.importantDesc")}
            </Typography>
          </Box>
          <Divider className="bg-gray dark:bg-primary" />
          {/* Metodos */}
          <Box my={2.5} sx={{ textAlign: "left" }}>
            <Box>
              <Typography
                className="text-secondary-800 dark:text-white"
                sx={{
                  fontSize: 15,
                }}
                lineHeight={1}
              >
                {t("simulator.paymentMethod")}
              </Typography>

              <Typography
                sx={{
                  fontSize: 17,
                  color: colors.primary,
                  marginTop: "3px",
                  fontWeight: 600,
                }}
                lineHeight={1}
              >
                {getPeriodPayDesc(simulationData.c200_rowid_periodo)}
              </Typography>
            </Box>
            <Box mt={2.5}>
              <Typography
                className="text-secondary-800	dark:text-white"
                sx={{
                  fontSize: 15,
                }}
                lineHeight={1}
              >
                {t("confirmLoan.duesToPay")}
              </Typography>

              <Typography
                sx={{
                  fontSize: 17,
                  color: colors.primary,
                  marginTop: "3px",
                  fontWeight: 600,
                }}
                lineHeight={1}
              >
                {simulationData?.c200_plazo ?? "n/a"}
              </Typography>
            </Box>
            <Box mt={2.5}>
              <Typography
                className="text-secondary-800	dark:text-white"
                sx={{
                  fontSize: 15,
                }}
                lineHeight={1}
              >
                {t("confirmLoan.debtValue")}
              </Typography>

              <Typography
                sx={{
                  fontSize: 17,
                  color: colors.primary,
                  marginTop: "3px",
                  fontWeight: 600,
                }}
                lineHeight={1}
              >
                $
                {formatNumberPoint(
                  parseInt(simulationData?.total_due_to_pay ?? "0")
                )}
              </Typography>
            </Box>
            <Box mt={2.5}>
              <Typography
                className="text-secondary-800	dark:text-white"
                sx={{
                  fontSize: 15,
                }}
                lineHeight={1}
              >
                {t("confirmLoan.nextPaymentDate")}
              </Typography>

              <Typography
                sx={{
                  fontSize: 17,
                  color: colors.primary,
                  marginTop: "3px",
                  fontWeight: 600,
                }}
                lineHeight={1}
              >
                {formatDateWithCapitalizedMonth(
                  simulationData?.date_first_due ?? new Date().toISOString()
                )}
              </Typography>
            </Box>
          </Box>
          <Divider className="bg-gray dark:bg-primary" />
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            marginTop={5}
          >
            <button
              className="w-64 rounded-lg bg-primary py-3 px-2 text-white font-bold text-base hover:opacity-80 "
              onClick={handleRequest}
            >
              {t("simulator.request")}
            </button>
          </Box>
        </Box>
      </div>
    </>
  );
}
