import { Box, Divider, styled, Typography } from "@mui/material";
import React from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { colors } from "../../../../styles/theme.tsx";
import { ChevronRightIcon } from "../../assets/iconsMui.tsx";
import DoPay, { doPayProps } from "../Payments/DoPay.tsx";
import { Modal } from "../../../../common/alerts.tsx";
import {
  Loan,
  PropItemsLoanInit,
  loans,
  navigationModals,
} from "../../../../constants/types.tsx";
import {
  formatNumberPoint,
  getPercentCredit,
} from "../../../../common/CommonFunctions.tsx";
import { ChooseCredit } from "./ChooseCredit.tsx";
import { useTranslation } from "react-i18next";
import { getValueStorage } from "../../../../common/storage.ts";

type DuesDetailsProps = Loan & doPayProps & navigationModals;

const PERCENT_LIMIT = 70;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: colors.primary,
    ...theme.applyStyles("dark", {
      backgroundColor: "#308fe8",
    }),
  },
}));

const BorderLinear = ({ value, ...props }) => {
  return (
    // Barra deprogreso
    <Box position="relative" width="100%">
      <BorderLinearProgress
        title={`Porcentaje pagado: ${value}%`}
        variant="determinate"
        value={value}
        {...props}
      />
      <Box
        position="absolute"
        top={0}
        bottom={0}
        left={"-32px"}
        display="flex"
        alignItems="center"
        justifyContent="right"
        className="dark:text-white"
        sx={{
          fontWeight: "bold",
          fontSize: 11,
          width: "30px",
        }}
      >
        {`${(value + "").split(".")[0]}%`}
      </Box>
      <Box
        className="top-[-12px] sm:top-[-20px]"
        sx={{
          position: "absolute",
          left: "70%",
          transform: "translateX(-50%)",
        }}
      >
        <img
          className="w-[25px] sm:w-[35px]"
          src={require("../../assets/images/Estrella.webp")}
          alt=""
        />
        <p className="text-secondary-800 dark:text-white font-normal text-sm">
          70%
        </p>
      </Box>
    </Box>
  );
};

export const RenovationInfoLoan = ({
  loan,
  getUser,
  setIsLoading,
  loanLocal,
  signOut,
  onClose,
}: DuesDetailsProps) => {
  const { t } = useTranslation();
  const [percentVal, setPercentVal] = React.useState(0);
  const [toLimit, setToLimit] = React.useState(0);
  const loans: loans = getValueStorage("loans");
  const [valueParcialPay, setValueParcialPay] = React.useState<number>(0);

  React.useEffect(() => {
    if (!loan) return;
    if (loan) {
      const { valToLimit, percentVal } = getPercentCredit(loan, PERCENT_LIMIT);
      /* setToLimit(
        valToLimit < parseInt(loan.minDue) ? parseInt(loan.minDue) : valToLimit
      ); */
      setToLimit(valToLimit);
      setPercentVal(percentVal);
    }
    if (loans?.parcial_payment?.c260_ind_estado !== "9") {
      setValueParcialPay(
        parseInt(loans?.parcial_payment?.c260_valor_acumulado ?? "0")
      );
    }
  }, []);
  

  //Realizar un pago
  const doPay = () => {
    Modal(
      <DoPay
        setIsLoading={setIsLoading}
        getUser={getUser}
        loanLocal={loanLocal}
        signOut={signOut}
      />,
      {
        title: "",
        width: 800,
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "bg-snow dark:bg-navy-800",
          htmlContainer: "!px-3 !overflow-x-hidden",
        },
      }
    );
  };

  // Solicitar renovacion
  const chooseCredit = () => {
    Modal(<ChooseCredit onClose={onClose} setIsLoading={setIsLoading} />, {
      title: "",
      width: "auto",
      showCloseButton: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        popup: "bg-snow dark:bg-navy-800 px-0 !w-max sm:!w-[380px]",
        htmlContainer: "!px-3 !overflow-x-hidden",
      },
    });
  };

  return (
    <>
      <p className="text-primary font-bold text-lg uppercase">
        {t("renovationInfoLoan.loanToRenew")}
      </p>
      <div className="flex justify-center mt-5">
        <img
          className="w-[100px] rounded-full"
          src={require("../../assets/images/BolsaDinero.webp")}
          alt=""
        />
      </div>
      <div className="my-5">
        <p className="text-primary font-bold text-base ">
          {t("renovationInfoLoan.creditProgress")}
        </p>
        <p className="text-secondary-800 dark:text-white font-normal text-sm">
          {percentVal < PERCENT_LIMIT
            ? t("renovationInfoLoan.onTheWayToRenew")
            : t("renovationInfoLoan.requestRenewalNow")}
        </p>
      </div>
      <div className="px-4 mt-6">
        <BorderLinear value={percentVal} />
      </div>
      <div className="mt-8">
        <div className="text-left ">
          <p className="uppercase text-xs font-medium text-secundary-800 dark:text-white leading-4 sm:leading-[inherit]">
            {t("noCredits.info")}
          </p>
          <p className="uppercase text-base font-semibold text-primary leading-4 sm:leading-[inherit]">
            {t("renovationInfoLoan.renewal")}
          </p>
        </div>
      </div>
      <Divider className="bg-gray dark:bg-primary" sx={{ my: 2 }} />
      <div className="grid gap-2">
        {/* Pago para solicitar renovación */}
        {percentVal < PERCENT_LIMIT && (
          <button
            className="flex grid grid-cols-12 bg-white dark:!bg-navy-600 w-[98%] mx-auto py-3 px-4 rounded-lg shadow-md dark:text-white dark:shadow-none hover:opacity-80 "
            onClick={doPay}
          >
            <div className="h-full col-span-8 flex flex-col items-start justify-center ml-3">
              <p className="text-left text-sm font-semibold leading-4 dark:text-secondary-500">
                {t("renovationInfoLoan.paymentForRenewal")}
              </p>
              <p className="text-sm font-semibold mt-1 text-primary">
                ${formatNumberPoint(Math.ceil(toLimit))}
              </p>
            </div>
            <div className="h-full col-span-4 flex items-center justify-end">
              <p className="text-sm font-semibold leading-4dark:text-white">
                {t("doPay.pay")}
              </p>
              <ChevronRightIcon />
            </div>
          </button>
        )}
        {/* Pago para finalizar tu crédito */}
        <div className="cursor-default flex grid grid-cols-12 bg-white dark:!bg-navy-600 w-[98%] mx-auto py-3 px-4 rounded-lg shadow-md dark:text-white dark:shadow-none ">
          <div className="h-full col-span-12 flex flex-col items-start justify-center ml-3">
            <p className="text-left text-sm font-semibold leading-4 dark:text-secondary-500">
              {t("renovationInfoLoan.paymentToCloseCredit")}
            </p>
            <p className="text-sm font-semibold mt-1 text-primary">
              $
              {formatNumberPoint(
                parseInt(loan.value_to_paid) - valueParcialPay
              )}
            </p>
          </div>
        </div>
        {loan.c200_ind_estado === "5" ? (
          // Solicitar renovación
          <div className="flex flex-col items-center justify-center my-6">
            <button
              className={`uppercase w-fit leading-4 rounded-lg py-3 px-8 sm:px-16 text-white font-bold text-base h${
                percentVal < PERCENT_LIMIT
                  ? "hover:opacity-100 bg-gray"
                  : "hover:opacity-75 bg-primary"
              }`}
              disabled={percentVal < PERCENT_LIMIT}
              onClick={chooseCredit}
            >
              {t("renovationInfoLoan.requestRenewal")}
            </button>
            <p className="text-sm font-semibold leading-4 dark:text-secondary-500 px-8 sm:px-16 mt-2">
              {t("renovationInfoLoan.renewalUnlockInfo")}
            </p>
          </div>
        ) : loan.c200_ind_estado + "" === "11" &&
          !loan.c200_rowid_nuevo_prestamo ? (
          // Ha sido reachazada
          <div>
            <p className="text-sm font-semibold leading-4 dark:text-secondary-500 px-5 sm:px-16 my-6">
              {t("renovationInfoLoan.previousRenewalRejected")}
            </p>
          </div>
        ) : (
          <div>
            {/* Se encuentra en proceso */}
            <p className="text-sm font-semibold leading-4 dark:text-secondary-500 px-8 my-6">
              {t("renovationInfoLoan.renewalInProcess")}
            </p>
          </div>
        )}
      </div>
    </>
  );
};
