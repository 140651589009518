import React from "react";

import UploadFile from "../../../components/UploadFile.tsx";
import { IRegisterStepState } from "../RegisterComponent.tsx";
import { navigationModals } from "../../../constants/types.tsx";

export type workDocsProps = IRegisterStepState & navigationModals;

export default function WorkDocs({
  setIsLoading,
  setRegisterStep,
}: workDocsProps) {
  return (
    <>
      <UploadFile setRegisterStep={setRegisterStep} setIsLoading={setIsLoading} />
    </>
  );
}
