import React, { useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";

interface Props {
  duration?: number;
  children: React.ReactNode;
  center?: boolean;
  style?: React.CSSProperties;
}

const FadeAnim: React.FC<Props> = ({ children, duration = 1000, center, style }) => {
  // useSpring hook to control opacity
  const styles = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration },
  });

  return (
    <animated.div
      style={{
        ...styles,
        display: "flex",
        justifyContent: center ? "center" : "flex-start",
        ...style,
      }}
    >
      {children}
    </animated.div>
  );
};

export default FadeAnim;
