import Swal from "sweetalert2";
// import Swal from "sweetalert2/dist/sweetalert2.js";
import withReactContent, {
  ReactSweetAlertOptions,
} from "sweetalert2-react-content";
import { colors } from "../styles/theme.tsx";
import i18n from "../constants/translations/i18n.js";
import { ReactElement } from "react";
import { getValueStorage } from "./storage.ts";

export const MySwal = withReactContent(Swal);

export const infoAlert = (
  title: string,
  footer: string | ReactElement<any, any>
) =>
  MySwal.fire({
    title,
    icon: "info",
    footer,
  });

export const successAlert = (title: string) =>
  MySwal.fire({
    title,
    icon: "success",
    showConfirmButton: false,
    timer: 1500,
  });

export const errorAlert = (title: string, text: string, footer: any) =>
  MySwal.fire({
    icon: "error",
    title,
    text,
    footer,
  });

export const confirmationAlert = async (
  title: string,
  text: string,
  confirmButtonText: string
): Promise<boolean> => {
  const res = await MySwal.fire({
    title,
    text,
    icon: "warning",
    confirmButtonColor: colors.primary,
    cancelButtonColor: colors.red,
    confirmButtonText,
    cancelButtonText: i18n.t("common.cancel"),
    showCancelButton: true,
    denyButtonText: i18n.t("common.cancel"),
    customClass: {
      popup: `bg-snow dark:bg-navy-800 text-secundary-800 dark:text-white`,
    },
  });

  return res.isConfirmed;
};

export const Modal = (
  children: JSX.Element,
  options: ReactSweetAlertOptions
) => {
  MySwal.fire({
    ...options,
    html: children,
  });
};

export const ModalDashboard = ({ element, widthParam = "380px", close = true }: { element: React.JSX.Element, widthParam?: string, close?: boolean }) => {

  // Width para ajustar modales de paso 0  
  const widthSize = getValueStorage("authSession").loanRequest.c204_paso_renovacion && JSON.parse(
    getValueStorage("authSession").loanRequest.c204_paso_renovacion
  ).paso === "0" ? "480px" : widthParam;

  MySwal.fire({
    ...{
      title: "",
      width: widthSize,
      showCloseButton: close,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      customClass: {
        popup: `bg-snow dark:bg-navy-800 px-0 sm:w-auto`,
        htmlContainer: "!px-3 !overflow-x-hidden",
      },
    },
    html: element,
  });
};
