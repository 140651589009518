import * as Yup from "yup";

export const yupAddressValidations = Yup.object().shape({
  home_address: Yup.string()
    .when(["hasVideoCalll"], ([hasVideoCalll], schema) => {
      // Si getUser.hasVideoCall.c155_rowid_estado + "" === "4"      
      return hasVideoCalll 
        ? schema.required("El campo es obligatorio")
        : schema.notRequired();
    })
    .matches(
      /^(?! )[a-zA-Z0-9#\-.,/()]+(?: [a-zA-Z0-9#\-.,/()]+)*$/,
      "Formato de dirección inválida"
    )
    .test(
      "no-prueba-admin",
      "El campo no puede contener 'prueba' o 'admin'",
      (value) => {
        return (
          !value ||
          !value
            .split(" ")
            .some(
              (word) =>
                word.toLowerCase() === "prueba" ||
                word.toLowerCase() === "admin"
            )
        );
      }
    ),
  home_address_description: Yup.string()
    .when(["hasVideoCalll"], ([hasVideoCalll], schema) => {
      // Si getUser.hasVideoCall.c155_rowid_estado + "" === "4"
      return hasVideoCalll 
        ? schema.required("El campo es obligatorio")
        : schema.notRequired();
    })
    .matches(
      /^(?! )[a-zA-Z0-9#\-.,/()]+(?: [a-zA-Z0-9#\-.,/()]+)*$/,
      "Valida los caracteres y espacios"
    )
    .test(
      "no-prueba-admin",
      "El campo no puede contener 'prueba' o 'admin'",
      (value) => {
        return (
          !value ||
          !value
            .split(" ")
            .some(
              (word) =>
                word.toLowerCase() === "prueba" ||
                word.toLowerCase() === "admin"
            )
        );
      }
    ),
});
