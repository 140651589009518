import React from "react";
import { requestSquematic } from "../../../../common/CommonFunctions.tsx";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { colors } from "../../../../styles/theme.tsx";
import { useTranslation } from "react-i18next";

export default function FormsInputLocation({
  names,
  setChanges,
  id,
  isLoading,
  setValids,
  setIsLoading,
  token,
}) {
  const { t } = useTranslation();
  const [location, setLocation] = React.useState({
    city: "",
    department: "",
    country: "169",
    locality: "",
    neighborhood: "",
    name: "",
  });
  const [departmentList, setDepartmentList] = React.useState<any[]>([]);
  const [departmentSelected, setDepartmentSelected] = React.useState<{ id: number; value: string } | null>(null);
  const [cityList, setCityList] = React.useState<any[]>([]);
  const [citySelected, setCitySelected] = React.useState<{ id: number; value: string } | null>(null);
  const [localityList, setLocalityList] = React.useState<any[]>([]);
  const [localitySelected, setLocalitySelected] = React.useState<{ id: number; value: string } | null>(null);
  const [neighList, setNeighList] = React.useState<any[]>([]);
  const [neighSelected, setNeighSelected] = React.useState<{ id: number; value: string } | null>(null);
  const [isModalVisible, setModalVisible] = React.useState(false);
  const [nothing, setNothing] = React.useState(false);

  const fetchDepartments = async () => {
    setIsLoading(true);

    const data = await requestSquematic(
      "GET",
      "/api/panel/geographic_location/get_departments",
      {
        c102_id_pais: "169",
      },
      token
    );

    if (data) {
      setDepartmentList(data
        .results!.map((deparments: any) => ({
          id: deparments.c102_id,
          value: deparments.c102_descripcion,
        }))
        .sort((a: any, b: any) => a.value.localeCompare(b.value)));
    }
    setIsLoading(false);
  };

  const fetchCities = async (department: string) => {
    setIsLoading(true);

    const data = await requestSquematic(
      "GET",
      "/api/panel/geographic_location/get_cities",
      {
        c103_id_pais: "169",
        c103_id_depto: department,
      },
      token
    );

    if (data) {
      setCityList(data.results!.map((deparments: any) => ({
        id: deparments.c103_id,
        value: deparments.c103_descripcion,
      })));
    }

    setIsLoading(false);
  };

  const fetchBarrios = async (department: string, city: string) => {
    setIsLoading(true);

    const data = await requestSquematic(
      "GET",
      "/api/panel/neighborhood/get_by_location",
      {
        c104_id_pais: "169",
        c104_id_depto: department,
        c104_id_ciudad: city,
      },
      token
    );

    if (data) {
      const filteredResults = data!.results.filter(
        ({ c104_ind_estado }: any) => c104_ind_estado?.trim() === "1"
      );
      // Si no hay barrios, se muestra un mensaje de alerta
      if (filteredResults.length === 0) {
        setNeighList([]);
        setNothing(true);
      } else {
        setNeighList(
          filteredResults
            .map(({ c104_id, c104_descripcion }: any) => ({
              id: c104_id.toString().trim(),
              value: c104_descripcion,
            }))
            .sort((a: any, b: any) =>
              a.value < b.value ? -1 : a.value > b.value ? 1 : 0
            )
        );
      }
    }

    setIsLoading(false);
  };

  const fetchLocalitiesBogota = async (department: string, city: string) => {
    setIsLoading(true);

    const data = await requestSquematic(
      "GET",
      "/api/panel/locality/get_by_location",
      {
        c108_id_pais: "169",
        c108_id_depto: department,
        c108_id_ciudad: city,
      },
      token
    );

    if (data) {
      setLocalityList(data.results!.map((locality: any) => ({
        id: locality.c106_rowid,
        value: locality.c106_descripcion,
        neighs: locality.communes_by_locality.map(({ commune }: any) =>
          commune.neighborhoods_by_commune.map(
            ({ neighborhood }: any) => neighborhood
          )
        ),
      })));
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    setValids((state: any) => ({
      ...state,
      [id]:
        location.city !== "" &&
        location.department !== "" &&
        (names[3] ? location.neighborhood !== "" : true),
    }));
  }, [location, setLocation]);

  const handleChange = React.useCallback(
    (value: any) => {
      setLocation((state) => ({ ...state, ...value }));
      setChanges((state: any) => ({
        ...state,
        [id]: { ...state[id], ...value },
      }));
    },
    [setLocation]
  );

  React.useEffect(() => {
    setValids((state: any) => ({
      ...state,
      [id]: false,
    }));

    fetchDepartments();
    handleChange({ country: "169", department: "", city: "" });
    setDepartmentSelected(null);
    setCitySelected(null);
    setLocalitySelected(null);
    setNeighSelected(null);
  }, []);

  return (
    <Box style={{ marginTop: 1, zIndex: 20 }}>
      {/* DEPARTAMENTO */}
      <label
        style={{
          fontSize: 13,
          fontWeight: 600,
          textWrap: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
        htmlFor={id}
      >
        {t(names[1])}
      </label >
      <Autocomplete
        options={departmentList}
        getOptionLabel={(option) => option?.value}
        value={departmentSelected}
        onChange={(event, newValue) => {
          if (!newValue) return;

          fetchCities(newValue.id);
          setCityList([]); setNeighList([]); setNothing(false);
          setValids((state: any) => ({
            ...state,
            c125_id_city_yo: newValue.id !== "",
          }));
          handleChange({ department: newValue.id, city: "", neighborhood: "", locality: "" });
          setLocation({ ...location, department: newValue.id });

          setDepartmentSelected(newValue);
          setCitySelected(null);
          setLocalitySelected(null);
          setNeighSelected(null);

          if (newValue.id !== "") {
            let element = document.getElementById(`errorM_${id}_department`);
            if (element) {
              element.style.display = "none";
            }
          }
        }}
        disabled={isLoading || departmentList.length === 0}
        onInputChange={(event, inputValue) => {
          // Si el valor ingresado no tiene una coincidencia, limpiar el campo en Formik
          const matchedOption = departmentList.find((option) => option?.value === inputValue);
          if (!matchedOption) {
            setLocation({ ...location, department: "" });
            setDepartmentSelected(null);
          }
        }}
        noOptionsText={t("common.empty")}
        renderInput={(params) => (
          <TextField
            {...params}
            name={id}
            disabled={isLoading}
            placeholder={departmentList.length === 0 ? "" : "Seleccione"}
            inputProps={{
              ...params.inputProps,
              readOnly: !!location.department, // Si hay una selección, el campo será de solo lectura
            }}
          />
        )}
      />
      <Typography id={`errorM_${id}_department`} sx={{ color: "red", fontSize: 13, marginTop: 1, fontWeight: "bold", display: "none" }}></Typography>
      {/* CIUDAD */}
      <label
        style={{
          fontSize: 13,
          fontWeight: 600,
          textWrap: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          color: cityList.length === 0 ? colors.disabled : "black",
        }}
        htmlFor={id}
      >
        {t(names[2])}
      </label>
      <Autocomplete
        options={cityList}
        getOptionLabel={(option) => option?.value}
        value={citySelected}
        onChange={(event, newValue) => {
          if (!newValue) return;

          handleChange({ city: newValue.id, neighborhood: "", locality: "" });
          setNothing(false);
          if (
            location.department.toString() === "11" &&
            newValue.id.toString() === "1"
          ) {
            fetchLocalitiesBogota(location.department, newValue.id);
          } else {
            fetchBarrios(location.department, newValue.id);
          }
          setLocation({ ...location, city: newValue.id });
          setValids((state: any) => ({
            ...state,
            c125_id_depto_yo: newValue.id !== "",
          }));

          setCitySelected(newValue);
          setLocalitySelected(null);
          setNeighSelected(null);

          if (newValue.id !== "") {
            let element = document.getElementById(`errorM_${id}_city`);
            if (element) {
              element.style.display = "none";
            }
          }
        }}
        disabled={isLoading || location.department === "" || cityList.length === 0}
        onInputChange={(event, inputValue) => {
          // Si el valor ingresado no tiene una coincidencia, limpiar el campo en Formik
          const matchedOption = cityList.find((option) => option?.value === inputValue);
          if (!matchedOption) {
            setLocation({ ...location, city: "" });
            setCitySelected(null);
          }
        }}
        noOptionsText={t("common.empty")}
        renderInput={(params) => (
          <TextField
            {...params}
            name={id}
            disabled={isLoading}
            placeholder={cityList.length === 0 ? "" : "Seleccione"}
            inputProps={{
              ...params.inputProps,
              readOnly: !!location.city, // Si hay una selección, el campo será de solo lectura
            }}
          />
        )}
      />
      <Typography id={`errorM_${id}_city`} sx={{ color: "red", fontSize: 13, marginTop: 1, fontWeight: "bold", display: "none" }}></Typography>
      {names.length > 3 ? (
        location.department.toString() === "11" &&
          location.city.toString() === "1" ? (
          <>
            <label
              style={{
                fontSize: 13,
                fontWeight: 600,
                textWrap: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              htmlFor={id}
            >
              {t(names[3])}
            </label >
            <Autocomplete
              options={localityList}
              getOptionLabel={(option) => option?.value}
              value={localitySelected}
              onChange={(event, newValue) => {
                if (!newValue) return;

                handleChange({ locality: newValue.id });

                setValids((state: any) => ({
                  ...state,
                  neigh: newValue.id !== "",
                }));
                setLocalitySelected(newValue);
                if (newValue.id !== "") {
                  let element = document.getElementById(`errorM_${id}_neighborhood`);
                  if (element) {
                    element.style.display = "none";
                  }
                }
              }}
              disabled={isLoading || location.city === "" || localityList.length === 0}
              onInputChange={(event, inputValue) => {
                // Si el valor ingresado no tiene una coincidencia, limpiar el campo en Formik
                const matchedOption = localityList.find((option) => option?.value === inputValue);
                if (!matchedOption) {
                  setLocation({ ...location, locality: "" });
                  setLocalitySelected(null);
                }
              }}
              noOptionsText={t("common.empty")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={id}
                  disabled={isLoading}
                  placeholder={localityList.length === 0 ? "" : "Seleccione"}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: !!location.department, // Si hay una selección, el campo será de solo lectura
                  }}
                />
              )}
            />
            {nothing && (
              <Typography marginTop={-15} marginBottom={15}>
                * No hay barrios para esta zona
              </Typography>
            )}
            {location.name != "" && (
              <Typography marginBottom={15}>
                Barrio seleccionado: {location.name}
              </Typography>
            )}
            <Typography id={`errorM_${id}_neighborhood`} sx={{ color: "red", fontSize: 13, marginTop: 1, fontWeight: "bold", display: "none" }}></Typography>
          </>
        ) : (
          <>
            <label
              style={{
                fontSize: 13,
                fontWeight: 600,
                textWrap: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                color: cityList.length === 0 ? colors.disabled : "black",
              }}
              htmlFor={id}
            >
              {t(names[4])}
            </label>
            <Autocomplete
              options={neighList}
              getOptionLabel={(option) => option?.value}
              value={neighSelected}
              onChange={(event, newValue) => {
                if (!newValue) return;

                handleChange({ neighborhood: newValue.id });

                setValids((state: any) => ({
                  ...state,
                  neigh: newValue.id !== "",
                }));
                setNeighSelected(newValue);
                if (newValue.id !== "") {
                  let element = document.getElementById(`errorM_${id}_neighborhood`);
                  if (element) {
                    element.style.display = "none";
                  }
                }
              }}
              disabled={isLoading || location.city === "" || neighList.length === 0}
              onInputChange={(event, inputValue) => {
                // Si el valor ingresado no tiene una coincidencia, limpiar el campo en Formik
                const matchedOption = neighList.find((option) => option?.value === inputValue);
                if (!matchedOption) {
                  setLocation({ ...location, neighborhood: "" });
                  setNeighSelected(null);
                }
              }}
              noOptionsText={t("common.empty")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={id}
                  disabled={isLoading}
                  placeholder={neighList.length === 0 ? "" : "Seleccione"}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: !!location.neighborhood, // Si hay una selección, el campo será de solo lectura
                  }}
                />
              )}
            />
            {nothing && (
              <Typography marginTop={-15} marginBottom={15}>
                * No hay barrios para esta zona
              </Typography>
            )}
            {location.name != "" && (
              <Typography marginBottom={15}>
                Barrio seleccionado: {location.name}
              </Typography>
            )}
            <Typography id={`errorM_${id}_neighborhood`} sx={{ color: "red", fontSize: 13, marginTop: 1, fontWeight: "bold", display: "none" }}></Typography>
          </>
        )
      ) : null}
    </Box>
  );
};