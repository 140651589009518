const colors = {
  // Verdes
  primary: "#2ECC71",
  lightPrimary: "#D3F9D8",
  darkGreen: "#1DA76C",
  loading: "rgba(30, 66, 44, 0.5)",
  lightCyan: "#cbeedf",
  springGreen: "#00FF6C",

  // Grises
  gray: "#616161",
  lightGray: "#EEEFF0",
  dimGray: "#525F7F",
  darkGray: "#A1A1A1",
  darkSlateGray: "#333333",
  slateGray: "#67748E",
  silver: "#afafaf",
  blueGray: "#A0A9BA",

  // Blancos
  white: "#FFFFFF",
  bone: "#F5F7FC",

  // Negros
  black: "#000000",

  //Rojos
  red: "#FF0000",

  //Button disabled
  disabled: "#627595",
};

const sizes = {
  header: 80,
};

const shadow = {
  black: "0px 7px 41.5px -11px #00000040",
};

export { colors, sizes, shadow };
