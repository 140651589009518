import React from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { colors, sizes, shadow } from "../../styles/theme.tsx";
import { useTranslation } from "react-i18next";

export default function WhoAreWe() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          flex: "1",
          flexGrow: 1,
          marginTop: `${sizes.header}px`,
        }}
      >
        <Box
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
          flexDirection={"column"}
          sx={{ flexGrow: 1, width: { xs: "85%", xl: "70%" }, margin: "0 auto" }}
          mt={{ xs: "50px", lg: "150px" }}
        >
          <Grid
            sx={{
              background: colors.white,
              height: "auto",
              borderRadius: "18px",
              boxShadow: shadow.black,
              padding: { xs: "40px", sm: "70px" },
              position: "relative",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "100px",
            }}
          >
            <Box
              sx={{
                width: 136,
                height: 10,
                background: colors.primary,
                position: "absolute",
                top: "0",
                left: "50%",
                borderRadius: 2,
                transform: "translateX(-50%)",
              }}
            />
            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: 32, md: 43 },
                  color: colors.primary,
                  textTransform: "uppercase",
                }}
                lineHeight={1}
                mb={{ xs: "30px", lg: "50px" }}
                textAlign={"center"}
              >
                {t("whoAreWe.title")}
              </Typography>
              <Typography
                sx={{ fontSize: { xs: 20, md: 23 }, color: colors.gray }}
                lineHeight={1.3}
              >
                {t("whoAreWe.descriptionP1")}
                <br /><br />
                {t("whoAreWe.descriptionP2")}
                <br /><br />
                {t("whoAreWe.descriptionP3")}
                <br /><br />
                {t("whoAreWe.descriptionP4")}
                <br /><br />
                {t("whoAreWe.descriptionP5")}
                <br /><br />
                {t("whoAreWe.descriptionP6")}
              </Typography>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
